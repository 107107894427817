import { AppliedFilterInterface, FilterInterface } from '@sixriver/lighthouse-web-community';
import { useCallback } from 'react';

export interface FilterFactories {
	getPolarisFilterProps: () => FilterInterface;
	getPolarisAppliedFilterProps: () => AppliedFilterInterface | undefined;
}

export type OnFilterChanged<TFilterObject> = (filterObject: TFilterObject | undefined) => void;

export type OnFilterFieldValueChanged = (value: any) => void;

export type GetFilterFieldValue<TFilterObject> = (filterObject: TFilterObject | undefined) => any;

export type SetFilterFieldValue<TFilterObject> = (
	filterObject: TFilterObject | undefined,
	value: any,
) => TFilterObject | undefined;

export type GetPolarisFilterProps = (
	name: string,
	value: any,
	onChange: OnFilterFieldValueChanged,
) => Omit<FilterInterface, 'key' | 'label'>;

export function usePolarisFilterPropsFactories<TFilterObject>(
	filter: TFilterObject | undefined,
	onFilterChanged: OnFilterChanged<TFilterObject>,
	name: string,
	getPolarisFilterProps: GetPolarisFilterProps,
	getFilterFieldValue: GetFilterFieldValue<TFilterObject>,
	setFilterFieldValue: SetFilterFieldValue<TFilterObject>,
): FilterFactories {
	const onChange = useCallback(
		(value: unknown) => onFilterChanged(setFilterFieldValue(filter, value)),
		[filter, onFilterChanged, setFilterFieldValue],
	);
	const onRemove = useCallback(
		() => onFilterChanged(setFilterFieldValue(filter, undefined)),
		[filter, onFilterChanged, setFilterFieldValue],
	);

	return {
		getPolarisAppliedFilterProps: () => {
			const value = getFilterFieldValue(filter);
			if (!value) {
				return undefined;
			}
			return {
				key: name,
				label: `${name}: ${value}`,
				onRemove,
			};
		},
		getPolarisFilterProps: () => ({
			...getPolarisFilterProps(name, getFilterFieldValue(filter), onChange),
			key: name,
			label: name,
		}),
	};
}
