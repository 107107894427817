import { useDebouncedValue } from '@shopify/react-hooks';
import { CircleTickMajor, AddMajor } from '@sixriver/lighthouse-icons';
import {
	Layout,
	Icon,
	Link,
	Stack,
	TextStyle,
	Tooltip,
	FilterInterface,
	ChoiceList,
	AppliedFilterInterface,
	Button,
} from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';
import { useState } from 'react';

import {
	GetContainerCountDocument,
	GetContainerCountQuery,
	GetContainerCountQueryVariables,
} from './graphql/GetContainerCount.w-api-graphql';
import {
	GetContainersDocument,
	GetContainersQuery,
	GetContainersQueryVariables,
} from './graphql/GetContainers.w-api-graphql';
import {
	ContainerTypeOrderByFields,
	ContainerTypeType,
	OrderByDirection,
} from '../../api/warehouse-api/types';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { DataTable } from '../../components/DataTable';
import { Error } from '../../components/Error';
import { NoData } from '../../components/NoData';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useExperimentalFlags, useIsPackoutEnabled } from '../../hooks/useConfig';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';
import { createColumnsAndRows } from '../../utils';

const defaultView: ContainerTypeType | 'all' = 'all';

export function Containers() {
	const { messages, formatLength } = useLocalization();
	const packoutEnabled = useIsPackoutEnabled();
	const { isUserAllowed } = useAuth();
	const experimentalFlags = useExperimentalFlags();

	// State
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	// Filters & Sorting
	const {
		view = defaultView,
		query = '',
		sort = ContainerTypeOrderByFields.Name + ' ' + OrderByDirection.Asc,
		inventoryState = 'all',
		// TODO: make visible once backend is complete
		// materialType,
	} = useFilters(['inventoryState']);

	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';
	const gqlSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;
	// TODO: make visible once backend is complete
	// const materialTypes = config?.containerMaterialTypes ?? [];
	// const selectedMaterialTypes = materialType ? [materialType] : undefined;
	const [orderBy, orderByDirection] = sort
		? (sort.split(' ') as [ContainerTypeOrderByFields, OrderByDirection])
		: [undefined, undefined];

	const sortChoices = [
		{
			label: messages.sortOptions.containerNameAsc,
			value: ContainerTypeOrderByFields.Name + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.containerNameDesc,
			value: ContainerTypeOrderByFields.Name + ' ' + OrderByDirection.Desc,
		},
	];

	const filters: FilterInterface[] = [
		{
			filter: (
				<ChoiceList
					title={messages.inventoryTracking}
					titleHidden
					choices={[
						{
							label: messages.inventoryTrackingStates.all,
							value: 'all',
						},
						{
							label: messages.inventoryTrackingStates.tracked,
							value: 'tracked',
						},
						{
							label: messages.inventoryTrackingStates.untracked,
							value: 'untracked',
						},
					]}
					selected={[inventoryState]}
					onChange={(selected) => {
						setFilters([{ key: 'inventoryState', value: selected[0] }]);
					}}
				/>
			),
			key: 'inventoryState',
			label: messages.inventoryTracking,
			shortcut: true,
		},
	];

	const appliedFilters: AppliedFilterInterface[] = [
		...(inventoryState !== 'all'
			? [
					{
						key: 'inventoryState',
						label:
							inventoryState === 'tracked'
								? messages.inventoryTrackingStates.tracked
								: messages.inventoryTrackingStates.untracked,
						onRemove: () => setFilters([{ key: 'inventoryState', value: 'all' }]),
					},
			  ]
			: []),
	];

	// Queries
	const [getGetContainerCountQuery] = usePollingQuery<
		GetContainerCountQuery,
		GetContainerCountQueryVariables
	>({
		query: GetContainerCountDocument,
		variables: {
			isInventory: inventoryState === 'all' ? undefined : inventoryState === 'tracked',
			searchText: gqlSearchText,
		},
	});

	const counts = (getGetContainerCountQuery.data?.countContainers || []).reduce<
		{ type: ContainerTypeType; count: number }[]
	>((acc, count) => {
		const currentCount = acc.find((ac) => ac.type === count.type);
		if (currentCount) {
			currentCount.count += count.count;
		} else {
			acc.push({ count: count.count, type: count.type! });
		}
		return acc;
	}, []);

	const [{ fetching: containersFetching, data: containersData, error: containersError }] =
		usePollingQuery<GetContainersQuery, GetContainersQueryVariables>({
			query: GetContainersDocument,
			variables: {
				after: paginationCursors[0],
				cursor: paginationCursors[0],
				first: getPageSize(),
				isInventory: inventoryState === 'all' ? undefined : inventoryState === 'tracked',
				orderBy,
				orderByDirection,
				searchText: gqlSearchText,
				types:
					view === 'all'
						? [ContainerTypeType.Picking, ContainerTypeType.Shipping, ContainerTypeType.Storage]
						: [view as ContainerTypeType],
				// TODO: make visible once backend is complete
				// materialTypes: selectedMaterialTypes,
			},
		});

	const { edges = [], pageInfo } = containersData?.containers || {};

	// Views (Tabs)
	const views = !packoutEnabled
		? [
				{
					id: 'all',
					label: messages.allContainers,
					metaLabel: counts.reduce<number>((acc, count) => (acc += count.count), 0),
				},
		  ]
		: [
				{
					id: 'all',
					label: messages.allContainers,
				},
				{
					id: ContainerTypeType.Picking,
					label: messages.pickingContainers,
					metaLabel: counts.find((count) => count.type === ContainerTypeType.Picking)?.count ?? 0,
				},
				...(packoutEnabled
					? [
							{
								id: ContainerTypeType.Shipping,
								label: messages.shippingContainers,
								metaLabel:
									counts.find((count) => count.type === ContainerTypeType.Shipping)?.count ?? 0,
							},
					  ]
					: []),
		  ];

	// DataTable values
	const { columns, rows } = createColumnsAndRows(
		[
			{
				heading: messages.name,
				render(container) {
					return <ContainerNameColumn container={container} />;
				},
				type: 'text',
			},
			{
				heading: messages.description,
				render(container) {
					return <ContainerDescriptionColumn container={container} />;
				},
				type: 'text',
			},
			{
				heading: messages.length,
				render(container) {
					return <>{formatLength(container.length || 0)}</>;
				},
				type: 'text',
			},
			{
				heading: messages.width,
				render(container) {
					return <>{formatLength(container.width || 0)}</>;
				},
				type: 'text',
			},
			{
				heading: messages.height,
				render(container) {
					return <>{formatLength(container.height || 0)}</>;
				},
				type: 'text',
			},
			{
				heading: messages.weight,
				render(container) {
					return <ContainerWeightColumn container={container} />;
				},
				type: 'text',
			},
			{
				heading: messages.containerType,
				render(container) {
					return <ContainerTypeColumn container={container} />;
				},
				type: 'text',
			},
			{
				heading: messages.materialType,
				render(container) {
					return <ContainerMaterialTypeColumn container={container} />;
				},
				type: 'text',
			},
		],
		edges.map((e) => e.node),
	);

	// Guards
	const error = containersError || getGetContainerCountQuery.error;
	const fetching = containersFetching || getGetContainerCountQuery.fetching;

	if (error) {
		return <Error graphQLError={error} />;
	}

	// Render
	return (
		<AutoRefreshPage queries={[getGetContainerCountQuery]} fullWidth title={messages.containers}>
			<Layout>
				<Layout.Section>
					<Stack distribution="trailing" alignment="trailing" spacing="extraLoose">
						{experimentalFlags.includes('MANAGE_CONTAINERS') ? (
							<Button
								plain
								monochrome
								removeUnderline
								disabled={!isUserAllowed([UserRole.Admin, UserRole.EmployeeManager])}
								icon={<Icon source={AddMajor} />}
								url={routes.addContainer()}
							>
								{messages.addContainer}
							</Button>
						) : null}
					</Stack>
				</Layout.Section>
				<Layout.Section>
					<DataTable
						loading={fetching}
						columns={columns}
						rows={rows}
						pageInfo={{ endCursor: pageInfo?.endCursor, hasNextPage: pageInfo?.hasNextPage }}
						filters={filters}
						appliedFilters={appliedFilters}
						setFilters={setFilters}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						views={views}
						selectedView={view}
						query={query}
						queryPlaceholder={messages.filterContainers}
						emptyStateHeading={messages.containersNotFound}
						sortChoices={sortChoices}
						sortValue={sort}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</AutoRefreshPage>
	);
}

// Columns
interface ColumnProps {
	container: GetContainersQuery['containers']['edges'][number]['node'];
}

function ContainerNameColumn({ container }: ColumnProps): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Stack vertical spacing="extraTight">
			<Link
				key={`container-${container.id}-name-link`}
				url={routes.container(container.id)}
				removeUnderline
			>
				{container.name}
			</Link>
			{container.enabled ? null : (
				<TextStyle variation="subdued">
					<small>{messages.containerInactive}</small>
				</TextStyle>
			)}
		</Stack>
	);
}

function ContainerDescriptionColumn({ container }: ColumnProps): JSX.Element {
	return container?.description !== container?.name ? (
		<TextStyle>{container.description}</TextStyle>
	) : (
		<NoData />
	);
}

function ContainerWeightColumn({ container }: ColumnProps): JSX.Element {
	const { formatWeight } = useLocalization();

	return container?.type === ContainerTypeType.Shipping ? (
		container?.weight && container?.weight > 0 ? (
			<TextStyle>{formatWeight(container.weight)}</TextStyle>
		) : (
			<NoData />
		)
	) : (
		<NoData />
	);
}

function ContainerTypeColumn({ container }: ColumnProps): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Stack spacing="tight">
			<span>{messages.containerTypes[container.type]}</span>
			{container.inventory ? (
				<Tooltip content={messages.containerTracked}>
					<Icon source={CircleTickMajor} color="success" />
				</Tooltip>
			) : null}
		</Stack>
	);
}

function ContainerMaterialTypeColumn({ container }: ColumnProps): JSX.Element {
	return container?.attributes?.packageMaterialType ? (
		<TextStyle>{container.attributes.packageMaterialType}</TextStyle>
	) : (
		<NoData />
	);
}
