import * as Sentry from '@sentry/react';
import {
	AlertMinor,
	RiskMinor,
	CircleTickMinor,
	CircleCancelMinor,
} from '@sixriver/lighthouse-icons';
import { Icon } from '@sixriver/lighthouse-web-community';

import { DeliveryState } from '../../api/edge/types';

export interface DeliveryStateIconProps {
	deliveryState: DeliveryState;
	attemptsStarted: number;
}

const warningAttemptCountThreshold = 5;

export function DeliveryStateIcon(props: DeliveryStateIconProps): JSX.Element | null {
	switch (props.deliveryState) {
		case DeliveryState.Pending:
			if (props.attemptsStarted > warningAttemptCountThreshold) {
				return <Icon source={RiskMinor} color="warning" />;
			}
			return null;
		case DeliveryState.Complete:
			return <Icon source={CircleTickMinor} color="success" />;
		case DeliveryState.Cancelled:
			return <Icon source={CircleCancelMinor} color="subdued" />;
		case DeliveryState.Abandoned:
			return <Icon source={CircleCancelMinor} color="warning" />;
		case DeliveryState.Failed:
			return <Icon source={AlertMinor} color="critical" />;
		/* istanbul ignore next */
		default:
			/* istanbul ignore next */
			Sentry.captureException({
				deliveryState: props.deliveryState,
				message: 'Could not render DeliveryStateIcon, unknown deliveryState',
			});

			return null;
	}
}
