// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
export type PickWaveOrderFieldsFragment = { __typename?: 'PickWaveOrder', id: string, status: Types.PickWaveOrderStatus, totalLinesCount: number, droppedLinesCount: number, errorCodes: Array<Types.PickWaveOrderLineErrorCode>, externalId: string };

export type PickWaveOrderPageInfoFieldsFragment = { __typename?: 'PickWaveOrderPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null };

export type PickWaveOrderConnectionFieldsFragment = { __typename?: 'PickWaveOrderConnection', edges?: Array<{ __typename?: 'PickWaveOrderEdge', node: { __typename?: 'PickWaveOrder', id: string, status: Types.PickWaveOrderStatus, totalLinesCount: number, droppedLinesCount: number, errorCodes: Array<Types.PickWaveOrderLineErrorCode>, externalId: string } }> | null, pageInfo?: { __typename?: 'PickWaveOrderPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null };

export const PickWaveOrderFieldsFragmentDoc = gql`
    fragment PickWaveOrderFields on PickWaveOrder {
  id
  status
  totalLinesCount
  droppedLinesCount
  errorCodes
  externalId
}
    `;
export const PickWaveOrderPageInfoFieldsFragmentDoc = gql`
    fragment PickWaveOrderPageInfoFields on PickWaveOrderPageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const PickWaveOrderConnectionFieldsFragmentDoc = gql`
    fragment PickWaveOrderConnectionFields on PickWaveOrderConnection {
  edges {
    node {
      ...PickWaveOrderFields
    }
  }
  pageInfo {
    ...PickWaveOrderPageInfoFields
  }
}
    ${PickWaveOrderFieldsFragmentDoc}
${PickWaveOrderPageInfoFieldsFragmentDoc}`;