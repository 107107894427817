import { InputMaybe, QueryJobsV2Args, Scalars } from '../../api/fulfillment-api/types';
import { usePollingQuery } from '../usePollingQuery';
import {
	GetJobCountsDocument,
	GetJobCountsQuery,
	GetJobCountsQueryVariables,
} from './graphql/GetJobCounts.w-api-graphql';

export interface StatusCounts {
	totalUnits: number;
	pickedUnits: number;
	inProgressUnits: number;
	completedLines: number;
	totalLines: number;
	totalJobs: number;
}

type WarehouseQueryJobsV2Args = QueryJobsV2Args & {
	isOnChuck?: InputMaybe<Scalars['Boolean']['input']>;
};

export function useJobsCounts(args: WarehouseQueryJobsV2Args, visible?: boolean): StatusCounts {
	const [{ data }] = usePollingQuery<GetJobCountsQuery, GetJobCountsQueryVariables>({
		pause: !visible,
		pollInterval: 5000,
		query: GetJobCountsDocument,
		variables: {
			...args,
		}, // 5 seconds
	});

	if (data && data.jobs?.edges) {
		let totalUnits = 0;
		let pickedUnits = 0;
		let inProgressUnits = 0;
		let completedLines = 0;
		let totalLines = 0;

		for (const jobEdge of data.jobs.edges) {
			const { node: job } = jobEdge;
			totalUnits += job.totalLineQuantities.totalUnits;
			pickedUnits += job.totalLineQuantities.pickedUnits;
			inProgressUnits += job.totalLineQuantities.inProgressUnits;
			completedLines += job.totalLineQuantities.completedLines;
			totalLines += job.totalLines.count;
		}

		return {
			completedLines,
			inProgressUnits,
			pickedUnits,
			totalJobs: data.jobs.edges.length,
			totalLines,
			totalUnits,
		};
	}

	return {
		completedLines: 0,
		inProgressUnits: 0,
		pickedUnits: 0,
		totalJobs: 0,
		totalLines: 0,
		totalUnits: 0,
	};
}
