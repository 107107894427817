import { Card, Layout, Page, Spinner } from '@sixriver/lighthouse-web-community';
import { DateTime, useAuth, UserRole, useSiteName } from '@sixriver/react-support';
import { useRouteMatch } from 'react-router-dom';

import { DeviceActivity } from './DeviceActivity';
import { DeviceBattery } from './DeviceBattery';
import { DeviceState, DeviceType, GlobalErrorType } from '../../api';
import { CardDetails } from '../../components/CardDetails';
import { Error } from '../../components/Error';
import { NoData } from '../../components/NoData';
import {
	getDeviceState,
	getDeviceType,
	isVirtualDeviceName,
	useAvailableMfp,
} from '../../hooks/useAvailableMfps';
import { useExperimentalFlags, useMfpConfigForDevice, useSsid } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

export default function Device(): JSX.Element {
	const experimentalFlags = useExperimentalFlags();
	const { isUserAllowed } = useAuth();
	const { messages } = useLocalization();
	const siteName = useSiteName();

	const {
		params: { deviceId },
	} = useRouteMatch<{ deviceId: string }>();
	const { data: device, error: fetchingDeviceError } = useAvailableMfp(deviceId);

	const { data: mfpConfig, error: fetchingMfpConfigError } = useMfpConfigForDevice(device);
	const ssid = useSsid();

	// Rendering

	if (fetchingDeviceError) {
		let fetchError = fetchingDeviceError;
		if (Object.hasOwn(fetchingDeviceError, 'error')) {
			fetchError = (fetchingDeviceError as object as { error: GlobalErrorType }).error;
		}
		let message = fetchError.message;
		if (fetchError.status === 404) {
			message = messages.deviceNotFound;
		}
		return <Error message={message} />;
	}

	if (fetchingMfpConfigError) {
		let fetchError = fetchingMfpConfigError;
		if (Object.hasOwn(fetchingMfpConfigError, 'error')) {
			fetchError = (fetchingMfpConfigError as object as { error: GlobalErrorType }).error;
		}
		let message = fetchError.message;
		if (fetchError.status === 404) {
			message = messages.mfpConfigNotFound;
		}
		return <Error message={message} />;
	}

	if (!device || !mfpConfig) {
		return (
			<Page title={messages.gettingDeviceData}>
				<Spinner />
			</Page>
		);
	}

	// At this point we have both device and it's mfpConfig so we show the device info
	const deviceType = getDeviceType(mfpConfig);
	const cannotEdit = getDeviceState(device) !== DeviceState.NotInUse;
	const isVirtualDevice = isVirtualDeviceName(mfpConfig.name, siteName);

	let actionContent = messages.edit;
	if (cannotEdit) {
		actionContent = messages.cannotEditDevice.status;
	}
	if (isVirtualDevice) {
		actionContent = messages.cannotEditDevice.virtualDevice;
	}
	const showEditAction =
		experimentalFlags.includes('EDIT_DEVICE') &&
		isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]);

	return (
		<Page title={mfpConfig.name}>
			<Layout>
				<Layout.Section></Layout.Section>
				<Layout.Section>
					<Card
						title={messages.profile}
						actions={
							showEditAction
								? [
										{
											content: actionContent,
											disabled: cannotEdit || isVirtualDevice,
											url: routes.editDevice(deviceId),
										},
								  ]
								: undefined
						}
					>
						<Card.Section>
							<CardDetails
								primary={[
									{
										content: mfpConfig.name,
										label: messages.name,
									},
									{
										content: device ? (
											messages.deviceTypes[deviceType as keyof typeof messages.deviceTypes]
										) : (
											<NoData />
										),
										label: messages.model,
									},
									{
										content: ssid,
										label: messages.network,
									},
								]}
							/>
						</Card.Section>
						{deviceType === DeviceType.TerminalOnWheels ||
						deviceType === DeviceType.Handheld ? null : (
							<Card.Section>
								<CardDetails
									primary={[
										{
											content: <DeviceBattery device={device} />,
											label: messages.batteryLevel,
										},
										{
											content: <DateTime date={mfpConfig.calibrationData.calibratedAt} />,
											label: messages.lastCalibrated,
										},
									]}
								/>
							</Card.Section>
						)}
					</Card>
				</Layout.Section>
				<Layout.Section>
					<DeviceActivity
						info={{ device, model: deviceType }}
						title={messages.active}
						findChuck={true}
					/>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
