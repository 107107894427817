// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetShuttleAvailableContainerQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetShuttleAvailableContainerQuery = { __typename?: 'Query', shuttleAvailableContainer?: { __typename?: 'ShuttleAvailableContainer', id: string, type: string, state: string, externalId: string, createdAt?: any | null, endedAt?: any | null, lastEventPersistedAt: any, currentActor?: { __typename?: 'UserActor', user?: { __typename?: 'BasicActor', type: string, id: string } | null, device?: { __typename?: 'BasicActor', type: string, id: string } | null } | null, inputs: { __typename?: 'ShuttleAvailableContainerInputs', externalIds: any, containerKind: string, containerDimensions?: any | null, containerTypeId?: string | null, priority: number, customerData: any, storageLocationId: string, storageLocationAddress: string, aisle: string, destinationCriteria?: any | null, containerLabels: { __typename?: 'ContainerLabels', default?: string | null } }, outputs: { __typename?: 'ShuttleAvailableContainerOutputs', activeTask?: string | null, pickedUpBy?: string | null } } | null };


export const GetShuttleAvailableContainerDocument = gql`
    query wapi_GetShuttleAvailableContainer($id: String!) {
  shuttleAvailableContainer(id: $id) {
    id
    type
    state
    externalId
    createdAt
    endedAt
    lastEventPersistedAt
    currentActor {
      user {
        type
        id
      }
      device {
        type
        id
      }
    }
    inputs {
      externalIds
      containerLabels {
        default
      }
      containerKind
      containerDimensions
      containerTypeId
      priority
      customerData
      storageLocationId
      storageLocationAddress
      aisle
      destinationCriteria
    }
    outputs {
      activeTask
      pickedUpBy
    }
  }
}
    `;

export function useGetShuttleAvailableContainerQuery(options: Omit<Urql.UseQueryArgs<GetShuttleAvailableContainerQueryVariables>, 'query'>) {
  return Urql.useQuery<GetShuttleAvailableContainerQuery, GetShuttleAvailableContainerQueryVariables>({ query: GetShuttleAvailableContainerDocument, ...options });
};