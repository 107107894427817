import {
	IndexTable,
	Pagination,
	TextStyle,
	useIndexResourceState,
	Select,
	TextField,
	Scrollable,
} from '@sixriver/lighthouse-web-community';
import { DateTime } from '@sixriver/react-support';
import { getAllStringUnionValues } from '@sixriver/typescript-support';
import clsx from 'clsx';
import { useState } from 'react';

import { DismissibleAlertBanner, NonDismissibleAlertBanner } from './Banner';
import { ConfirmationModal } from './ConfirmationModal';
import { DeliveryFilters } from './DeliveryFilters';
import { DeliveryStateIcon } from './DeliveryStateIcon';
import styles from './DeliveryTable.module.css';
import { DeliveryTypeIcon } from './DeliveryTypeIcon';
import tableStyles from './Table.module.css';
import { DeliveryFragment } from './graphql/GetDeliveries.edge-graphql';
import {
	DeliveryState,
	DeliveryType,
	MessageDeliveryFilter,
	SortDirection,
} from '../../api/edge/types';

export interface DeliveryTableData {
	data: DeliveryFragment[];
	pageInfo: {
		hasPreviousPage?: boolean | null;
		hasNextPage?: boolean | null;
	};
}

export type OnDeliverySelectionChange = ReturnType<
	typeof useIndexResourceState
>['handleSelectionChange'];

const allSortDirections = getAllStringUnionValues<SortDirection>({
	ASC: true,
	DESC: true,
});

export interface DeliveryTableProps {
	style?: React.CSSProperties;
	className?: string;

	tableData: DeliveryTableData;

	onNext: () => void;
	onPrevious: () => void;

	// this is the single selected row
	selectedRow: string | undefined;
	onSelectedRowChanged: (value: string | undefined) => void;

	// this is for the Polaris check boxes
	selectedResources: string[];
	allResourcesSelected: boolean;
	onSelectionChange: OnDeliverySelectionChange;

	onReplay: (selectedNodes: 'All' | DeliveryFragment[]) => void;
	onCancel: (selectedNodes: 'All' | DeliveryFragment[]) => void;

	filter: MessageDeliveryFilter | undefined;
	onFilterChanged: (value: MessageDeliveryFilter | undefined) => void;

	sortDirection: SortDirection;
	onSortDirectionChanged: (value: SortDirection) => void;

	loading: boolean;
}

type DeliveriesSelected = {
	canBeCancelled: DeliveryFragment[];
	cannotBeCancelled: DeliveryFragment[];
};

export function DeliveryTable(props: DeliveryTableProps): JSX.Element {
	const { data, pageInfo } = props.tableData;

	const [deliveriesSelected, setDeliveriesSelected] = useState<DeliveriesSelected>({
		canBeCancelled: [],
		cannotBeCancelled: [],
	});

	const clearDeliveriesSelected = () => {
		setDeliveriesSelected({
			canBeCancelled: [],
			cannotBeCancelled: [],
		});
	};

	const [
		showNoSelectedDeliveriesCanBeCancelledBanner,
		setShowNoSelectedDeliveriesCanBeCancelledBanner,
	] = useState(false);

	const bulkActions = [];

	bulkActions.push({
		content: 'Cancel Selected',
		onAction: () => {
			// always reset this banner in case its currently rendered and
			// we end up with deliveries that can be cancelled
			setShowNoSelectedDeliveriesCanBeCancelledBanner(false);

			const selectedDeliveries = getSelectedNodes();
			const deliveriesCanBeCancelled = selectedDeliveries.filter(
				(n) =>
					n.deliveryState !== DeliveryState.Cancelled && n.deliveryState !== DeliveryState.Complete,
			);
			const deliveriesCannotBeCancelled = selectedDeliveries.filter(
				(n) =>
					n.deliveryState === DeliveryState.Cancelled || n.deliveryState === DeliveryState.Complete,
			);

			if (deliveriesCanBeCancelled.length === 0) {
				setShowNoSelectedDeliveriesCanBeCancelledBanner(true);
			} else {
				setDeliveriesSelected({
					canBeCancelled: deliveriesCanBeCancelled,
					cannotBeCancelled: deliveriesCannotBeCancelled,
				});
			}
		},
	});

	const getSelectedNodes = () => {
		return data.filter((d) => props.selectedResources.some((r) => r === d.id));
	};

	const rowsMarkup = () => {
		return data?.map((delivery, index: number) => {
			const checked = props.selectedResources.includes(delivery.id) || props.allResourcesSelected;
			const selected = props.selectedRow === delivery.id;
			// NOTE: see `Table.module.css` for styles hackery that makes this work
			const cellContainerStyle = clsx({
				[tableStyles.tableCellContainer]: true,
				[tableStyles.selectedTableCell]: selected,
				[tableStyles.nonSelectedTableCell]: !selected,
			});

			const onClickRow = (e: React.MouseEvent) => {
				e.stopPropagation();
				if (!selected) {
					props.onSelectedRowChanged?.(delivery.id);
				}
			};
			return (
				<IndexTable.Row id={delivery.id} key={delivery.id} selected={checked} position={index}>
					<IndexTable.Cell className={cellContainerStyle} flush>
						<DeliveryStateIcon
							deliveryState={delivery.deliveryState}
							attemptsStarted={delivery.attemptsStarted}
						/>
						<div className={tableStyles.tableCell} onClick={onClickRow} />
					</IndexTable.Cell>
					<IndexTable.Cell className={cellContainerStyle} flush>
						<DeliveryTypeIcon
							deliveryType={delivery.message.deliveryType ?? DeliveryType.Message}
							color="subdued"
							includeTooltip
						/>
						<div className={tableStyles.tableCell} onClick={onClickRow} />
					</IndexTable.Cell>
					<IndexTable.Cell className={cellContainerStyle}>
						<div onClick={onClickRow}>
							<TextField
								autoComplete="off"
								label=""
								value={delivery.id}
								readOnly
								selectTextOnFocus
							/>
						</div>
						<div className={tableStyles.tableCell} onClick={onClickRow} />
					</IndexTable.Cell>
					<IndexTable.Cell className={cellContainerStyle}>
						<div onClick={onClickRow}>
							<TextField
								autoComplete="off"
								label=""
								value={delivery.message.id}
								readOnly
								selectTextOnFocus
							/>
						</div>
						<div className={tableStyles.tableCell} onClick={onClickRow} />
					</IndexTable.Cell>
					<IndexTable.Cell className={cellContainerStyle}>
						{delivery.message.messageType}
						<div className={tableStyles.tableCell} onClick={onClickRow} />
					</IndexTable.Cell>
					<IndexTable.Cell className={cellContainerStyle}>
						{delivery.hook.name}
						<div className={tableStyles.tableCell} onClick={onClickRow} />
					</IndexTable.Cell>
					<IndexTable.Cell className={cellContainerStyle}>
						<TextStyle variation="strong">{delivery.deliveryState}</TextStyle>
						<div className={tableStyles.tableCell} onClick={onClickRow} />
					</IndexTable.Cell>
					<IndexTable.Cell className={cellContainerStyle}>
						{delivery.attemptsStarted}
						<div className={tableStyles.tableCell} onClick={onClickRow} />
					</IndexTable.Cell>
					<IndexTable.Cell className={cellContainerStyle}>
						<DateTime date={delivery.enqueuedAt} includeSecondsAndMilliseconds />
						<div className={tableStyles.tableCell} onClick={onClickRow} />
					</IndexTable.Cell>
				</IndexTable.Row>
			);
		});
	};

	const noSelectedDeliveriesCanBeCancelledBanner = showNoSelectedDeliveriesCanBeCancelledBanner ? (
		<DismissibleAlertBanner
			title="No selected deliveries could be cancelled"
			description="Only deliveries that are not yet cancelled or complete can be cancelled"
			status="warning"
			onDismiss={() => setShowNoSelectedDeliveriesCanBeCancelledBanner(false)}
		/>
	) : undefined;

	const numDeliveriesCanBeCancelled = deliveriesSelected.canBeCancelled.length;
	const numDeliveriesCannotBeCancelled = deliveriesSelected.cannotBeCancelled.length;
	const getDeliveryTerm = (len: number) => {
		return len === 1 ? 'delivery' : 'deliveries';
	};

	return (
		<div style={props.style} className={clsx(styles.deliveryTable, props.className)}>
			{noSelectedDeliveriesCanBeCancelledBanner}
			<div className={tableStyles.tableFilterSortContainer}>
				<div className={tableStyles.tableFilters}>
					<DeliveryFilters filter={props.filter} onFilterChanged={props.onFilterChanged} />
				</div>
				<Pagination
					hasPrevious={pageInfo?.hasPreviousPage ?? false}
					onPrevious={props.onPrevious}
					hasNext={pageInfo?.hasNextPage ?? false}
					onNext={props.onNext}
				/>
				<div className={tableStyles.tableSortSelector}>
					<Select
						labelInline
						label="Sort Direction"
						options={allSortDirections}
						value={props.sortDirection}
						onChange={(selected) => props.onSortDirectionChanged(selected as SortDirection)}
					/>
				</div>
			</div>
			<Scrollable>
				<IndexTable
					resourceName={{ plural: 'deliveries', singular: 'delivery' }}
					itemCount={data.length}
					selectedItemsCount={props.allResourcesSelected ? 'All' : props.selectedResources.length}
					onSelectionChange={props.onSelectionChange}
					loading={props.loading}
					promotedBulkActions={bulkActions}
					headings={[
						{ hidden: true, title: 'Status' },
						{ title: 'Type' },
						{ title: 'Delivery ID' },
						{ title: 'Message ID' },
						{ title: 'Message Type' },
						{ title: 'Hook Name' },
						{ title: 'State' },
						{ title: 'Attempts' },
						{ title: 'Enqueued At' },
					]}
					hasMoreItems={pageInfo.hasNextPage ?? false}
				>
					{data.length && rowsMarkup()}
				</IndexTable>
			</Scrollable>
			<ConfirmationModal
				show={numDeliveriesCanBeCancelled > 0}
				title="Cancel Deliveries"
				description={`Are you sure you want to cancel ${numDeliveriesCanBeCancelled} ${getDeliveryTerm(
					numDeliveriesCanBeCancelled,
				)}?`}
				banner={
					numDeliveriesCannotBeCancelled > 0 ? (
						<NonDismissibleAlertBanner
							title="Some deliveries could not be cancelled"
							description={`${numDeliveriesCannotBeCancelled} ${getDeliveryTerm(
								numDeliveriesCannotBeCancelled,
							)} ${
								numDeliveriesCannotBeCancelled === 1 ? 'is' : 'are'
							} already cancelled or complete`}
							status="warning"
						/>
					) : undefined
				}
				onConfirmAction={() => {
					props.onCancel(deliveriesSelected.canBeCancelled);

					clearDeliveriesSelected();
				}}
				onCancelAction={() => {
					clearDeliveriesSelected();
				}}
			/>
		</div>
	);
}
