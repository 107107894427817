// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetPickWavesCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPickWavesCountQuery = { __typename?: 'Query', errorPickWavesCount: { __typename?: 'Count', count: number }, totalPickWavesCount: { __typename?: 'Count', count: number } };

export type PickWavesCountFieldsFragment = { __typename?: 'Count', count: number };

export const PickWavesCountFieldsFragmentDoc = gql`
    fragment PickWavesCountFields on Count {
  count
}
    `;
export const GetPickWavesCountDocument = gql`
    query fapi_GetPickWavesCount {
  errorPickWavesCount: pickWavesCount(
    statuses: [Failure, PartialSuccess]
    isResolved: false
  ) {
    ...PickWavesCountFields
  }
  totalPickWavesCount: pickWavesCount {
    ...PickWavesCountFields
  }
}
    ${PickWavesCountFieldsFragmentDoc}`;

export function useGetPickWavesCountQuery(options?: Omit<Urql.UseQueryArgs<GetPickWavesCountQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPickWavesCountQuery, GetPickWavesCountQueryVariables>({ query: GetPickWavesCountDocument, ...options });
};