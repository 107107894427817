// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddJobFlowRuleMutationVariables = Types.Exact<{
  input: Types.JobFlowRuleInput;
}>;


export type AddJobFlowRuleMutation = { __typename?: 'Mutation', addJobFlowRule: { __typename?: 'MutationResponse', success: boolean, referenceId?: string | null } };


export const AddJobFlowRuleDocument = gql`
    mutation fapi_AddJobFlowRule($input: JobFlowRuleInput!) {
  addJobFlowRule(input: $input) {
    success
    referenceId
  }
}
    `;

export function useAddJobFlowRuleMutation() {
  return Urql.useMutation<AddJobFlowRuleMutation, AddJobFlowRuleMutationVariables>(AddJobFlowRuleDocument);
};