// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetEmployeeQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetEmployeeQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, email?: string | null, name?: string | null, locale: string, badge?: string | null, roles: Array<string>, isActive: boolean, lastLogin?: any | null, createdAt?: any | null, updatedAt?: any | null, phone?: string | null, tags: Array<{ __typename?: 'Tag', name: string }> } | null };


export const GetEmployeeDocument = gql`
    query fapi_GetEmployee($id: String!) {
  user(id: $id) {
    id
    email
    name
    locale
    badge
    roles
    isActive
    lastLogin
    createdAt
    updatedAt
    phone
    tags {
      name
    }
  }
}
    `;

export function useGetEmployeeQuery(options: Omit<Urql.UseQueryArgs<GetEmployeeQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEmployeeQuery, GetEmployeeQueryVariables>({ query: GetEmployeeDocument, ...options });
};