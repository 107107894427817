import { useAuth } from '@sixriver/react-support';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';

import cs from '../messages/cs/cs.json';
import en from '../messages/en-US/en-US.json';
import es from '../messages/es-MX/es-MX.json';
import nl from '../messages/nl/nl.json';
import pl from '../messages/pl/pl.json';
import * as dateOps from '../utils/date';
import * as dateDiffOps from '../utils/date-diff';
import * as numberOps from '../utils/number';
import * as translateOps from '../utils/translate';

export const SUPPORTED_LOCALES = ['en-US', 'es-MX', 'cs', 'nl', 'pl'] as const;
export type LocaleType = (typeof SUPPORTED_LOCALES)[number];

let _messages = en;
let _locale = 'en-US';

export function useLocalization() {
	const { getUser } = useAuth();
	const user = getUser();
	const userLocale = (user?.locale || '') as LocaleType;
	const locale = SUPPORTED_LOCALES.includes(userLocale) ? userLocale : 'en-US';

	// A bit funky, but this is how the translation system was set up
	/* eslint-disable react-hooks/exhaustive-deps */
	const translate = useCallback(translateOps.translate.bind(null, locale), [locale]);
	const formatNumber = useCallback(numberOps.formatNumber.bind(null, locale), [locale]);
	const formatPercent = useCallback(numberOps.formatPercent.bind(null, locale), [locale]);
	const formatWeight = useCallback(numberOps.formatWeight.bind(null, locale), [locale]);
	const formatLength = useCallback(numberOps.formatLength.bind(null, locale), [locale]);
	const formatVolume = useCallback(numberOps.formatVolume.bind(null, locale), [locale]);
	const formatDateTime = useCallback(dateOps.formatDateTime.bind(null, locale), [locale]);
	const formatDate = useCallback(dateOps.formatDate.bind(null, locale), [locale]);
	const formatTime = useCallback(dateOps.formatTime.bind(null, locale), [locale]);
	const formatDateDiff = useCallback(dateDiffOps.formatDateDiff.bind(null, locale), [locale]);
	/* eslint-enable react-hooks/exhaustive-deps */

	const messages = useMemo(() => {
		const msgs = {
			cs,
			'en-US': en,
			'es-MX': es,
			nl,
			pl,
		}[locale];

		// we don't need this to run every time a component mounts. it should run once at most
		if (locale !== _locale) {
			_messages = _.merge(JSON.parse(JSON.stringify(en)), msgs);
			_locale = locale;
		}

		return _messages;
	}, [locale]);

	return {
		formatDate,
		formatDateDiff,
		formatDateTime,
		formatLength,
		formatNumber,
		formatPercent,
		formatTime,
		formatVolume,
		formatWeight,
		locale,
		messages,
		translate,
	};
}
