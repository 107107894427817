// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetDevicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDevicesQuery = { __typename?: 'Query', devices: { __typename?: 'DeviceConnection', edges: Array<{ __typename?: 'DeviceEdge', node: { __typename?: 'Device', id: string, name?: string | null, badge?: string | null, phase: string, workAreaId?: string | null, updatedAt: any } }> } };


export const GetDevicesDocument = gql`
    query fapi_GetDevices {
  devices(types: [Chuck], first: 299, limit: 300) {
    edges {
      node {
        id
        name
        badge
        phase
        workAreaId
        updatedAt
      }
    }
  }
}
    `;

export function useGetDevicesQuery(options?: Omit<Urql.UseQueryArgs<GetDevicesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDevicesQuery, GetDevicesQueryVariables>({ query: GetDevicesDocument, ...options });
};