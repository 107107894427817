import { NorthboundMessageType } from '@sixriver/edge-services-oas';
import { Autocomplete } from '@sixriver/lighthouse-web-community';
import { asArray } from '@sixriver/typescript-support';
import { useState, useCallback, useMemo } from 'react';

import { allMessageTypeOptions, MessageTypeGroup, MessageTypeOptionGroup } from './HookMessageType';

export interface MessageTypePickerProps {
	label?: string;
	labelHidden?: boolean;

	value: NorthboundMessageType | string | undefined;
	onChange: (value: NorthboundMessageType | string | undefined) => void;
}

export function MessageTypePicker(props: MessageTypePickerProps) {
	const { label, labelHidden, value, onChange } = props;

	const deselectedOptions = useMemo(() => allMessageTypeOptions, []);

	const [inputValue, setInputValue] = useState<string>('');
	const [options, setOptions] = useState(deselectedOptions);

	const updateText = useCallback(
		(value: string) => {
			setInputValue(value);

			const resultOptions = getVisibleOptions(deselectedOptions, value);
			setOptions(resultOptions);
		},
		[deselectedOptions, setInputValue, setOptions],
	);

	const updateSelection = useCallback(
		([selected]: string[]) => {
			const [selectedValue] = selected
				? options.flatMap((o) => o.options.map((i) => i.label)).filter((i) => !!selected.match(i))
				: [];

			onChange(selectedValue);
			setInputValue(selectedValue);
		},
		[options, onChange],
	);

	return (
		<Autocomplete
			options={options}
			selected={asArray(value)}
			onSelect={updateSelection}
			textField={
				<Autocomplete.TextField
					onChange={updateText}
					label={label}
					labelHidden={labelHidden}
					value={inputValue}
					autoComplete="off"
				/>
			}
		/>
	);
}

function getVisibleOptions(
	deselectedOptions: MessageTypeOptionGroup[],
	value: string | undefined,
): MessageTypeOptionGroup[] {
	const messageTypeFilterRegex = value ? new RegExp(value, 'i') : undefined;
	return deselectedOptions
		.map((o) => ({
			options: o.options.filter(
				(o) => !messageTypeFilterRegex || !!o.value.match(messageTypeFilterRegex),
			),
			title: o.title as MessageTypeGroup,
		}))
		.filter((o) => o.options.length > 0);
}
