import { PropsWithChildren, createContext, useState, useRef } from 'react';

interface BusyIndicatorContextProps {
	busy: boolean;
	lookBusy: (busy: boolean) => boolean;
}

export const BusyIndicatorContext = createContext<BusyIndicatorContextProps>({
	busy: false,
	lookBusy: () => false,
});

export function BusyIndicatorProvider({ children }: PropsWithChildren<Record<never, any>>) {
	const pending = useRef<number>(0);
	const [busy, setBusy] = useState<boolean>(false);

	const open = () => {
		if (busy && !pending.current) {
			return; // nothing to do
		}

		setBusy(true);
		clearTimeout(pending.current);
		pending.current = 0;
	};

	const close = () => {
		if (!busy || pending.current) {
			return; // nothing to do
		}

		pending.current = window.setTimeout(() => setBusy(false), 500);
	};

	return (
		<BusyIndicatorContext.Provider
			value={{
				busy,
				lookBusy: (busy: boolean) => {
					if (busy) {
						open();
					} else {
						close();
					}
					return busy;
				},
			}}
		>
			{children}
		</BusyIndicatorContext.Provider>
	);
}
