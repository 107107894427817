import { useDebouncedValue } from '@shopify/react-hooks';
import { Filters, AppliedFilterInterface } from '@sixriver/lighthouse-web-community';
import { useCallback, useEffect, useState } from 'react';

import { StatusFilter } from './StatusFilter';
import { PickWaveStatus } from '../../../api/fulfillment-api/types';

export interface PickWavesFilter {
	searchText?: string;
	statuses: PickWaveStatus[];
}

export interface PickWavesFiltersProps {
	filter: PickWavesFilter;
	onChange: (filter: PickWavesFilter) => void;
}

const STATUS_FILTER_KEY = 'status';

export function PickWavesFilters({ filter, onChange }: PickWavesFiltersProps) {
	const [searchText, setSearchText] = useState<string | undefined>(filter.searchText);
	const debouncedSearchText = useDebouncedValue(searchText);
	const [statuses, setStatuses] = useState<PickWaveStatus[]>(filter.statuses);

	useEffect(() => {
		let searchText;

		if (debouncedSearchText && debouncedSearchText.length >= 3) {
			searchText = debouncedSearchText;
		}

		onChange({
			searchText,
			statuses,
		});
	}, [debouncedSearchText, onChange, statuses]);

	const handleStatusesChange = useCallback(
		(value: PickWaveStatus[]) => {
			setStatuses(value);
		},
		[setStatuses],
	);

	const handleStatusesClear = useCallback(() => {
		setStatuses([]);
	}, [setStatuses]);

	const handleQueryChange = useCallback(
		(queryValue: string) => {
			setSearchText(queryValue);
		},
		[setSearchText],
	);

	const handleQueryClear = useCallback(() => {
		setSearchText(undefined);
	}, [setSearchText]);

	const handleClearAll = useCallback(() => {
		handleStatusesClear();
	}, [handleStatusesClear]);

	const filters = [
		{
			filter: <StatusFilter selected={statuses} onChange={handleStatusesChange} />,
			key: STATUS_FILTER_KEY,
			label: 'Status',
		},
	];
	const appliedFilters: AppliedFilterInterface[] = [];

	if (!isEmpty(statuses)) {
		appliedFilters.push({
			key: STATUS_FILTER_KEY,
			label: disambiguateLabel(STATUS_FILTER_KEY, statuses),
			onRemove: handleStatusesClear,
		});
	}

	return (
		<Filters
			queryValue={searchText}
			queryPlaceholder="Search by order ID"
			onQueryChange={handleQueryChange}
			onQueryClear={handleQueryClear}
			filters={filters}
			appliedFilters={appliedFilters}
			onClearAll={handleClearAll}
		></Filters>
	);
}

function isEmpty(value: unknown): boolean {
	if (Array.isArray(value)) {
		return value.length === 0;
	} else {
		return value === '' || value === null || value === undefined;
	}
}

function disambiguateLabel(key: string, value: string[] | string): string {
	if (key === STATUS_FILTER_KEY) {
		if (Array.isArray(value)) {
			return `Status: ${value.join(', ')}`;
		}

		return `Status: ${value}`;
	}

	if (Array.isArray(value)) {
		return value.join(', ');
	}

	return value;
}
