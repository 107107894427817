import { Banner, List } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';
import { useMfas } from '../providers/Mfas';
import { useReservations } from '../providers/Reservations';
import { useResources } from '../providers/Resources';

interface SummaryProps {
	resourceId?: string;
	mfaId?: string;
}

export function Summary({ resourceId, mfaId }: SummaryProps) {
	const { resources } = useResources();
	const { mfas } = useMfas();
	const { reservations } = useReservations();
	const { messages, translate } = useLocalization();

	// Entities that have been selected
	const selectedResource = resources?.find((r) => r.id === resourceId);
	const selectedMfa = mfas?.find((m) => m.deviceId === mfaId);

	// Reservations for those entities, if any
	const reservationForMfa = reservations?.find((res) => res.deviceId === mfaId);
	const reservationForResource = reservations?.find((res) => res.resource.id === resourceId);

	const shouldWarn = reservationForMfa || reservationForResource;

	return (
		<Banner
			status={shouldWarn ? 'warning' : 'info'}
			title={messages.mfas.assignLicenseModal.summary.title}
		>
			<List>
				<List.Item>
					{translate(messages.mfas.assignLicenseModal.summary.newReservation, {
						device: selectedMfa?.name,
						resource: selectedResource?.name,
					})}
				</List.Item>
				{reservationForMfa && (
					<List.Item>
						{translate(messages.mfas.assignLicenseModal.summary.oldReservation, {
							resource: selectedResource?.name,
						})}
					</List.Item>
				)}
				{reservationForResource && (
					<List.Item>
						{translate(messages.mfas.assignLicenseModal.summary.oldDevice, {
							device: selectedMfa?.name,
						})}
					</List.Item>
				)}
			</List>
		</Banner>
	);
}
