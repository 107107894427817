import { DuplicateMinor } from '@sixriver/lighthouse-icons';
import { Layout, Tabs, Stack, Frame, Loading, TextStyle } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { PackingDetails } from './PackingDetails';
import { PickingDetails } from './PickingDetails';
import { PickingProgress } from './PickingProgress';
import { getRelatedJobs } from './RelatedJobs';
import { SortationDetails } from './SortationDetails';
import { GetJobDocument, GetJobQuery, GetJobQueryVariables } from './graphql/GetJob.w-api-graphql';
import {
	GetJobEventsDocument,
	GetJobEventsQuery,
	GetJobEventsQueryVariables,
} from './graphql/GetJobEvents.f-api-graphql';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { Error } from '../../components/Error';
import { ErrorBanner } from '../../components/ErrorBanner';
import { Lines } from '../../components/Lines';
import { LinesTableColumn } from '../../components/Lines/LinesTable';
import { LinesTab } from '../../components/Lines/LinesTabs';
import { OutboundJobStatusBadgeV2 } from '../../components/OutboundJobStatusBadge/OutboundJobStatusBadgeV2';
import { Timeline } from '../../components/Timeline';
import { useWorkflowEvents } from '../../components/Timeline/Timeline.hooks';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import {
	useIsPackoutEnabled,
	useIsSortationEnabled,
	useIsWorkAreasEnabled,
} from '../../hooks/useConfig';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';

export interface RouteMatchParams {
	outboundJobId: string;
}

export function OutboundJob() {
	const {
		params: { outboundJobId },
	} = useRouteMatch<RouteMatchParams>();
	const { messages } = useLocalization();
	const { copyToClipboard } = useCopyToClipboard();

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);

	const { tab } = useFilters(['tab']);
	const setFilters = useSetFilters();
	const tabs = [
		{
			content: messages.overview,
			id: 'overview',
		},
		{
			content: messages.history,
			id: 'history',
		},
	];
	const tabIndex = tab === tabs[1]?.id ? 1 : 0;

	const [getJobQuery] = usePollingQuery<GetJobQuery, GetJobQueryVariables>({
		query: GetJobDocument,
		variables: {
			id: outboundJobId,
		},
	});

	const [getJobEventsQuery] = usePollingQuery<GetJobEventsQuery, GetJobEventsQueryVariables>({
		pause: tab !== 'history',
		query: GetJobEventsDocument,
		variables: { id: outboundJobId },
	});

	const fetching = getJobQuery.fetching || getJobEventsQuery.fetching;
	const error = getJobQuery.error || getJobEventsQuery.error;
	const job = getJobQuery.data?.job;
	const sortWall = job?.servicingSortations[0];
	const isPackoutEnabled = useIsPackoutEnabled();
	const isPackingMode = isPackoutEnabled && !!(job as any)?.packouts;
	const isSortationEnabled = useIsSortationEnabled();
	const isSortationMode = isSortationEnabled && !!sortWall;
	const isWorkAreaEnabled = useIsWorkAreasEnabled();

	const relatedJobs = getRelatedJobs(job);

	const events = useWorkflowEvents(getJobEventsQuery.data?.jobEventsV2 || [], 'JOB');

	if (fetching) {
		return (
			<Frame>
				<Loading />
			</Frame>
		);
	}
	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!job) {
		return <Error heading={messages.outboundJobNotFound} />;
	}

	return (
		<>
			<AutoRefreshPage
				queries={[getJobQuery, getJobEventsQuery]}
				title={job.externalContainerId || job.id}
				titleMetadata={
					<Stack spacing="extraTight">
						{job.isHealJob ? (
							<TextStyle variation="subdued">{`${messages.resolutionShortHand}`}</TextStyle>
						) : null}
						<OutboundJobStatusBadgeV2 job={job} />
					</Stack>
				}
				secondaryActions={[
					{
						content: messages.copyLicensePlate,
						disabled: !job.externalContainerId,
						icon: DuplicateMinor,
						onAction: job.externalContainerId
							? () => copyToClipboard(job.externalContainerId)
							: undefined,
					},
				]}
			>
				<Tabs
					tabs={tabs}
					selected={tabIndex}
					onSelect={(tabIndex) => {
						setFilters([{ key: 'tab', value: tabs[tabIndex]?.id }]);
					}}
				>
					<Layout>
						<Layout.Section>
							<ErrorBanner
								isVisible={isBannerErrorVisible}
								onDismiss={() => {
									setIsBannerErrorVisible(false);
								}}
							/>
						</Layout.Section>
						{tab === 'history' ? (
							<Layout.Section>
								<Timeline
									title={messages.outboundJobHistory}
									events={events}
									loading={getJobEventsQuery.fetching}
								/>
							</Layout.Section>
						) : (
							<>
								{/* LOL-3313 {job.workOrderTypes?.includes(WorkOrderType.InventoryTransferToNode) && (
									<Layout.Section>
										<TransferDetails loading={fetching} job={job} />
									</Layout.Section>
								)} */}
								<Layout.Section>
									<>
										{/* <ResolutionCalloutCard job={job} /> */}
										{isPackingMode ? (
											<PackingDetails
												loading={fetching}
												job={job}
												relatedJobs={relatedJobs}
												cutoff={job.expectedShipDate}
											/>
										) : null}
										<PickingDetails
											loading={fetching}
											job={job}
											relatedJobs={relatedJobs}
											isPackoutEnabled={isPackoutEnabled}
											isWorkAreaEnabled={isWorkAreaEnabled}
											condensed={isPackingMode}
											sortWall={sortWall}
										/>
									</>
								</Layout.Section>
								<Layout.Section>
									{isSortationMode ? (
										<SortationDetails loading={fetching} sortWall={sortWall} job={job} />
									) : isPackingMode ? null : (
										<PickingProgress loading={fetching} job={job} />
									)}
								</Layout.Section>
								<Layout.Section>
									{isPackingMode ? (
										<Lines
											job={job}
											columns={[
												LinesTableColumn.id,
												LinesTableColumn.name,
												LinesTableColumn.packingUnits,
											]}
										/>
									) : (
										<Lines
											job={job}
											tabs={[LinesTab.all, LinesTab.exceptions]}
											columns={[
												LinesTableColumn.id,
												LinesTableColumn.name,
												LinesTableColumn.pickingUnits,
												LinesTableColumn.location,
												LinesTableColumn.associate,
												LinesTableColumn.exceptions,
												isWorkAreaEnabled ? LinesTableColumn.workArea : null,
											]}
										/>
									)}
								</Layout.Section>
							</>
						)}
						<Layout.Section>
							<TimezoneFooter />
						</Layout.Section>
					</Layout>
				</Tabs>
			</AutoRefreshPage>
		</>
	);
}
