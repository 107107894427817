import { IndexTable, TextStyle, Stack } from '@sixriver/lighthouse-web-community';
import { NoData } from '@sixriver/react-support';

import { PickWaveOrderStatus } from '../../../api/fulfillment-api/types';
import { PickWaveOrderFieldsFragment } from '../graphql/PickWaveOrderConnectionFields.f-api-graphql';
import { errorCodeToMessage } from '../helpers';

interface FailureTypeCellProps {
	order: PickWaveOrderFieldsFragment;
}

export function FailureTypeCell({ order }: FailureTypeCellProps) {
	if (order.status === PickWaveOrderStatus.Success) {
		return (
			<IndexTable.Cell>
				<NoData />
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<FailureType order={order} />
		</IndexTable.Cell>
	);
}

interface FailureTypeProps {
	order: PickWaveOrderFieldsFragment;
}

export function FailureType({ order }: FailureTypeProps) {
	const messages = order.errorCodes.map((errorCode, index) => {
		const message = errorCodeToMessage(errorCode);
		return <TextStyle key={index}>{message}</TextStyle>;
	});

	return (
		<Stack vertical spacing="extraTight">
			{messages}
		</Stack>
	);
}
