import { Card, Link } from '@sixriver/lighthouse-web-community';

import { GetJobSortationJobFragment } from './graphql/GetJob.w-api-graphql';
import { CardDetails } from '../../components/CardDetails';
import { DateTime } from '../../components/DateTime';
import { NoData } from '../../components/NoData';
import { GetJobQueryJob } from '../../graphql/derived';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface Props {
	job?: GetJobQueryJob;
	loading?: boolean;
	sortWall?: GetJobSortationJobFragment;
}

export function SortationDetails({ loading, sortWall, job }: Props) {
	const { messages, translate } = useLocalization();

	const pickedUnits = job?.totalLineQuantities?.pickedUnits;
	const totalUnits = job?.totalLineQuantities?.totalUnits;

	const completedLines = job?.totalLineQuantities?.completedLines;
	const totalLines = job?.totalLines?.count;

	return (
		<Card sectioned title={messages.sortationDetails}>
			<CardDetails
				loading={loading}
				primary={[
					{
						content: sortWall?.wall ? (
							<Link url={routes.sortWall(sortWall.id)}>{sortWall.wall}</Link>
						) : (
							<NoData />
						),
						label: messages.sortWall,
					},
					{
						content: translate(messages.pickedOfTotal, {
							picked: pickedUnits,
							total: totalUnits,
						}),
						label: messages.unitsPicked,
					},
					{
						content: translate(messages.pickedOfTotal, {
							picked: completedLines,
							total: totalLines,
						}),
						label: messages.linesPicked,
					},
				]}
				secondary={[
					{
						content: sortWall?.batchId || <NoData />,
						label: messages.batchId,
					},
					{
						// LOL-3313
						// content: sortWall?.kiosk || <NoData />,
						content: <NoData />,
						label: messages.assignedKiosk,
					},
					{
						content: <DateTime date={sortWall?.completedAt} />,
						label: messages.completedAt,
					},
				]}
			/>
		</Card>
	);
}
