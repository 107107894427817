import { IndexTable, TextStyle } from '@sixriver/lighthouse-web-community';

import { PickWaveOrderFieldsFragment } from '../graphql/PickWaveOrderConnectionFields.f-api-graphql';

interface TotalLinesCellProps {
	order: PickWaveOrderFieldsFragment;
}

export function TotalLinesCell({ order }: TotalLinesCellProps) {
	return (
		<IndexTable.Cell>
			<TextStyle>{order.totalLinesCount}</TextStyle>
		</IndexTable.Cell>
	);
}
