import { useEffect, useState } from 'react';
import { AnyVariables, useQuery, UseQueryArgs, UseQueryResponse } from 'urql';

import { usePolling } from './usePolling';

type UsePollingQueryArgs<Variables extends AnyVariables = AnyVariables, Data = any> = UseQueryArgs<
	Variables,
	Data
> & {
	pollInterval?: number;
};

export function usePollingQuery<Data = any, Variables extends AnyVariables = AnyVariables>(
	args: UsePollingQueryArgs<Variables, Data>,
): UseQueryResponse<Data, Variables> {
	const query = useQuery<Data, Variables>(args);
	const [result, executeQuery] = query;
	const { queryPollInterval, pollingEnabled } = usePolling();

	const [isPolling, setIsPolling] = useState(pollingEnabled);

	useEffect(() => {
		// The user has just toggled polling
		if (!isPolling && pollingEnabled) {
			executeQuery();
		}

		setIsPolling(pollingEnabled);
	}, [executeQuery, isPolling, pollingEnabled]);

	useEffect(() => {
		const pollInterval = args.pollInterval ?? queryPollInterval;
		if (
			// Global polling enabled
			pollingEnabled &&
			// This query isn't paused
			!args.pause &&
			// We're not already fetching this query
			!result.fetching &&
			!result.stale
		) {
			const id = setTimeout(() => executeQuery(), pollInterval);
			return () => clearTimeout(id);
		}
		return;
	}, [
		args.pause,
		args.pollInterval,
		executeQuery,
		pollingEnabled,
		queryPollInterval,
		result.fetching,
		result.stale,
	]);

	return query;
}
