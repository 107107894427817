import { InfoMinor } from '@sixriver/lighthouse-icons';
import { Icon, Tooltip } from '@sixriver/lighthouse-web-community';

import { JobAllocationMethod } from '../../api/fulfillment-api/types';
import { Toteboard } from '../../components/Toteboard';
import { useIsSortationEnabled, useJobAllocationMethods } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';

export interface OrdersToteboardProps {
	totalCount?: number;
	unassignedCount?: number;
	inProgressCount?: number;
	completedCount?: number;
	cancelledCount?: number;
	interruptedCount?: number;
	assignedToBatchCount?: number;
	assignedToSortWallCount?: number;
}

export function OrdersToteboard({
	totalCount = 0,
	unassignedCount = 0,
	inProgressCount = 0,
	completedCount = 0,
	interruptedCount = 0,
	assignedToBatchCount = 0,
	assignedToSortWallCount = 0,
}: OrdersToteboardProps) {
	const { messages, formatNumber } = useLocalization();
	const isSortationEnabled = useIsSortationEnabled();
	const jobAllocationMethods = useJobAllocationMethods();
	const toteboardItems = [
		{
			label: messages.totalOrders,
			primaryValue: <span>{formatNumber(totalCount)}</span>,
		},
		{
			label: messages.unassigned,
			primaryValue: <span>{formatNumber(unassignedCount)}</span>,
		},
		...(isSortationEnabled
			? [
					{
						label: messages.assignedToWall,
						primaryValue: <span>{formatNumber(assignedToSortWallCount)}</span>,
					},
			  ]
			: []),
		...(jobAllocationMethods.includes(JobAllocationMethod.BulkOrders)
			? [
					{
						label: messages.assignedToBatch,
						primaryValue: <span>{formatNumber(assignedToBatchCount)}</span>,
					},
			  ]
			: []),
		{
			label: messages.inProgress,
			primaryValue: <span>{formatNumber(inProgressCount)}</span>,
		},
		{
			label: messages.completedToday,
			primaryValue: <span>{formatNumber(completedCount)}</span>,
			tooltip: (
				<Tooltip content={messages.orderHelperTextCompleted} dismissOnMouseOut>
					<Icon source={InfoMinor} color="subdued" />
				</Tooltip>
			),
		},
		{
			label: messages.interrupted,
			primaryValue: <span>{formatNumber(interruptedCount)}</span>,
		},
	];

	return <Toteboard items={toteboardItems} />;
}
