import { IndexTable, TextStyle } from '@sixriver/lighthouse-web-community';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { SourceAddressPopover } from './SourceAddressPopover';
import { GetOutboundJobLineFragment } from '../../../../pages/OutboundJob/graphql/GetLines.w-api-graphql';
import * as routes from '../../../../routes';
import { NoData } from '../../../NoData';

export interface RouteMatchParams {
	outboundJobId: string;
}

const getParams = (tab: string) => {
	const searchParams = new URLSearchParams();

	searchParams.set('tab', tab);

	return '?' + searchParams.toString();
};

interface Props {
	line: GetOutboundJobLineFragment;
}

export function SourceAddressCell({ line }: Props): JSX.Element {
	const history = useHistory();
	const {
		params: { outboundJobId },
	} = useRouteMatch<RouteMatchParams>();
	const batchPickTasks = line.tasks.filter((task) => task.type === 'batchPick');

	if (batchPickTasks.length <= 1) {
		if (line.storageLocation?.address) {
			return (
				<IndexTable.Cell>
					<TextStyle>{line.storageLocation.address}</TextStyle>
				</IndexTable.Cell>
			);
		}
	}
	if (batchPickTasks.length > 1) {
		return (
			<IndexTable.Cell>
				<SourceAddressPopover
					tasks={batchPickTasks}
					onViewJobHistory={() =>
						history.push(`${routes.outboundJob(outboundJobId)}${getParams('history')}`)
					}
				/>
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
