import { IndexTable, TextStyle } from '@sixriver/lighthouse-web-community';

import { LineStatus } from '../../../api/fulfillment-api/types';
import { useLocalization } from '../../../hooks/useLocalization';
import { GetOutboundJobLineFragment } from '../../../pages/OutboundJob/graphql/GetLines.w-api-graphql';
import { NoData } from '../../NoData';

interface ReasonCellProps {
	line: GetOutboundJobLineFragment;
}

export function ReasonCell({ line }: ReasonCellProps) {
	const { messages } = useLocalization();

	if (line.status?.includes(LineStatus.DamagedProduct)) {
		return (
			<IndexTable.Cell>
				<TextStyle>{messages.exceptionTypes.DAMAGED_PRODUCT}</TextStyle>
			</IndexTable.Cell>
		);
	}

	if (line.status?.includes(LineStatus.MissingProduct)) {
		return (
			<IndexTable.Cell>
				<TextStyle>{messages.exceptionTypes.MISSING_PRODUCT}</TextStyle>
			</IndexTable.Cell>
		);
	}

	if (line.status?.includes(LineStatus.WorkflowException)) {
		return (
			<IndexTable.Cell>
				<TextStyle>{messages.exceptionTypes.WORKFLOW_EXCEPTION}</TextStyle>
			</IndexTable.Cell>
		);
	}

	return <NoData />;
}
