import { AuthProvider } from '@sixriver/react-support';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { BusyIndicatorProvider } from '../BusyIndicatorProvider';
import { ErrorBoundary } from '../ErrorBoundary';
import { GraphQLProvider } from '../GraphQLProvider';
import { LighthouseAppProvider } from '../LighthouseAppProvider';
import { PollingProvider } from '../PollingProvider/PollingProvider';
import { ToastProvider } from '../ToastProvider';

const queryClient = new QueryClient();

export function AppProvider({ children }: PropsWithChildren<Record<never, any>>) {
	return (
		<LighthouseAppProvider>
			<ErrorBoundary>
				<PollingProvider>
					<BrowserRouter basename={import.meta.env.BASE_URL}>
						<GraphQLProvider>
							<QueryClientProvider client={queryClient}>
								<AuthProvider>
									<BusyIndicatorProvider>
										<ToastProvider>{children}</ToastProvider>
									</BusyIndicatorProvider>
								</AuthProvider>
							</QueryClientProvider>
						</GraphQLProvider>
					</BrowserRouter>
				</PollingProvider>
			</ErrorBoundary>
		</LighthouseAppProvider>
	);
}
