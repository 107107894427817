import { CombinedError } from 'urql';

import { useGetConfigQuery } from './GetConfig.f-api-graphql';
import { Config } from '../../graphql/derived';

export function useConfig(isAuthenticated = true): {
	config: Config | undefined;
	error: CombinedError | undefined;
	fetching: boolean;
} {
	const [configQuery] = useGetConfigQuery({
		pause: !isAuthenticated,
		requestPolicy: 'cache-first',
	});

	return {
		config: configQuery.data?.config,
		error: configQuery.error,
		fetching: configQuery.fetching,
	};
}
