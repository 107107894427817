import { TagList } from '@sixriver/map-element';
import { MapStack } from '@sixriver/map-io';
import { useEffect, useState } from 'react';

import { MapPointConfig } from '../../api/fulfillment-api/types';

/**
 * This hook creates "tags" needed by the Map API to determine which workflow
 * points are disabled (grayed out).
 */
export function useTags(mapStack: MapStack, configs: MapPointConfig[]) {
	const [tags, setTags] = useState<TagList>({});

	useEffect(() => {
		if (mapStack && configs.length) {
			const tags: TagList = {};
			const features = mapStack.workflowPoints.features;

			configs.forEach((config) => {
				// configs do not have IDs (doh!) so we need to cross-reference them with the map stack
				const feature = features.find((f: any) => f?.properties?.name === config.mapPointName);
				if (feature) {
					tags[feature?.properties?.id] = config.enabled
						? { pointType: mapLabelsToPointType(feature) }
						: { disabled: true };
				}
			});

			setTags(tags);
		}
	}, [mapStack, configs]);

	return tags;
}

function mapLabelsToPointType(feature: any) {
	const labelType = feature?.properties?.labels?.find((label: string) =>
		['charge', 'preCharge', 'picking', 'induct', 'putaway', 'takeoff'].includes(label),
	);
	return labelType;
}
