import { Banner, BannerStatus } from '@sixriver/lighthouse-web-community';

export interface DismissibleAlertBannerProps extends NonDismissibleAlertBannerProps {
	onDismiss: () => void;
}

export function DismissibleAlertBanner(props: DismissibleAlertBannerProps): JSX.Element {
	return (
		<Banner title={props.title} status={props.status} onDismiss={props.onDismiss}>
			<p>{props.description}</p>
		</Banner>
	);
}

export interface NonDismissibleAlertBannerProps {
	title: string;
	description: string;
	status: BannerStatus;
}

export function NonDismissibleAlertBanner(props: NonDismissibleAlertBannerProps): JSX.Element {
	return (
		<Banner title={props.title} status={props.status}>
			<p>{props.description}</p>
		</Banner>
	);
}
