import * as Sentry from '@sentry/react';
import { AlertMinor, CircleTickMinor } from '@sixriver/lighthouse-icons';
import { Icon } from '@sixriver/lighthouse-web-community';

import { AttemptResultCode } from '../../api/edge/types';

export interface AttemptStatusIconProps {
	sendResult: AttemptResultCode | undefined | null | '';
}

export function AttemptStatusIcon(props: AttemptStatusIconProps): JSX.Element | null {
	if (!props.sendResult) {
		return null;
	}
	switch (props.sendResult) {
		case AttemptResultCode.Success:
			return <Icon source={CircleTickMinor} color="success" />;
		case AttemptResultCode.DeliverySuppressed:
		case AttemptResultCode.Failed:
		case AttemptResultCode.MessageTypeNotEnabled:
		case AttemptResultCode.SiteNameMismatch:
			return <Icon source={AlertMinor} color="critical" />;
		default:
			Sentry.captureException({
				message: 'Could not render AttemptStatusIcon, unknown sendResult',
				sendResult: props.sendResult,
			});
			return null;
	}
}
