import { SelectOption } from '@sixriver/lighthouse-web-community';
import { useCallback } from 'react';

import { useGetContainersSummaryQuery } from './graphql/GetContainersSummary.f-api-graphql';
import { useGetLocationsSummaryQuery } from './graphql/GetLocationsSummary.f-api-graphql';
import { getJobFlowRuleFilters } from '../../helpers/job-flow-rule-filters';
import { useServiceConfig } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { useWorkAreas } from '../../hooks/useWorkAreas';
import { useGetAislesQuery } from '../Locations/graphql/GetAisles.f-api-graphql';

export function useWorkAreaOptions(): SelectOption[] {
	const { data: workAreas } = useWorkAreas();

	return workAreas.map((area) => {
		return {
			label: area.displayName || 'N/A',
			value: area.mapChunkId || 'N/A',
		};
	});
}

export function useAisleOptions(): SelectOption[] {
	const [{ data }] = useGetAislesQuery({
		requestPolicy: 'cache-first',
	});

	return (data?.aisles || [])
		.sort((a, b) => (a.externalId > b.externalId ? 1 : -1))
		.map((aisle) => {
			return {
				label: aisle.externalId,
				value: aisle.externalId,
			};
		});
}

export function useAddressOptions(searchText?: string): SelectOption[] {
	const [{ data }] = useGetLocationsSummaryQuery({
		requestPolicy: 'cache-first',
		variables: {
			searchText,
		},
	});

	return (data?.locations.results || []).map((result) => {
		return {
			label: result.address,
			value: result.address,
		};
	});
}

export function useContainerOptions(searchText?: string): SelectOption[] {
	const [{ data }] = useGetContainersSummaryQuery({
		requestPolicy: 'cache-first',
		variables: {
			searchText,
		},
	});

	return (data?.containers.results || []).map((result) => {
		return {
			label: result.externalId,
			value: result.externalId,
		};
	});
}

export function useEquipmentName() {
	const { messages } = useLocalization();

	return (equipmentType: string) => {
		if (equipmentType === 'chuck') {
			return 'Chuck';
		}

		// the key might not be in our list of known equipment types
		const key = equipmentType as keyof typeof messages.equipmentTypes;
		return messages.equipmentTypes[key] || equipmentType;
	};
}

export function useWorkAreaName() {
	const workAreasQuery = useWorkAreas();

	return (mapChunkId: string): string => {
		const area = workAreasQuery.data.find((area) => area.mapChunkId === mapChunkId);
		return area?.displayName || '?';
	};
}

export function useFilterLabel() {
	const { data } = useServiceConfig();
	const filters = getJobFlowRuleFilters(data);
	const callback = useCallback(
		(key: string) => {
			const filter = filters.find((f) => 'data.' + f.key === key);
			return filter?.label || key;
		},
		[filters],
	);
	return callback;
}
