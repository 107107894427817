// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetProductQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetProductQuery = { __typename?: 'Query', product?: { __typename?: 'AssetType', id: string, customerIdentifier: string, scanValues: Array<string>, name: string, kind: string, image?: string | null, description: string, length?: number | null, width?: number | null, height?: number | null, weight?: number | null, attributes?: any | null } | null };


export const GetProductDocument = gql`
    query wapi_GetProduct($id: String!) {
  product(id: $id) {
    id
    customerIdentifier
    scanValues
    name
    kind
    image
    description
    length
    width
    height
    weight
    attributes
  }
}
    `;

export function useGetProductQuery(options: Omit<Urql.UseQueryArgs<GetProductQueryVariables>, 'query'>) {
  return Urql.useQuery<GetProductQuery, GetProductQueryVariables>({ query: GetProductDocument, ...options });
};