import { Card, Heading, Pagination, Stack } from '@sixriver/lighthouse-web-community';
import { useCallback, useState } from 'react';

import { LinesFilter } from './LinesFilter';
import { LinesProvider } from './LinesProvider';
import { LinesTable, LinesTableColumn } from './LinesTable';
import { LinesTab, LinesTabs } from './LinesTabs';
import {
	JobLinesCountDocument,
	JobLinesCountQuery,
	JobLinesCountQueryVariables,
} from './graphql/JobLinesCount.f-api-graphql';
import { LinesViews } from '../../api/fulfillment-api/types';
import { GetExceptionOrderQueryJob, GetJobQueryJob, GetOrderQueryJob } from '../../graphql/derived';
import { getPageSize } from '../../helpers/page-size';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useQueryState } from '../../hooks/useQueryState';
import {
	GetLinesDocument,
	GetLinesQuery,
	GetLinesQueryVariables,
} from '../../pages/OutboundJob/graphql/GetLines.w-api-graphql';

const SEARCH_TEXT_KEY = 'linesQuery';
const SELECTED_TAB = 'selectedTab';

export type GQLJobFragment = GetOrderQueryJob | GetJobQueryJob | GetExceptionOrderQueryJob;

export interface LinesProps {
	job: GQLJobFragment;
	columns: (LinesTableColumn | null)[];
	tabs?: LinesTab[];
	loading?: boolean;
}

export function Lines({ job, columns, tabs = [], loading }: LinesProps) {
	const { messages } = useLocalization();
	const [selectedTab, setSelectedTab] = useQueryState<LinesViews>(SELECTED_TAB, LinesViews.All);
	const [searchText, setSearchText] = useQueryState(SEARCH_TEXT_KEY, '');
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const [jobLinesCountQuery] = usePollingQuery<JobLinesCountQuery, JobLinesCountQueryVariables>({
		query: JobLinesCountDocument,
		variables: {
			id: job.id,
			searchText: searchText.length >= 3 ? searchText : undefined,
		},
	});
	const [getLinesQuery, refetchGetLinesQuery] = usePollingQuery<
		GetLinesQuery,
		GetLinesQueryVariables
	>({
		query: GetLinesDocument,
		variables: {
			after: paginationCursors[0],
			first: getPageSize(),
			id: job.id,
			searchText: searchText.length >= 3 ? searchText : undefined,
		},
	});
	const isLoading = loading ?? getLinesQuery.fetching;

	const lines = getLinesQuery.data?.job?.lines?.edges ?? [];
	const linesPageInfo = getLinesQuery.data
		? getLinesQuery.data?.job?.lines.pageInfo
		: { endCursor: null, hasNextPage: false, hasPreviousPage: false };
	const counts = {
		all: jobLinesCountQuery.data?.All.count,
		complete: jobLinesCountQuery.data?.Completed.count,
		exceptions: jobLinesCountQuery.data?.Exceptions.count,
		inProgress: jobLinesCountQuery.data?.InProgress.count,
		unassigned: jobLinesCountQuery.data?.Unassigned.count,
	};
	const totalLinesCount = counts.all ?? 0;
	const totalLinesPages = Math.max(Math.trunc(totalLinesCount / getPageSize()), 1);

	const handleOnNextPage = useCallback(() => {
		const endCursor = linesPageInfo?.endCursor ?? undefined;
		if (endCursor) {
			setPaginationCursors((current) => [endCursor].concat(current));
			refetchGetLinesQuery();
		}
	}, [linesPageInfo?.endCursor, refetchGetLinesQuery]);

	const handleOnPreviousPage = useCallback(() => {
		setPaginationCursors((current) => current.slice(1));
		refetchGetLinesQuery();
	}, [refetchGetLinesQuery]);

	return (
		<LinesProvider job={job}>
			<Card>
				<Card.Section>
					<Heading>{messages.lines}</Heading>
				</Card.Section>
				<Card.Section fullWidth flush>
					<LinesTabs tabs={tabs} counts={counts} selected={selectedTab} onSelect={setSelectedTab} />
					<LinesFilter queryValue={searchText} onQueryChange={(value) => setSearchText(value)} />
					<LinesTable loading={isLoading} data={lines} columns={columns} />
				</Card.Section>
				<Card.Section>
					<Stack distribution="center">
						<Pagination
							label={`${paginationCursors.length + 1} ${messages.of} ${totalLinesPages}`}
							hasNext={linesPageInfo?.hasNextPage}
							hasPrevious={linesPageInfo?.hasPreviousPage}
							onNext={handleOnNextPage}
							onPrevious={handleOnPreviousPage}
						/>
					</Stack>
				</Card.Section>
			</Card>
		</LinesProvider>
	);
}
