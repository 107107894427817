// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOutboundJobLineTaskFragment = { __typename?: 'Task', id: string, type: string, sourceLocationId?: string | null, sourceLocationAddress?: string | null, isUserDirectedProduct: boolean, isUserDirectedSourceLocation: boolean, isShorted: boolean, quantity: number, doneQuantity: number };

export type GetOutboundJobLineFragment = { __typename?: 'Line', id: string, status?: string | null, quantity: number, doneQuantity: number, completedAt?: any | null, isShorted: boolean, productId: string, productName?: string | null, productCustomerIdentifier?: string | null, productDescription?: string | null, tasks: Array<{ __typename?: 'Task', id: string, type: string, sourceLocationId?: string | null, sourceLocationAddress?: string | null, isUserDirectedProduct: boolean, isUserDirectedSourceLocation: boolean, isShorted: boolean, quantity: number, doneQuantity: number }>, workArea?: { __typename?: 'WorkArea', id: string } | null, storageLocation: { __typename?: 'LineStorageLocation', address?: string | null } };

export type GetOutboundJobLineEdgeFragment = { __typename?: 'LineEdge', node: { __typename?: 'Line', id: string, status?: string | null, quantity: number, doneQuantity: number, completedAt?: any | null, isShorted: boolean, productId: string, productName?: string | null, productCustomerIdentifier?: string | null, productDescription?: string | null, tasks: Array<{ __typename?: 'Task', id: string, type: string, sourceLocationId?: string | null, sourceLocationAddress?: string | null, isUserDirectedProduct: boolean, isUserDirectedSourceLocation: boolean, isShorted: boolean, quantity: number, doneQuantity: number }>, workArea?: { __typename?: 'WorkArea', id: string } | null, storageLocation: { __typename?: 'LineStorageLocation', address?: string | null } } };

export type GetOutboundJobLinesPageInfoFragment = { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null };

export type GetLinesQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetLinesQuery = { __typename?: 'Query', job?: { __typename?: 'Job', lines: { __typename?: 'LineConnection', edges: Array<{ __typename?: 'LineEdge', node: { __typename?: 'Line', id: string, status?: string | null, quantity: number, doneQuantity: number, completedAt?: any | null, isShorted: boolean, productId: string, productName?: string | null, productCustomerIdentifier?: string | null, productDescription?: string | null, tasks: Array<{ __typename?: 'Task', id: string, type: string, sourceLocationId?: string | null, sourceLocationAddress?: string | null, isUserDirectedProduct: boolean, isUserDirectedSourceLocation: boolean, isShorted: boolean, quantity: number, doneQuantity: number }>, workArea?: { __typename?: 'WorkArea', id: string } | null, storageLocation: { __typename?: 'LineStorageLocation', address?: string | null } } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } } | null };

export const GetOutboundJobLineTaskFragmentDoc = gql`
    fragment GetOutboundJobLineTask on Task {
  id
  type
  sourceLocationId
  sourceLocationAddress
  isUserDirectedProduct
  isUserDirectedSourceLocation
  isShorted
  quantity
  doneQuantity
}
    `;
export const GetOutboundJobLineFragmentDoc = gql`
    fragment GetOutboundJobLine on Line {
  id
  status
  quantity
  doneQuantity
  completedAt
  isShorted
  tasks {
    ...GetOutboundJobLineTask
  }
  productId
  productName
  productCustomerIdentifier
  productDescription
  workArea {
    id
  }
  storageLocation {
    address
  }
}
    ${GetOutboundJobLineTaskFragmentDoc}`;
export const GetOutboundJobLineEdgeFragmentDoc = gql`
    fragment GetOutboundJobLineEdge on LineEdge {
  node {
    ...GetOutboundJobLine
  }
}
    ${GetOutboundJobLineFragmentDoc}`;
export const GetOutboundJobLinesPageInfoFragmentDoc = gql`
    fragment GetOutboundJobLinesPageInfo on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const GetLinesDocument = gql`
    query wapi_GetLines($id: String!, $searchText: String, $after: String, $first: Int) {
  job(id: $id) {
    lines(searchText: $searchText, after: $after, first: $first) {
      edges {
        ...GetOutboundJobLineEdge
      }
      pageInfo {
        ...GetOutboundJobLinesPageInfo
      }
    }
  }
}
    ${GetOutboundJobLineEdgeFragmentDoc}
${GetOutboundJobLinesPageInfoFragmentDoc}`;

export function useGetLinesQuery(options: Omit<Urql.UseQueryArgs<GetLinesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLinesQuery, GetLinesQueryVariables>({ query: GetLinesDocument, ...options });
};