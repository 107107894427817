import { Card } from '@sixriver/lighthouse-web-community';

import { SortationStatus } from '../../../api/fulfillment-api/types';
import { CardDetails } from '../../../components/CardDetails';
import { CopyButton } from '../../../components/CopyButton';
import { DateTime } from '../../../components/DateTime';
import { NoData } from '../../../components/NoData';
import { RelativeDateTime } from '../../../components/RelativeDateTime';
import { useLocalization } from '../../../hooks/useLocalization';

interface Props {
	loading?: boolean;
	sortWall?: any;
}

export function SortWallDetails({ loading, sortWall }: Props) {
	const { messages } = useLocalization();
	const sortations = sortWall?.sortations.edges ?? [];
	const sortation = sortations[0] ? sortations[0].node : {};

	return (
		<Card title={messages.details}>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							content: sortation.wallExternalId || <NoData />,
							label: messages.batchId,
						},
						{
							content: sortation.mfpId || <NoData />,
							label: messages.assignedKiosk,
						},
						{
							content: sortWall?.takeoffDestination || <NoData />,
							label: messages.takeoffLocation,
						},
						{
							content: sortWall?.expectedShipDate ? (
								<>
									<DateTime date={sortWall?.expectedShipDate} />
									<RelativeDateTime
										date={sortWall?.expectedShipDate}
										mode={sortWall.status === SortationStatus.Sorted ? undefined : 'deadline'}
									/>
								</>
							) : (
								<NoData />
							),
							label: messages.expectedShipDate,
						},
					]}
					secondary={[
						{
							content: (
								<>
									<DateTime date={sortation.createdAt} />
									<RelativeDateTime date={sortation.createdAt} />
								</>
							),
							label: messages.createdAt,
						},
						{
							content: (
								<>
									<DateTime date={sortation.updatedAt} />
									<RelativeDateTime date={sortation.updatedAt} />
								</>
							),
							label: messages.updatedAt,
						},
						{
							content: (
								<>
									<DateTime date={sortation.completedAt} />
									<RelativeDateTime date={sortation.completedAt} />
								</>
							),
							label: messages.completedAt,
						},
					]}
				/>
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							content: sortWall?.id ? <CopyButton text={sortWall.id} /> : null,
							label: messages.sortWallId,
						},
					]}
				/>
			</Card.Section>
		</Card>
	);
}
