import { useDebouncedValue, usePrevious } from '@shopify/react-hooks';
import { Card, Layout, Pagination, Stack } from '@sixriver/lighthouse-web-community';
import { useArrayQueryState, useDateQueryState, useQueryState } from '@sixriver/react-support';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { OutboundJobsFilters } from './OutboundJobsFilters';
import { Alert } from './OutboundJobsFilters/AlertsFilter';
import { OutboundJobsTable } from './OutboundJobsTable';
import { OutboundJobsTab, OutboundJobsTabs } from './OutboundJobsTabs';
import { OutboundJobsToteboard } from './OutboundJobsToteboard';
import {
	GetOutboundJobsCountDocument,
	GetOutboundJobsCountQuery,
	GetOutboundJobsCountQueryVariables,
} from './graphql/GetOuboundJobsCount.w-api-graphql';
import {
	GetOutboundJobsDocument,
	GetOutboundJobsQuery,
	GetOutboundJobsQueryVariables,
} from './graphql/GetOutboundJobs.w-api-graphql';
import {
	GetOutboundJobsCutoffsDocument,
	GetOutboundJobsCutoffsQuery,
	GetOutboundJobsCutoffsQueryVariables,
} from './graphql/GetOutboundJobsCutoffs.f-api-graphql';
import { DatePrecision } from '../../api/fulfillment-api/types';
import { JobOrderBy, JobStatus, PickStrategy, WorkOrderType } from '../../api/warehouse-api/types';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { DateRangeFilter } from '../../components/DateRangeFilter';
import { OptionValues } from '../../components/DateRangeSelect';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { WorkAreaMenu } from '../../components/WorkAreaMenu';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { getMidnight } from '../../helpers/time';
import { useIsWorkAreasEnabled, useJobAllocationMethods } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { usePickStrategies } from '../../hooks/usePickStrategies';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useWorkAreas } from '../../hooks/useWorkAreas';

const DEFAULT_DATE = getMidnight(-7);
const IDS_KEY = 'ids';
const SEARCH_TEXT_KEY = 'query';
const CUTOFF_DATE_KEY = 'cutoff';
const PICK_STRATEGY_KEY = 'pickStrategies';
const HISTORY_DATE_LIMITER_KEY = 'historyDateLimiter';
const PAGINATION_CURSORS_KEY = 'paginationCursors';
const ALERTS_KEY = 'alerts';
const STATUSES_KEY = 'statuses';
const WORK_ORDER_TYPES_KEY = 'workOrderTypes';
const WORK_AREA_IDS = 'workAreaIds';
const USED_EQUIPMENT_TYPES_KEY = 'usedEquipmentTypes';
const IS_ON_CHUCK_KEY = 'isOnChuck';

export function OutboundJobs() {
	const { messages } = useLocalization();
	const { data: workAreas } = useWorkAreas();
	const [selectedTab, setSelectedTab] = useState<OutboundJobsTab>(OutboundJobsTab.All);
	const [paginationCursors, setPaginationCursors] = useArrayQueryState<string[]>(
		PAGINATION_CURSORS_KEY,
		[],
	);
	const [historyDateLimiter, setHistoryDateLimiter] = useDateQueryState<Date>(
		HISTORY_DATE_LIMITER_KEY,
		DEFAULT_DATE,
	);
	const [searchText, setSearchText] = useQueryState(SEARCH_TEXT_KEY, '');
	const [isOnChuck, setIsOnChuck] = useQueryState<true | undefined>(
		IS_ON_CHUCK_KEY,
		undefined,
		(value) => {
			if (value === undefined) {
				return undefined;
			}
			return true;
		},
	);
	const [selectedStatuses, setSelectedStatuses] = useArrayQueryState<JobStatus[]>(STATUSES_KEY, []);
	const [selectedAlerts, setSelectedAlerts] = useArrayQueryState<Alert[]>(ALERTS_KEY, []);
	const [selectedUsedEquipmentTypes, setSelectedUsedEquipmentTypes] = useArrayQueryState<string[]>(
		USED_EQUIPMENT_TYPES_KEY,
		[],
	);
	const [selectedSort, setSelectedSort] = useState<JobOrderBy[]>([]);
	const [selectedCutoffDate, setSelectedCutoffDate] = useDateQueryState<Date | undefined>(
		CUTOFF_DATE_KEY,
		undefined,
	);
	const [selectedPickStrategies, setselectedPickStrategies] = useArrayQueryState<PickStrategy[]>(
		PICK_STRATEGY_KEY,
		[],
	);
	const [selectedWorkOrderTypes, setselectedWorkOrderTypes] = useArrayQueryState<WorkOrderType[]>(
		WORK_ORDER_TYPES_KEY,
		[],
	);
	const [selectedWorkAreaIds, setSelectedWorkAreaIds] = useArrayQueryState<string[]>(
		WORK_AREA_IDS,
		[],
	);
	const [ids, setIds] = useArrayQueryState<string[]>(IDS_KEY, []);
	const jobAllocationMethods = useJobAllocationMethods();
	const availablePickStrategies = usePickStrategies(jobAllocationMethods);
	const showPickStrategy = availablePickStrategies.length > 1;
	const showWorkArea = useIsWorkAreasEnabled();
	const debouncedSearchText = useDebouncedValue(searchText);

	/**
	 * Queries
	 */
	const queryParamsForJobsAndCounts = useMemo(
		() => ({
			createdAtFrom: historyDateLimiter,
			expectedShipDateFrom: selectedCutoffDate ?? undefined,
			expectedShipDateTo: selectedCutoffDate
				? new Date(selectedCutoffDate.getTime() + 60 * 1000)
				: undefined,
			ids,
			isAwaitingInventory:
				(selectedAlerts.length > 0 && selectedAlerts.includes(Alert.AwaitingInventory)) ||
				undefined,
			isHealJob:
				(selectedAlerts.length > 0 && selectedAlerts.includes(Alert.IsResolution)) || undefined,
			isLate: (selectedAlerts.length > 0 && selectedAlerts.includes(Alert.Late)) || undefined,
			isOnChuck,
			isShorted:
				(selectedAlerts.length > 0 && selectedAlerts.includes(Alert.Exception)) || undefined,
			pickStrategies: selectedPickStrategies.length > 0 ? selectedPickStrategies : undefined,
			searchText: debouncedSearchText.length >= MIN_QUERY_LENGTH ? debouncedSearchText : undefined,
			statuses: selectedStatuses,
			workAreaIds: selectedWorkAreaIds.length > 0 ? selectedWorkAreaIds : undefined,
			workOrderTypes: selectedWorkOrderTypes.length > 0 ? selectedWorkOrderTypes : undefined,
		}),
		[
			debouncedSearchText,
			historyDateLimiter,
			ids,
			selectedAlerts,
			selectedCutoffDate,
			selectedPickStrategies,
			selectedStatuses,
			selectedWorkOrderTypes,
			selectedWorkAreaIds,
			isOnChuck,
		],
	);
	const [{ data: countData }] = usePollingQuery<
		GetOutboundJobsCountQuery,
		GetOutboundJobsCountQueryVariables
	>({
		query: GetOutboundJobsCountDocument,
		variables: {
			...queryParamsForJobsAndCounts,
			usedEquipmentTypes: selectedUsedEquipmentTypes,
		},
	});
	const totalCount = countData?.TotalCount.count ?? 0;
	const totalPages = Math.max(Math.ceil(totalCount / getPageSize()), 1);

	const [getOutboundJobsQuery] = usePollingQuery<
		GetOutboundJobsQuery,
		GetOutboundJobsQueryVariables
	>({
		query: GetOutboundJobsDocument,
		variables: {
			...queryParamsForJobsAndCounts,
			after: paginationCursors[0],
			first: getPageSize(),
			orderBy: selectedSort.length > 0 ? selectedSort : undefined,
			usedEquipmentTypes: selectedUsedEquipmentTypes,
		},
	});
	const ordersPageInfo = getOutboundJobsQuery.data?.jobs.pageInfo;
	const outboundJobs = getOutboundJobsQuery.data?.jobs.edges;

	const [getOutboundJobsCutoffsQuery] = usePollingQuery<
		GetOutboundJobsCutoffsQuery,
		GetOutboundJobsCutoffsQueryVariables
	>({
		query: GetOutboundJobsCutoffsDocument,
		variables: {
			createdAtFrom: historyDateLimiter,
			datePrecision: DatePrecision.Minute,
		},
	});

	const cutoffDates = getOutboundJobsCutoffsQuery?.data?.cutoffForJobsV2.dates.map(
		(date) => new Date(date as string),
	);

	/**
	 * Filter functions
	 */
	const handleStatusesRemove = useCallback(() => {
		setSelectedStatuses([]);
		setSelectedTab(OutboundJobsTab.All);
	}, [setSelectedStatuses, setSelectedTab]);
	const handleAlertsRemove = useCallback(() => {
		setSelectedAlerts([]);
		setSelectedTab(OutboundJobsTab.All);
	}, [setSelectedAlerts, setSelectedTab]);
	const handleCutoffDateRemove = useCallback(() => {
		setSelectedCutoffDate(undefined);
	}, [setSelectedCutoffDate]);
	const handlePickStrategyRemove = useCallback(() => {
		setselectedPickStrategies([]);
	}, [setselectedPickStrategies]);
	const handleWorkOrderTypeRemove = useCallback(() => {
		setselectedWorkOrderTypes([]);
	}, [setselectedWorkOrderTypes]);
	const handleWorkAreaRemove = useCallback(() => {
		setSelectedWorkAreaIds([]);
	}, [setSelectedWorkAreaIds]);
	const handleIdsRemove = useCallback(() => {
		setIds([]);
	}, [setIds]);
	const handleUsedEquipmentTypesRemove = useCallback(() => {
		setSelectedUsedEquipmentTypes([]);
		setSelectedTab(OutboundJobsTab.All);
	}, [setSelectedUsedEquipmentTypes, setSelectedTab]);

	const handleClearAllFilters = useCallback(() => {
		setSelectedStatuses([]);
		setSelectedAlerts([]);
		setSelectedCutoffDate(undefined);
		setselectedPickStrategies([]);
		setselectedWorkOrderTypes([]);
		setSelectedWorkAreaIds([]);
		// TODO should this clear cutoff date filter when switching tabs?
		// TODO should this clear pick strategy filter when switching tabs?
	}, [
		setSelectedAlerts,
		setSelectedCutoffDate,
		setSelectedStatuses,
		setSelectedWorkAreaIds,
		setselectedPickStrategies,
		setselectedWorkOrderTypes,
	]);

	const handleHistoryLimitChange = useCallback(
		(date: Date) => {
			// clearing cutoff filter because the list is dynamically determined by history limitor range
			handleCutoffDateRemove();
			setHistoryDateLimiter(date);
		},
		[handleCutoffDateRemove, setHistoryDateLimiter],
	);

	useEffect(() => {
		// don't do this here! it prevents linking into this page --a.m.
		// handleClearAllFilters();

		switch (selectedTab) {
			case OutboundJobsTab.Late:
				setSelectedAlerts([Alert.Late]);
				return;
			case OutboundJobsTab.Exceptions:
				setSelectedAlerts([Alert.Exception]);
				return;
			case OutboundJobsTab.AwaitingInventory:
				setSelectedAlerts([Alert.AwaitingInventory]);
				return;
			case OutboundJobsTab.Interrupted:
				setSelectedStatuses([JobStatus.Interrupted]);
				return;
		}
	}, [selectedTab, setSelectedAlerts, setSelectedStatuses]);

	/**
	 * Pagination
	 * */
	const handleOnNextPage = useCallback(() => {
		window.scrollTo(0, 0);
		const endCursor = ordersPageInfo?.endCursor ?? undefined;
		if (endCursor) {
			setPaginationCursors([endCursor].concat(paginationCursors));
		}
	}, [ordersPageInfo?.endCursor, paginationCursors, setPaginationCursors]);

	const handleOnPreviousPage = useCallback(() => {
		window.scrollTo(0, 0);
		setPaginationCursors(paginationCursors.slice(1));
	}, [paginationCursors, setPaginationCursors]);

	// // Previous filters
	const prevQueryParams = usePrevious(queryParamsForJobsAndCounts);
	useEffect(() => {
		// Reset pagination when changing views or filters
		if (!isEqual(prevQueryParams, queryParamsForJobsAndCounts)) {
			setPaginationCursors([]);
		}
	}, [prevQueryParams, queryParamsForJobsAndCounts, setPaginationCursors]);

	return (
		<>
			<AutoRefreshPage
				queries={[getOutboundJobsQuery, getOutboundJobsCutoffsQuery, getOutboundJobsQuery]}
				fullWidth
				title={messages.outboundJobs}
				primaryAction={
					showWorkArea ? (
						<WorkAreaMenu
							workAreas={workAreas}
							selectedIds={selectedWorkAreaIds || []}
							onChange={(selected: string[]) => {
								setSelectedWorkAreaIds(selected);
							}}
						/>
					) : null
				}
			>
				<Layout>
					<Layout.Section>
						<Stack distribution="equalSpacing">
							<DateRangeFilter
								options={[
									OptionValues.today,
									OptionValues.last3Days,
									OptionValues.last7Days,
									OptionValues.last30Days,
									OptionValues.last90Days,
									OptionValues.last180Days,
								]}
								selectedOption={historyDateLimiter}
								title={messages.createdAt}
								onChange={handleHistoryLimitChange}
							/>
						</Stack>
					</Layout.Section>

					<Layout.Section>
						<OutboundJobsToteboard
							totalCount={countData?.StaticTotalCount.count}
							unassignedResolutionCount={countData?.StaticTotalCount.unassigned}
							pickingCount={countData?.StaticTotalCount.picking}
							pickedCount={countData?.StaticTotalCount.picked}
							readyToPackCount={countData?.StaticTotalCount.ready}
							packingCount={countData?.StaticTotalCount.packing}
							packedCount={countData?.StaticTotalCount.packed}
							interruptedCount={countData?.StaticTotalCount.interrupted}
							readyToSortCount={countData?.StaticTotalCount.sortable}
							sortingCount={countData?.StaticTotalCount.sorting}
							sortededCount={countData?.StaticTotalCount.sorted}
						/>
					</Layout.Section>

					<Layout.Section>
						<Card>
							<div style={{ paddingBottom: '2rem' }}>
								<OutboundJobsTabs
									tabs={[
										OutboundJobsTab.All,
										OutboundJobsTab.Late,
										OutboundJobsTab.Exceptions,
										OutboundJobsTab.AwaitingInventory,
									]}
									lateCount={countData?.TotalCount.late ?? 0}
									exceptionsCount={countData?.TotalCount.shorted ?? 0}
									interruptedCount={countData?.TotalCount.interrupted ?? 0}
									awaitingInventoryCount={countData?.TotalCount.awaitingInventory ?? 0}
									selected={selectedTab}
									onSelect={setSelectedTab}
								/>
							</div>

							<div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
								<OutboundJobsFilters
									queryValue={searchText}
									onClearAll={handleClearAllFilters}
									onQueryChange={setSearchText}
									selectedSort={selectedSort}
									onSortChange={setSelectedSort}
									selectedCutoffDate={selectedCutoffDate}
									cutoffDates={cutoffDates}
									onCutoffChange={setSelectedCutoffDate}
									onCutoffRemove={handleCutoffDateRemove}
									selectedStatuses={selectedStatuses}
									onStatusChange={setSelectedStatuses}
									onStatusRemove={handleStatusesRemove}
									selectedAlerts={selectedAlerts}
									onAlertChange={setSelectedAlerts}
									onAlertRemove={handleAlertsRemove}
									showPickStrategy={showPickStrategy}
									selectedPickStrategies={selectedPickStrategies}
									availablePickStrategies={availablePickStrategies}
									onPickStrategyChange={setselectedPickStrategies}
									onPickStrategyRemove={handlePickStrategyRemove}
									selectedWorkOrderTypes={selectedWorkOrderTypes}
									onWorkOrderTypeChange={setselectedWorkOrderTypes}
									onWorkOrderTypeRemove={handleWorkOrderTypeRemove}
									selectedWorkAreaIds={selectedWorkAreaIds}
									workAreas={workAreas}
									onWorkAreaRemove={handleWorkAreaRemove}
									ids={ids}
									onIdsRemove={handleIdsRemove}
									isOnChuck={isOnChuck}
									isOnChuckChange={setIsOnChuck}
									selectedUsedEquipmentTypes={selectedUsedEquipmentTypes}
									onUsedEquipmentTypesChange={setSelectedUsedEquipmentTypes}
									onUsedEquipmentTypesRemove={handleUsedEquipmentTypesRemove}
								/>
							</div>

							<div style={{ padding: '1rem' }}>
								{`${totalCount} ${
									totalCount === 1 ? messages.result.toLowerCase() : messages.results.toLowerCase()
								}`}
							</div>

							<OutboundJobsTable
								loading={getOutboundJobsQuery.fetching}
								data={outboundJobs}
								pageInfo={ordersPageInfo}
								showPickStrategyColumn={showPickStrategy}
								totalFilteredJobs={totalCount}
							/>
							<Card.Section>
								<Stack distribution="center">
									<Pagination
										label={`${paginationCursors.length + 1} ${messages.of} ${totalPages}`}
										hasNext={ordersPageInfo?.hasNextPage}
										hasPrevious={ordersPageInfo?.hasPreviousPage}
										onNext={handleOnNextPage}
										onPrevious={handleOnPreviousPage}
									/>
								</Stack>
							</Card.Section>
						</Card>
					</Layout.Section>

					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</AutoRefreshPage>
		</>
	);
}
