import { Modal, Page, PageActions } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import JobFlowRuleForm from './JobFlowRuleForm';
import { useCloseJobFlowRuleMutation } from './graphql/CloseJobFlowRule.f-api-graphql';
import { useEditJobFlowRuleMutation } from './graphql/EditJobFlowRule.f-api-graphql';
import { useGetJobFlowRuleQuery } from './graphql/GetJobFlowRule.f-api-graphql';
import { JobFlowRuleInput } from '../../api/fulfillment-api/types';
import { Error } from '../../components/Error';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';

export function EditJobFlowRule() {
	const {
		params: { jobFlowRuleId },
	} = useRouteMatch<{ jobFlowRuleId: string }>();

	const { messages } = useLocalization();

	const history = useHistory();

	const { showToast } = useToast();

	const [modalVisible, setModalVisible] = useState(false);

	const [getJobFlowRuleQuery] = useGetJobFlowRuleQuery({
		variables: {
			id: jobFlowRuleId,
		},
	});

	const [editJobFlowRuleMutation, executeEditJobFlowRuleMutation] = useEditJobFlowRuleMutation();

	const [closeJobFlowRuleMutation, executeCloseJobFlowRuleMutation] = useCloseJobFlowRuleMutation();

	const onSubmit = async (input: JobFlowRuleInput) => {
		const { error } = await executeEditJobFlowRuleMutation({ input });

		if (!error) {
			showToast(messages.dataSaved);

			history.push(routes.jobFlowRule(jobFlowRuleId));
		}
	};

	const deleteJobFlowRule = async () => {
		const { error } = await executeCloseJobFlowRuleMutation({ id: jobFlowRuleId });

		setModalVisible(false);

		if (error) {
			showToast(messages.dataNotSaved, true);
			return;
		}

		history.push(routes.jobFlowRules());
	};

	const rule = getJobFlowRuleQuery.data?.jobFlowRule;

	if (getJobFlowRuleQuery.fetching || !rule) {
		return null;
	}

	if (getJobFlowRuleQuery.error || closeJobFlowRuleMutation.error) {
		return <Error graphQLError={getJobFlowRuleQuery.error || closeJobFlowRuleMutation.error} />;
	}

	const input: JobFlowRuleInput = {
		id: rule.id,
		rule: rule.rule,
		ruleDescription: rule.ruleDescription,
		ruleSubType: rule.ruleSubType,
	};

	return (
		<Page
			fullWidth
			title={rule.ruleDescription}
			breadcrumbs={[{ url: routes.jobFlowRule(rule.id) }]}
		>
			<JobFlowRuleForm
				mode="edit"
				data={input}
				error={editJobFlowRuleMutation.error}
				onSubmit={onSubmit}
			/>
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteJobFlowRule,
						destructive: true,
						onAction: () => setModalVisible(true),
						outline: true,
					},
				]}
			/>
			<Modal
				open={modalVisible}
				onClose={() => setModalVisible(false)}
				title={messages.deleteJobFlowRule}
				primaryAction={{
					content: messages.deleteJobFlowRule,
					destructive: true,
					loading: closeJobFlowRuleMutation.fetching,
					onAction: () => void deleteJobFlowRule(),
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.confirmDeleteJobFlowRule}</Modal.Section>
			</Modal>
		</Page>
	);
}
