import { Page } from '@sixriver/lighthouse-web-community';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ContainerForm } from './Container.form';
import { useCreateContainerMutation } from './graphql/CreateContainer.f-api-graphql';
import { AddContainerInput, ContainerType } from '../../api/fulfillment-api/types';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

export function AddContainer(): JSX.Element {
	const { messages } = useLocalization();

	const formDefaults: AddContainerInput = {
		// strings
		barcode: '',

		// ints
		columns: 1,
		description: '',

		// bools
		enabled: true,
		externalId: '',
		height: 1,
		imageURL: '',
		instructions: '',
		inventory: false,

		// floats
		length: 1,
		materialType: '',
		name: '',
		rows: 1,

		// enums
		type: ContainerType.Picking,
		weight: 0,
		width: 1,
	};

	// Routing
	const history = useHistory();

	// Mutations
	const [createContainerMutation, executeCreateContainerMutation] = useCreateContainerMutation();

	// Methods
	const onSubmit = useCallback(
		async (input: AddContainerInput) => {
			const { data } = await executeCreateContainerMutation({ input });
			if (data?.createContainer?.success) {
				history.push(routes.containers());
			}
		},
		[executeCreateContainerMutation, history],
	);

	// Render
	return (
		<Page
			title={messages.newContainer}
			breadcrumbs={[{ content: messages.containers, url: routes.containers() }]}
			fullWidth
		>
			<ContainerForm
				mode="add"
				data={formDefaults}
				onSubmit={onSubmit}
				error={createContainerMutation.error}
			/>
		</Page>
	);
}
