import { Page, PageProps } from '@sixriver/lighthouse-web-community';
import { UseQueryState } from 'urql';

import { AutoRefresh } from '../AutoRefresh';

interface AutoRefreshPageGQLProps extends PageProps {
	queries: Array<UseQueryState>;
}

interface AutoRefreshPageFetchProps extends PageProps {
	discriminatorData: Record<string, unknown>;
}

type AutoRefreshPageProps = AutoRefreshPageGQLProps | AutoRefreshPageFetchProps;

export function AutoRefreshPage(props: AutoRefreshPageProps) {
	const discriminatorData =
		'queries' in props
			? props.queries.map((query) => query.operation?.context.meta?.startTime)
			: props.discriminatorData;

	return (
		<Page {...props}>
			{props.children}
			<AutoRefresh discriminatorData={discriminatorData} />
		</Page>
	);
}
