import { Spinner } from '@sixriver/lighthouse-web-community';

import { useServiceConfig } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { Error } from '../Error';
import styles from './FeatureFlaggedRoute.module.css';

export type FeatureFlaggedRouteOpts = React.PropsWithChildren<{
	flag: string;
}>;

export function FeatureFlaggedRoute(opts: FeatureFlaggedRouteOpts): JSX.Element {
	const { data, fetching } = useServiceConfig();
	const { messages } = useLocalization();

	if (fetching) {
		return (
			<div className={styles.container}>
				<Spinner />
			</div>
		);
	}

	if (!data?.config.managementConsole?.experimentalFlags?.includes(opts.flag)) {
		return <Error heading={messages.problemLoadingPage} />;
	}

	return <>{opts.children}</>;
}
