import { NorthboundMessageType, SouthboundMessageType } from '@sixriver/edge-services-oas';
import { groupBy } from 'lodash';

import { HookMessageType } from '../../api/edge/types';

type MessageTypeOptionsTemplate = {
	[K in NorthboundMessageType | SouthboundMessageType]: { group: MessageTypeGroup };
};

export interface MessageTypeOptionGroup {
	title: MessageTypeGroup;
	options: MessageTypeOption[];
}

export interface MessageTypeOption {
	value: NorthboundMessageType;
	label: string;
}

function createMessageTypeOptions(template: MessageTypeOptionsTemplate): MessageTypeOptionGroup[] {
	const groups = groupBy(Object.entries(template), ([, t]) => t.group);

	return Object.entries(groups).map(([groupName, group]) => {
		return {
			options: group.map(([messageType]) => ({
				label: messageType,
				value: messageType as NorthboundMessageType,
			})),
			title: groupName as MessageTypeGroup,
		};
	});
}

export namespace MessageTypeGroups {
	export const CONFIRMATIONS_GROUP = 'Confirmations';
	export const PICKING_EVENTS_GROUP = 'Picking events';
	export const WORK_ORDER_EVENTS = 'Work order events';
	export const PUTAWAY_EVENTS = 'Putaway events';
	export const SHUTTLE_EVENTS = 'Shuttle events';
	export const INVENTORY_EVENTS = 'Inventory events';
	export const LOCATION_EVENTS = 'Location events';
	export const REQUESTS = 'Requests';
	export const SOUTHBOUND_MESSAGES = 'Southbound';
}

export type MessageTypeGroup = (typeof MessageTypeGroups)[keyof typeof MessageTypeGroups];

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const allMessageTypeOptions = createMessageTypeOptions({
	// requests
	['alternatePickLocations']: { group: MessageTypeGroups.REQUESTS },
	['balanceOnHand']: { group: MessageTypeGroups.REQUESTS },
	['containerValidation']: { group: MessageTypeGroups.REQUESTS },
	['printDataRequest']: { group: MessageTypeGroups.REQUESTS },
	['printRequest']: { group: MessageTypeGroups.REQUESTS },
	['substitutionRequest']: { group: MessageTypeGroups.REQUESTS },
	['productLocations']: { group: MessageTypeGroups.REQUESTS },

	// confirmations
	['acknowledgement']: { group: MessageTypeGroups.CONFIRMATIONS_GROUP },
	['pickTaskAccepted']: { group: MessageTypeGroups.CONFIRMATIONS_GROUP },
	['pickTaskGrouped']: { group: MessageTypeGroups.CONFIRMATIONS_GROUP },
	['pickTaskRejected']: { group: MessageTypeGroups.CONFIRMATIONS_GROUP },
	['eventMessage']: { group: MessageTypeGroups.CONFIRMATIONS_GROUP },
	['containerDestination']: { group: MessageTypeGroups.CONFIRMATIONS_GROUP },
	['laneAssignment']: { group: MessageTypeGroups.CONFIRMATIONS_GROUP },

	// picking events
	['batchFirstContainerInducted']: { group: MessageTypeGroups.PICKING_EVENTS_GROUP },
	['batchLastContainerPickComplete']: { group: MessageTypeGroups.PICKING_EVENTS_GROUP },
	['containerAccepted']: { group: MessageTypeGroups.PICKING_EVENTS_GROUP },
	['containerInducted']: { group: MessageTypeGroups.PICKING_EVENTS_GROUP },
	['containerPacked']: { group: MessageTypeGroups.PICKING_EVENTS_GROUP },
	['containerPickComplete']: { group: MessageTypeGroups.PICKING_EVENTS_GROUP },
	['containerSorted']: { group: MessageTypeGroups.PICKING_EVENTS_GROUP },
	['containerTakenOff']: { group: MessageTypeGroups.PICKING_EVENTS_GROUP },
	['pickTaskPicked']: { group: MessageTypeGroups.PICKING_EVENTS_GROUP },
	['packoutContainerDetermined']: { group: MessageTypeGroups.PICKING_EVENTS_GROUP },
	['packoutPrintServiceRequest']: { group: MessageTypeGroups.PICKING_EVENTS_GROUP },
	['mfpArrived']: { group: MessageTypeGroups.PICKING_EVENTS_GROUP },

	// location events
	['locationPicked']: { group: MessageTypeGroups.LOCATION_EVENTS },
	['locationTasksDone']: { group: MessageTypeGroups.LOCATION_EVENTS },

	// work order events
	['workOrderStatus']: { group: MessageTypeGroups.WORK_ORDER_EVENTS },
	['workOrderComplete']: { group: MessageTypeGroups.WORK_ORDER_EVENTS },

	// inventory events
	['warehouseInventoryChanged']: { group: MessageTypeGroups.INVENTORY_EVENTS },
	['product-created']: { group: MessageTypeGroups.INVENTORY_EVENTS },
	['product-updated']: { group: MessageTypeGroups.INVENTORY_EVENTS },
	['product-inspected']: { group: MessageTypeGroups.INVENTORY_EVENTS },

	// putaway events
	['putawayContainerLineCompleted']: { group: MessageTypeGroups.PUTAWAY_EVENTS },
	['putawayContainerCompleted']: { group: MessageTypeGroups.PUTAWAY_EVENTS },
	['putawayContainerCompletedRollup']: { group: MessageTypeGroups.PUTAWAY_EVENTS },
	['putawayWaveIngested']: { group: MessageTypeGroups.PUTAWAY_EVENTS },
	['putawayCancelContainersResult']: { group: MessageTypeGroups.PUTAWAY_EVENTS },
	['putawayContainerInducted']: { group: MessageTypeGroups.PUTAWAY_EVENTS },

	// shuttle events
	['shuttleWaveIngested']: { group: MessageTypeGroups.SHUTTLE_EVENTS },
	['shuttleContainerAssigned']: { group: MessageTypeGroups.SHUTTLE_EVENTS },
	['shuttleContainerPickupResult']: { group: MessageTypeGroups.SHUTTLE_EVENTS },
	['shuttleContainerDropoffResult']: { group: MessageTypeGroups.SHUTTLE_EVENTS },
	['shuttleContainerResult']: { group: MessageTypeGroups.SHUTTLE_EVENTS },
	['shuttleCancelContainersResult']: { group: MessageTypeGroups.SHUTTLE_EVENTS },
	['shuttleRedirectContainersResult']: { group: MessageTypeGroups.SHUTTLE_EVENTS },
	// shuttle available containers/destinations
	['shuttleAvailableContainersResult']: { group: MessageTypeGroups.SHUTTLE_EVENTS },
	['shuttleAvailableDestinationsResult']: { group: MessageTypeGroups.SHUTTLE_EVENTS },
	['shuttleWithdrawDestinationsResult']: { group: MessageTypeGroups.SHUTTLE_EVENTS },
	['shuttleContainerDestinationSelected']: { group: MessageTypeGroups.SHUTTLE_EVENTS },

	// southbound (pickwaves)
	pickWave: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	['splittablePickWave']: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	mfpTaskCompletion: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },

	// southbound (order update/cancel)
	groupUpdate: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	updateGroupTakeoffDestination: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	groupCancel: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	bulkGroupCancel: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },

	// southbound (packout)
	packoutPrintServiceResponse: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	availableCarrierLanesUpdated: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	containerPackoutUpdate: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },

	// southbound (product API)
	product: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },

	// southbound (goals)
	preKitRequest: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	workOrderRequest: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	putawayWave: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	putawayCancelContainers: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	bulkGroupUpdate: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	// shuttle southbound (still goals)
	shuttleWave: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	shuttleCancelContainers: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	shuttleRedirectContainers: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	shuttleAvailableContainers: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	shuttleAvailableDestinations: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
	shuttleWithdrawDestinations: { group: MessageTypeGroups.SOUTHBOUND_MESSAGES },
});
/* eslint-enable sort-keys-fix/sort-keys-fix */

export const allMessageTypes = new Set(
	allMessageTypeOptions.flatMap((g) => g.options.map((o) => o.value)),
);

export function sortMessageTypes(messageTypes: HookMessageType[]): HookMessageType[] {
	const messageTypesMap = new Map(messageTypes.map((mt) => [mt.messageType, mt]));
	const result: HookMessageType[] = [];
	for (const evt of allMessageTypeOptions.flatMap((o) => o.options)) {
		const mt = messageTypesMap.get(evt.value as NorthboundMessageType);
		if (mt) {
			result.push(mt);
		}
	}
	return result;
}

export function findMessageTypesForGroup(
	group: MessageTypeGroup,
	messageTypes: HookMessageType[],
): HookMessageType[] {
	const messageTypesForGroup = groupToMessageType.get(group);
	if (!messageTypesForGroup) {
		throw new Error('MessageType not found');
	}
	// TODO: we should drop `messageTypeFilter`, then we can make `messageType` required and lose the `!`
	return messageTypes.filter((mt) => messageTypesForGroup.has(mt.messageType!));
}

export function getGroupForMessageType(messageType: NorthboundMessageType): MessageTypeGroup {
	const group = messageTypeToGroup.get(messageType);
	if (!group) {
		throw new Error('Group type not found');
	}
	return group;
}

const messageTypeToGroup = new Map<NorthboundMessageType | string, MessageTypeGroup>();
const groupToMessageType = new Map<MessageTypeGroup, Set<NorthboundMessageType | string>>();

for (const group of allMessageTypeOptions) {
	const allTypes = group.options.map((o) => o.value);
	groupToMessageType.set(group.title, new Set(allTypes));

	for (const type of allTypes) {
		messageTypeToGroup.set(type, group.title);
	}
}
