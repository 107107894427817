import { IndexTable } from '@sixriver/lighthouse-web-community';

import { ExceptionBadge } from './ExceptionBadge';
import { OrderExceptionStatus, OrderV2Status } from '../../../api/fulfillment-api/types';
import { NoData } from '../../../components/NoData';

interface StatusCellProps {
	order: any;
}

export function StatusCell({ order }: StatusCellProps) {
	if (order.isShorted && order.status === OrderV2Status.Canceled) {
		return (
			<IndexTable.Cell>
				<ExceptionBadge status={OrderExceptionStatus.Canceled} />
			</IndexTable.Cell>
		);
	}

	if (order.isShorted && order.isHealable && order.status === OrderV2Status.InProgress) {
		return (
			<IndexTable.Cell>
				<ExceptionBadge status={OrderExceptionStatus.Resolving} />
			</IndexTable.Cell>
		);
	}

	if (!order.isShorted && order.healPlans.length > 0) {
		return (
			<IndexTable.Cell>
				<ExceptionBadge status={OrderExceptionStatus.Cleared} />
			</IndexTable.Cell>
		);
	}

	if (order.isShorted && order.isHealable) {
		return (
			<IndexTable.Cell>
				<ExceptionBadge status={OrderExceptionStatus.Resolvable} />
			</IndexTable.Cell>
		);
	}

	if (order.isShorted && order.isHealable === false) {
		return (
			<IndexTable.Cell>
				<ExceptionBadge status={OrderExceptionStatus.Unresolvable} />
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<NoData />;
		</IndexTable.Cell>
	);
}
