import { IndexTable, Link } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../../components/NoData';
import {
	GetShuttleAvailableContainersQueryNode,
	GetShuttleAvailableDestinationsQueryNode,
	GetShuttleJobsQueryNode,
} from '../../../graphql/derived';
import * as routes from '../../../routes';

export function DeviceCell({
	node,
}: {
	node:
		| GetShuttleJobsQueryNode
		| GetShuttleAvailableContainersQueryNode
		| GetShuttleAvailableDestinationsQueryNode;
}) {
	const currentActor = node.currentActor;
	const device =
		currentActor?.device?.type === 'Mfp'
			? { deviceId: currentActor?.device?.id, deviceType: 'mfp' }
			: undefined;

	return (
		<IndexTable.Cell>
			{device ? (
				<Link url={`${routes.floorView()}/?chuck=${device.deviceId}`} key={device.deviceId}>
					{device.deviceId}
				</Link>
			) : (
				<NoData />
			)}
		</IndexTable.Cell>
	);
}
