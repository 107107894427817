// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateContainerMutationVariables = Types.Exact<{
  input: Types.UpdateContainerInput;
}>;


export type UpdateContainerMutation = { __typename?: 'Mutation', updateContainer: { __typename?: 'MutationResponse', success: boolean, referenceId?: string | null } };


export const UpdateContainerDocument = gql`
    mutation fapi_UpdateContainer($input: UpdateContainerInput!) {
  updateContainer(input: $input) {
    success
    referenceId
  }
}
    `;

export function useUpdateContainerMutation() {
  return Urql.useMutation<UpdateContainerMutation, UpdateContainerMutationVariables>(UpdateContainerDocument);
};