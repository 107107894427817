import { Banner, BannerStatus, Tooltip } from '@sixriver/lighthouse-web-community';
import { useEffect, useMemo, useState } from 'react';

import styles from './AutoRefresh.module.css';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { DateTime } from '../DateTime';
import { Toggle } from '../Toggle';

interface Props {
	discriminatorData?: object | null;
}

export function AutoRefresh({ discriminatorData }: Props) {
	const { messages, translate } = useLocalization();
	const [fetchedAt, setFetchedAt] = useState(new Date());
	const { pollingEnabled, togglePolling } = usePolling();

	useEffect(() => {
		setFetchedAt(new Date());
	}, [discriminatorData]);

	const status: BannerStatus = useMemo(() => {
		if (!pollingEnabled) {
			return 'warning';
		}

		return 'success';
	}, [pollingEnabled]);

	const toggleLabel = pollingEnabled ? messages.autoRefreshEnabled : messages.autoRefreshDisabled;

	const appliedStyles = [styles.container];

	if (pollingEnabled) {
		appliedStyles.push(styles.enabled);
	}

	return (
		<div
			className={appliedStyles.join(' ')}
			onClick={
				// Intentionally not adding semantic roles as the toggle is the accessible input
				togglePolling
			}
		>
			<Banner status={status} stopAnnouncements>
				{translate(messages.updatedTimestamp, { time: <DateTime inline date={fetchedAt} /> })}
				<Tooltip content={messages.autoRefreshHint} activatorWrapper="div">
					<div
						onClick={
							// Stop the click event from propagating to the click listener above
							(event) => event.stopPropagation()
						}
					>
						<Toggle
							label={toggleLabel}
							checked={pollingEnabled}
							onCheck={togglePolling}
							align="right"
						/>
					</div>
				</Tooltip>
			</Banner>
		</div>
	);
}
