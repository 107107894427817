import { components } from '@sixriver/audit-log-oas-schema';

type PaginatedAuditLogList = components['schemas']['PaginatedAuditLogList'];

export async function fetchAuditLogs(
	operationType: string[],
	cursors: string[],
	limit: number,
	periodFrom: string,
	periodTo: string,
	query: string = '',
): Promise<PaginatedAuditLogList> {
	let url = '/cfs/audit-log/api/v1/audit-logs?';
	const params = new URLSearchParams();

	if (operationType.length > 0) {
		params.append('eventType', operationType[0]);
	}
	if (cursors && cursors.length) {
		params.append('cursor', cursors[0]);
	}
	if (limit) {
		params.append('limit', limit.toString());
	}
	if (periodFrom !== '') {
		const periodFromDate = new Date(periodFrom);
		params.append('after', periodFromDate.toISOString());
	}
	if (periodTo !== '') {
		const periodToDate = new Date(periodTo);
		params.append('before', periodToDate.toISOString());
	}

	if (query !== '') {
		params.append('searchQuery', query);
	}

	url += params.toString();

	const response = await fetch(url, {
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'GET',
	});

	if (!response.ok) {
		throw new Error('Error fetching audit logs');
	}

	const result = await response.json();
	return result;
}
