import { Page } from '@sixriver/lighthouse-web-community';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { EmployeeForm, EmployeeFormData } from './Employee.form';
import { getRolesFromFormData } from './Employee.utils';
import { useCreateUserMutation } from './graphql/CreateUser.f-api-graphql';
import { UserAuthMethod } from '../../api/fulfillment-api/types';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

export function AddEmployee(): JSX.Element {
	const history = useHistory();
	const { showToast } = useToast();
	const { messages } = useLocalization();
	const { lookBusy } = useContext(BusyIndicatorContext);

	// Mutations
	const [addEmployeeMutation, executeAddEmployeeMutation] = useCreateUserMutation();

	// Methods
	const onSubmit = useCallback(
		async (formData: EmployeeFormData) => {
			lookBusy(true);

			const result = await executeAddEmployeeMutation({
				input: {
					authMethod: UserAuthMethod.Ldap,
					badgeBarcode: formData.badgeBarcode || null,
					email: formData.email || null,
					locale: formData.locale || null,
					name: formData.name || null,
					roles: getRolesFromFormData(formData).map((name) => ({ name })),
					tags: formData.tags.map((tag) => ({ name: tag })),
				},
			});

			if (result.error || !result.data?.createUser?.referenceId) {
				lookBusy(false);
				showToast(messages.errorToast, true);
				return;
			}

			const newId = result.data.createUser.referenceId;

			setTimeout(() => {
				lookBusy(false);
				history.push(routes.employee(newId));
			}, 500);
		},
		[executeAddEmployeeMutation, history, messages, showToast, lookBusy],
	);

	// Render
	return (
		<Page
			title={messages.addEmployee}
			breadcrumbs={[
				{
					content: messages.employees,
					url: routes.employees(),
				},
			]}
			fullWidth
		>
			<EmployeeForm
				mode="add"
				onSubmit={onSubmit}
				error={addEmployeeMutation.error}
				data={
					{
						badgeBarcode: '',
						email: '',
						isBridgeAccessAllowed: false,
						isDeviceAccessAllowed: false,
						locale: 'en-US',
						name: '',
						phoneNumber: '',
						tags: [],
					} as EmployeeFormData
				}
			/>
		</Page>
	);
}
