import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { ClockMinor, PlusMinor } from '@sixriver/lighthouse-icons';
import { Button, Heading, Stack } from '@sixriver/lighthouse-web-community';
import { ShuttlePoint, ShuttleRoute } from '@sixriver/task-coordinator-oas';
import { useCallback, useEffect, useState } from 'react';

import styles from './Shuttle.module.css';
import { ShuttlePointCard } from './ShuttlePointCard';
import { useLocalization } from '../../../hooks/useLocalization';
import { uuidv4 } from '../../../utils';
import { mapApi } from '../MapOptions';
import { MapSelection } from '../MapSelection';
import { getWorkflowColors, getWorkflowPointType } from '../WorkflowPointColors';

interface Props {
	points: ShuttlePoint[];
	setPoints: (points: ShuttlePoint[]) => void;
	cycleType: ShuttleRoute['cycleType'];
	mapSelection?: MapSelection;
}

interface Feature {
	id: string;
	labels: string[];
	name: string;
	object: string;
	orientation: number;
	type: string;
	workflowOptions: string[];
}

export function ShuttlePointsList({ points, setPoints, mapSelection, cycleType }: Props) {
	const { messages } = useLocalization();
	const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
	const [listeningForNewPoint, setListeningForNewPoint] = useState(false);

	const onSelect = useCallback(
		(id: string) => {
			if (selectedId === id) {
				setSelectedId(undefined);
			} else {
				setSelectedId(id);
			}
		},
		[selectedId],
	);

	// When a user selects a point, highlight it on the map
	useEffect(() => {
		if (selectedId) {
			mapApi?.selectFeatures(
				(points.filter((point) => point.id === selectedId) ?? []).map((point) => point.mapPointId),
			);
		} else {
			mapApi?.selectFeatures(points.map((point) => point.mapPointId) ?? []);
		}
	}, [selectedId, points]);

	// When a user clicks on the map, add a new point to the list
	useEffect(() => {
		if (!listeningForNewPoint) {
			return;
		}

		if (!mapSelection || mapSelection.type !== 'point') {
			return;
		}

		const existingShuttlePoint = points.some((point) => point.mapPointId === mapSelection.id);
		if (existingShuttlePoint) {
			return;
		}

		const features = mapApi?.getFeatures([mapSelection.id]);
		if (!features || features.length !== 1) {
			return;
		}

		const feature = features[0] as Feature;

		const pointType = getWorkflowPointType(feature.labels);
		const workflowPointColors = getWorkflowColors(messages);
		const color = workflowPointColors.find((color) => color.baseType === pointType)?.color;
		const shuttlePoint: ShuttlePoint = {
			color,
			confirm: false,
			delay: 0,
			doWorkflow: false,
			enabled: true,
			id: uuidv4(),
			instructions: '',
			mapPointId: feature.id,
			name: feature.name,
			reserve: true,
			skipToDestination: false,
		};
		setPoints([...points, shuttlePoint]);
		setListeningForNewPoint(false);
	}, [listeningForNewPoint, mapSelection, messages, points, setPoints]);

	// reorder the list of points
	const handleOnDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		if (result.destination.index === result.source.index) {
			// No change..
			return;
		}

		const newItems = Array.from(points);
		const [reorderedItem] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, reorderedItem);
		setPoints(newItems);
	};

	return (
		<div>
			<Stack alignment="center" distribution="equalSpacing">
				<Heading>{`${messages.shuttlePoints} (${points.length})`}</Heading>
				<Button
					size="slim"
					icon={!listeningForNewPoint ? PlusMinor : ClockMinor}
					outline={false}
					onClick={() => setListeningForNewPoint(true)}
					disabled={listeningForNewPoint}
				>
					{listeningForNewPoint
						? messages.shuttleRoutePointList.clickPoint
						: messages.shuttleRoutePointList.add}
				</Button>
			</Stack>
			<div className={styles.dropzone}>
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<Droppable droppableId="items">
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{points.map((point, index) => {
									const mapPoint = mapApi?.getFeatures([point.mapPointId])?.[0] as Feature;
									return (
										<Draggable key={point.name} draggableId={point.name} index={index}>
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={{
														...provided.draggableProps.style,
													}}
													key={point.name}
												>
													<ShuttlePointCard
														key={point.name}
														shuttlePoint={point}
														selected={selectedId === point.id}
														onDelete={() => {
															setPoints(points.filter((p) => p.id !== point.id));
														}}
														onHeaderClick={() => onSelect(point.id)}
														onChange={(shuttlePoint) => {
															setPoints(
																points.map((p) => (p.id === shuttlePoint.id ? shuttlePoint : p)),
															);
														}}
														workflowEligible={
															// The last point of the route which is
															// either induct or putawy is eligible to
															// start the workflow when the route is done
															cycleType === 'oneShot' &&
															index === points.length - 1 &&
															(mapPoint?.labels.includes('induct') ||
																mapPoint?.labels.includes('putaway'))
														}
													/>
												</div>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		</div>
	);
}
