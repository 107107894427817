import { IndexTable, Stack, TextStyle } from '@sixriver/lighthouse-web-community';
import { NoData } from '@sixriver/react-support';

import { PickWaveStatus } from '../../../api/fulfillment-api/types';
import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';

interface LinesDroppedCellProps {
	pickWave: PickWaveFieldsFragment;
}

export function LinesDroppedCell({ pickWave }: LinesDroppedCellProps) {
	if (pickWave.status === PickWaveStatus.Success) {
		return (
			<IndexTable.Cell>
				<NoData />
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<Stack vertical spacing="extraTight">
				<TextStyle variation={pickWave.resolvedAt ? undefined : 'negative'}>
					{pickWave.droppedLinesCount}
				</TextStyle>
				<TextStyle variation="subdued">{pickWave.totalLinesCount} total</TextStyle>
			</Stack>
		</IndexTable.Cell>
	);
}
