// Change these values on every LTS
const ltsVersion = 31;
const releaseDate = new Date('2024-12-06');

// Constants around when we should be showing warnings
const ltsInterval = 7 * 7 * 24 * 60 * 60 * 1_000; // 7 weeks
const supportedIntervals = 3;
const warningThreshold = 21 * 24 * 60 * 60 * 1_000; // 21 days

// End of life date is 3 LTS intervals after the release date
const endOfLifeDate = new Date(releaseDate.getTime() + supportedIntervals * ltsInterval);

// Warning date is 21 days before the end of life date
const warningDate = new Date(endOfLifeDate.getTime() - warningThreshold);

export { endOfLifeDate, warningDate, ltsVersion };
