// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetJobFlowRuleQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetJobFlowRuleQuery = { __typename?: 'Query', jobFlowRule: { __typename?: 'JobFlowRule', id: string, ruleSubType: Array<string>, ruleDescription: string, rule: any } };


export const GetJobFlowRuleDocument = gql`
    query fapi_GetJobFlowRule($id: String!) {
  jobFlowRule(id: $id) {
    id
    ruleSubType
    ruleDescription
    rule
  }
}
    `;

export function useGetJobFlowRuleQuery(options: Omit<Urql.UseQueryArgs<GetJobFlowRuleQueryVariables>, 'query'>) {
  return Urql.useQuery<GetJobFlowRuleQuery, GetJobFlowRuleQueryVariables>({ query: GetJobFlowRuleDocument, ...options });
};