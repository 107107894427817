// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.w-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetExceptionOrdersQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statuses?: Types.InputMaybe<Array<Types.JobStatus> | Types.JobStatus>;
  isShorted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isHealable?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetExceptionOrdersQuery = { __typename?: 'Query', getOrders: { __typename?: 'JobConnection', edges: Array<{ __typename?: 'JobEdge', node: { __typename?: 'Job', id: string, externalId: string, status: Types.JobStatus, createdAt: any, expectedShipDate?: any | null, externalContainerId?: string | null, isShorted: boolean, isHealable: boolean, tasks?: Array<{ __typename?: 'Task', workArea?: { __typename?: 'WorkArea', id: string, name?: string | null } | null }> | null, lines: { __typename?: 'LineConnection', edges: Array<{ __typename?: 'LineEdge', node: { __typename?: 'Line', quantity: number, doneQuantity: number, status?: string | null } }> }, totalLineQuantities: { __typename?: 'LineQuantities', pickedUnits: number, totalUnits: number }, workflows: Array<{ __typename?: 'JobWorkflow', type: string, status: string }>, healPlans?: Array<{ __typename?: 'HealPlan', id: string }> | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };


export const GetExceptionOrdersDocument = gql`
    query wapi_GetExceptionOrders($after: String, $first: Int, $searchText: String, $statuses: [JobStatus!], $isShorted: Boolean, $isHealable: Boolean) {
  getOrders(
    after: $after
    first: $first
    searchText: $searchText
    statuses: $statuses
    isShorted: $isShorted
    isHealable: $isHealable
  ) {
    edges {
      node {
        id
        externalId
        status
        createdAt
        expectedShipDate
        externalContainerId
        isShorted
        isHealable
        tasks {
          workArea {
            id
            name
          }
        }
        lines {
          edges {
            node {
              quantity
              doneQuantity
              status
            }
          }
        }
        totalLineQuantities {
          pickedUnits
          totalUnits
        }
        workflows {
          type
          status
        }
        healPlans {
          id
        }
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${PageInfoFragmentDoc}`;

export function useGetExceptionOrdersQuery(options?: Omit<Urql.UseQueryArgs<GetExceptionOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetExceptionOrdersQuery, GetExceptionOrdersQueryVariables>({ query: GetExceptionOrdersDocument, ...options });
};