// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddProductMutationVariables = Types.Exact<{
  input: Types.ProductInput;
}>;


export type AddProductMutation = { __typename?: 'Mutation', addProduct: { __typename?: 'MutationResponse', success: boolean, referenceId?: string | null } };


export const AddProductDocument = gql`
    mutation fapi_AddProduct($input: ProductInput!) {
  addProduct(input: $input) {
    success
    referenceId
  }
}
    `;

export function useAddProductMutation() {
  return Urql.useMutation<AddProductMutation, AddProductMutationVariables>(AddProductDocument);
};