// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.w-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetProductsQueryVariables = Types.Exact<{
  cursor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  type?: Types.InputMaybe<Types.StorageLocationType>;
  orderBy?: Types.InputMaybe<Types.AssetTypeOrderByFields>;
  orderByDirection?: Types.InputMaybe<Types.OrderByDirection>;
  kinds?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  isLotTracked?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isExpirationTracked?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetProductsQuery = { __typename?: 'Query', products: { __typename?: 'AssetTypeConnection', edges: Array<{ __typename?: 'AssetTypeEdge', node: { __typename?: 'AssetType', id: string, customerIdentifier: string, name: string, description: string, isContainerType: boolean, attributes?: any | null, identifiers: Array<{ __typename?: 'ProductIdentifier', label: string, order: number, validationStrategy: Types.ValidationStrategy, allowedValues: Array<string>, grabQuantity?: number | null }> } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };


export const GetProductsDocument = gql`
    query wapi_GetProducts($cursor: String, $after: String, $limit: Float, $first: Int, $searchText: String, $type: StorageLocationType, $orderBy: AssetTypeOrderByFields, $orderByDirection: OrderByDirection, $kinds: [String!], $isLotTracked: Boolean, $isExpirationTracked: Boolean) {
  products(
    cursor: $cursor
    limit: $limit
    after: $after
    searchText: $searchText
    first: $first
    type: $type
    orderBy: $orderBy
    orderByDirection: $orderByDirection
    kinds: $kinds
    isLotTracked: $isLotTracked
    isExpirationTracked: $isExpirationTracked
  ) {
    edges {
      node {
        id
        customerIdentifier
        name
        description
        isContainerType
        identifiers {
          label
          order
          validationStrategy
          allowedValues
          grabQuantity
        }
        attributes
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${PageInfoFragmentDoc}`;

export function useGetProductsQuery(options?: Omit<Urql.UseQueryArgs<GetProductsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetProductsQuery, GetProductsQueryVariables>({ query: GetProductsDocument, ...options });
};