import { Badge, IndexTable } from '@sixriver/lighthouse-web-community';

import { LineStatus } from '../../../api/fulfillment-api/types';
import { countLineExceptionsV2 } from '../../../helpers/exception';
import { useLocalization } from '../../../hooks/useLocalization';
import { GetOutboundJobLineFragment } from '../../../pages/OutboundJob/graphql/GetLines.w-api-graphql';
import { NoData } from '../../NoData';

interface Props {
	line: GetOutboundJobLineFragment;
}

export function ExceptionBadgeCell({ line }: Props): JSX.Element {
	const { messages } = useLocalization();

	const hasException = countLineExceptionsV2([line]) > 0;

	if (hasException) {
		return (
			<IndexTable.Cell>
				<Badge status="critical">{messages.exception}</Badge>
			</IndexTable.Cell>
		);
	}

	if (line.status?.includes(LineStatus.Cleared)) {
		return (
			<IndexTable.Cell>
				<Badge status="success">{messages.cleared}</Badge>
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
