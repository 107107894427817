import { Card } from '@sixriver/lighthouse-web-community';

import { DeviceEditFormInfo } from './Device.utils';
import { DeviceBattery } from './DeviceBattery';
import { CardDetails } from '../../components/CardDetails';
import { DateTime } from '../../components/DateTime';
import { useLocalization } from '../../hooks/useLocalization';

interface DeviceInfoProps {
	info: DeviceEditFormInfo;
}

export function DeviceInfo({ info }: DeviceInfoProps) {
	const { messages } = useLocalization();

	const device = info.device;

	return (
		<Card sectioned>
			<Card.Section fullWidth flush>
				<CardDetails
					primary={[
						{
							content: device ? <DeviceBattery device={device} /> : <></>,
							label: messages.batteryLevel,
						},
						{
							content: <DateTime date={info.lastCalibratedAt} />,
							label: messages.lastCalibrated,
						},
					]}
				/>
			</Card.Section>
		</Card>
	);
}
