// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/edge/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CancelDeliveryMutationVariables = Types.Exact<{
  deliveryId: Types.Scalars['String']['input'];
  orderingKey?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CancelDeliveryMutation = { __typename?: 'Mutation', cancelDelivery: { __typename?: 'MessageDelivery', id: string, startedAt?: any | null, deliveryState: Types.DeliveryState, message: { __typename?: 'Message', id: string }, hook: { __typename?: 'Hook', id: string } } };


export const CancelDeliveryDocument = gql`
    mutation edge_CancelDelivery($deliveryId: String!, $orderingKey: String) {
  cancelDelivery(deliveryId: $deliveryId, orderingKey: $orderingKey) {
    id
    startedAt
    message {
      id
    }
    hook {
      id
    }
    deliveryState
  }
}
    `;

export function useCancelDeliveryMutation() {
  return Urql.useMutation<CancelDeliveryMutation, CancelDeliveryMutationVariables>(CancelDeliveryDocument);
};