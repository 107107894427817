// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetDevicesOrdersQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;


export type GetDevicesOrdersQuery = { __typename?: 'Query', jobs: { __typename?: 'JobConnection', edges: Array<{ __typename?: 'JobEdge', node: { __typename?: 'Job', orders: Array<{ __typename?: 'Job', id: string }> } }> } };


export const GetDevicesOrdersDocument = gql`
    query wapi_GetDevicesOrders($ids: [String!]) {
  jobs(ids: $ids) {
    edges {
      node {
        orders {
          id
        }
      }
    }
  }
}
    `;

export function useGetDevicesOrdersQuery(options?: Omit<Urql.UseQueryArgs<GetDevicesOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDevicesOrdersQuery, GetDevicesOrdersQueryVariables>({ query: GetDevicesOrdersDocument, ...options });
};