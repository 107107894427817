import { DiamondAlertMajor } from '@sixriver/lighthouse-icons';
import {
	FilterInterface,
	AppliedFilterInterface,
	ChoiceList,
	Link,
	Icon,
	Stack,
	Tooltip,
	TextStyle,
} from '@sixriver/lighthouse-web-community';
import { useCallback } from 'react';

import { useLocationsTableFilters } from './legacy-use-locations-table-filters';
import { MapChunk } from '../../api';
import {
	InventoryTag,
	OrderByDirection,
	StorageLocationOrderByFields,
	StorageLocationType,
	StorageLocationTypeCount,
} from '../../api/fulfillment-api/types';
import {
	PaginationCursors,
	SetPaginationCursors,
	Column,
	DataTable,
} from '../../components/DataTable';
import { FilterPicker } from '../../components/FilterPicker';
import { LocationPeek } from '../../components/LocationPeek';
import { NoData } from '../../components/NoData';
import { LocationsQueryLocations } from '../../graphql/derived';
import { boolStringToTriStateBool } from '../../helpers/boolean';
import { getSummaryFullness /* , isPickableType */ } from '../../helpers/location';
import { useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { useLocationTabs } from '../../hooks/useLocationTabs';
import * as routes from '../../routes';

interface LocationsTableProps {
	data?: LocationsQueryLocations;
	counts?: StorageLocationTypeCount[];
	loading?: boolean;
	paginationCursors: PaginationCursors;
	setPaginationCursors: SetPaginationCursors;
	isInventoryEnabled: boolean;
	workAreas: MapChunk[];
}

function conditionalArrayItem<T>(include: boolean, item: T) {
	return include ? [item] : [];
}

export function LocationsTable({
	isInventoryEnabled,
	data,
	loading,
	counts = [],
	paginationCursors,
	setPaginationCursors,
	workAreas,
}: LocationsTableProps) {
	const { messages, formatNumber, formatPercent, translate } = useLocalization();
	const isWorkAreasEnabled = useIsWorkAreasEnabled();

	// Tabs
	const tabs = useLocationTabs(counts);

	// Filters
	const { filters, keys, setLocationTableFilter, setFilters } = useLocationsTableFilters();
	const { workArea } = useFilters(['workArea']);
	const selectedWorkAreas = workArea ? workArea.split(' ') : undefined;
	const isSlottedFilterParam = boolStringToTriStateBool(filters.isSlotted);
	const selectedLocationCondition = filters.locationCondition
		? filters.locationCondition?.split(',')
		: [];

	// Filter Handlers
	const alterConditionSet = useCallback(
		(newConditions: string[]) => {
			const values = newConditions.join(',');
			setLocationTableFilter(keys.LOCATION_CONDITION_KEY, values);
		},
		[keys, setLocationTableFilter],
	);

	const handleConditionRemoval = useCallback(() => {
		alterConditionSet([]);
	}, [alterConditionSet]);

	const handleConditionChange = useCallback(
		(newValue: string[]) => {
			alterConditionSet(newValue);
		},
		[alterConditionSet],
	);

	const locationConditionChoices = [
		{
			label: messages.inventoryStateNames.damaged,
			value: messages.inventoryStateNames.damaged,
		},
		{
			label: messages.inventoryStateNames.reworkPending,
			value: messages.inventoryStateNames.reworkPending,
		},
		{
			label: messages.inventoryStateNames.sellable,
			value: messages.inventoryStateNames.sellable,
		},
	];

	const filtersMarkup: FilterInterface[] = [
		{
			filter: (
				<ChoiceList
					title=""
					titleHidden
					selected={[filters.isConflicted || 'none']}
					choices={[
						{
							label: messages.suspectedDiscrepancies,
							value: 'true',
						},
					]}
					onChange={(selected) => setLocationTableFilter('isConflicted', selected[0])}
				/>
			),
			key: keys.IS_CONFLICTED_KEY,
			label: messages.conflicts,
			shortcut: true,
		},

		// Location condition
		{
			filter: (
				<FilterPicker
					availableChoices={locationConditionChoices}
					selectedChoices={selectedLocationCondition}
					onChange={handleConditionChange}
				/>
			),
			key: keys.LOCATION_CONDITION_KEY,
			label: messages.locationCondition,
			shortcut: true,
		},

		// In use (formerly Status)
		{
			filter: (
				<ChoiceList
					title=""
					titleHidden
					selected={[filters.isSlotted || 'none']}
					choices={[
						{
							label: messages.yes,
							value: true.toString(),
						},
						{
							label: messages.no,
							value: false.toString(),
						},
					]}
					onChange={(selected) => setLocationTableFilter('isSlotted', selected[0])}
				/>
			),
			key: keys.IS_SLOTTED_KEY,
			label: messages.inUse,
			shortcut: true,
		},

		// Nesting
		{
			filter: (
				<ChoiceList
					title=""
					titleHidden
					selected={['none']}
					choices={[
						{ label: 'All locations', value: '0' },
						{ label: 'Only locations with nested containers', value: '1' },
						{ label: 'Only locations without nested containers', value: '1' },
					]}
				/>
			),
			key: keys.NESTED_KEY,
			label: 'Nesting',
			shortcut: true,
		},
		// Fullness
		{
			filter: (
				<ChoiceList
					title=""
					titleHidden
					selected={[filters.fullness || 'none']}
					choices={[
						{ label: messages.fullnessTypes.empty, value: '0' },
						{
							label: messages.fullnessTypes.lessThanTenPercentFull,
							value: '0.10',
						},
						{
							label: messages.fullnessTypes.lessThanFiftyPercentFull,
							value: '0.50',
						},
						{
							label: messages.fullnessTypes.full,
							value: '1',
						},
					]}
					onChange={(selected) => setLocationTableFilter('fullness', selected[0])}
				/>
			),
			key: keys.FULLNESS_KEY,
			label: messages.fullness,
			shortcut: true,
		},
	].filter((filterInterface) => {
		// TODO: remove this restriction when LoopBack security hole is patched and
		// new query args are available for filtering of the DataTable rows
		return ![messages.locationCondition, 'Nesting'].includes(filterInterface.label);
	});

	const appliedFilters: AppliedFilterInterface[] = [
		...conditionalArrayItem(filters.isConflicted !== undefined, {
			key: keys.IS_CONFLICTED_KEY,
			label: messages.suspectedDiscrepancies,
			onRemove: () => setLocationTableFilter(keys.IS_CONFLICTED_KEY, ''),
		}),
		...conditionalArrayItem(filters.fullness !== undefined, {
			key: keys.FULLNESS_KEY,
			label:
				filters.fullness === '1'
					? messages.fullnessTypes.full
					: filters.fullness === '0'
					? messages.fullnessTypes.empty
					: filters.fullness === '0.10'
					? messages.fullnessTypes.lessThanTenPercentFull
					: messages.fullnessTypes.lessThanFiftyPercentFull,
			onRemove: () => setLocationTableFilter(keys.FULLNESS_KEY, ''),
		}),
		...conditionalArrayItem(filters.locationCondition !== undefined, {
			key: keys.LOCATION_CONDITION_KEY,
			label: `${filters.locationCondition}`,
			onRemove: () => handleConditionRemoval(),
		}),
		...conditionalArrayItem(filters.isSlotted !== undefined, {
			key: keys.IS_SLOTTED_KEY,
			label:
				isSlottedFilterParam === true
					? messages.inUse
					: isSlottedFilterParam === false
					? messages.available
					: '',
			onRemove: () => setLocationTableFilter(keys.IS_SLOTTED_KEY, ''),
		}),
		...(selectedWorkAreas && selectedWorkAreas.length
			? [
					{
						key: 'workArea',
						label: workAreas
							.filter((area) => selectedWorkAreas.includes(area.mapChunkId))
							.map((area, i) => area.displayName || `Work area ${i + 1}`)
							.join(', '),
						onRemove: () => {
							setFilters([{ key: 'workArea', value: '' }]);
						},
					},
			  ]
			: []),
	];

	// Sorting
	const sortChoices = [
		{
			label: messages.sortOptions.addressAsc,
			value: `${StorageLocationOrderByFields.Address} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.addressDesc,
			value: `${StorageLocationOrderByFields.Address} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.quantityAsc,
			value: `${StorageLocationOrderByFields.LiveQuantity} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.quantityDesc,
			value: `${StorageLocationOrderByFields.LiveQuantity} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.fullnessAsc,
			value: `${StorageLocationOrderByFields.FillFraction} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.fullnessDesc,
			value: `${StorageLocationOrderByFields.FillFraction} ${OrderByDirection.Desc}`,
		},
	];

	// Data
	const { results, cursor } = data || {};

	// Render
	const workAreaColumn: Column = {
		heading: messages.workArea,
		sortable: false,
		type: 'text',
	};

	const fmsColumns: Column[] = [
		{
			heading: messages.address,
			sortable: false,
			type: 'text',
		},
		...(isWorkAreasEnabled ? [workAreaColumn] : []),
		{
			heading: messages.sku,
			sortable: false,
			type: 'text',
		},
		{
			heading: messages.locationCondition,
			sortable: false,
			type: 'text',
		},
		{
			heading: messages.quantity,
			sortable: false,
			type: 'text',
		},
		{
			heading: messages.fullness,
			sortable: false,
			type: 'text',
		},
		{
			heading: messages.locationType,
			sortable: false,
			type: 'text',
		},
	];

	const fasColumns: Column[] = [
		{
			heading: messages.address,
			sortable: false,
			type: 'text',
		},
		...(isWorkAreasEnabled ? [workAreaColumn] : []),
		{
			heading: messages.capacityType,
			sortable: false,
			type: 'text',
		},
		{
			heading: messages.locationType,
			sortable: false,
			type: 'text',
		},
	];

	const fmsRows = (results || []).map((summary) => {
		const conflictReasons = summary.inventories.map((inv) => inv.conflictReasons).flat();
		const conflictReason =
			conflictReasons.length === 0
				? undefined
				: conflictReasons.length === 1
				? messages.discrepancyTypes[conflictReasons?.[0]]
				: messages.discrepancyTypes.Other;
		const isSlotted = summary.numAssetTypes > 0;
		const liveQuantity = summary.liveQuantity;
		const fullness = getSummaryFullness(summary);
		let locationCondition: string | undefined;
		if (liveQuantity && liveQuantity > 0) {
			if (summary.type === StorageLocationType.Damaged) {
				locationCondition = 'damaged';
			} else {
				locationCondition = analyzeTaggage(summary.inventoryTags || undefined);
			}
		}

		const subLocationStates = summary.inventories.filter((inv) => inv.subLocationId);
		const uniqueSubLocationSkus = new Set(subLocationStates.map((state) => state.assetTypeId));
		const subLocationQuantity = subLocationStates.reduce((a, b) => a + (b.liveQuantity || 0), 0);

		return [
			// location address
			<div key={summary.id}>
				<Link url={routes.location(summary.id)} removeUnderline>
					{summary.address}
				</Link>
				{/* Nested containers */}
				{summary.numSubLocations ? (
					<div>
						<TextStyle variation="subdued">
							{translate(messages.countNestedContainers, { count: summary.numSubLocations })}
						</TextStyle>
					</div>
				) : null}
			</div>,

			// SKU
			<div key={`${summary.id}-sku`}>
				{summary.numAssetTypes > 1 ? (
					<LocationPeek summary={summary} />
				) : (
					summary.inventories[0]?.assetTypeExternalId || <NoData />
				)}
				{/* Nested containers */}
				{uniqueSubLocationSkus.size ? (
					<div>
						<TextStyle variation="subdued">
							{translate(messages.countSkus, { count: uniqueSubLocationSkus.size })}
						</TextStyle>
					</div>
				) : null}
			</div>,

			// Inventory State ((sub-asset-type) AKA "Condition")
			conditionValue(locationCondition, messages.inventoryStateNames) || <NoData />,

			// Work Area
			...(isWorkAreasEnabled
				? [summary.workArea ? <div>{summary.workArea?.name}</div> : <NoData />]
				: []),

			// Quantity
			<div key={`${summary.id}-qty`}>
				<Stack key="qty" vertical={false}>
					<Stack.Item>
						{isSlotted ? liveQuantity ? formatNumber(liveQuantity) : <NoData /> : <NoData />}
					</Stack.Item>
					{conflictReason ? (
						<Stack.Item>
							<Tooltip content={conflictReason}>
								<Icon source={DiamondAlertMajor} color="critical" />
							</Tooltip>
						</Stack.Item>
					) : null}
				</Stack>
				{/* Nested containers */}
				{subLocationStates.length ? (
					<div>
						<TextStyle variation="subdued">{subLocationQuantity}</TextStyle>
					</div>
				) : null}
			</div>,

			// Fullness
			fullness === null ? <NoData /> : formatPercent(fullness),
			// Type
			summary.type ? messages.locationTypes[summary.type] : <NoData />,
		];
	});

	const fasRows = (results || []).map((summary) => {
		return [
			// Address
			<Link key={summary.id} url={routes.location(summary.id)}>
				{summary.address}
			</Link>,
			// Work Area
			...(isWorkAreasEnabled
				? [summary.workArea ? <div>{summary.workArea?.name}</div> : <NoData />]
				: []),
			// Capacity Type
			summary.containerTypeName || <NoData />,
			// Location Type
			summary.type ? messages.locationTypes[summary.type] : <NoData />,
		];
	});

	const columns = isInventoryEnabled ? fmsColumns : fasColumns;
	const rows = isInventoryEnabled ? fmsRows : fasRows;

	return (
		<DataTable
			sortChoices={sortChoices}
			views={tabs}
			selectedView={filters.view || 'All'}
			viewKey={keys.view}
			columns={columns}
			filters={isInventoryEnabled ? filtersMarkup : []}
			appliedFilters={appliedFilters}
			rows={rows}
			loading={loading}
			pageInfo={{ endCursor: cursor }}
			sortValue={filters.sort}
			setFilters={setFilters}
			query={filters.searchText}
			queryKey={keys.SEARCH_TEXT_KEY}
			queryPlaceholder={messages.filterLocations}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
		/>
	);
}

export function conditionValue(s: string | undefined, inventoryStateNames: any) {
	switch (s) {
		case 'mixed':
			return inventoryStateNames.mixed;
		case 'reworkPending':
		case 'needsRework':
			return inventoryStateNames.reworkPending;
		case 'sellable':
			return inventoryStateNames.sellable;
		case 'damaged':
			return inventoryStateNames.damaged;
		default:
			return undefined;
	}
}

export function analyzeTaggage(t: InventoryTag[] | undefined) {
	let locationCondition: string | undefined = `sellable`;
	if (t && t.length) {
		// we have a populated list of tags. Only one tag here?
		if (t.length === 1) {
			const tagCondition = t[0]?.definition?.condition;
			locationCondition = tagCondition || 'sellable';
		} else {
			// check whether all tags indicate the same condition
			const conditionKey = t
				.map((item) => {
					return item.definition?.condition || 'sellable';
				})
				.reduce((_accum, _cur) => {
					return _cur === _accum ? _cur : 'mixed';
				});
			locationCondition = conditionKey || undefined;
		}
	}
	return locationCondition;
}
