import { Button } from '@sixriver/lighthouse-web-community';

import { GetJobQuery } from './graphql/GetJob.w-api-graphql';
import * as routes from '../../routes';

const getParams = (ids?: string[]) => {
	const searchParams = new URLSearchParams();

	if (ids) {
		ids.forEach((id) => {
			searchParams.append('ids', id);
		});
	}

	return '?' + searchParams.toString();
};

export function getRelatedJobs(job?: GetJobQuery['job']) {
	const orders = job?.orders ?? [];

	// return orders.reduce<Job[]>((jobs, order) => [...jobs, ...order.pickingJobs], []);
	return orders;
}

interface ButtonProps {
	relatedJobs: GetJobQuery['job'][];
	label: string;
}

export function RelatedJobsButton({ relatedJobs, label }: ButtonProps) {
	const unique = relatedJobs
		.filter((job) => !!job)
		.map((job) => job.id)
		.reduce((ids, id) => {
			return ids.add(id);
		}, new Set<string>());
	return (
		<Button
			plain
			url={`${routes.outboundJobs()}/${getParams(Array.from(unique))}`}
			disabled={unique.size <= 1}
		>
			{label}
		</Button>
	);
}
