import { AvailableMfp, DeviceType, GlobalErrorType } from '../../api';

export interface DeviceEditFormData {
	name: string;
	modelConfigId: string;
}

export interface DeviceEditFormInfo {
	model: DeviceType;
	lastCalibratedAt: string | undefined;
	network: string;
	device: AvailableMfp | null;
}

export type NameValidationError = 'charset' | 'length' | 'siteName';

// NOTE: The validation logic is based on the one from the following two functions from chuck-resin-web:
//
// eslint-disable-next-line max-len
// https://github.com/6RiverSystems/chuck-resin-web/blob/06774d33d10d981c075c9c625a82a2c64bbb74f6/src/routes/devices.ts#L46
//
// eslint-disable-next-line max-len
// https://github.com/6RiverSystems/chuck-resin-web/blob/06774d33d10d981c075c9c625a82a2c64bbb74f6/src/routes/devices.ts#L29
//
// On top of the validation conditions from the chuck-resin-web we check if the name contains the Site name.

// The following function is copied from:
// eslint-disable-next-line max-len
// https://github.com/6RiverSystems/chuck-resin-web/blob/06774d33d10d981c075c9c625a82a2c64bbb74f6/src/routes/devices.ts#L46
// and modified to also verify that the site name is part of the new name.
export function validateDeviceName(
	name: string,
	siteName: string,
): NameValidationError | undefined {
	// Names must be valid hostnames or device networking will not work
	// Device names must be 36 chars or less due to a bug in the TC
	// available_mfps.id and user_tasks.mfp_id are varchar(36)
	// NOTE: modification from the oen in the chuck-resin-web: check that the new name includes the site name
	//
	//
	if (!isValidHostname(name)) {
		return 'charset';
	}
	if (name.length > 36) {
		return 'length';
	}
	if (!name.includes(siteName)) {
		return 'siteName';
	}

	return undefined;
}

// The following function is copied from:
// eslint-disable-next-line max-len
// https://github.com/6RiverSystems/chuck-resin-web/blob/06774d33d10d981c075c9c625a82a2c64bbb74f6/src/routes/devices.ts#L29
function isValidHostname(hostname: string) {
	// Linux hostnames must be no longer than 255 characters
	if (hostname.length > 255) {
		return false;
	}
	// Linux hostnames can only contain letters, numbers, and the '-' character
	const hostnameRegex = /^[a-zA-Z0-9-]+$/;
	if (!hostnameRegex.test(hostname)) {
		return false;
	}
	// Linux hostnames cannot begin or end with '-'
	if (hostname[0] === '-' || hostname[hostname.length - 1] === '-') {
		return false;
	}
	return true;
}

export async function renameDevice(
	siteName: string,
	currentName: string,
	newName: string,
): Promise<void> {
	try {
		const response = await fetch(
			`${import.meta.env.VITE_GATEKEEPER_API_URL}/devices/${currentName}/rename/`,
			{
				body: JSON.stringify({ name: newName }),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'chuck-site-name': siteName,
				},
				method: 'POST',
			},
		);
		if (response.status !== 200) {
			const body = (await response.json()) as GlobalErrorType;
			// eslint-disable-next-line no-console
			console.error(
				`failed to rename device '${currentName}' to '${newName}' for site '${siteName}'`,
				body,
			);
			throw new Error(`failed to rename the device: ${body}`);
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('failed to make a renaming request', error);
		throw error;
	}
}
