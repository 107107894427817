// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateContainerMutationVariables = Types.Exact<{
  input: Types.AddContainerInput;
}>;


export type CreateContainerMutation = { __typename?: 'Mutation', createContainer: { __typename?: 'MutationResponse', success: boolean, referenceId?: string | null } };


export const CreateContainerDocument = gql`
    mutation fapi_CreateContainer($input: AddContainerInput!) {
  createContainer(input: $input) {
    success
    referenceId
  }
}
    `;

export function useCreateContainerMutation() {
  return Urql.useMutation<CreateContainerMutation, CreateContainerMutationVariables>(CreateContainerDocument);
};