import { IndexTable } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../../components/NoData';

interface Props {
	tag: string;
}

export function TagCell({ tag }: Props) {
	if (tag) {
		return <IndexTable.Cell>{<div>{tag}</div>}</IndexTable.Cell>;
	}

	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
