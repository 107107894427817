// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetLocationByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetLocationByIdQuery = { __typename?: 'Query', location?: { __typename?: 'StorageLocation', id: string, address: string, type?: Types.StorageLocationType | null, description: string, requiredReach?: number | null, externalAisleId?: string | null, containerType?: { __typename?: 'Container', id: string, name: string } | null, coordinates?: { __typename?: 'StorageLocationCoordinates', x: number, y: number, z: number } | null } | null };


export const GetLocationByIdDocument = gql`
    query fapi_GetLocationById($id: String!) {
  location(id: $id) {
    id
    address
    type
    description
    requiredReach
    externalAisleId
    containerType {
      id
      name
    }
    coordinates {
      x
      y
      z
    }
  }
}
    `;

export function useGetLocationByIdQuery(options: Omit<Urql.UseQueryArgs<GetLocationByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>({ query: GetLocationByIdDocument, ...options });
};