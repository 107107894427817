import { AppliedFilterInterface, FilterInterface, Link } from '@sixriver/lighthouse-web-community';

import { GetProductsQuery } from './graphql/GetProducts.w-api-graphql';
import { StorageLocationType } from '../../api/fulfillment-api/types';
import {
	PaginationCursors,
	SetPaginationCursors,
	Column,
	DataTable,
} from '../../components/DataTable';
import { NoData } from '../../components/NoData';
import { Peek } from '../../components/Peek';
import { SetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface TableProps {
	appliedFilters?: AppliedFilterInterface[];
	data?: GetProductsQuery['products'];
	filters?: FilterInterface[];
	loading?: boolean;
	paginationCursors: PaginationCursors;
	query?: string;
	selectedSort?: string;
	selectedView: StorageLocationType | 'all';
	setFilters: SetFilters;
	setPaginationCursors: SetPaginationCursors;
	sorts: Array<{ label: string; value: string }>;
}

export function ProductsTable({
	loading = false,
	data,
	selectedView,
	sorts,
	selectedSort,
	query,
	filters,
	appliedFilters,
	setFilters,
	paginationCursors,
	setPaginationCursors,
}: TableProps) {
	const { messages } = useLocalization();

	const { edges, pageInfo } = data || {};

	const columns: Column[] = [
		{
			heading: messages.item,
			type: 'text',
		},
		{
			heading: messages.barcode,
			type: 'text',
		},
		{
			heading: messages.name,
			type: 'text',
		},
		{
			heading: messages.description,
			type: 'text',
		},
	];

	const rows = (edges || []).map((edge) => {
		const { id, customerIdentifier, name, description, identifiers } = edge.node;

		const row = [
			<Link url={routes.product(id)} key="id" removeUnderline>
				{customerIdentifier}
			</Link>,
			identifiers.length ? (
				identifiers.map((identifier) => {
					if (identifier.allowedValues.length === 1) {
						return identifier.allowedValues[0];
					} else {
						return (
							<Peek
								key={id}
								activator={<div>{messages.multipleIdentifiers}</div>}
								title={messages.identifiers}
							>
								{identifiers.map((identifier) =>
									identifier.allowedValues.map((av, idx) => {
										return <div key={idx}>{av}</div>;
									}),
								)}
							</Peek>
						);
					}
				})
			) : (
				<NoData />
			),
			name ?? <NoData />,
			description ?? <NoData />,
		];

		return row;
	});

	return (
		<DataTable
			appliedFilters={appliedFilters}
			columns={columns}
			emptyStateHeading={messages.productsNotFound}
			filters={filters}
			loading={loading}
			pageInfo={{ endCursor: pageInfo?.endCursor, hasNextPage: pageInfo?.hasNextPage }}
			paginationCursors={paginationCursors}
			query={query}
			queryPlaceholder={messages.filterProducts}
			rows={rows}
			selectedView={selectedView}
			setFilters={setFilters}
			setPaginationCursors={setPaginationCursors}
			sortChoices={sorts}
			sortValue={selectedSort}
		/>
	);
}
