// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetDetailedLocationQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetDetailedLocationQuery = { __typename?: 'Query', location?: { __typename?: 'StorageLocation', id: string, address: string, externalAisleId?: string | null, type?: Types.StorageLocationType | null, description: string, requiredReach?: number | null, containerType?: { __typename?: 'Container', id: string, name: string, width: number, height: number, length: number, volume: number } | null, coordinates?: { __typename?: 'StorageLocationCoordinates', x: number, y: number, z: number } | null, products: Array<{ __typename?: 'ProductInventory', product: { __typename?: 'Product', id: string, name: string, image?: string | null, description: string, customerIdentifier: string, width: number, height: number, length: number, volume: number, scanValues: Array<string> }, inventory: { __typename?: 'InventoryState', assetTypeId: string, conflicted: boolean, conflictReasons: Array<Types.InventoryConflictReason>, totalPlannedRemoveQuantity: number, totalPlannedAddQuantity: number, liveQuantity?: number | null, subAssetDescriptorId?: string | null, subLocationDescriptorId?: string | null, subLocation?: { __typename?: 'InventorySubLocation', definition?: { __typename?: 'InventorySubLocationDefinition', externalId?: string | null } | null } | null, subAssetType?: { __typename?: 'InventoryTag', definition?: { __typename?: 'InventorySATDefinition', condition?: string | null } | null } | null } }>, workArea?: { __typename?: 'WorkArea', id: string, name?: string | null } | null } | null };


export const GetDetailedLocationDocument = gql`
    query fapi_GetDetailedLocation($id: String!) {
  location(id: $id) {
    id
    address
    externalAisleId
    type
    description
    requiredReach
    containerType {
      id
      name
      width
      height
      length
      volume
    }
    coordinates {
      x
      y
      z
    }
    products {
      product {
        id
        name
        image
        description
        customerIdentifier
        width
        height
        length
        volume
        scanValues
      }
      inventory {
        assetTypeId
        conflicted
        conflictReasons
        totalPlannedRemoveQuantity
        totalPlannedAddQuantity
        liveQuantity
        subAssetDescriptorId
        subLocationDescriptorId
        subLocation {
          definition {
            externalId
          }
        }
        subAssetType {
          definition {
            condition
          }
        }
      }
    }
    workArea {
      id
      name
    }
  }
}
    `;

export function useGetDetailedLocationQuery(options: Omit<Urql.UseQueryArgs<GetDetailedLocationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDetailedLocationQuery, GetDetailedLocationQueryVariables>({ query: GetDetailedLocationDocument, ...options });
};