// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddNewLocationMutationVariables = Types.Exact<{
  input: Types.StorageLocationInput;
}>;


export type AddNewLocationMutation = { __typename?: 'Mutation', addLocation: { __typename?: 'MutationResponse', success: boolean, referenceId?: string | null } };


export const AddNewLocationDocument = gql`
    mutation fapi_AddNewLocation($input: StorageLocationInput!) {
  addLocation(input: $input) {
    success
    referenceId
  }
}
    `;

export function useAddNewLocationMutation() {
  return Urql.useMutation<AddNewLocationMutation, AddNewLocationMutationVariables>(AddNewLocationDocument);
};