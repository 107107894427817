import { AddMajor, SortMinor } from '@sixriver/lighthouse-icons';
import { ChoiceList, ContextualSaveBar, Link, Page } from '@sixriver/lighthouse-web-community';
import { useEffect, useState } from 'react';

import { useEquipmentName } from './JobFlowRuleHelpers';
import { PriorityControls } from './PriorityControls';
import { useGetJobFlowRulesQuery } from './graphql/GetJobFlowRules.f-api-graphql';
import { usePrioritizeJobFlowRulesMutation } from './graphql/PrioritizeJobFlowRules.f-api-graphql';
import { Column, DataTable } from '../../components/DataTable';
import { Error } from '../../components/Error';
import { GetJFRQueryJFR } from '../../graphql/derived';
import { useEquipmentTypes } from '../../hooks/useConfig';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';

export function JobFlowRules() {
	const { messages } = useLocalization();
	const { query, equipment } = useFilters(['query', 'equipment']);
	const { showToast } = useToast();
	const setFilters = useSetFilters();
	const getEquipmentName = useEquipmentName();
	const equipmentTypes = useEquipmentTypes();

	const [getJobFlowRulesQuery, refetchJobFlowRules] = useGetJobFlowRulesQuery();

	const [prioritizeJobFlowRulesMutation, executePrioritizeJobFlowRulesMutation] =
		usePrioritizeJobFlowRulesMutation();

	// Are we in "editing" mode?
	const [editing, setEditing] = useState(false);

	// Make a copy of the rules for editing purposes
	const [copy, setCopy] = useState<GetJFRQueryJFR[]>([]);

	const startEditing = () => {
		// Show everything!
		setFilters([{ key: 'query', value: '' }]);

		// Copy the source data
		setCopy(Array.from(getJobFlowRulesQuery.data?.jobFlowRules || []));

		// Go into "editing" mode
		setEditing(true);
	};

	const savePriorities = async () => {
		await executePrioritizeJobFlowRulesMutation({
			input: {
				ids: copy.map((r) => r.id),
			},
		});

		// Refetch the rules for good measure
		refetchJobFlowRules();

		showToast(messages.reordered);
	};

	useEffect(() => {
		// This gets triggered after a refetch
		setEditing(false);
	}, [getJobFlowRulesQuery.data]);

	if (getJobFlowRulesQuery.error || prioritizeJobFlowRulesMutation.error) {
		return (
			<Error graphQLError={getJobFlowRulesQuery.error || prioritizeJobFlowRulesMutation.error} />
		);
	}

	const columns: Column[] = [
		{
			heading: messages.name,
			type: 'text',
		},
		{
			heading: messages.ruleType,
			type: 'text',
		},
		{
			heading: messages.equipment,
			type: 'text',
		},
	];

	if (editing) {
		columns.push({
			heading: messages.move,
			type: 'text',
		});
	}

	// Results are not paginated. All filtering is done here!
	const rules = (getJobFlowRulesQuery.data?.jobFlowRules || []).filter((rule) => {
		let matched = true;

		if (query) {
			matched &&= rule.ruleDescription.toLowerCase().indexOf(query.toLowerCase()) > -1;
		}

		if (equipment) {
			let matchedAny = false;
			for (const device of rule.ruleSubType) {
				matchedAny ||= equipment.split('+').includes(device);
			}
			matched &&= matchedAny;
		}

		return matched;
	});

	const moveRule = (fromIndex: number, toIndex: number): void => {
		const [rule] = copy.splice(fromIndex, 1);
		copy.splice(toIndex, 0, rule);

		setCopy(Array.from(copy));
	};

	const rows = (editing ? copy : rules).map((rule, i) => {
		const row: React.ReactNode[] = [
			<Link url={routes.jobFlowRule(rule.id)} key="id" removeUnderline>
				{rule.ruleDescription}
			</Link>,
			rule.rule.job ? messages.job : messages.line,
			rule.ruleSubType.map(getEquipmentName).join(', '),
		];

		if (editing) {
			row.push(
				<PriorityControls
					onMoveUp={i > 0 ? moveRule.bind(null, i, i - 1) : undefined}
					onMoveDown={i < rules.length - 1 ? moveRule.bind(null, i, i + 1) : undefined}
				/>,
			);
		}

		return row;
	});

	const filters = [
		{
			filter: (
				<ChoiceList
					title={messages.equipment}
					titleHidden
					allowMultiple
					choices={equipmentTypes.map((type) => {
						return {
							label: getEquipmentName(type),
							value: type,
						};
					})}
					selected={equipment?.split('+') || []}
					onChange={(selected) => {
						setFilters([{ key: 'equipment', value: selected.join('+') }]);
					}}
				/>
			),
			key: 'equipment',
			label: messages.equipment,
			shortcut: true,
		},
	];

	const appliedFilters = [];

	if (equipment) {
		appliedFilters.push({
			key: 'equipment',
			label: equipment.split('+').map(getEquipmentName).join(', '),
			onRemove: () => setFilters([{ key: 'equipment', value: '' }]),
		});
	}

	return (
		<Page
			fullWidth
			title={messages.jobFlowRules}
			subtitle={messages.jobFlowRuleHints.priorityOrder}
			primaryAction={{
				content: messages.addRule,
				disabled: editing,
				icon: AddMajor,
				url: routes.addJobFlowRule(),
			}}
			secondaryActions={[
				{
					content: messages.reorderRules,
					disabled: editing || rules.length < 2,
					icon: SortMinor,
					onAction: startEditing,
				},
			]}
		>
			<DataTable
				loading={getJobFlowRulesQuery.fetching}
				columns={columns}
				rows={rows}
				query={query}
				queryPlaceholder={messages.filterJobFlowRules}
				filters={filters}
				appliedFilters={appliedFilters}
				disableFilters={editing}
				setFilters={setFilters}
				emptyStateHeading={messages.jobFlowRulesNotFound}
			/>
			{editing ? (
				<ContextualSaveBar
					fullWidth={false}
					alignContentFlush={false}
					message={messages.unsavedChanges}
					saveAction={{
						content: messages.save,
						disabled: false,
						loading: prioritizeJobFlowRulesMutation.fetching,
						onAction: () => void savePriorities(),
					}}
					discardAction={{
						content: messages.discard,
						onAction: () => setEditing(false),
					}}
				/>
			) : null}
		</Page>
	);
}
