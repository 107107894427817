import { IndexTable, TextStyle } from '@sixriver/lighthouse-web-community';

import { GetOutboundJobLineFragment } from '../../../pages/OutboundJob/graphql/GetLines.w-api-graphql';
import { NoData } from '../../NoData';

interface Props {
	line: GetOutboundJobLineFragment;
}

export function ProductNameCell({ line }: Props): JSX.Element {
	return (
		<IndexTable.Cell>
			{line?.productName ? <TextStyle>{line.productName}</TextStyle> : <NoData />}
		</IndexTable.Cell>
	);
}
