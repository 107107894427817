// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteContainerMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeleteContainerMutation = { __typename?: 'Mutation', deleteContainer: { __typename?: 'MutationResponse', success: boolean, referenceId?: string | null } };


export const DeleteContainerDocument = gql`
    mutation fapi_DeleteContainer($id: String!) {
  deleteContainer(id: $id) {
    success
    referenceId
  }
}
    `;

export function useDeleteContainerMutation() {
  return Urql.useMutation<DeleteContainerMutation, DeleteContainerMutationVariables>(DeleteContainerDocument);
};