import { DiamondAlertMajor } from '@sixriver/lighthouse-icons';
import {
	Card,
	DataTable,
	Icon,
	Stack,
	TextStyle,
	Thumbnail,
} from '@sixriver/lighthouse-web-community';

import { analyzeTaggage, conditionValue } from './LegacyLocationsTable';
import { LocationProductSummary } from './LocationProductSummary';
import styles from './Locations.module.css';
import { StorageLocationType } from '../../api/fulfillment-api/types';
import { NoData } from '../../components/NoData';
import { LocationQueryLocation, LocationQueryProduct } from '../../graphql/derived';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';
import { transformImageUrl } from '../../utils';

interface Props {
	condition?: string;
	location: LocationQueryLocation;
	containerId?: string;
}

export function LocationInventory({ location, containerId }: Props) {
	const { messages, formatNumber, translate } = useLocalization();

	const iconStyle = {
		display: 'inline-block',
		marginLeft: '5px',
		transform: 'scale(0.75)',
		verticalAlign: 'bottom',
	};

	const createRow = ({ product, inventory }: LocationQueryProduct) => {
		const reservedCount = Math.abs(inventory.totalPlannedRemoveQuantity);
		let locationCondition: string | undefined;

		if (inventory.liveQuantity && inventory.liveQuantity > 0) {
			if (location.type === StorageLocationType.Damaged) {
				locationCondition = 'damaged';
			} else {
				locationCondition = analyzeTaggage(
					inventory.subAssetType ? [inventory.subAssetType] : undefined,
				);
			}
		}

		return [
			<Stack key="product" vertical={false}>
				<Stack.Item>
					<Thumbnail
						key="image"
						source={transformImageUrl(product.image)}
						alt={product.description}
					/>
				</Stack.Item>
				<Stack.Item>
					<LocationProductSummary
						key="inventory"
						location={location}
						product={product}
						inventory={inventory}
						productURL={routes.product(product.id)}
						barcodeListLabel={messages.identifiers}
					/>
				</Stack.Item>
			</Stack>,

			// TODO: STRATA-60 decode subAssetID > inventory condition
			<TextStyle key="condition">
				{conditionValue(locationCondition, messages.inventoryStateNames) || <NoData />}
			</TextStyle>,

			<Stack key="quantity" vertical={true} spacing="extraTight" distribution="leading">
				<Stack.Item>
					{location.type === StorageLocationType.Staging ? null : messages.total + ': '}
					{inventory.liveQuantity ? formatNumber(inventory.liveQuantity) : <NoData />}
					{inventory.conflicted ? (
						<span style={iconStyle}>
							<Icon color="critical" source={DiamondAlertMajor} />
						</span>
					) : null}
				</Stack.Item>
				{location.type === StorageLocationType.Staging
					? null
					: [
							<Stack.Item key="a">
								{messages.reserved + ': '}
								{formatNumber(reservedCount)}
							</Stack.Item>,
							<Stack.Item key="b">
								{messages.available + ': '}
								{inventory.liveQuantity ? (
									formatNumber(inventory.liveQuantity - reservedCount)
								) : (
									<NoData />
								)}
							</Stack.Item>,
					  ]}
			</Stack>,
		];
	};

	// Filter only products at location (or sublocation)
	const products = location.products.filter(
		(product) =>
			(product.inventory.subLocationDescriptorId === '00000000-0000-0000-0000-000000000000' &&
				!containerId) ||
			product.inventory.subLocationDescriptorId === containerId,
	);
	const rows = products.map(createRow);
	const totalUnits = products.reduce((units, product) => {
		return (units += product.inventory.liveQuantity || 0);
	}, 0);
	const uniqueSkus = new Set(products.map((product) => product.inventory.assetTypeId));

	return (
		<>
			<Card
				title={messages.inventory}
				actions={[{ content: `Permanent location`, disabled: true }]}
			>
				<Card.Section fullWidth>
					<div className={styles.containers}>
						<DataTable
							columnContentTypes={['text', 'text', 'text']}
							headings={[
								messages.product.toUpperCase(),
								messages.inventoryState.toUpperCase(),
								messages.quantity.toUpperCase(),
							]}
							totals={['', '', translate(messages.countUnits, { count: totalUnits })]}
							totalsName={{
								plural: translate(messages.countProducts, {
									count: uniqueSkus.size,
								}) as string,
								singular: translate(messages.countProducts, {
									count: uniqueSkus.size,
								}) as string,
							}}
							rows={rows}
						></DataTable>
					</div>
					<div style={{ marginLeft: '1.5rem', marginTop: `1.5rem` }}>
						{rows.length ? '' : 'No inventory in this location'}
					</div>
				</Card.Section>
			</Card>
		</>
	);
}
