import { GoalStates } from '../../api/fulfillment-api/types';

export enum ShuttleAvailableDestinationsViews {
	All = 'All',
	Unassigned = 'Unassigned',
	InProgress = 'In progress',
	// destinations are never queued (yet)
	Completed = 'Completed',
	Cancelled = 'Cancelled',
}

export const ShuttleAvailableDestinationsViewsViewStateMap: {
	[key in ShuttleAvailableDestinationsViews]: GoalStates[];
} = {
	[ShuttleAvailableDestinationsViews.All]: [],
	[ShuttleAvailableDestinationsViews.Unassigned]: [
		GoalStates.Creating,
		GoalStates.Created,
		GoalStates.Ready,
	],
	// destinations are never queued (yet)
	[ShuttleAvailableDestinationsViews.InProgress]: [
		GoalStates.Running,
		GoalStates.TerminateRequested,
		GoalStates.Terminating,
		GoalStates.CancelRequested,
		GoalStates.Cancelling,
	],
	[ShuttleAvailableDestinationsViews.Completed]: [GoalStates.Complete],
	[ShuttleAvailableDestinationsViews.Cancelled]: [GoalStates.Cancelled, GoalStates.Terminated],
};
