import { IndexTable } from '@sixriver/lighthouse-web-community';

import { ProgressBadge } from './ProgressBadge';
import { GetOutboundJobLineFragment } from '../../../../pages/OutboundJob/graphql/GetLines.w-api-graphql';

interface Props {
	line: GetOutboundJobLineFragment;
}

export function ProgressCell({ line }: Props): JSX.Element {
	return (
		<IndexTable.Cell>
			<ProgressBadge line={line} />
		</IndexTable.Cell>
	);
}
