// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.w-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetSortWallsSortationFragment = { __typename?: 'Sortation', mfpId?: string | null, userId?: string | null, createdAt: any, updatedAt: any, completedAt?: any | null, wallDisplayId: string, wallExternalId: string };

export type GetSortWallsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  statuses: Array<Types.JobStatus> | Types.JobStatus;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  completedAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  completedAtTo?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type GetSortWallsQuery = { __typename?: 'Query', sortWalls: { __typename?: 'JobConnection', edges: Array<{ __typename?: 'JobEdge', node: { __typename?: 'Job', id: string, wall?: string | null, status: Types.JobStatus, batchId?: string | null, expectedShipDate?: any | null, createdAt: any, isShorted: boolean, lines: { __typename?: 'LineConnection', edges: Array<{ __typename?: 'LineEdge', node: { __typename?: 'Line', id: string, status?: string | null, quantity: number, doneQuantity: number, isShorted: boolean } }> }, totalLines: { __typename?: 'Count', count: number }, totalLineQuantities: { __typename?: 'LineQuantities', pickedUnits: number, inProgressUnits: number, unassignedUnits: number, completedLines: number, totalUnits: number }, pickingJobs: Array<{ __typename?: 'Job', id: string, status: Types.JobStatus, lines: { __typename?: 'LineConnection', edges: Array<{ __typename?: 'LineEdge', node: { __typename?: 'Line', id: string, status?: string | null } }> } }>, sortations: { __typename?: 'SortationConnection', edges: Array<{ __typename?: 'SortationEdge', node: { __typename?: 'Sortation', mfpId?: string | null, userId?: string | null, createdAt: any, updatedAt: any, completedAt?: any | null, wallDisplayId: string, wallExternalId: string } }> } } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export const GetSortWallsSortationFragmentDoc = gql`
    fragment GetSortWallsSortation on Sortation {
  mfpId
  userId
  createdAt
  updatedAt
  completedAt
  wallDisplayId
  wallExternalId
}
    `;
export const GetSortWallsDocument = gql`
    query wapi_GetSortWalls($after: String, $before: String, $first: Int, $last: Int, $statuses: [JobStatus!]!, $searchText: String, $completedAtFrom: DateTime, $completedAtTo: DateTime) {
  sortWalls(
    after: $after
    before: $before
    first: $first
    last: $last
    statuses: $statuses
    searchText: $searchText
    completedAtFrom: $completedAtFrom
    completedAtTo: $completedAtTo
  ) {
    edges {
      node {
        id
        wall
        status
        batchId
        expectedShipDate
        createdAt
        isShorted
        lines {
          edges {
            node {
              id
              status
              quantity
              doneQuantity
              isShorted
            }
          }
        }
        totalLines {
          count
        }
        totalLineQuantities {
          pickedUnits
          inProgressUnits
          unassignedUnits
          completedLines
          totalUnits
          unassignedUnits
        }
        pickingJobs {
          id
          status
          lines {
            edges {
              node {
                id
                status
              }
            }
          }
        }
        sortations {
          edges {
            node {
              ...GetSortWallsSortation
            }
          }
        }
        createdAt
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${GetSortWallsSortationFragmentDoc}
${PageInfoFragmentDoc}`;

export function useGetSortWallsQuery(options: Omit<Urql.UseQueryArgs<GetSortWallsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSortWallsQuery, GetSortWallsQueryVariables>({ query: GetSortWallsDocument, ...options });
};