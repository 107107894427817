// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import { PickWaveOrderConnectionFieldsFragmentDoc } from './PickWaveOrderConnectionFields.f-api-graphql';
export type PickWaveFieldsFragment = { __typename?: 'PickWave', id: string, createdAt: any, resolvedAt?: any | null, status: Types.PickWaveStatus, totalLinesCount: number, droppedLinesCount: number, errorCodes: Array<Types.PickWaveOrderLineErrorCode>, orders: { __typename?: 'PickWaveOrderConnection', edges?: Array<{ __typename?: 'PickWaveOrderEdge', node: { __typename?: 'PickWaveOrder', id: string, status: Types.PickWaveOrderStatus, totalLinesCount: number, droppedLinesCount: number, errorCodes: Array<Types.PickWaveOrderLineErrorCode>, externalId: string } }> | null, pageInfo?: { __typename?: 'PickWaveOrderPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null } };

export const PickWaveFieldsFragmentDoc = gql`
    fragment PickWaveFields on PickWave {
  id
  createdAt
  resolvedAt
  status
  totalLinesCount
  droppedLinesCount
  errorCodes
  orders {
    ...PickWaveOrderConnectionFields
  }
}
    ${PickWaveOrderConnectionFieldsFragmentDoc}`;