// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.w-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetLocationsQueryVariables = Types.Exact<{
  cursor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.StorageLocationOrderByFields>;
  orderByDirection?: Types.InputMaybe<Types.OrderByDirection>;
  type?: Types.InputMaybe<Types.StorageLocationType>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetLocationsQuery = { __typename?: 'Query', locations: { __typename?: 'StorageLocationConnection', edges: Array<{ __typename?: 'StorageLocationEdge', node: { __typename?: 'StorageLocation', id: string, type: string, address: string, description: string, externalAisleId?: string | null, containerTypeId?: string | null, containerTypeName?: string | null, mapChunkId?: string | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };


export const GetLocationsDocument = gql`
    query wapi_GetLocations($cursor: String, $after: String, $limit: Float, $first: Int, $orderBy: StorageLocationOrderByFields, $orderByDirection: OrderByDirection, $type: StorageLocationType, $searchText: String) {
  locations(
    cursor: $cursor
    limit: $limit
    after: $after
    first: $first
    orderBy: $orderBy
    orderByDirection: $orderByDirection
    type: $type
    searchText: $searchText
  ) {
    edges {
      node {
        id
        type
        address
        description
        externalAisleId
        containerTypeId
        containerTypeName
        mapChunkId
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${PageInfoFragmentDoc}`;

export function useGetLocationsQuery(options?: Omit<Urql.UseQueryArgs<GetLocationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLocationsQuery, GetLocationsQueryVariables>({ query: GetLocationsDocument, ...options });
};