// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CancelSortWallMutationVariables = Types.Exact<{
  projectionId: Types.Scalars['String']['input'];
}>;


export type CancelSortWallMutation = { __typename?: 'Mutation', cancelSortWall: { __typename?: 'MutationResponse', success: boolean } };


export const CancelSortWallDocument = gql`
    mutation fapi_CancelSortWall($projectionId: String!) {
  cancelSortWall(projectionId: $projectionId) {
    success
  }
}
    `;

export function useCancelSortWallMutation() {
  return Urql.useMutation<CancelSortWallMutation, CancelSortWallMutationVariables>(CancelSortWallDocument);
};