// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CancelOrdersFragment = { __typename?: 'MutationResponse', success: boolean, referenceId?: string | null };

export type CancelOrdersMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type CancelOrdersMutation = { __typename?: 'Mutation', cancelOrders: { __typename?: 'MutationResponse', success: boolean, referenceId?: string | null } };

export const CancelOrdersFragmentDoc = gql`
    fragment CancelOrders on MutationResponse {
  success
  referenceId
}
    `;
export const CancelOrdersDocument = gql`
    mutation fapi_CancelOrders($ids: [String!]!) {
  cancelOrders(ids: $ids) {
    ...CancelOrders
  }
}
    ${CancelOrdersFragmentDoc}`;

export function useCancelOrdersMutation() {
  return Urql.useMutation<CancelOrdersMutation, CancelOrdersMutationVariables>(CancelOrdersDocument);
};