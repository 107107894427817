import { IndexTable } from '@sixriver/lighthouse-web-community';

import { GoalStates } from '../../../api/fulfillment-api/types';
import { DateTime } from '../../../components/DateTime';
import { NoData } from '../../../components/NoData';
import { RelativeDateTime } from '../../../components/RelativeDateTime';
import { GetPutawayJobsQueryNode } from '../../../graphql/derived';

export function UpdatedAtCell({ putawayJob }: { putawayJob: GetPutawayJobsQueryNode }) {
	return (
		<IndexTable.Cell>
			{putawayJob?.state.includes(GoalStates.Running.toLowerCase()) && (
				<>
					<DateTime date={putawayJob?.lastEventPersistedAt} />
					<RelativeDateTime date={putawayJob?.lastEventPersistedAt} />
				</>
			)}
			{putawayJob?.state.includes(GoalStates.Cancelled.toLowerCase()) && (
				<>
					<DateTime date={putawayJob?.endedAt} />
					<RelativeDateTime date={putawayJob?.endedAt} />
				</>
			)}
			{putawayJob?.state.includes(GoalStates.Terminated.toLowerCase()) && (
				<>
					<DateTime date={putawayJob?.endedAt} />
					<RelativeDateTime date={putawayJob?.endedAt} />
				</>
			)}
			{putawayJob?.state.includes(GoalStates.Complete.toLowerCase()) && (
				<>
					<DateTime date={putawayJob?.endedAt} />
					<RelativeDateTime date={putawayJob?.endedAt} />
				</>
			)}
			{putawayJob?.state.includes(GoalStates.Ready.toLowerCase()) && <NoData />}
		</IndexTable.Cell>
	);
}
