// interface Props {
// 	order?: GetOrderQueryJob;
// }

export function ShippedShortBanner(): JSX.Element | null {
	return null;
	// LOL-3312
	// return order?.isShippedShort ? (
	// 	<Banner status="warning">
	// 		<Heading>{messages.shippedShortBannerHeading}</Heading>
	// 		<TextStyle>{messages.shippedShortBannerBody}</TextStyle>
	// 	</Banner>
	// ) : null;
}
