// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetCapacityTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCapacityTypesQuery = { __typename?: 'Query', containers: { __typename?: 'ContainersPage', results: Array<{ __typename?: 'Container', id: string, type: Types.ContainerTypeWithFallback, name: string }> } };


export const GetCapacityTypesDocument = gql`
    query fapi_GetCapacityTypes {
  containers {
    results {
      id
      type
      name
    }
  }
}
    `;

export function useGetCapacityTypesQuery(options?: Omit<Urql.UseQueryArgs<GetCapacityTypesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCapacityTypesQuery, GetCapacityTypesQueryVariables>({ query: GetCapacityTypesDocument, ...options });
};