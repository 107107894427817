import { TextField, TextFieldProps } from '@sixriver/lighthouse-web-community';
import { useState, useEffect, useRef, useCallback } from 'react';

export type ValidationResult = ValidResult | InvalidResult;
export interface ValidResult {
	valid: true;
}
export interface InvalidResult {
	valid: false;
	message: string;
}

export function isValidResult(result: ValidationResult): result is ValidResult {
	return result.valid;
}
export function isInvalidResult(result: ValidationResult): result is InvalidResult {
	return !result.valid;
}

export type ValidatedTextFieldProps = Omit<TextFieldProps, 'error' | 'onChange'> & {
	isValid: (value: string | undefined) => ValidationResult;
	onChange: (value: string | undefined) => void;
};

export function ValidatedTextField(props: ValidatedTextFieldProps) {
	const { value: propsValue, onChange: propsOnChange, isValid, ...others } = props;

	const prevPropsValueRef = useRef<string>();

	const [value, setValue] = useState<string>();
	const [validationError, setValidationError] = useState<string>();

	// sync changes from incoming props value
	useEffect(() => {
		if (prevPropsValueRef.current !== propsValue) {
			prevPropsValueRef.current = propsValue;
			setValue(propsValue);
		}
	}, [propsValue]);

	// handles changes from the TextField
	const handleValueChanged = useCallback(
		(value: string) => {
			setValue(value);

			const validationResult = isValid(value);
			const newValidationError = isValidResult(validationResult)
				? undefined
				: validationResult.message;

			if (newValidationError !== validationError) {
				setValidationError(newValidationError);
			}
			if (validationResult.valid && value !== propsValue) {
				propsOnChange(value);
			}
		},
		[isValid, propsOnChange, propsValue, validationError],
	);

	return (
		<TextField
			{...others}
			value={value ?? ''}
			onChange={handleValueChanged}
			error={validationError}
		/>
	);
}
