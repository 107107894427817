import clsx from 'clsx';

import { ExpandButton } from './ExpandButton';
import styles from './ObjectDiff.module.css';
import { isExpandable } from './helpers';

type ObjectDiffValueProps = {
	value: string;
	expanded: boolean;
	variant: 'before' | 'after';
};

export function ObjectDiffValue({ value, expanded, variant }: ObjectDiffValueProps) {
	const variantClass = variant === 'before' ? styles.OldValue : styles.NewValue;

	const wrapperClassNames = clsx(styles.ObjectDiffValue, {
		[styles.ValueExpandable]: isExpandable(value),
		[styles.ValueExpanded]: expanded,
	});

	return (
		<div className={wrapperClassNames} data-testid={`object-diff-${variant}`}>
			<pre className={variantClass}>{value}</pre>
			<ExpandButton expanded={expanded} />
		</div>
	);
}
