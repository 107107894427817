import { isToday } from '../../helpers/time';
import { useLocalization } from '../../hooks/useLocalization';
import { NoData } from '../NoData';

interface Props {
	date?: Date | number | string;
	inline?: boolean;
}

export function DateTime({ date, inline = false }: Props) {
	const { formatTime, formatDateTime } = useLocalization();

	if (date) {
		const str = isToday(new Date(date)) ? formatTime(date) : formatDateTime(date);
		const iso = new Date(date).toISOString();

		const time = (
			<time dateTime={iso} title={iso}>
				{str}
			</time>
		);

		if (inline) {
			return time;
		}

		return <div>{time}</div>;
	}

	return <NoData />;
}
