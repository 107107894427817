import {
	EmptySearchResult,
	IndexTableProps,
	IndexTable,
	useIndexResourceState,
} from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';
import { noop } from 'lodash';

import { AssociatedOrderCell } from './AssociatedOrderCell';
import { CarrierCutoffCell } from './CarrierCutoffCell';
import { ContainerCell } from './ContainerCell';
import { CreatedAtCell } from './CreatedAtCell';
import { StatusCell } from './StatusCell';
import { TypesCell } from './TypesCell';
import { UnitsPickedCell } from './UnitsPickedCell';
import { WorkAreasCell } from './WorkAreasCell';
import { TaskV2, WorkArea } from '../../../api/fulfillment-api/types';
import { useIsHealingEnabled, useIsWorkAreasEnabled } from '../../../hooks/useConfig';
import { useLocalization } from '../../../hooks/useLocalization';

export enum ExceptionsAction {
	ManuallyResolve,
}

export interface ExceptionsTableProps {
	loading: boolean;
	data?: any[];
	selectable?: boolean;
	actions?: ExceptionsAction[];
	onManuallyResolve?: (selectedIds: string[]) => void;
}

export function ExceptionsTable({
	loading,
	data = [],
	selectable = false,
	actions = [],
	onManuallyResolve = noop,
}: ExceptionsTableProps) {
	const { messages } = useLocalization();
	const { isUserAllowed } = useAuth();
	const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
		data,
		{ resourceIDResolver: (edge) => edge.node.id },
	);
	const emptyStateMarkup = (
		<EmptySearchResult title={messages.exceptionsNotFound} withIllustration />
	);
	const resourceName = {
		plural: messages.exceptions.toLowerCase(),
		singular: messages.exception.toLowerCase(),
	};
	const isHealingEnabled = useIsHealingEnabled();
	const isWorkAreaEnabled = useIsWorkAreasEnabled();

	const headings: IndexTableProps['headings'] = [
		{ title: messages.order },
		{ title: messages.exceptionStatus },
		...(!isHealingEnabled ? [{ title: messages.reason }] : []),
		{ title: messages.unitsPicked },
		{ title: messages.createdAt },
		{ title: messages.carrierCutoff },
		...(isWorkAreaEnabled ? [{ title: messages.workArea }] : []),
		{ title: messages.initialLicensePlate },
	] as IndexTableProps['headings'];

	const rows = data.map(({ node: order }, index) => {
		const {
			id,
			externalId,
			lines,
			createdAt,
			expectedShipDate,
			externalContainerId,
			tasks,
			totalLineQuantities,
		} = order;
		const uniqueWorkAreas = tasks.reduce((workAreas: any, task: TaskV2) => {
			if (task.workArea) {
				workAreas.add(task.workArea);
			}
			return workAreas;
		}, new Set<WorkArea>());

		return (
			<IndexTable.Row
				id={id}
				key={id}
				position={index}
				selected={allResourcesSelected || selectedResources.includes(id)}
			>
				<AssociatedOrderCell id={id} externalId={externalId} />
				<StatusCell order={order} />
				<TypesCell lines={lines} />
				<UnitsPickedCell totalLineQuantities={totalLineQuantities} />
				<CreatedAtCell createdAt={createdAt} />
				<CarrierCutoffCell expectedShipDate={expectedShipDate} />
				{isWorkAreaEnabled ? <WorkAreasCell workAreas={uniqueWorkAreas} /> : null}
				<ContainerCell externalContainerId={externalContainerId} />
			</IndexTable.Row>
		);
	});

	const promotedBulkActions: IndexTableProps['promotedBulkActions'] = actions
		.map((action) => {
			switch (action) {
				case ExceptionsAction.ManuallyResolve:
					return {
						content: messages.manuallyResolved,
						disabled: !isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]),
						onAction: () => {
							onManuallyResolve(selectedResources);
						},
					};
				default:
					return undefined;
			}
		})
		.filter((action): action is any => !!action);

	return (
		<IndexTable
			emptyState={emptyStateMarkup}
			resourceName={resourceName}
			headings={headings}
			hasMoreItems={false}
			itemCount={data.length}
			loading={loading}
			selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
			onSelectionChange={handleSelectionChange}
			promotedBulkActions={promotedBulkActions}
			selectable={selectable}
		>
			{rows}
		</IndexTable>
	);
}
