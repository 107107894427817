import { IndexTable } from '@sixriver/lighthouse-web-community';

import { GetPutawayJobsQueryLines } from '../../../graphql/derived';
import { useLocalization } from '../../../hooks/useLocalization';

export function LinesCell({ lines }: { lines: GetPutawayJobsQueryLines }) {
	const { messages, translate } = useLocalization();

	// if the completedCount === totalCount on a line, we can assume the line is complete
	const completedLines = lines.filter((line) => {
		return line.outputs.totalCompleted === line.inputs.amount;
	});

	return (
		<IndexTable.Cell>
			{translate(messages.xOfY, {
				x: completedLines?.length,
				y: lines?.length,
			})}
		</IndexTable.Cell>
	);
}
