import { IndexTable } from '@sixriver/lighthouse-web-community';

import { ShuttleJobStatus } from './ShuttleJobStatus';
import { GoalStates } from '../../../api/fulfillment-api/types';

export function ProgressCell({ status }: { status?: GoalStates }) {
	return (
		<IndexTable.Cell>
			<ShuttleJobStatus status={status} />
		</IndexTable.Cell>
	);
}
