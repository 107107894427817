import { ChoiceList, ChoiceListProps } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';

import { PickWaveStatus } from '../../../api/fulfillment-api/types';

export interface StatusFilterProps {
	selected: string[];
	onChange: (selected: PickWaveStatus[]) => void;
}

export function StatusFilter({ selected, onChange }: StatusFilterProps) {
	const [choices] = useState<ChoiceListProps['choices']>([
		{
			label: 'Failure',
			value: PickWaveStatus.Failure,
		},
		{
			label: 'Partial Success',
			value: PickWaveStatus.PartialSuccess,
		},
		{
			label: 'Success',
			value: PickWaveStatus.Success,
		},
	]);
	return (
		<ChoiceList
			title="Status"
			titleHidden
			choices={choices}
			selected={selected}
			onChange={onChange}
			allowMultiple
		/>
	);
}
