import { MarkFulfilledMinor } from '@sixriver/lighthouse-icons';
import { Frame, Layout, Loading, MenuActionDescriptor } from '@sixriver/lighthouse-web-community';
import { useRouteMatch } from 'react-router-dom';

import { Details } from './Details';
import {
	GetExceptionOrderDocument,
	GetExceptionOrderQuery,
	GetExceptionOrderQueryVariables,
} from './graphql/GetExceptionOrder.w-api-graphql';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { CancelOrderModal } from '../../components/CancelOrderModal';
import { Error } from '../../components/Error';
import { Lines } from '../../components/Lines';
import { LinesTableColumn } from '../../components/Lines/LinesTable';
import { useCancelOrder } from '../../hooks/useCancelOrder';
import { useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useApplyManualPickHealPlan } from '../../hooks/useHealPlan';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';

export interface RouteMatchParams {
	exceptionId: string;
}

export function Exception() {
	const {
		params: { exceptionId },
	} = useRouteMatch<RouteMatchParams>();
	const { messages } = useLocalization();
	const [getExceptionOrderQuery] = usePollingQuery<
		GetExceptionOrderQuery,
		GetExceptionOrderQueryVariables
	>({
		query: GetExceptionOrderDocument,
		variables: {
			id: exceptionId,
		},
	});
	const { job: order } = getExceptionOrderQuery.data || {};

	const isWorkAreaEnabled = useIsWorkAreasEnabled();

	const { isCancelModalOpen, openCancelModal, closeCancelModal, cancelOrder, cancelOrderLoading } =
		useCancelOrder({ orderId: exceptionId });
	const actions: MenuActionDescriptor[] = [
		{
			content: messages.cancel,
			// LOL-3316, just enable it all the time and hope
			// disabled: !order?.isCancelable,
			onAction: openCancelModal,
		},
	];

	const { fetching: applyManualHealPlanFetching, applyManualHealPlan } =
		useApplyManualPickHealPlan();
	if ((order as any)?.isHealable) {
		actions.push({
			content: messages.manuallyResolved,
			icon: MarkFulfilledMinor,
			loading: applyManualHealPlanFetching,
			onAction: () => {
				if (order) {
					applyManualHealPlan(order.id);
				}
			},
		});
	}

	if (getExceptionOrderQuery.fetching) {
		return (
			<Frame>
				<Loading />
			</Frame>
		);
	}

	if (getExceptionOrderQuery.error) {
		return <Error graphQLError={getExceptionOrderQuery.error} />;
	}

	if (!order) {
		return <Error heading={messages.orderNotFound} />;
	}

	return (
		<AutoRefreshPage
			queries={[getExceptionOrderQuery]}
			title={exceptionId}
			secondaryActions={actions}
		>
			<Layout>
				<Layout.Section>
					<Details isWorkAreaEnabled={isWorkAreaEnabled} order={order} />
				</Layout.Section>
				<Layout.Section>
					<Lines
						job={order}
						columns={[
							LinesTableColumn.id,
							LinesTableColumn.name,
							LinesTableColumn.pickingUnits,
							LinesTableColumn.exceptions,
							LinesTableColumn.reason,
							LinesTableColumn.location,
						]}
					/>
				</Layout.Section>
			</Layout>

			<CancelOrderModal
				isOpen={isCancelModalOpen}
				onClose={closeCancelModal}
				onConfirm={cancelOrder}
				loading={cancelOrderLoading}
			/>
		</AutoRefreshPage>
	);
}
