// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetShuttleAvailableContainersCountQueryVariables = Types.Exact<{
  cursor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statuses?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  orderBy?: Types.InputMaybe<Array<Types.ShuttleAvailableContainerOrderBy> | Types.ShuttleAvailableContainerOrderBy>;
}>;


export type GetShuttleAvailableContainersCountQuery = { __typename?: 'Query', countShuttleAvailableContainers: { __typename?: 'ShuttleAvailableContainerCount', unassigned: number, inProgress: number, interrupted: number, held: number, completed: number, cancelled: number } };


export const GetShuttleAvailableContainersCountDocument = gql`
    query wapi_GetShuttleAvailableContainersCount($cursor: String, $limit: Float, $searchText: String, $statuses: [String!], $orderBy: [ShuttleAvailableContainerOrderBy!]) {
  countShuttleAvailableContainers(
    cursor: $cursor
    limit: $limit
    searchText: $searchText
    statuses: $statuses
    orderBy: $orderBy
  ) {
    unassigned
    inProgress
    interrupted
    held
    completed
    cancelled
  }
}
    `;

export function useGetShuttleAvailableContainersCountQuery(options?: Omit<Urql.UseQueryArgs<GetShuttleAvailableContainersCountQueryVariables>, 'query'>) {
  return Urql.useQuery<GetShuttleAvailableContainersCountQuery, GetShuttleAvailableContainersCountQueryVariables>({ query: GetShuttleAvailableContainersCountDocument, ...options });
};