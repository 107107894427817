import { Modal, TextContainer } from '@sixriver/lighthouse-web-community';

export interface ConfirmationModalProps {
	show: boolean;
	title: string;
	description: string;
	banner: JSX.Element | undefined;
	onConfirmAction: () => void;
	onCancelAction: () => void;
}

export function ConfirmationModal(props: ConfirmationModalProps): JSX.Element {
	return (
		<Modal
			open={props.show}
			onClose={props.onCancelAction}
			title={props.title}
			primaryAction={{
				content: 'Confirm',
				onAction: () => {
					props.onConfirmAction();
				},
			}}
			secondaryActions={[
				{
					content: 'Cancel',
					onAction: () => {
						props.onCancelAction();
					},
				},
			]}
		>
			<Modal.Section>
				<TextContainer>
					{props.banner}
					<p>{props.description}</p>
				</TextContainer>
			</Modal.Section>
		</Modal>
	);
}
