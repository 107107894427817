// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditProductMutationVariables = Types.Exact<{
  input: Types.ProductInput;
}>;


export type EditProductMutation = { __typename?: 'Mutation', editProduct: { __typename?: 'MutationResponse', success: boolean } };


export const EditProductDocument = gql`
    mutation fapi_EditProduct($input: ProductInput!) {
  editProduct(input: $input) {
    success
  }
}
    `;

export function useEditProductMutation() {
  return Urql.useMutation<EditProductMutation, EditProductMutationVariables>(EditProductDocument);
};