import { createContext, useState, useCallback } from 'react';

export const POLLING_LOCALSTORAGE_KEY = 'POLLING_ENABLED';
const POLLING_INTERVAL = 20_000;

interface PollingProviderContext {
	pollingEnabled: boolean;
	queryPollInterval: number;
	togglePolling: () => void;
}

export const PollingContext = createContext<PollingProviderContext>({
	pollingEnabled: localStorage.getItem(POLLING_LOCALSTORAGE_KEY) === 'true',
	queryPollInterval: 0,
	togglePolling: () => {},
});

export function PollingProvider({ children }: { children: React.ReactNode }) {
	const [pollingEnabled, setPollingEnabled] = useState(
		localStorage.getItem(POLLING_LOCALSTORAGE_KEY) === 'true',
	);

	const queryPollInterval = pollingEnabled ? POLLING_INTERVAL : 0;

	const togglePolling = useCallback(() => {
		if (pollingEnabled) {
			localStorage.removeItem(POLLING_LOCALSTORAGE_KEY);
			setPollingEnabled(false);
		} else {
			localStorage.setItem(POLLING_LOCALSTORAGE_KEY, 'true');
			setPollingEnabled(true);
		}
	}, [pollingEnabled]);

	return (
		<PollingContext.Provider value={{ pollingEnabled, queryPollInterval, togglePolling }}>
			{children}
		</PollingContext.Provider>
	);
}
