import { TextStyle } from '@sixriver/lighthouse-web-community';
import { NoData } from '@sixriver/react-support';

import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';

interface DroppedLinesProps {
	pickWave: PickWaveFieldsFragment;
}

export function DroppedLines({ pickWave }: DroppedLinesProps) {
	if (pickWave.droppedLinesCount > 0) {
		return <TextStyle variation="negative">{pickWave.droppedLinesCount}</TextStyle>;
	}

	return <NoData />;
}
