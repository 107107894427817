import { Badge, Card } from '@sixriver/lighthouse-web-community';

import { CardDetails } from '../../components/CardDetails';
import { useLocalization } from '../../hooks/useLocalization';
import { ShuttleJobStatus } from '../ShuttleJobs/ShuttleJobsTable';
import { GetShuttleJobQuery } from './graphql/GetShuttleJob.w-api-graphql';
import { GoalStates } from '../../api/fulfillment-api/types';

export function Progress({ shuttleJob }: GetShuttleJobQuery) {
	const { messages } = useLocalization();

	// count the number of exceptions on each line
	const numberOfExceptions =
		shuttleJob?.lines.reduce((acc: number, line: any) => {
			return acc + line?.outputs?.transfers?.flatMap((t: any) => t.exceptions)?.length;
		}, 0) || 0;

	const pickupLine = shuttleJob?.lines.find((l) => l.type === 'storage-to-container');
	const dropoffLine = shuttleJob?.lines.find((l) => l.type === 'container-to-storage');

	return (
		<Card sectioned title={messages.progress}>
			<CardDetails
				primary={[
					{
						content: <ShuttleJobStatus status={pickupLine?.state as GoalStates | undefined} />,
						label: messages.shuttle.stepPickup,
					},
					{
						content: <ShuttleJobStatus status={dropoffLine?.state as GoalStates | undefined} />,
						label: messages.shuttle.stepDropoff,
					},
				]}
				secondary={[
					{
						content: (
							<Badge status={numberOfExceptions ? 'critical' : undefined}>
								{numberOfExceptions.toString()}
							</Badge>
						),
						label: messages.exceptions,
					},
				]}
			/>
		</Card>
	);
}
