// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CancelOrderMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type CancelOrderMutation = { __typename?: 'Mutation', cancelOrder: { __typename?: 'MutationResponse', success: boolean } };


export const CancelOrderDocument = gql`
    mutation fapi_CancelOrder($id: String!) {
  cancelOrder(id: $id) {
    success
  }
}
    `;

export function useCancelOrderMutation() {
  return Urql.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument);
};