import { Layout, Page, Stack } from '@sixriver/lighthouse-web-community';
import { useRouteMatch } from 'react-router-dom';

import { Details } from './Details';
import { Lines } from './Lines';
import { Progress } from './Progress';
import { useGetPutawayJobQuery } from './graphql/GetPutawayJob.w-api-graphql';
import { GoalStates } from '../../api/fulfillment-api/types';
import { Error } from '../../components/Error';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useLocalization } from '../../hooks/useLocalization';
import { PutawayJobStatus } from '../PutawayJobs/PutawayJobsTable/PutawayJobStatus';

export function PutawayJob() {
	const { messages } = useLocalization();

	const {
		params: { putawayJobId },
	} = useRouteMatch<{ putawayJobId: string }>();

	// query logic
	const [getPutawayJobQuery] = useGetPutawayJobQuery({
		variables: {
			id: putawayJobId,
		},
	});

	const { putawayJob } = getPutawayJobQuery.data || {};

	if (getPutawayJobQuery.error) {
		return <Error graphQLError={getPutawayJobQuery.error} />;
	}

	if (!getPutawayJobQuery.fetching && !putawayJob) {
		return <Error heading={messages.putawayJobNotFound} />;
	}

	return (
		<Page
			title={putawayJob?.inputs.containerLabels.default || ''}
			titleMetadata={
				<Stack spacing="extraTight">
					<PutawayJobStatus status={putawayJob?.state as GoalStates} />
					{/* {numberOfExceptions ? <Badge status="critical">{messages.exception}</Badge> : null} */}
				</Stack>
			}
		>
			<Layout>
				<Layout.Section>
					<Details putawayJob={putawayJob} />
				</Layout.Section>
				<Layout.Section>
					<Progress putawayJob={putawayJob} />
				</Layout.Section>
				<Layout.Section>
					<Lines putawayJob={putawayJob} />
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
