// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.w-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetContainersQueryVariables = Types.Exact<{
  types?: Types.InputMaybe<Array<Types.ContainerTypeType> | Types.ContainerTypeType>;
  cursor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderBy?: Types.InputMaybe<Types.ContainerTypeOrderByFields>;
  orderByDirection?: Types.InputMaybe<Types.OrderByDirection>;
  isInventory?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetContainersQuery = { __typename?: 'Query', containers: { __typename?: 'ContainerTypeConnection', edges: Array<{ __typename?: 'ContainerTypeEdge', node: { __typename?: 'ContainerType', id: string, name: string, type: Types.ContainerTypeType, description: string, inventory: boolean, externalId: string, length?: number | null, width?: number | null, height?: number | null, weight?: number | null, enabled: boolean, attributes?: any | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };


export const GetContainersDocument = gql`
    query wapi_GetContainers($types: [ContainerTypeType!], $cursor: String, $after: String, $limit: Float, $first: Int, $searchText: String, $orderBy: ContainerTypeOrderByFields, $orderByDirection: OrderByDirection, $isInventory: Boolean) {
  containers(
    types: $types
    cursor: $cursor
    limit: $limit
    after: $after
    first: $first
    searchText: $searchText
    orderBy: $orderBy
    orderByDirection: $orderByDirection
    isInventory: $isInventory
  ) {
    edges {
      node {
        id
        name
        type
        description
        inventory
        externalId
        length
        width
        height
        weight
        enabled
        attributes
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${PageInfoFragmentDoc}`;

export function useGetContainersQuery(options?: Omit<Urql.UseQueryArgs<GetContainersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetContainersQuery, GetContainersQueryVariables>({ query: GetContainersDocument, ...options });
};