import * as Sentry from '@sentry/react';
import { useDebouncedValue } from '@shopify/react-hooks';
import { components, EventTypeEnum } from '@sixriver/audit-log-oas-schema';
import { CalendarMinor } from '@sixriver/lighthouse-icons';
import {
	Page,
	Layout,
	ChoiceList,
	FilterInterface,
	Card,
	Icon,
} from '@sixriver/lighthouse-web-community';
import { AppliedFilterInterface } from '@sixriver/lighthouse-web-community';
import { useArrayQueryState, useQueryState } from '@sixriver/react-support';
import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';

import AuditLogCalendar, { DateRange } from './AuditLogCalendar';
import { AuditLogsTable } from './AuditLogTable';
import { getLabel } from './auditLogsUtils';
import { OrderByDirection } from '../../api/fulfillment-api/types';
import { AutoRefresh } from '../../components/AutoRefresh';
import { Error } from '../../components/Error';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { fetchAuditLogs } from '../../hooks/useAuditLogs';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useInterval } from '../../hooks/useInterval';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';

type PaginatedAuditLogList = components['schemas']['PaginatedAuditLogList'];
const operationTypesChoices = Object.values(EventTypeEnum).map((value) => ({
	label: value,
	value,
}));

export function AuditLogs() {
	const { messages, locale } = useLocalization();
	const [data, setData] = useState<PaginatedAuditLogList>();
	const [error, setError] = useState<string | null>(null);
	const [fetching, setFetching] = useState(false);
	const [cursors, setCursors] = useState<string[]>([]);
	const [limit] = useState<number>(50);
	const { queryPollInterval } = usePolling();

	const [operationType, setOperationType] = useArrayQueryState<string[]>('operationType', []);
	const [selectedDates, setSelectedDates] = useQueryState<Partial<DateRange>>(
		'dates',
		{
			end: undefined,
			start: undefined,
		},
		deserializeDateRange,
		serializeDateRange,
	);

	const dateFrom = selectedDates.start?.toISOString() || '';
	const dateTo = selectedDates.end?.toISOString() || '';

	const { query = '', sort = 'Timestamp' + ' ' + OrderByDirection.Desc } = useFilters([
		'operationType',
	]);
	const setFilters = useSetFilters();
	const debouncedQuery = useDebouncedValue(query) || '';
	const searchText = debouncedQuery.length >= MIN_QUERY_LENGTH ? debouncedQuery : '';

	type fetchDataOpts = {
		fromAPoll: boolean;
	};
	const fetchData = async ({ fromAPoll }: fetchDataOpts) => {
		// abort the request if the polling mechanism has fired and we already have
		// a request in flight (presumably a user action, e.g. filtering)
		if (fetching && fromAPoll) {
			return;
		}

		if (!fromAPoll) {
			setFetching(true);
		}

		setError(null);

		try {
			const result = await fetchAuditLogs(
				operationType,
				cursors,
				limit,
				dateFrom,
				dateTo,
				searchText,
			);
			setData(result);
		} catch (err: any) {
			setError(err.message);

			Sentry.captureException({
				err,
				params: { cursors, dateFrom, dateTo, limit, operationType },
				reason: 'fetching audit logs failed',
			});
		} finally {
			if (!fromAPoll) {
				setFetching(false);
			}
		}
	};

	useInterval(queryPollInterval, () => fetchData({ fromAPoll: true }));

	// use Array.toString to prevent arrays from triggering a fetch on every
	// keystroke in the filter text input
	const cursorsHash = cursors.toString();
	const operationTypeHash = operationType.toString();
	// Fetch data
	useEffect(() => {
		fetchData({ fromAPoll: false });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cursorsHash, limit, operationTypeHash, dateFrom, dateTo, searchText]);

	const appliedFilters: AppliedFilterInterface[] = [];
	if (!isEmpty(operationType)) {
		const key = 'operationType';
		appliedFilters.push({
			key,
			label: getLabel(key, operationType, locale),
			onRemove: () => {
				setOperationType([]);
			},
		});
	}

	if (dateFrom !== '' && dateTo !== '') {
		const key = 'timestampPeriod';
		appliedFilters.push({
			key,
			label: getLabel(key, [dateFrom, dateTo], locale),
			onRemove: () => {
				setSelectedDates({ end: undefined, start: undefined });
			},
		});
	}

	const filters: FilterInterface[] = [
		{
			filter: (
				<ChoiceList
					title={messages.operationType}
					choices={operationTypesChoices}
					selected={operationType}
					onChange={setOperationType}
					titleHidden
				/>
			),
			key: 'operationType',
			label: messages.operationType,
			shortcut: true,
		},
		{
			filter: (
				<div style={{ '--p-shadow-card': 'none', paddingBottom: '10px' } as React.CSSProperties}>
					<Card>
						<Icon source={CalendarMinor} />
						<AuditLogCalendar
							onChange={setSelectedDates}
							selectedDates={{
								end: selectedDates.end || new Date(),
								start: selectedDates.start || new Date(),
							}}
						/>
					</Card>
				</div>
			),
			key: 'timestampPeriod',
			label: messages.timestampPeriod,
			shortcut: true,
		},
	];

	if (error) {
		return <Error message={messages.errorTitle} />;
	}

	return (
		<Page fullWidth title={messages.auditLogs}>
			<Layout>
				<AutoRefresh discriminatorData={{}} />
				<Layout.Section>
					<AuditLogsTable
						fetching={fetching}
						appliedFilters={appliedFilters}
						filters={filters}
						query={query}
						setFilters={setFilters}
						sort={sort}
						cursors={cursors}
						setCursors={setCursors}
						data={data}
					/>
				</Layout.Section>
			</Layout>
		</Page>
	);
}

export const rangeSerializeDelim = '|';
export function serializeDateRange(range: Partial<DateRange>): string | undefined {
	if (!range.start || !range.end) {
		return undefined;
	}

	// toISOString throws on invalid dates
	try {
		const start = range.start.toISOString();
		const end = range.end.toISOString();
		return `${start}${rangeSerializeDelim}${end}`;
	} catch {
		return undefined;
	}
}

export function deserializeDateRange(val: string): Partial<DateRange> {
	const emptyRange = { end: undefined, start: undefined };

	if (!val?.length) {
		return emptyRange;
	}

	const parts = val.split(rangeSerializeDelim);
	if (parts.length !== 2) {
		return emptyRange;
	}

	try {
		const end = new Date(parts[1]);
		const start = new Date(parts[0]);
		// these will throw if the date is invalid
		end.toISOString();
		start.toISOString();

		return { end, start };
	} catch {
		return emptyRange;
	}
}
