import clsx from 'clsx';
import { Suspense, lazy } from 'react';

import type { SvelteJsonEditorProps } from './SvelteJsonEditor.dynamic';
import styles from './SvelteJsonEditor.module.css';

export { SvelteJsonEditorProps };

const SvelteJsonEditorDynamic = lazy(() => import('./SvelteJsonEditor.dynamic'));

// NOTE: this is a wrapper of the dynamically imported SvelteJsonEditorDynamic so that
//       we can automatically extract the rather large vanilla-jsoneditor library to a
//       separate chunk
export function SvelteJsonEditor(props: SvelteJsonEditorProps<any>): JSX.Element {
	return (
		<Suspense
			fallback={
				<div
					className={clsx(styles.svelteJsoneditorReactContainer, props.className)}
					style={props.style}
				></div>
			}
		>
			<SvelteJsonEditorDynamic {...props} />
		</Suspense>
	);
}
