import { TextStyle, TextContainer } from '@sixriver/lighthouse-web-community';

import { Count } from '../../api/fulfillment-api/types';
import { useLocalization } from '../../hooks/useLocalization';
import { GetOrdersLineQuantityFragment } from '../../pages/Orders/graphql/GetOrders.w-api-graphql';

interface OrderQuantityProps {
	totalLines: Count;
	totalLineQuantities: GetOrdersLineQuantityFragment;
}

export function OrderQuantityV2({ totalLines, totalLineQuantities }: OrderQuantityProps) {
	const { messages, translate } = useLocalization();

	return (
		<TextContainer>
			<p>{translate(messages.countUnits, { count: totalLineQuantities?.totalUnits })}</p>
			<TextStyle variation="subdued">
				{translate(messages.countLines, { count: totalLines?.count })}
			</TextStyle>
		</TextContainer>
	);
}
