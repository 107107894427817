// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetSortWallQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetSortWallQuery = { __typename?: 'Query', job?: { __typename?: 'Job', id: string, status: Types.JobStatus, batchId?: string | null, expectedShipDate?: any | null, isShorted: boolean, wall?: string | null, totalLineQuantities: { __typename?: 'LineQuantities', pickedUnits: number, inProgressUnits: number, unassignedUnits: number, completedLines: number, totalUnits: number }, totalLines: { __typename?: 'Count', count: number }, lines: { __typename?: 'LineConnection', edges: Array<{ __typename?: 'LineEdge', node: { __typename?: 'Line', id: string, status?: string | null, quantity: number, doneQuantity: number, productId: string, productName?: string | null, productDescription?: string | null, productCustomerIdentifier?: string | null } }> }, servicing: Array<{ __typename?: 'Job', id: string }>, servicedBy: Array<{ __typename?: 'Job', id: string }>, sortations: { __typename?: 'SortationConnection', edges: Array<{ __typename?: 'SortationEdge', node: { __typename?: 'Sortation', mfpId?: string | null, userId?: string | null, createdAt: any, updatedAt: any, completedAt?: any | null, wallDisplayId: string, wallExternalId: string } }> } } | null };


export const GetSortWallDocument = gql`
    query wapi_GetSortWall($id: String!) {
  job(id: $id) {
    id
    status
    batchId
    expectedShipDate
    isShorted
    wall
    totalLineQuantities {
      pickedUnits
      inProgressUnits
      unassignedUnits
      completedLines
      totalUnits
      unassignedUnits
    }
    totalLines {
      count
    }
    lines {
      edges {
        node {
          id
          status
          quantity
          doneQuantity
          productId
          productName
          productDescription
          productCustomerIdentifier
        }
      }
    }
    servicing {
      id
    }
    servicedBy {
      id
    }
    sortations {
      edges {
        node {
          mfpId
          userId
          createdAt
          updatedAt
          completedAt
          wallDisplayId
          wallExternalId
        }
      }
    }
  }
}
    `;

export function useGetSortWallQuery(options: Omit<Urql.UseQueryArgs<GetSortWallQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSortWallQuery, GetSortWallQueryVariables>({ query: GetSortWallDocument, ...options });
};