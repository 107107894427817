import { Badge } from '@sixriver/lighthouse-web-community';

import { GoalStates } from '../../../api/fulfillment-api/types';
import { NoData } from '../../../components/NoData';
import { useLocalization } from '../../../hooks/useLocalization';

export function ShuttleJobStatus({ status }: { status?: GoalStates }) {
	const { messages } = useLocalization();

	const statusToBadgeStatus: {
		[key in GoalStates]?: 'info' | 'critical' | 'warning';
	} = {
		[GoalStates.Creating.toLowerCase()]: 'warning',
		[GoalStates.Created.toLowerCase()]: 'warning',
		[GoalStates.Ready.toLowerCase()]: 'warning',
		[GoalStates.Running.toLowerCase()]: 'info',
		[GoalStates.Paused.toLowerCase()]: 'warning',
		[GoalStates.Hold.toLowerCase()]: undefined,
		[GoalStates.TerminateRequested.toLowerCase()]: 'critical',
		[GoalStates.Terminating.toLowerCase()]: 'critical',
		[GoalStates.Terminated.toLowerCase()]: 'critical',
		[GoalStates.Complete.toLowerCase()]: undefined,
		[GoalStates.CancelRequested.toLowerCase()]: 'critical',
		[GoalStates.Cancelling.toLowerCase()]: 'critical',
		[GoalStates.Cancelled.toLowerCase()]: 'critical',
	};

	const labels = {
		[GoalStates.Creating.toLowerCase()]: messages.unassigned,
		[GoalStates.Created.toLowerCase()]: messages.unassigned,
		[GoalStates.Ready.toLowerCase()]: messages.unassigned,
		[GoalStates.Running.toLowerCase()]: messages.inProgress,
		[GoalStates.Paused.toLowerCase()]: messages.interrupted,
		[GoalStates.Hold.toLowerCase()]: messages.queued,
		[GoalStates.TerminateRequested.toLowerCase()]: messages.goalStates.terminateRequested,
		[GoalStates.Terminating.toLowerCase()]: messages.goalStates.terminating,
		[GoalStates.Terminated.toLowerCase()]: messages.canceled,
		[GoalStates.Complete.toLowerCase()]: messages.completed,
		[GoalStates.CancelRequested.toLowerCase()]: messages.goalStates.cancelRequested,
		[GoalStates.Cancelling.toLowerCase()]: messages.goalStates.cancelling,
		[GoalStates.Cancelled.toLowerCase()]: messages.canceled,
	};

	return status ? <Badge status={statusToBadgeStatus[status]}>{labels[status]}</Badge> : <NoData />;
}
