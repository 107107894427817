import { Link } from '@sixriver/lighthouse-web-community';

import { WorkAreaCell } from './WorkAreaCell';
import { GetLocationsQuery } from './graphql/GetLocations.w-api-graphql';
import { useLocationsTableFilters } from './legacy-use-locations-table-filters';
import { MapChunk } from '../../api';
import { InventoryTag } from '../../api/fulfillment-api/types';
import {
	OrderByDirection,
	StorageLocationOrderByFields,
	StorageLocationType,
	StorageLocationTypeCount,
} from '../../api/warehouse-api/types';
import {
	PaginationCursors,
	SetPaginationCursors,
	Column,
	DataTable,
} from '../../components/DataTable';
import { NoData } from '../../components/NoData';
import { useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { useLocationTabs } from '../../hooks/useLocationTabs';
import * as routes from '../../routes';

interface LocationsTableProps {
	data?: GetLocationsQuery['locations'];
	counts?: StorageLocationTypeCount[];
	loading?: boolean;
	paginationCursors: PaginationCursors;
	setPaginationCursors: SetPaginationCursors;
	workAreas: MapChunk[];
}

export function LocationsTable({
	data,
	loading,
	counts = [],
	paginationCursors,
	setPaginationCursors,
}: LocationsTableProps) {
	const { messages } = useLocalization();
	const isWorkAreasEnabled = useIsWorkAreasEnabled();
	// Tabs
	const tabs = useLocationTabs(counts);

	// Filters
	const { filters, keys, setFilters } = useLocationsTableFilters();

	// Sorting
	const sortChoices = [
		{
			label: messages.sortOptions.addressAsc,
			value: `${StorageLocationOrderByFields.Address} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.addressDesc,
			value: `${StorageLocationOrderByFields.Address} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.typeAsc,
			value: `${StorageLocationOrderByFields.Type} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.typeDsc,
			value: `${StorageLocationOrderByFields.Type} ${OrderByDirection.Desc}`,
		},
	];

	// Data
	const { edges, pageInfo } = data || {};

	// Render
	const workAreaColumn: Column = {
		heading: messages.workArea,
		sortable: false,
		type: 'text',
	};

	const columns: Column[] = [
		{
			heading: messages.address,
			sortable: false,
			type: 'text',
		},
		...(isWorkAreasEnabled ? [workAreaColumn] : []),
		{
			heading: messages.capacityType,
			sortable: false,
			type: 'text',
		},
		{
			heading: messages.locationType,
			sortable: false,
			type: 'text',
		},
	];

	const rows = (edges || []).map((edge) => {
		const { id, address, type, containerTypeName, mapChunkId } = edge.node;
		return [
			// Address
			<Link key={id} url={routes.location(id)}>
				{address}
			</Link>,
			mapChunkId ? <WorkAreaCell mapChunkId={mapChunkId} /> : <NoData />,
			containerTypeName || <NoData />,
			// Location Type
			type ? messages.locationTypes[type as StorageLocationType] : <NoData />,
		];
	});

	return (
		<DataTable
			sortChoices={sortChoices}
			views={tabs}
			selectedView={filters.view || 'All'}
			viewKey={keys.view}
			columns={columns}
			rows={rows}
			loading={loading}
			pageInfo={{ endCursor: pageInfo?.endCursor, hasNextPage: pageInfo?.hasNextPage }}
			sortValue={filters.sort}
			query={filters.searchText}
			queryKey={keys.SEARCH_TEXT_KEY}
			queryPlaceholder={messages.filterLocations}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			filters={[]}
			appliedFilters={[]}
			setFilters={setFilters}
		/>
	);
}

export function conditionValue(s: string | undefined, inventoryStateNames: any) {
	switch (s) {
		case 'mixed':
			return inventoryStateNames.mixed;
		case 'reworkPending':
		case 'needsRework':
			return inventoryStateNames.reworkPending;
		case 'sellable':
			return inventoryStateNames.sellable;
		case 'damaged':
			return inventoryStateNames.damaged;
		default:
			return undefined;
	}
}

export function analyzeTaggage(t: InventoryTag[] | undefined) {
	let locationCondition: string | undefined = `sellable`;
	if (t && t.length) {
		// we have a populated list of tags. Only one tag here?
		if (t.length === 1) {
			const tagCondition = t[0]?.definition?.condition;
			locationCondition = tagCondition || 'sellable';
		} else {
			// check whether all tags indicate the same condition
			const conditionKey = t
				.map((item) => {
					return item.definition?.condition || 'sellable';
				})
				.reduce((_accum, _cur) => {
					return _cur === _accum ? _cur : 'mixed';
				});
			locationCondition = conditionKey || undefined;
		}
	}
	return locationCondition;
}
