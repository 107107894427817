import { JobCancelStateError } from '@sixriver/cfs_models';

import { client } from './client';

type CancellationStatus = 'closed' | 'in-progress' | 'pending';
type SuccessStatus = 'success';
type LineStatusSummaryKey = SuccessStatus | JobCancelStateError;
export type LineStatusSummary = Partial<Record<LineStatusSummaryKey, number>>;

export type BulkCancelBatch = {
	id: string;
	userId?: string;
	status: CancellationStatus;
	lineStatusSummary: LineStatusSummary;
	createdAt: Date | string;
	startedAt?: Date | string;
	completedAt?: Date | string | null;
};

export type BulkCancelRequestsStatusResponse = {
	results: BulkCancelBatch[];
	meta: {
		cursor?: string | null;
	};
};

export const getBulkCancelBatches = client.createRequest<BulkCancelRequestsStatusResponse>()({
	endpoint: '/cfs/wis-southbound/v1/BulkCancelBatches',
	method: 'GET',
});
