import { Card, Stack, TextStyle } from '@sixriver/lighthouse-web-community';

import { ProgressBar } from '../../components/ProgressBar';
import { GetOrderQueryJob } from '../../graphql/derived';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	order: GetOrderQueryJob;
}

export function Progress({ order }: Props) {
	const { messages, translate } = useLocalization();

	const getProgressPercentage = (units: number): number => {
		return (units / order?.totalLineQuantities?.totalUnits) * 100;
	};

	return (
		<Card title={messages.progress} footerActionAlignment="left" sectioned>
			<Stack vertical={false} distribution={'fillEvenly'}>
				<Stack vertical={true}>
					<TextStyle>{messages.unassigned}</TextStyle>
					<div>
						<ProgressBar
							progress={getProgressPercentage(order?.totalLineQuantities?.unassignedUnits)}
							color={'primary'}
						></ProgressBar>
					</div>
					<div data-testid="progress-unassigned">
						{order?.totalLineQuantities?.unassignedUnits > 0 ? (
							<TextStyle variation="warning">
								{translate(messages.countUnits, {
									count: order?.totalLineQuantities?.unassignedUnits,
								})}
							</TextStyle>
						) : (
							<TextStyle>
								{translate(messages.countUnits, {
									count: order?.totalLineQuantities?.unassignedUnits,
								})}
							</TextStyle>
						)}
					</div>
				</Stack>
				<Stack vertical={true}>
					<TextStyle>{messages.inProgress}</TextStyle>
					<div>
						<ProgressBar
							progress={getProgressPercentage(order?.totalLineQuantities?.inProgressUnits)}
							color={'primary'}
						></ProgressBar>
					</div>
					<div data-testid="progress-in-progress">
						<TextStyle>
							{translate(messages.countUnits, {
								count: order?.totalLineQuantities?.inProgressUnits,
							})}
						</TextStyle>
					</div>
				</Stack>
				<Stack vertical={true}>
					<TextStyle>{messages.completed}</TextStyle>
					<div>
						<ProgressBar
							// packedUnits = completeUnits for FES sites
							progress={getProgressPercentage(order?.totalLineQuantities?.completedUnits)}
							color={'primary'}
						></ProgressBar>
					</div>
					<div data-testid="progress-completed">
						<TextStyle>
							{translate(messages.countUnits, {
								count: order?.totalLineQuantities?.completedUnits,
							})}
						</TextStyle>
					</div>
				</Stack>
			</Stack>
		</Card>
	);
}
