import { Link, TextStyle } from '@sixriver/lighthouse-web-community';

import { JobStatus, SortationStatus } from '../../../api/fulfillment-api/types';
import {
	PaginationCursors,
	SetPaginationCursors,
	DataTableView,
	Column,
	DataTable,
} from '../../../components/DataTable';
import { DateTime } from '../../../components/DateTime';
import { NoData } from '../../../components/NoData';
import { RelativeDateTime } from '../../../components/RelativeDateTime';
import { SortationStatusBadge } from '../../../components/SortationStatusBadge';
import { SetFilters } from '../../../hooks/useFilters';
import { useLocalization } from '../../../hooks/useLocalization';
import * as routes from '../../../routes';
import {
	GetSortWallsQuery,
	GetSortWallsSortationFragment,
} from '../../SortWall/graphql/GetSortWalls.w-api-graphql';

interface Props {
	loading?: boolean;
	data?: GetSortWallsQuery;
	paginationCursors: PaginationCursors;
	setPaginationCursors: SetPaginationCursors;
	views: DataTableView[];
	query?: string;
	setFilters: SetFilters;
	selectedView?: string;
}

export function SortWallsTable({
	loading = false,
	data,
	paginationCursors,
	setPaginationCursors,
	views,
	query,
	setFilters,
	selectedView,
}: Props) {
	const { messages, translate } = useLocalization();

	const { pageInfo, edges = [] } = data?.sortWalls || {};

	const columns: Column[] = [
		{
			heading: messages.wallId,
			type: 'text',
		},
		{
			heading: messages.batchId,
			type: 'text',
		},
		{
			heading: messages.createdAt,
			type: 'text',
		},
		{
			heading: messages.earliestCutoff,
			type: 'text',
		},
		{
			heading: messages.progress,
			type: 'text',
		},
		{
			heading: messages.units,
			type: 'text',
		},
		{
			heading: messages.lines,
			type: 'text',
		},
		{
			heading: messages.exceptions,
			type: 'text',
		},
	];

	const rows = (edges || []).map(({ node }) => {
		const {
			id,
			expectedShipDate,
			totalLines,
			// linesExceptionsCount,
			// pickingJobsExceptionsCount,
			status,
			// job: { isShorted },
			totalLineQuantities,
			sortations = { edges: [] },
			lines = { edges: [] },
		} = node;
		const sortation = sortations.edges[0]
			? sortations.edges[0].node
			: ({} as GetSortWallsSortationFragment);

		// const numberOfLineExceptions = linesExceptionsCount.count;
		// const numberOfPickingExceptions = pickingJobsExceptionsCount.count;
		// const numberOfExceptions = !isShorted ? 0 : numberOfLineExceptions + numberOfPickingExceptions;
		const numberOfExceptions = lines.edges.reduce((count, line) => {
			if (line.node.isShorted) {
				count += 1;
			}
			return count;
		}, 0);
		const numberOfLines = totalLines.count;
		const numberOfUnits = totalLineQuantities.totalUnits;

		return [
			sortation.wallDisplayId ? (
				<Link url={routes.sortWall(id)} key="wallId">
					{sortation.wallDisplayId}
				</Link>
			) : (
				<NoData />
			),
			<div key="batchId">{sortation.wallExternalId}</div>,
			<div key="date-created">
				<DateTime date={sortation.createdAt} />
				<RelativeDateTime date={sortation.createdAt} />
			</div>,
			<div key="cutoff">
				<DateTime date={expectedShipDate} />
				<RelativeDateTime
					date={expectedShipDate}
					mode={
						status === (SortationStatus.Sorted as unknown as JobStatus) ? undefined : 'deadline'
					}
				/>
			</div>,
			<SortationStatusBadge key="progress" sortationStatus={status} />,
			<div key="units">{numberOfUnits}</div>,
			<div key="lines">{numberOfLines}</div>,
			numberOfExceptions === 0 ? (
				<NoData />
			) : (
				<TextStyle variation="negative">
					{translate(messages.countExceptions, {
						count: numberOfExceptions,
					})}
				</TextStyle>
			),
		];
	});

	return (
		<DataTable
			loading={loading}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.noSortWallsFound}
			pageInfo={pageInfo}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			views={views}
			query={query}
			queryPlaceholder={messages.filterSortWalls}
			setFilters={setFilters}
			selectedView={selectedView}
		/>
	);
}
