import { IndexTable } from '@sixriver/lighthouse-web-community';

import { GoalStates } from '../../../api/fulfillment-api/types';
import { DateTime } from '../../../components/DateTime';
import { NoData } from '../../../components/NoData';
import { RelativeDateTime } from '../../../components/RelativeDateTime';
import {
	GetShuttleAvailableContainersQueryNode,
	GetShuttleAvailableDestinationsQueryNode,
	GetShuttleJobsQueryNode,
} from '../../../graphql/derived';

export function UpdatedAtCell({
	node,
}: {
	node:
		| GetShuttleJobsQueryNode
		| GetShuttleAvailableContainersQueryNode
		| GetShuttleAvailableDestinationsQueryNode;
}) {
	return (
		<IndexTable.Cell>
			{node?.state.includes(GoalStates.Running.toLowerCase()) && (
				<>
					<DateTime date={node?.lastEventPersistedAt} />
					<RelativeDateTime date={node?.lastEventPersistedAt} />
				</>
			)}
			{node?.state.includes(GoalStates.Cancelled.toLowerCase()) && (
				<>
					<DateTime date={node?.endedAt} />
					<RelativeDateTime date={node?.endedAt} />
				</>
			)}
			{node?.state.includes(GoalStates.Terminated.toLowerCase()) && (
				<>
					<DateTime date={node?.endedAt} />
					<RelativeDateTime date={node?.endedAt} />
				</>
			)}
			{node?.state.includes(GoalStates.Complete.toLowerCase()) && (
				<>
					<DateTime date={node?.endedAt} />
					<RelativeDateTime date={node?.endedAt} />
				</>
			)}
			{node?.state.includes(GoalStates.Ready.toLowerCase()) && <NoData />}
		</IndexTable.Cell>
	);
}
