import {
	Cache,
	CacheAsyncStorageType,
	Client,
	DateInterval,
	RequestInstance,
} from '@hyper-fetch/core';
import { get, set, del, keys } from 'idb-keyval';

const asyncStorage: CacheAsyncStorageType = {
	delete: (key: string) => del(key),
	get: (key: string) => get(key),
	keys: () => keys(),
	set: (key: string, data: any) => set(key, data),
};

export type GlobalErrorType = {
	message: string;
	status: 400 | 404 | 500;
};

const client = new Client<GlobalErrorType>({
	cache: (instance) =>
		new Cache(instance, {
			lazyStorage: asyncStorage,
		}),
	url: window.location.origin,
}).setRequestDefaultOptions((requestOptions: Partial<RequestInstance>) => {
	if (requestOptions.method === 'GET') {
		const options = {
			cache: true,
			cacheTime: 20 * DateInterval.second,
			deduplicate: true,
			...requestOptions,
		};
		return options;
	}

	return {
		cache: false,
		deduplicate: false,
		...requestOptions,
	};
});

export { client };
