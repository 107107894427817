import { Button, IndexTable } from '@sixriver/lighthouse-web-community';

import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';

type IdCellProps = {
	pickWave: PickWaveFieldsFragment;
	onClickPickWaveId: (id: string) => void;
};

export function IdCell({ pickWave, onClickPickWaveId }: IdCellProps) {
	return (
		<IndexTable.Cell>
			<Button
				plain
				onClick={() => {
					onClickPickWaveId(pickWave.id);
				}}
			>
				{pickWave.id}
			</Button>
		</IndexTable.Cell>
	);
}
