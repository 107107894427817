// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetWorkAreasQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetWorkAreasQuery = { __typename?: 'Query', workAreas: Array<{ __typename?: 'WorkArea', id: string, name?: string | null, map: any }> };


export const GetWorkAreasDocument = gql`
    query fapi_GetWorkAreas {
  workAreas {
    id
    name
    map
  }
}
    `;

export function useGetWorkAreasQuery(options?: Omit<Urql.UseQueryArgs<GetWorkAreasQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWorkAreasQuery, GetWorkAreasQueryVariables>({ query: GetWorkAreasDocument, ...options });
};