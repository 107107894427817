import { IndexTable } from '@sixriver/lighthouse-web-community';

import { WorkArea } from '../../../api/fulfillment-api/types';
import { NoData } from '../../../components/NoData';

export function WorkAreasCell({ workAreas }: { workAreas: WorkArea[] }) {
	return (
		<IndexTable.Cell>
			{workAreas.length ? <div>{workAreas.map((area) => area.name).join(', ')}</div> : <NoData />}
		</IndexTable.Cell>
	);
}
