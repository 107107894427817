// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetJobFlowRulesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetJobFlowRulesQuery = { __typename?: 'Query', jobFlowRules: Array<{ __typename?: 'JobFlowRule', id: string, ruleSubType: Array<string>, ruleDescription: string, rule: any }> };


export const GetJobFlowRulesDocument = gql`
    query fapi_GetJobFlowRules {
  jobFlowRules {
    id
    ruleSubType
    ruleDescription
    rule
  }
}
    `;

export function useGetJobFlowRulesQuery(options?: Omit<Urql.UseQueryArgs<GetJobFlowRulesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetJobFlowRulesQuery, GetJobFlowRulesQueryVariables>({ query: GetJobFlowRulesDocument, ...options });
};