import { IndexTable } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../NoData';

// interface Props {
// 	line: GetOutboundJobLineFragment;
// }

// TODO - LOL-3305
export function AssociatesCell(): JSX.Element {
	// const associates = line.tasks.reduce((names, task) => {
	// 	const name = task.user?.name ?? task.user?.email ?? task.user?.badge;
	// 	if (name) {
	// 		return names.add(name);
	// 	}
	// 	return names;
	// }, new Set<string>());

	// if (associates.size) {
	// 	return (
	// 		<IndexTable.Cell>
	// 			<TextStyle>{Array.from(associates).join(', ')}</TextStyle>
	// 		</IndexTable.Cell>
	// 	);
	// }
	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
