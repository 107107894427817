import { HealingStrategy } from '../../api/fulfillment-api/types';
import { useHealingStrategies, useIsHealingEnabled } from '../useConfig';

export function useShowExceptionPage() {
	const isHealingEnabled = useIsHealingEnabled();
	const healingStrategies = useHealingStrategies();
	const showExceptionPage =
		isHealingEnabled &&
		healingStrategies.some(
			(strategy) =>
				strategy.includes(HealingStrategy.Manual) || strategy.includes(HealingStrategy.Automated),
		);
	return showExceptionPage;
}
