import { TextStyle } from '@sixriver/lighthouse-web-community';
import { NoData } from '@sixriver/react-support';
import { useState } from 'react';

import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';
import { isPickWaveOrderDropped } from '../helpers';

interface DroppedOrdersProps {
	pickWave: PickWaveFieldsFragment;
}

export function DroppedOrders({ pickWave }: DroppedOrdersProps) {
	const [droppedOrders] = useState(
		pickWave.orders.edges?.filter((orderEdge) => isPickWaveOrderDropped(orderEdge.node)),
	);

	if (droppedOrders && droppedOrders.length > 0) {
		return <TextStyle variation="negative">{droppedOrders.length}</TextStyle>;
	}

	return <NoData />;
}
