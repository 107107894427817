import { IndexTable } from '@sixriver/lighthouse-web-community';
import { DateTime } from '@sixriver/react-support';

import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';

interface ReceivedCellProps {
	pickWave: PickWaveFieldsFragment;
}

export function ReceivedCell({ pickWave }: ReceivedCellProps) {
	const createdAt = new Date(pickWave.createdAt);
	return (
		<IndexTable.Cell>
			<DateTime date={createdAt} />
		</IndexTable.Cell>
	);
}
