import { createContext, PropsWithChildren } from 'react';

import { GQLJobFragment } from './Lines';

export const LinesContext = createContext<
	| {
			job: GQLJobFragment;
	  }
	| undefined
>(undefined);

interface LinesProviderProps {
	job: GQLJobFragment;
}

export function LinesProvider({
	children,
	job,
}: PropsWithChildren<LinesProviderProps>): JSX.Element {
	return <LinesContext.Provider value={{ job }}>{children}</LinesContext.Provider>;
}
