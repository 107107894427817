import {
	SendMajor as MessageIcon,
	RepeatOrderMajor as RequestIcon,
} from '@sixriver/lighthouse-icons';
import { Icon, IconProps, Tooltip } from '@sixriver/lighthouse-web-community';
import { assertNever } from '@sixriver/typescript-support';

import { DeliveryType } from '../../api/edge/types';

export interface DeliveryTypeIconProps {
	deliveryType: DeliveryType | undefined;
	includeTooltip?: boolean;
	color?: IconProps['color'];
}

export function DeliveryTypeIcon(props: DeliveryTypeIconProps) {
	const deliveryType = props.deliveryType ?? DeliveryType.Message;

	const icon = <Icon source={getDeliveryTypeIcon(deliveryType)} color={props.color} />;

	if (props.includeTooltip) {
		return <Tooltip content={deliveryType}>{icon}</Tooltip>;
	}

	return icon;
}

function getDeliveryTypeIcon(deliveryType: DeliveryType) {
	switch (deliveryType) {
		case DeliveryType.Message:
			return MessageIcon;
		case DeliveryType.Request:
			return RequestIcon;
		/* istanbul ignore next */
		default:
			/* istanbul ignore next */
			assertNever(deliveryType);
	}
}
