import { UserRole } from '@sixriver/react-support';

import { EmployeeFormData } from './Employee.form';
import { User } from '../../graphql/derived';
import { LocaleType } from '../../hooks/useLocalization';

/**
 * A password is required for an employee if "chuck_operator" is NOT their !!only!! role (besides "user")
 * @param roles the employee's roles
 * @returns `true` if a password is required, `false` otherwise
 */
export function isPasswordRequiredForRoles(roles: UserRole[]) {
	return !(roles || [])
		.filter((role) => role !== UserRole.User)
		.every((role) => role === UserRole.ChuckOperator);
}

export function getRolesFromFormData(formData: EmployeeFormData): UserRole[] {
	const roles = [];

	if (formData.isBridgeAccessAllowed) {
		roles.push(UserRole.Admin, UserRole.BridgeAssociate);
	}
	if (formData.isDeviceAccessAllowed) {
		roles.push(UserRole.ChuckOperator);
	}

	return roles;
}

export function getEmployeeFormDataFromExistingEmployee(employee?: User): EmployeeFormData {
	return {
		badgeBarcode: employee?.badge || '',
		email: employee?.email || '',
		isBridgeAccessAllowed: employee?.roles?.includes(UserRole.Admin) || false,
		isDeviceAccessAllowed: !!employee?.badge,
		locale: employee?.locale as LocaleType,
		name: employee?.name || '',
		phoneNumber: '',
		tags: employee?.tags?.map((tag) => tag.name) || [],
	};
}
