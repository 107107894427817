import { IndexTable } from '@sixriver/lighthouse-web-community';

import { OutboundJobStatusBadgeV2 } from '../../../components/OutboundJobStatusBadge/OutboundJobStatusBadgeV2';
import { GetOutboundJobsQueryJobFragment } from '../graphql/GetOutboundJobs.w-api-graphql';

interface StatusCellProps {
	job: GetOutboundJobsQueryJobFragment;
}

export function StatusCell({ job }: StatusCellProps) {
	return (
		<IndexTable.Cell>
			<OutboundJobStatusBadgeV2 job={job} />
		</IndexTable.Cell>
	);
}
