import { useFetch } from '@hyper-fetch/react';
import { ModelConfig } from '@sixriver/config-schema';

import { GlobalErrorType, getModelConfigs } from '../../api';

export function useModelConfigs(): {
	data: ModelConfig[] | null;
	error?: GlobalErrorType | null;
	fetching: boolean;
} {
	const { loading, data, error } = useFetch(getModelConfigs);

	return { data, error, fetching: loading };
}
