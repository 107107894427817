// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.w-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrdersLineQuantityFragment = { __typename?: 'LineQuantities', totalUnits: number };

export type GetOrdersPickingJobFragment = { __typename?: 'Job', id: string, isHealJob: boolean, createdAt: any, type: string, pickStrategy: Types.PickStrategy, status: Types.JobStatus, completedAt?: any | null, lines: { __typename?: 'LineConnection', edges: Array<{ __typename?: 'LineEdge', node: { __typename?: 'Line', id: string, status?: string | null, quantity: number, doneQuantity: number, completedAt?: any | null } }> } };

export type GetOrdersOrderFragment = { __typename?: 'Job', id: string, stagingIdentifier?: string | null, externalId: string, pickStrategy: Types.PickStrategy, createdAt: any, expectedShipDate?: any | null, isLate: boolean, isAwaitingInventory: boolean, isShorted: boolean, isHealable: boolean, status: Types.JobStatus, isTerminal: boolean, lines: { __typename?: 'LineConnection', edges: Array<{ __typename?: 'LineEdge', node: { __typename?: 'Line', quantity: number, doneQuantity: number, customer: { __typename?: 'Customer', name?: string | null }, workArea?: { __typename?: 'WorkArea', id: string } | null } }> }, totalLines: { __typename?: 'Count', count: number }, totalLineQuantities: { __typename?: 'LineQuantities', totalUnits: number }, servicedBy: Array<{ __typename?: 'Job', id: string, stagingIdentifier?: string | null }>, pickingJobs: Array<{ __typename?: 'Job', id: string, isHealJob: boolean, createdAt: any, type: string, pickStrategy: Types.PickStrategy, status: Types.JobStatus, completedAt?: any | null, lines: { __typename?: 'LineConnection', edges: Array<{ __typename?: 'LineEdge', node: { __typename?: 'Line', id: string, status?: string | null, quantity: number, doneQuantity: number, completedAt?: any | null } }> } }>, healJobs: Array<{ __typename?: 'Job', type: string, status: Types.JobStatus, pickStrategy: Types.PickStrategy, createdAt: any, completedAt?: any | null }>, workflows: Array<{ __typename?: 'JobWorkflow', type: string, status: string }> };

export type GetOrdersQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statuses?: Types.InputMaybe<Array<Types.JobStatus> | Types.JobStatus>;
  equipmentTypes?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  isLate?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isShorted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isAwaitingInventory?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isServicedByHealJobs?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  completedAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  completedAtTo?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  expectedShipDateFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  expectedShipDateTo?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  createdAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  pickStrategies?: Types.InputMaybe<Array<Types.PickStrategy> | Types.PickStrategy>;
  orderBy?: Types.InputMaybe<Array<Types.JobOrderBy> | Types.JobOrderBy>;
  workOrderTypes?: Types.InputMaybe<Array<Types.WorkOrderType> | Types.WorkOrderType>;
  workAreaIds?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  jobFlowRuleLpn?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bulkOrderProjectionId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetOrdersQuery = { __typename?: 'Query', getOrders: { __typename?: 'JobConnection', edges: Array<{ __typename?: 'JobEdge', node: { __typename?: 'Job', id: string, stagingIdentifier?: string | null, externalId: string, pickStrategy: Types.PickStrategy, createdAt: any, expectedShipDate?: any | null, isLate: boolean, isAwaitingInventory: boolean, isShorted: boolean, isHealable: boolean, status: Types.JobStatus, isTerminal: boolean, lines: { __typename?: 'LineConnection', edges: Array<{ __typename?: 'LineEdge', node: { __typename?: 'Line', quantity: number, doneQuantity: number, customer: { __typename?: 'Customer', name?: string | null }, workArea?: { __typename?: 'WorkArea', id: string } | null } }> }, totalLines: { __typename?: 'Count', count: number }, totalLineQuantities: { __typename?: 'LineQuantities', totalUnits: number }, servicedBy: Array<{ __typename?: 'Job', id: string, stagingIdentifier?: string | null }>, pickingJobs: Array<{ __typename?: 'Job', id: string, isHealJob: boolean, createdAt: any, type: string, pickStrategy: Types.PickStrategy, status: Types.JobStatus, completedAt?: any | null, lines: { __typename?: 'LineConnection', edges: Array<{ __typename?: 'LineEdge', node: { __typename?: 'Line', id: string, status?: string | null, quantity: number, doneQuantity: number, completedAt?: any | null } }> } }>, healJobs: Array<{ __typename?: 'Job', type: string, status: Types.JobStatus, pickStrategy: Types.PickStrategy, createdAt: any, completedAt?: any | null }>, workflows: Array<{ __typename?: 'JobWorkflow', type: string, status: string }> } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export const GetOrdersLineQuantityFragmentDoc = gql`
    fragment GetOrdersLineQuantity on LineQuantities {
  totalUnits
}
    `;
export const GetOrdersPickingJobFragmentDoc = gql`
    fragment GetOrdersPickingJob on Job {
  id
  isHealJob
  createdAt
  type
  pickStrategy
  status
  completedAt
  lines {
    edges {
      node {
        id
        status
        quantity
        doneQuantity
        status
        completedAt
      }
    }
  }
}
    `;
export const GetOrdersOrderFragmentDoc = gql`
    fragment GetOrdersOrder on Job {
  id
  stagingIdentifier
  externalId
  pickStrategy
  createdAt
  expectedShipDate
  isLate
  isAwaitingInventory
  isShorted
  isHealable
  status
  lines {
    edges {
      node {
        quantity
        doneQuantity
        customer {
          name
        }
        workArea {
          id
        }
      }
    }
  }
  totalLines {
    count
  }
  totalLineQuantities {
    ...GetOrdersLineQuantity
  }
  servicedBy {
    id
    stagingIdentifier
  }
  pickingJobs {
    ...GetOrdersPickingJob
  }
  healJobs {
    type
    status
    pickStrategy
    createdAt
    completedAt
  }
  isTerminal
  workflows {
    type
    status
  }
}
    ${GetOrdersLineQuantityFragmentDoc}
${GetOrdersPickingJobFragmentDoc}`;
export const GetOrdersDocument = gql`
    query wapi_GetOrders($after: String, $first: Int, $ids: [String!], $searchText: String, $statuses: [JobStatus!], $equipmentTypes: [String!], $isLate: Boolean, $isShorted: Boolean, $isAwaitingInventory: Boolean, $isServicedByHealJobs: Boolean, $completedAtFrom: DateTime, $completedAtTo: DateTime, $expectedShipDateFrom: DateTime, $expectedShipDateTo: DateTime, $createdAtFrom: DateTime, $pickStrategies: [PickStrategy!], $orderBy: [JobOrderBy!], $workOrderTypes: [WorkOrderType!], $workAreaIds: [String!], $jobFlowRuleLpn: String, $bulkOrderProjectionId: String) {
  getOrders(
    after: $after
    first: $first
    ids: $ids
    searchText: $searchText
    statuses: $statuses
    equipmentTypes: $equipmentTypes
    isLate: $isLate
    isShorted: $isShorted
    isAwaitingInventory: $isAwaitingInventory
    isServicedByHealJobs: $isServicedByHealJobs
    completedAtFrom: $completedAtFrom
    completedAtTo: $completedAtTo
    expectedShipDateFrom: $expectedShipDateFrom
    expectedShipDateTo: $expectedShipDateTo
    createdAtFrom: $createdAtFrom
    pickStrategies: $pickStrategies
    orderBy: $orderBy
    workOrderTypes: $workOrderTypes
    workAreaIds: $workAreaIds
    jobFlowRuleLpn: $jobFlowRuleLpn
    bulkOrderProjectionId: $bulkOrderProjectionId
  ) {
    edges {
      node {
        ...GetOrdersOrder
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${GetOrdersOrderFragmentDoc}
${PageInfoFragmentDoc}`;

export function useGetOrdersQuery(options?: Omit<Urql.UseQueryArgs<GetOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrdersQuery, GetOrdersQueryVariables>({ query: GetOrdersDocument, ...options });
};