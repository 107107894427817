import { Filters, AppliedFilterInterface, TextField } from '@sixriver/lighthouse-web-community';
import { useCallback } from 'react';

import {
	usePolarisFilterPropsFactories,
	GetFilterFieldValue,
	GetPolarisFilterProps,
	SetFilterFieldValue,
} from './usePolarisFilterPropsFactories';
import { MessageDeliveryAttemptFilter } from '../../api/edge/types';

export interface AttemptFiltersProps {
	filter: MessageDeliveryAttemptFilter | undefined;
	onFilterChanged: (value: MessageDeliveryAttemptFilter | undefined) => void;
}

export function AttemptFilters(props: AttemptFiltersProps): JSX.Element {
	const filterFactories = [
		useFilterFactories(
			'HTTP Status',
			useCallback(
				(name, value, onChange) => ({
					filter: (
						<TextField
							type="number"
							step={1}
							label={name}
							value={value ?? ''}
							onChange={(v) => onChange(Number(v))}
							autoComplete="off"
							labelHidden
						/>
					),
					shortcut: true,
				}),
				[],
			),
			useCallback((f) => f?.httpStatus, []),
			useCallback(
				(f, v) => ({
					...f,
					httpStatus: v,
				}),
				[],
			),
		),
	];

	const appliedFilters = filterFactories
		.map((f) => f.getPolarisAppliedFilterProps())
		.filter((a): a is AppliedFilterInterface => !!a);

	return (
		<Filters
			filters={filterFactories.map((f) => f.getPolarisFilterProps())}
			appliedFilters={appliedFilters}
			onClearAll={() => props.onFilterChanged(undefined)}
			hideQueryField
			onQueryChange={emptyFunction}
			onQueryClear={emptyFunction}
		/>
	);

	function useFilterFactories(
		name: string,
		getPolarisFilterProps: GetPolarisFilterProps,
		getFilterFieldValue: GetFilterFieldValue<MessageDeliveryAttemptFilter>,
		setFilterFieldValue: SetFilterFieldValue<MessageDeliveryAttemptFilter>,
	) {
		return usePolarisFilterPropsFactories(
			props.filter,
			props.onFilterChanged,
			name,
			getPolarisFilterProps,
			getFilterFieldValue,
			setFilterFieldValue,
		);
	}
}

const emptyFunction = () => {
	return;
};
