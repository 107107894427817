import { useDebouncedValue } from '@shopify/react-hooks';
import {
	AppliedFilterInterface,
	FilterInterface,
	Layout,
} from '@sixriver/lighthouse-web-community';
import { useState } from 'react';

import {
	ShuttleAvailableContainersViews,
	ShuttleAvailableContainersViewsViewStateMap,
} from './ShuttleAvailableContainerViews';
import { ShuttleAvailableContainersTable } from './ShuttleAvailableContainersTable';
import {
	GetShuttleAvailableContainersDocument,
	GetShuttleAvailableContainersQuery,
} from './graphql/GetShuttleAvailableContainers.w-api-graphql';
import {
	GetShuttleAvailableContainersCountDocument,
	GetShuttleAvailableContainersCountQuery,
} from './graphql/GetShuttleAvailableContainersCount.w-api-graphql';
import {
	OrderByDirection,
	ShuttleAvailableContainerCount,
	ShuttleAvailableContainerOrderByFields,
} from '../../api/warehouse-api/types';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { Error } from '../../components/Error';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { Toteboard } from '../../components/Toteboard';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useArrayQueryState } from '../../hooks/useQueryState';

const PAGINATION_CURSORS_KEY = 'paginationCursors';

export interface ShuttleAvailableContainerOrderBy {
	direction: OrderByDirection;
	field: ShuttleAvailableContainerOrderByFields;
}

export function ShuttleAvailableContainers() {
	const { messages } = useLocalization();

	const defaultView = ShuttleAvailableContainersViews.All;

	// Pagination
	const [paginationCursors, setPaginationCursors] = useArrayQueryState<string[]>(
		PAGINATION_CURSORS_KEY,
		[],
	);
	const [selectedSort, setSelectedSort] = useState<ShuttleAvailableContainerOrderBy[]>([
		{ direction: OrderByDirection.Desc, field: ShuttleAvailableContainerOrderByFields.CreatedAt },
	]);

	// filtering logic
	const { query, view = defaultView } = useFilters([]);
	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';
	const actualSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;

	const statuses =
		ShuttleAvailableContainersViewsViewStateMap[view as ShuttleAvailableContainersViews];

	const [itemsQuery] = usePollingQuery<GetShuttleAvailableContainersQuery>({
		query: GetShuttleAvailableContainersDocument,
		variables: {
			after: paginationCursors[0],
			cursor: paginationCursors[0],
			first: getPageSize(),
			orderBy: selectedSort.length > 0 ? selectedSort : undefined,
			searchText: actualSearchText,
			statuses,
		},
	});
	const [countQuery] = usePollingQuery<GetShuttleAvailableContainersCountQuery>({
		query: GetShuttleAvailableContainersCountDocument,
	});

	const zeros: ShuttleAvailableContainerCount = {
		cancelled: 0,
		completed: 0,
		held: 0,
		inProgress: 0,
		interrupted: 0,
		unassigned: 0,
	};
	const counts = {
		...zeros,
		...(countQuery.data?.countShuttleAvailableContainers ?? {}),
	};

	const totalCount = Object.values(counts).reduce<number>(
		(sum, count) => sum + (typeof count === 'number' ? count : 0),
		0,
	);
	const totalPages = Math.max(Math.ceil(totalCount / getPageSize()), 1);

	// big card counts
	const toteboardItems = [
		{
			label: messages.unassigned,
			primaryValue: <span>{counts.unassigned}</span>,
		},
		{
			label: messages.inProgress,
			primaryValue: <span>{counts.inProgress}</span>,
		},
		{
			label: messages.interrupted,
			primaryValue: <span>{counts.interrupted}</span>,
		},
		{
			label: messages.queued,
			primaryValue: <span>{counts.held}</span>,
		},
		{
			label: messages.canceled,
			primaryValue: <span>{counts.cancelled}</span>,
		},
		{
			label: messages.completed,
			primaryValue: <span>{counts.completed}</span>,
		},
	];

	const queries = [itemsQuery];
	const fetching = queries.some((q) => q.fetching);
	const error = queries.find((q) => q.error)?.error;

	const filters: FilterInterface[] = [];
	const appliedFilters: AppliedFilterInterface[] = [];

	const views = [
		{ id: ShuttleAvailableContainersViews.All, label: messages.all },
		{ id: ShuttleAvailableContainersViews.Unassigned, label: messages.unassigned },
		{ id: ShuttleAvailableContainersViews.InProgress, label: messages.inProgress },
		{ id: ShuttleAvailableContainersViews.Queued, label: messages.queued },
		{ id: ShuttleAvailableContainersViews.Completed, label: messages.completed },
		{ id: ShuttleAvailableContainersViews.Cancelled, label: messages.canceled },
	];

	return error ? (
		<Error graphQLError={error} />
	) : (
		<AutoRefreshPage
			queries={[itemsQuery, countQuery]}
			fullWidth
			title={messages.shuttleAvailableContainers}
		>
			<Layout>
				<Layout.Section>
					<Toteboard items={toteboardItems} />
				</Layout.Section>
				<Layout.Section>
					<ShuttleAvailableContainersTable
						data={itemsQuery.data?.shuttleAvailableContainers?.edges}
						fetching={fetching}
						pageInfo={itemsQuery.data?.shuttleAvailableContainers?.pageInfo}
						query={query}
						selectedView={view}
						setFilters={setFilters}
						totalPages={totalPages}
						views={views}
						selectedSort={selectedSort}
						onSortChange={setSelectedSort}
						filters={filters}
						appliedFilters={appliedFilters}
						setPaginationCursors={setPaginationCursors}
						paginationCursors={paginationCursors}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</AutoRefreshPage>
	);
}
