import { GoalStates } from '../../api/fulfillment-api/types';

export enum ShuttleAvailableContainersViews {
	All = 'All',
	Unassigned = 'Unassigned',
	InProgress = 'In progress',
	Queued = 'Queued',
	Completed = 'Completed',
	Cancelled = 'Cancelled',
}

export const ShuttleAvailableContainersViewsViewStateMap: {
	[key in ShuttleAvailableContainersViews]: GoalStates[];
} = {
	[ShuttleAvailableContainersViews.All]: [],
	[ShuttleAvailableContainersViews.Unassigned]: [
		GoalStates.Creating,
		GoalStates.Created,
		GoalStates.Ready,
	],
	[ShuttleAvailableContainersViews.Queued]: [GoalStates.Hold],
	[ShuttleAvailableContainersViews.InProgress]: [
		GoalStates.Running,
		GoalStates.TerminateRequested,
		GoalStates.Terminating,
		GoalStates.CancelRequested,
		GoalStates.Cancelling,
	],
	[ShuttleAvailableContainersViews.Completed]: [GoalStates.Complete],
	[ShuttleAvailableContainersViews.Cancelled]: [GoalStates.Cancelled, GoalStates.Terminated],
};
