import { DatePicker, Range } from '@sixriver/lighthouse-web-community';
import { useCallback, useEffect, useState } from 'react';

import { cloneDate } from '../../utils';

export type DateRange = Range;
export type AuditLogCalendarOpts = {
	onChange: (range: DateRange) => void;
	selectedDates: DateRange;
};

export default function AuditLogCalendar({ onChange, selectedDates }: AuditLogCalendarOpts) {
	const [dates, setDates] = useState<DateRange>({
		end: selectedDates.end,
		start: selectedDates.start,
	});

	const [{ month, year }, setDate] = useState({
		month: new Date().getMonth(),
		year: new Date().getFullYear(),
	});
	const handleMonthChange = useCallback(
		(month: number, year: number) => setDate({ month, year }),
		[],
	);

	// update the internal references once the selected dates change from outside
	useEffect(() => {
		setDates(selectedDates);
	}, [selectedDates]);

	const handleDateChange = useCallback(
		(range) => {
			setDates(range);

			if (range.start.toDateString() === range.end.toDateString()) {
				return;
			}

			const start = cloneDate(range.start);
			start.setHours(0);
			start.setMinutes(0);
			start.setSeconds(0);

			const end = cloneDate(range.end);
			end.setHours(23);
			end.setMinutes(59);
			end.setSeconds(59);

			onChange({ end, start });
		},
		[onChange],
	);

	return (
		<DatePicker
			id="datePicker"
			month={month}
			year={year}
			onChange={handleDateChange}
			onMonthChange={handleMonthChange}
			selected={dates}
			allowRange
		/>
	);
}
