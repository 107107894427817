abstract class SettingsSection {
	abstract render(): JSX.Element;

	abstract validate(): boolean;
	abstract visit(config: any, changes: string[]): void;
	abstract discard(): void;
	abstract hasWarnings(): string[];
}

export default SettingsSection;
