import { Card, Link } from '@sixriver/lighthouse-web-community';
import { ReactNode } from 'react';

import { GetJobSortationJobFragment } from './graphql/GetJob.w-api-graphql';
import { JobStatus, PickStrategy } from '../../api/warehouse-api/types';
import { CardDetails, listItem } from '../../components/CardDetails';
import { CopyButton } from '../../components/CopyButton';
import { DateTimeWithRelativeTime, DateTime } from '../../components/DateTime';
import { NoData } from '../../components/NoData';
import { GetJobQueryJob } from '../../graphql/derived';
import { useLocalization } from '../../hooks/useLocalization';
import { usePickStrategyName } from '../../hooks/usePickStrategies';
import { useSortationJobsCount } from '../../hooks/useSortationJobsCount';
import { sortWall as sortWallRoute } from '../../routes';

interface Props {
	loading?: boolean;
	job?: GetJobQueryJob;
	relatedJobs?: GetJobQueryJob['orders'];
	isPackoutEnabled?: boolean;
	isConsolidationEnabled?: boolean;
	isWorkAreaEnabled?: boolean;
	condensed?: boolean;
	isSortationEnabled?: boolean;
	sortWall?: GetJobSortationJobFragment;
}

export function PickingDetails({
	loading,
	job,
	relatedJobs,
	// LOL-3313
	// isPackoutEnabled,
	isWorkAreaEnabled,
	condensed,
	sortWall,
}: Props) {
	const { messages } = useLocalization();

	const getPickStrategyName = usePickStrategyName();

	const BATCH_JOB_PICK_STRATEGIES = [PickStrategy.Consolidation, PickStrategy.Group];
	const isBatchJob = job?.pickStrategy && BATCH_JOB_PICK_STRATEGIES.includes(job?.pickStrategy);
	const isSortJob = job?.pickStrategy === PickStrategy.Sortation;

	const jobsCount = useSortationJobsCount(sortWall);

	const pickedAt = job?.completedAt;
	const tasks = job?.tasks ? [...job.tasks] : [];
	const takeoffDestination = tasks[tasks.length - 1]?.takeoffDestination;
	const container = job?.tasks?.find((task) => !!task.container)?.container;
	const lines = job?.lines.edges ?? [];
	const workAreaNames: string[] = Array.from(
		new Set<string>(lines.map((line) => line?.node?.workArea?.name ?? '').filter((name) => !!name)),
	);

	const sortWallJobId = sortWall?.id;
	const sortWallBatchId = sortWall?.batchId;
	const sortWallId = sortWall?.wall;
	const stagingId = job?.stagingIdentifier ?? '';
	const sortWallBatchIdLink = (
		<Link url={sortWallRoute(sortWallJobId)} removeUnderline>
			{sortWallBatchId}
		</Link>
	);
	const sortWallIdLink = (
		<Link url={sortWallRoute(sortWallJobId)} removeUnderline>
			{sortWallId}
		</Link>
	);

	const equipmentTypeContent: ReactNode[] = [];
	const equipmentIdContent: ReactNode[] = [];
	// const hasEquipment = job?.usedPickEquipment && job?.usedPickEquipment.length > 0;
	// const equipmentTypeContent =
	// 	[...Array.from(new Set(job?.usedPickEquipment.map((x) => x.deviceClass)))].join(', ') || null;

	// const equipmentIdContent =
	// 	[...Array.from(new Set(job?.usedPickEquipment.map((x) => x.name)))].join(', ') || null;
	const hasEquipment = false;

	// LOL-3314
	// const [allocationRules, setAllocationRules] = useState<string[]>([]);
	// useEffect(() => {
	// 	const fetchAllocatorStats = async () => {
	// 		try {
	// 			const response = await fetch(`${ALLOCATOR_URL}/v1/stats/jobDetails/${job?.orders[0].id}`);
	// 			if (!response.ok) {
	// 				setAllocationRules([]);
	// 				return;
	// 			}
	// 			const data = await response.json();

	// 			if (data) {
	// 				setAllocationRules(
	// 					data.precomputedBreakdown
	// 						?.filter(({ descriptor }) => descriptor.allocRule)
	// 						.map(({ descriptor }) => descriptor.name),
	// 				);
	// 			}
	// 		} catch (e) {
	// 			setAllocationRules([]);
	// 		}
	// 	};
	// 	fetchAllocatorStats();
	// }, [job.orders]);

	if (condensed) {
		return (
			<Card title={messages.pickingDetails} sectioned>
				<CardDetails
					loading={loading}
					primary={[
						{
							content: job?.externalContainerId ? (
								<CopyButton text={job?.externalContainerId} />
							) : null,
							label: messages.lpn,
						},
						stagingId
							? [
									sortWall ? listItem(messages.sortWallBatchId, sortWallBatchIdLink) : null,
									listItem(messages.stagingId, [sortWall ? sortWallIdLink : stagingId]),
							  ]
							: null,
						{
							content: job?.pickStrategy ? getPickStrategyName(job.pickStrategy) : null,
							label: messages.pickStrategy,
						},
						hasEquipment
							? [
									listItem(messages.equipmentType, equipmentTypeContent),
									listItem(messages.equipmentId, equipmentIdContent),
							  ]
							: null,
					]}
					secondary={[
						{
							content: (job as any)?.mfpId,
							label: messages.deviceId,
						},
						{
							content: container?.name,
							label: messages.container,
						},
						{
							content: takeoffDestination,
							label: messages.takeoff,
						},
					]}
				/>
			</Card>
		);
	}

	return (
		<Card title={messages.pickingDetails}>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							content: job?.pickStrategy ? getPickStrategyName(job.pickStrategy) : null,
							label: messages.pickStrategy,
						},
						stagingId
							? [
									sortWall ? listItem(messages.sortWallBatchId, sortWallBatchIdLink) : null,
									listItem(messages.stagingId, [sortWall ? sortWallIdLink : stagingId]),
							  ]
							: null,
						isBatchJob
							? {
									content: (relatedJobs || []).length,
									label: messages.totalJobs,
							  }
							: null,
						isSortJob
							? {
									content: jobsCount || <NoData />,
									label: messages.totalJobs,
							  }
							: null,
						// LOL-3313
						// isPackoutEnabled
						// 	? {
						// 			content: job?.isPackoutRequired ? messages.yes : messages.no,
						// 			label: messages.packoutRequired,
						// 	  }
						// 	: null,
						isWorkAreaEnabled
							? {
									content: Array.from(workAreaNames).join(', '),
									label: messages.workArea,
							  }
							: null,
						isSortJob
							? {
									content: messages.yes,
									label: messages.sortationRequired,
							  }
							: null,
					]}
					secondary={[
						{
							content: <DateTimeWithRelativeTime date={job?.createdAt} />,
							label: messages.createdAt,
						},
						{
							content: <DateTime date={job?.updatedAt} />,
							label: messages.updatedAt,
						},
						{
							content: (
								<DateTimeWithRelativeTime
									date={job?.expectedShipDate}
									mode={
										[JobStatus.Packed, JobStatus.Picked, JobStatus.Sorted].includes(
											job?.status as JobStatus,
										)
											? undefined
											: 'deadline'
									}
								/>
							),
							label: messages.carrierCutoff,
						},
						isSortJob
							? {
									content: <DateTime date={pickedAt} />,
									label: messages.pickingCompletedAt,
							  }
							: {
									content: <DateTime date={job?.completedAt} />,
									label: messages.completedAt,
							  },
					]}
				/>
				{/* <div className={styles.button}>
					<RelatedJobsButton relatedJobs={relatedJobs || []} label={messages.viewRelatedJobs} />
				</div> */}
			</Card.Section>
			<Card.Section>
				{/* <CardDetails
					loading={loading}
					primary={[
						{
							content: job?.mfp?.name,
							label: messages.deviceId,
						},
						hasEquipment
							? [
									listItem(messages.equipmentType, equipmentTypeContent),
									listItem(messages.equipmentId, equipmentIdContent),
							  ]
							: null,
					]}
					secondary={[
						{
							content: container?.name,
							label: messages.container,
						},
						{
							content: takeoffDestination,
							label: messages.takeoff,
						},
					]}
				/> */}

				{/* LOL-3313 {job?.usedPickEquipment && job?.usedPickEquipment.length > 0 ? (
					[]
				) : (
					<div className={styles.button}>
						<Button plain url={getMfpUrl(job?.mfp?.name)} disabled={!job?.mfp?.name}>
							{messages.findThisChuck}
						</Button>
					</div>
				)} */}
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							content: job?.id ? <CopyButton text={job.id} /> : null,
							label: messages.uniqueJobId,
						},
						// LOL-3314
						// allocationRules.length > 0
						// 	? {
						// 			content: allocationRules.join(', '),
						// 			label: messages.associatedAllocationRules,
						// 	  }
						// 	: null,
					]}
				/>
			</Card.Section>
		</Card>
	);
}
