// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetContainersSummaryQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetContainersSummaryQuery = { __typename?: 'Query', containers: { __typename?: 'ContainersPage', results: Array<{ __typename?: 'Container', externalId: string }> } };


export const GetContainersSummaryDocument = gql`
    query fapi_GetContainersSummary($searchText: String) {
  containers(limit: 50, searchText: $searchText) {
    results {
      externalId
    }
  }
}
    `;

export function useGetContainersSummaryQuery(options?: Omit<Urql.UseQueryArgs<GetContainersSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<GetContainersSummaryQuery, GetContainersSummaryQueryVariables>({ query: GetContainersSummaryDocument, ...options });
};