import { ContextualSaveBar, Form, Layout } from '@sixriver/lighthouse-web-community';
import { useCallback, useMemo } from 'react';

import { DeviceEditFormData, DeviceEditFormInfo } from './Device.utils';
import { DeviceActivity } from './DeviceActivity';
import { DeviceInfo } from './DeviceInfo';
import { DeviceProfile } from './DeviceProfile';
import { BasicFormProps, useBasicForm } from '../../hooks/useBasicForm';
import { useLocalization } from '../../hooks/useLocalization';

interface DeviceEditFormAdditionalProps {
	info: DeviceEditFormInfo;
}

type Props = BasicFormProps<DeviceEditFormData> & DeviceEditFormAdditionalProps;

export function DeviceEditForm<T extends Props>({
	data,
	info,
	errors: formErrors,
	onSubmit,
}: T): JSX.Element {
	const { messages } = useLocalization();

	// Form
	const defaultValues: DeviceEditFormData = useMemo(
		() => ({
			modelConfigId: data.modelConfigId,
			name: data.name,
		}),
		[data],
	);

	const { editForm, discardForm, input, dirty, validations } = useBasicForm<DeviceEditFormData>(
		defaultValues,
		formErrors,
	);

	const submitForm = useCallback(() => {
		onSubmit(input);
	}, [input, onSubmit]);

	return (
		<Form onSubmit={submitForm} noValidate preventDefault>
			{/* Save Bar */}
			{dirty ? (
				<ContextualSaveBar
					alignContentFlush={false}
					message={messages.unsavedChanges}
					saveAction={{
						content: messages.save,
						disabled: false,
						loading: false,
						onAction: submitForm,
					}}
					discardAction={{
						content: messages.discard,
						onAction: discardForm,
					}}
				/>
			) : null}

			<Layout>
				<Layout.Section>
					{/* Device Profile */}
					<Layout.AnnotatedSection
						title={messages.formDeviceEdit.section.deviceProfile.title}
						description={messages.formDeviceEdit.section.deviceProfile.description}
					>
						<DeviceProfile
							editForm={editForm}
							info={info}
							input={input}
							validations={validations}
						/>
					</Layout.AnnotatedSection>
					{/* Device Info */}
					<Layout.AnnotatedSection
						title={messages.formDeviceEdit.section.deviceInfo.title}
						description={messages.formDeviceEdit.section.deviceInfo.description}
					>
						<DeviceInfo info={info} />
					</Layout.AnnotatedSection>
					{/* Device Activity */}
					<Layout.AnnotatedSection
						title={messages.formDeviceEdit.section.deviceActivity.title}
						description={messages.formDeviceEdit.section.deviceActivity.description}
					>
						<DeviceActivity info={info} flush={true} sectioned={true} />
					</Layout.AnnotatedSection>
				</Layout.Section>
			</Layout>
		</Form>
	);
}
