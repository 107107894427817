// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateSortWallMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateSortWallMutation = { __typename?: 'Mutation', createSortWall: { __typename?: 'MutationResponse', success: boolean } };


export const CreateSortWallDocument = gql`
    mutation fapi_CreateSortWall {
  createSortWall {
    success
  }
}
    `;

export function useCreateSortWallMutation() {
  return Urql.useMutation<CreateSortWallMutation, CreateSortWallMutationVariables>(CreateSortWallDocument);
};