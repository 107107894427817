// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/edge/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReplayMessagesMutationVariables = Types.Exact<{
  orderingKey: Types.Scalars['String']['input'];
  messageIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type ReplayMessagesMutation = { __typename?: 'Mutation', replayMessages: { __typename?: 'ReplayResult', status: Types.ReplayStatusCode, results: Array<{ __typename?: 'ReplayDeliveryResult', replayed: Array<{ __typename?: 'MessageDelivery', id: string, deliveryState: Types.DeliveryState, startedAt?: any | null, hook: { __typename?: 'Hook', id: string }, message: { __typename?: 'Message', payload: any } }>, skipped: Array<{ __typename?: 'Message', id: string }> }> } };


export const ReplayMessagesDocument = gql`
    mutation edge_ReplayMessages($orderingKey: String!, $messageIds: [String!]!) {
  replayMessages(orderingKey: $orderingKey, messageIds: $messageIds) {
    status
    results {
      replayed {
        id
        deliveryState
        startedAt
        hook {
          id
        }
        message {
          payload
        }
      }
      skipped {
        id
      }
    }
  }
}
    `;

export function useReplayMessagesMutation() {
  return Urql.useMutation<ReplayMessagesMutation, ReplayMessagesMutationVariables>(ReplayMessagesDocument);
};