import { Layout, Page, Stack } from '@sixriver/lighthouse-web-community';
import { useRouteMatch } from 'react-router-dom';

import { Details } from './Details';
import { Progress } from './Progress';
import { GoalStates } from '../../api/fulfillment-api/types';
import { Error } from '../../components/Error';
import { useLocalization } from '../../hooks/useLocalization';
import { ShuttleJobStatus } from '../ShuttleJobs/ShuttleJobsTable';
import { useGetShuttleAvailableContainerQuery } from './graphql/GetShuttleAvailableContainer.w-api-graphql';
import { TimezoneFooter } from '../../components/TimezoneFooter';

export function ShuttleAvailableContainer() {
	const { messages } = useLocalization();
	const {
		params: { id },
	} = useRouteMatch<{ id: string }>();

	const [query] = useGetShuttleAvailableContainerQuery({
		variables: {
			id,
		},
	});
	const { shuttleAvailableContainer } = query.data ?? {};

	if (query.error) {
		return <Error graphQLError={query.error} />;
	} else if (!query.fetching && !shuttleAvailableContainer) {
		return <Error heading={messages.shuttleAvailableContainerNotFound} />;
	}

	return (
		<Page
			title={shuttleAvailableContainer?.inputs.containerLabels.default || ''}
			titleMetadata={
				<Stack spacing="extraTight">
					<ShuttleJobStatus status={shuttleAvailableContainer?.state as GoalStates} />
				</Stack>
			}
		>
			<Layout>
				<Layout.Section>
					<Details shuttleAvailableContainer={shuttleAvailableContainer} />
				</Layout.Section>
				<Layout.Section>
					<Progress shuttleAvailableContainer={shuttleAvailableContainer} />
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
