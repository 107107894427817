// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CountEmployeesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CountEmployeesQuery = { __typename?: 'Query', ActiveCount: { __typename?: 'Count', count: number }, InactiveCount: { __typename?: 'Count', count: number } };


export const CountEmployeesDocument = gql`
    query fapi_CountEmployees {
  ActiveCount: usersCount(type: active) {
    count
  }
  InactiveCount: usersCount(type: inactive) {
    count
  }
}
    `;

export function useCountEmployeesQuery(options?: Omit<Urql.UseQueryArgs<CountEmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<CountEmployeesQuery, CountEmployeesQueryVariables>({ query: CountEmployeesDocument, ...options });
};