import { useEffect, useRef } from 'react';

import { Chuck } from './Chuck.type';
import { distanceTraveled } from './ChuckUtils';
import { Telemetry } from './Telemetry';
import { Device } from '../../api/fulfillment-api/types';
import {
	useBatteryThresholds,
	useDeviceDwellThreshold,
	useDeviceOfflineThreshold,
} from '../../hooks/useConfig';
/**
 * This hook accepts the latest telemetry events from the Socket.IO endpoint and
 * the latest list of devices from the Fulfillment API and merges them into a
 * singular list of Chucks. Critically, it uses the telemetry data (or lack thereof)
 * to determine whether a Chuck is "dwelling" or "offline" for the purposes of
 * visualization.
 */
export function useChucks(devices: Device[], telemetryEvents?: Telemetry[] | null): Chuck[] {
	const chucks = useRef<Chuck[]>([]);
	const batteryThresholds = useBatteryThresholds();
	const deviceOfflineThreshold = useDeviceOfflineThreshold();
	const deviceDwellThreshold = useDeviceDwellThreshold();

	useEffect(() => {
		devices.forEach((device) => {
			const chuck = chucks.current.find((c) => c.id === device.id);

			if (chuck) {
				chuck.phase = device.phase;
				chuck.workAreaId = device.workAreaId || undefined;
				chuck.badge = device.badge || undefined;
			} else {
				chucks.current.push({
					badge: device.badge || undefined,
					batteryThreshold: batteryThresholds.critical || undefined,
					distance: 0,
					id: device.id,
					name: device.name || undefined,
					online: false,
					phase: device.phase,
					updatedAt: device.updatedAt,
					workAreaId: device.workAreaId || undefined,
				});
			}
		});

		// Intentionally using a custom dep array
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(devices)]);

	useEffect(() => {
		if (telemetryEvents) {
			telemetryEvents.forEach((telemetry) => {
				const chuck = chucks.current.find((c) => c.id === telemetry.id);

				if (chuck) {
					chuck.distance = chuck.telemetry ? distanceTraveled(chuck.telemetry, telemetry) : 0;
					chuck.telemetry = telemetry;
				}
			});
		}
	}, [telemetryEvents]);

	useEffect(() => {
		const offlineThresholdMs = deviceOfflineThreshold * 1000;
		const dwellThresholdMs = deviceDwellThreshold * 1000;

		const interval = setInterval(() => {
			const now = Date.now();

			chucks.current.forEach((chuck) => {
				const updatedAt = chuck.telemetry?.updatedAt?.getTime() || 0;
				const idleAt = chuck.telemetry?.idleAt?.getTime() || now;
				const pausedAt = chuck.telemetry?.pausedAt?.getTime() || now;

				chuck.online = now - updatedAt < offlineThresholdMs;
				chuck.dwellingIdle = now - idleAt > dwellThresholdMs;
				chuck.dwellingPaused = now - pausedAt > dwellThresholdMs;
			});
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [deviceOfflineThreshold, deviceDwellThreshold]);

	return chucks.current;
}
