import { Listbox, Tooltip } from '@sixriver/lighthouse-web-community';
import { useMemo } from 'react';

import styles from './ChuckJobsProgressBar.module.css';
import { StatusCounts } from '../../hooks/useJobCounts/useJobsCounts';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	counts: StatusCounts;
}

export function ChuckJobsProgressBar({ counts }: Props) {
	const { messages } = useLocalization();
	const total = counts.totalUnits || 0;

	const statusCounts = {
		[messages.unitsReadyToSort]: 0,
		[messages.unitsReadyToPack]: 0,
		[messages.unitsPicking]: counts.inProgressUnits,
		[messages.unitsSorting]: 0,
		[messages.unitsPacking]: 0,
		[messages.unitsPicked]: counts.pickedUnits,
		[messages.unitsSorted]: 0,
		[messages.unitsPacked]: 0,
		[messages.unitsCanceled]: 0,
		[messages.unitsUnknown]: counts.totalUnits - counts.inProgressUnits - counts.pickedUnits,
		[messages.unitsTotal]: counts.totalUnits,
	};

	// This status map has more statuses than can be on a chuck
	// in the future we probably will need to abstract this out or
	// make this progress bar an MFP progress bar that is used on all MFP devices
	// For now though we only show picking progress on chucks
	const statusMap = useMemo(() => {
		return {
			[messages.unitsReadyToSort]: { color: '#E8548F' },
			[messages.unitsReadyToPack]: { color: '#D100D1' },
			[messages.unitsPicking]: { color: 'green' },
			[messages.unitsSorting]: { color: '#DC1E6A' },
			[messages.unitsPacking]: { color: '#BD00BD' },
			[messages.unitsPicked]: { color: 'blue' },
			[messages.unitsSorted]: { color: '#B81959' },
			[messages.unitsPacked]: { color: '#750075' },
			[messages.unitsCanceled]: { color: 'red' },
			[messages.unitsUnknown]: { color: '#949489' },
		};
	}, [
		messages.unitsPacked,
		messages.unitsPicked,
		messages.unitsPicking,
		messages.unitsSorted,
		messages.unitsReadyToSort,
		messages.unitsReadyToPack,
		messages.unitsSorting,
		messages.unitsPacking,
		messages.unitsCanceled,
		messages.unitsUnknown,
	]);

	let progressBarLegend: { status: string; color: string }[] = [];
	progressBarLegend = statusCounts
		? Object.entries(statusCounts)
				.filter(([k, v]) => k !== messages.unitsTotal && v > 0)
				.map(([k]) => [{ color: statusMap[k]?.color, status: k }])
				.flat()
		: [];
	return (
		<>
			{counts && counts.totalUnits > 0 && (
				<Tooltip
					content={
						<Listbox>
							{progressBarLegend.map(({ color, status }) => {
								return (
									<div key={status} className={styles.legendKeyValues}>
										<div style={{ backgroundColor: color, height: '10px', width: '10px' }} />
										<div>{`${statusCounts[status]} ${status}`}</div>
									</div>
								);
							})}
						</Listbox>
					}
					preferredPosition="below"
				>
					<div className={styles.multiProgressBar}>
						{Object.entries(statusCounts)
							.filter(([k, v]) => k !== messages.unitsTotal && v > 0)
							.map(([k, v]: any) => {
								return (
									<div
										key={k}
										className={styles.progressBar}
										style={{
											backgroundColor: statusMap[k].color,
											minWidth: '3ch',
											width: `${(v / total) * 100}%`,
										}}
									>
										{`${Math.round((v / total) * 100)}%`}
									</div>
								);
							})}
					</div>
				</Tooltip>
			)}
		</>
	);
}
