// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import { PickWaveOrderConnectionFieldsFragmentDoc } from './PickWaveOrderConnectionFields.f-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetPickWaveOrdersQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  pickWaveIds?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  statuses?: Types.InputMaybe<Array<Types.PickWaveOrderStatus> | Types.PickWaveOrderStatus>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetPickWaveOrdersQuery = { __typename?: 'Query', pickWaveOrders: { __typename?: 'PickWaveOrderConnection', edges?: Array<{ __typename?: 'PickWaveOrderEdge', node: { __typename?: 'PickWaveOrder', id: string, status: Types.PickWaveOrderStatus, totalLinesCount: number, droppedLinesCount: number, errorCodes: Array<Types.PickWaveOrderLineErrorCode>, externalId: string } }> | null, pageInfo?: { __typename?: 'PickWaveOrderPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null } };


export const GetPickWaveOrdersDocument = gql`
    query fapi_GetPickWaveOrders($searchText: String, $pickWaveIds: [String!], $statuses: [PickWaveOrderStatus!], $first: Int, $after: String) {
  pickWaveOrders(
    searchText: $searchText
    pickWaveIds: $pickWaveIds
    statuses: $statuses
    first: $first
    after: $after
  ) {
    ...PickWaveOrderConnectionFields
  }
}
    ${PickWaveOrderConnectionFieldsFragmentDoc}`;

export function useGetPickWaveOrdersQuery(options?: Omit<Urql.UseQueryArgs<GetPickWaveOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPickWaveOrdersQuery, GetPickWaveOrdersQueryVariables>({ query: GetPickWaveOrdersDocument, ...options });
};