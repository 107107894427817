import { useDebouncedValue } from '@shopify/react-hooks';
import {
	AppliedFilterInterface,
	FilterInterface,
	Layout,
} from '@sixriver/lighthouse-web-community';
import { useState } from 'react';

import {
	ShuttleAvailableDestinationsViews,
	ShuttleAvailableDestinationsViewsViewStateMap,
} from './ShuttleAvailableDestinationViews';
import { ShuttleAvailableDestinationsTable } from './ShuttleAvailableDestinationsTable';
import {
	GetShuttleAvailableDestinationsDocument,
	GetShuttleAvailableDestinationsQuery,
} from './graphql/GetShuttleAvailableDestinations.w-api-graphql';
import {
	GetShuttleAvailableDestinationsCountDocument,
	GetShuttleAvailableDestinationsCountQuery,
} from './graphql/GetShuttleAvailableDestinationsCount.w-api-graphql';
import {
	OrderByDirection,
	ShuttleAvailableDestinationCount,
	ShuttleAvailableDestinationOrderByFields,
} from '../../api/warehouse-api/types';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { Error } from '../../components/Error';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { Toteboard } from '../../components/Toteboard';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useArrayQueryState } from '../../hooks/useQueryState';

const PAGINATION_CURSORS_KEY = 'paginationCursors';

export interface ShuttleAvailableDestinationOrderBy {
	direction: OrderByDirection;
	field: ShuttleAvailableDestinationOrderByFields;
}

export function ShuttleAvailableDestinations() {
	const { messages } = useLocalization();

	const defaultView = ShuttleAvailableDestinationsViews.All;

	// Pagination
	const [paginationCursors, setPaginationCursors] = useArrayQueryState<string[]>(
		PAGINATION_CURSORS_KEY,
		[],
	);
	const [selectedSort, setSelectedSort] = useState<ShuttleAvailableDestinationOrderBy[]>([
		{ direction: OrderByDirection.Desc, field: ShuttleAvailableDestinationOrderByFields.CreatedAt },
	]);

	// filtering logic
	const { query, view = defaultView } = useFilters([]);
	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';
	const actualSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;

	const statuses =
		ShuttleAvailableDestinationsViewsViewStateMap[view as ShuttleAvailableDestinationsViews];

	const [itemsQuery] = usePollingQuery<GetShuttleAvailableDestinationsQuery>({
		query: GetShuttleAvailableDestinationsDocument,
		variables: {
			after: paginationCursors[0],
			cursor: paginationCursors[0],
			first: getPageSize(),
			orderBy: selectedSort.length > 0 ? selectedSort : undefined,
			searchText: actualSearchText,
			statuses,
		},
	});
	const [countQuery] = usePollingQuery<GetShuttleAvailableDestinationsCountQuery>({
		query: GetShuttleAvailableDestinationsCountDocument,
	});

	const zeros: ShuttleAvailableDestinationCount = {
		cancelled: 0,
		completed: 0,
		held: 0,
		inProgress: 0,
		interrupted: 0,
		unassigned: 0,
	};
	const counts = {
		...zeros,
		...(countQuery.data?.countShuttleAvailableDestinations ?? {}),
	};

	const totalCount = Object.values(counts).reduce<number>(
		(sum, count) => sum + (typeof count === 'number' ? count : 0),
		0,
	);
	const totalPages = Math.max(Math.ceil(totalCount / getPageSize()), 1);

	// big card counts
	const toteboardItems = [
		{
			label: messages.unassigned,
			primaryValue: <span>{counts.unassigned}</span>,
		},
		{
			label: messages.inProgress,
			primaryValue: <span>{counts.inProgress}</span>,
		},
		{
			label: messages.interrupted,
			primaryValue: <span>{counts.interrupted}</span>,
		},
		// destinations are never queued (yet)
		{
			label: messages.canceled,
			primaryValue: <span>{counts.cancelled}</span>,
		},
		{
			label: messages.completed,
			primaryValue: <span>{counts.completed}</span>,
		},
	];

	const queries = [itemsQuery];
	const fetching = queries.some((q) => q.fetching);
	const error = queries.find((q) => q.error)?.error;

	const filters: FilterInterface[] = [];
	const appliedFilters: AppliedFilterInterface[] = [];

	const views = [
		{ id: ShuttleAvailableDestinationsViews.All, label: messages.all },
		{ id: ShuttleAvailableDestinationsViews.Unassigned, label: messages.unassigned },
		{ id: ShuttleAvailableDestinationsViews.InProgress, label: messages.inProgress },
		// destinations are never queued (yet)
		{ id: ShuttleAvailableDestinationsViews.Completed, label: messages.completed },
		{ id: ShuttleAvailableDestinationsViews.Cancelled, label: messages.canceled },
	];

	return error ? (
		<Error graphQLError={error} />
	) : (
		<AutoRefreshPage
			queries={[itemsQuery, countQuery]}
			fullWidth
			title={messages.shuttleAvailableDestinations}
		>
			<Layout>
				<Layout.Section>
					<Toteboard items={toteboardItems} />
				</Layout.Section>
				<Layout.Section>
					<ShuttleAvailableDestinationsTable
						data={itemsQuery.data?.shuttleAvailableDestinations?.edges}
						fetching={fetching}
						pageInfo={itemsQuery.data?.shuttleAvailableDestinations?.pageInfo}
						query={query}
						selectedView={view}
						setFilters={setFilters}
						totalPages={totalPages}
						views={views}
						selectedSort={selectedSort}
						onSortChange={setSelectedSort}
						filters={filters}
						appliedFilters={appliedFilters}
						setPaginationCursors={setPaginationCursors}
						paginationCursors={paginationCursors}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</AutoRefreshPage>
	);
}
