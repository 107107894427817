// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetProductByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetProductByIdQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: string, customerIdentifier: string, name: string, description: string, image?: string | null, width: number, height: number, length: number, weight: number } };


export const GetProductByIdDocument = gql`
    query fapi_GetProductById($id: String!) {
  product(id: $id) {
    id
    customerIdentifier
    name
    description
    image
    width
    height
    length
    weight
  }
}
    `;

export function useGetProductByIdQuery(options: Omit<Urql.UseQueryArgs<GetProductByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetProductByIdQuery, GetProductByIdQueryVariables>({ query: GetProductByIdDocument, ...options });
};