import { Page } from '@sixriver/lighthouse-web-community';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { StorageLocationForm } from './Location.form';
import { useAddNewLocationMutation } from './graphql/AddLocation.f-api-graphql';
import { StorageLocationInput, StorageLocationType } from '../../api/fulfillment-api/types';
import { useLocalization } from '../../hooks/useLocalization';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

export function AddStorageLocation(): JSX.Element {
	const history = useHistory();
	const { messages } = useLocalization();
	const { lookBusy } = useContext(BusyIndicatorContext);

	// Mutations
	const [addStorageLocationMutation, executeAddStorageLocationMutation] =
		useAddNewLocationMutation();

	// Methods
	const onSubmit = async (input: StorageLocationInput) => {
		lookBusy(true);

		const { data } = await executeAddStorageLocationMutation({ input });

		const id = data?.addLocation?.referenceId;

		if (!id) {
			lookBusy(false);
			return;
		}

		setTimeout(() => {
			lookBusy(false);
			history.push(routes.location(id));
		}, 500);
	};

	// Render
	return (
		<Page
			fullWidth
			title={messages.addLocation}
			breadcrumbs={[{ content: messages.locations, url: routes.locations() }]}
		>
			<StorageLocationForm
				mode="add"
				onSubmit={onSubmit}
				error={addStorageLocationMutation.error}
				data={
					{
						address: '',
						containerTypeId: undefined,
						description: '',
						externalAisleId: messages.none,
						type: StorageLocationType.Bin,
						x: 0,
						y: 0,
						z: 0,
					} as StorageLocationInput
				}
			/>
		</Page>
	);
}
