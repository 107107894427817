import * as Sentry from '@sentry/react';
import { IAllocationRule } from '@sixriver/cfs_models';

const ALLOCATOR_URL = import.meta.env.VITE_ALLOCATOR_API_URL;

export async function fetchAllocationRules(): Promise<IAllocationRule[]> {
	const url = `${ALLOCATOR_URL}/v1/AllocationRules`;

	const response = await fetch(url, {
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'GET',
	});

	if (!response.ok) {
		const err = new Error('Failed to fetch allocation rules.');
		Sentry.captureException({ err, status: response.status });
		throw err;
	}

	const data: IAllocationRule[] = await response.json();
	return data;
}
