import { useAuth, UserRole } from '@sixriver/react-support';

import { usePrintLabelMutation } from './graphql/PrintLabel.f-api-graphql';
import { useStartManualHealPlanMutation } from './graphql/StartManualHealPlan.f-api-graphql';
import { Order } from '../../api/fulfillment-api/types';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../useToast';

const PRINT_LABEL_HEAL_PLAN_LOCAL_STORAGE_KEY_PREFIX = 'heal-plan-label-printed-';
const PRINT_LABEL_SORT_WALL_LOCAL_STORAGE_KEY_PREFIX = 'sort-wall-label-printed-';

export const cleanUpPrintLabelLocalStorage = () => {
	Object.keys(localStorage).forEach((key) => {
		if (
			key.startsWith(PRINT_LABEL_HEAL_PLAN_LOCAL_STORAGE_KEY_PREFIX) ||
			key.startsWith(PRINT_LABEL_SORT_WALL_LOCAL_STORAGE_KEY_PREFIX)
		) {
			const value = localStorage.getItem(key);

			if (value) {
				const isMoreThanTwoWeeksAgo = Date.now() - new Date(value).getTime() > 1209600000;

				if (isMoreThanTwoWeeksAgo) {
					localStorage.removeItem(key);
				}
			}
		}
	});
};

export type onErrorProp = (error: Error) => void;

export const usePrintLabelButton: any = ({
	order,
	onError,
	onBefore,
}: {
	order?: Order;
	onError?: onErrorProp;
	onBefore?: () => void;
}) => {
	const { messages } = useLocalization();
	const { isUserAllowed } = useAuth();
	const hasBeenClicked = order?.id
		? !!localStorage.getItem(`${PRINT_LABEL_HEAL_PLAN_LOCAL_STORAGE_KEY_PREFIX}${order.id}`)
		: false;
	const disabled =
		!isUserAllowed([UserRole.Admin, UserRole.EmployeeManager]) ||
		order?.isHealed ||
		order?.healPlan?.status === 'RUNNING' ||
		false;

	const [printLabelMutation, executePrintLabelMutation] = usePrintLabelMutation();
	const { showToast } = useToast();

	const onClick = async () => {
		if (order) {
			try {
				if (onBefore) {
					onBefore();
				}

				const { error, data } = await executePrintLabelMutation({ id: order.id });

				if (error) {
					showToast(messages.errorToast, true);
					if (onError) {
						onError(error);
					}
				} else if (data?.startHealPlanAndPrintLabels.success) {
					// We set the date as the value here to be able to clean up old values from localStorage if it grows too big
					localStorage.setItem(
						`${PRINT_LABEL_HEAL_PLAN_LOCAL_STORAGE_KEY_PREFIX}${order.id}`,
						new Date().toISOString(),
					);

					showToast(messages.labelPrinted);
				} else {
					throw new Error('Unable to start heal plan.');
				}
			} catch (error: any) {
				showToast(messages.errorToast, true);
				if (onError) {
					onError(error as Error);
				}
			}
		}
	};

	const buttonText = disabled
		? messages.unavailable
		: hasBeenClicked
		? messages.reprintLabel
		: messages.printLabel;

	return {
		buttonText,
		disabled,
		error: printLabelMutation.error,
		hasBeenClicked,
		loading: printLabelMutation.fetching,
		onClick,
	};
};

export const useManualException: any = ({
	order,
	onError,
}: {
	order?: Order;
	onError?: onErrorProp;
}) => {
	const { messages } = useLocalization();
	const { isUserAllowed } = useAuth();
	const hasBeenClicked = order?.id
		? !!localStorage.getItem(`${PRINT_LABEL_HEAL_PLAN_LOCAL_STORAGE_KEY_PREFIX}${order.id}`)
		: false;
	const disabled =
		!isUserAllowed([UserRole.Admin, UserRole.EmployeeManager]) ||
		order?.isHealed ||
		order?.healPlan?.status === 'RUNNING' ||
		false;

	const [startManualHealPlanMutation, executeStartManualHealPlanMutation] =
		useStartManualHealPlanMutation();
	const { showToast } = useToast();

	const onClick = async () => {
		if (order) {
			try {
				const { error, data } = await executeStartManualHealPlanMutation({ id: order.id });

				if (error) {
					showToast(messages.errorToast, true);
					if (onError) {
						onError(error);
					}
				} else if (data?.startManualHealPlan.success) {
					// We set the date as the value here to be able to clean up old values from localStorage if it grows too big
					localStorage.setItem(
						`${PRINT_LABEL_HEAL_PLAN_LOCAL_STORAGE_KEY_PREFIX}${order.id}`,
						new Date().toISOString(),
					);

					showToast(messages.labelPrinted);
				} else {
					throw new Error('Unable to start heal plan.');
				}
			} catch (error: any) {
				showToast(messages.errorToast, true);
				if (onError) {
					onError(error as Error);
				}
			}
		}
	};

	const buttonText = disabled ? messages.unavailable : messages.markResolved;

	return {
		buttonText,
		disabled,
		error: startManualHealPlanMutation.error,
		hasBeenClicked,
		loading: startManualHealPlanMutation.fetching,
		onClick,
	};
};

export const usePrintSortWallLabel = ({ id, disabled }: { id?: string; disabled?: boolean }) => {
	const { messages } = useLocalization();

	const hasBeenClicked = id
		? !!localStorage.getItem(`${PRINT_LABEL_SORT_WALL_LOCAL_STORAGE_KEY_PREFIX}${id}`)
		: false;

	const onLabelPrintSuccess = () => {
		localStorage.setItem(
			`${PRINT_LABEL_SORT_WALL_LOCAL_STORAGE_KEY_PREFIX}${id}`,
			new Date().toISOString(),
		);
	};

	const buttonText = disabled
		? messages.unavailable
		: hasBeenClicked
		? messages.reprintLabel
		: messages.printLabel;

	return { buttonText, onLabelPrintSuccess };
};
