// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTagsQuery = { __typename?: 'Query', searchTags: Array<{ __typename?: 'Tag', name: string }>, getPopularTags: Array<{ __typename?: 'Tag', name: string }> };


export const GetTagsDocument = gql`
    query fapi_GetTags {
  searchTags(searchTerm: "") {
    name
  }
  getPopularTags(limit: 4) {
    name
  }
}
    `;

export function useGetTagsQuery(options?: Omit<Urql.UseQueryArgs<GetTagsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTagsQuery, GetTagsQueryVariables>({ query: GetTagsDocument, ...options });
};