import { Card, Badge } from '@sixriver/lighthouse-web-community';

import { GetPutawayJobQuery } from './graphql/GetPutawayJob.w-api-graphql';
import { CardDetails } from '../../components/CardDetails';
import { useLocalization } from '../../hooks/useLocalization';

export function Progress({ putawayJob }: GetPutawayJobQuery) {
	const { messages, translate } = useLocalization();

	// reduce lines, track completedCount and totalCount per line
	const totalCompletedCount = putawayJob?.lines?.reduce((acc: number, line: any) => {
		return acc + line.outputs.totalCompleted;
	}, 0);

	const totalTotalCount = putawayJob?.lines?.reduce((acc: number, line: any) => {
		return acc + line.inputs.amount;
	}, 0);

	// if the completedCount === totalCount on a line, we can assume the line is complete
	const completedLines = putawayJob?.lines?.filter((line: any) => {
		return line.outputs.totalCompleted === line.inputs.amount;
	});

	// count the number of exceptions on each line
	const numberOfExceptions =
		putawayJob?.lines?.reduce((acc: number, line: any) => {
			return acc + line?.outputs?.transfers?.flatMap((t: any) => t.exceptions)?.length;
		}, 0) || 0;

	return (
		<Card sectioned title={messages.progress}>
			<CardDetails
				primary={[
					{
						content: translate(messages.xOfY, {
							x: totalCompletedCount,
							y: totalTotalCount,
						}),
						label: messages.unitsPutAway,
					},
					{
						content: translate(messages.xOfY, {
							x: completedLines?.length,
							y: putawayJob?.lines?.length,
						}),
						label: messages.linesPutAway,
					},
				]}
				secondary={[
					{
						content: (
							<Badge status={numberOfExceptions > 0 ? 'critical' : undefined}>
								{numberOfExceptions.toString()}
							</Badge>
						),
						label: messages.exceptions,
					},
				]}
			/>
		</Card>
	);
}
