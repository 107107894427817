// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetExceptionReasonsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetExceptionReasonsQuery = { __typename?: 'Query', getExceptions?: Array<{ __typename?: 'IMExceptionCount', reason: string, count: number }> | null };


export const GetExceptionReasonsDocument = gql`
    query wapi_GetExceptionReasons {
  getExceptions {
    reason
    count
  }
}
    `;

export function useGetExceptionReasonsQuery(options?: Omit<Urql.UseQueryArgs<GetExceptionReasonsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetExceptionReasonsQuery, GetExceptionReasonsQueryVariables>({ query: GetExceptionReasonsDocument, ...options });
};