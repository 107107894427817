import { Link, Badge } from '@sixriver/lighthouse-web-community';

import { GetPutawayJobQuery } from './graphql/GetPutawayJob.w-api-graphql';
import { Column, DataTable } from '../../components/DataTable';
import { NoData } from '../../components/NoData';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

export function Lines({ putawayJob }: GetPutawayJobQuery) {
	const { messages, translate } = useLocalization();

	const columns: Column[] = [
		{
			heading: messages.item,
			type: 'text',
		},
		{
			heading: messages.description,
			type: 'text',
		},
		{
			heading: messages.destination,
			type: 'text',
		},
		{
			heading: messages.units,
			type: 'text',
		},

		{
			heading: messages.associate,
			type: 'text',
		},
		{
			heading: messages.exception,
			type: 'text',
		},
		{
			heading: messages.exceptionReason,
			type: 'text',
		},
	];

	const rows = (putawayJob?.lines || []).map((line) => {
		const lineExceptions = line?.outputs?.transfers?.flatMap((t: any) => t.exceptions);
		const exceptionsReasonsString = lineExceptions?.join(', ');
		const product = line.inputs.assetTypes[0];
		const location = line?.inputs?.reservationSources?.find((rs: any) => rs.type === 'location');

		return [
			// name hyperlinked to asset page
			product?.id ? (
				<Link url={routes.product(product.id as string)} key="product-id">
					{product.name}
				</Link>
			) : (
				<NoData />
			),

			// description
			product?.description ? <p key="product-name">{product.description}</p> : <NoData />,

			// location
			location?.address || <NoData />,

			// units
			<div key="units">
				{translate(messages.xOfY, {
					x: line.outputs.totalCompleted,
					y: line.inputs.amount,
				})}
			</div>,

			// associate
			line?.currentActor?.user?.id ? (
				<div key="associate">{line.currentActor.user.id}</div>
			) : (
				<NoData />
			),

			// Exception
			lineExceptions.length > 0 ? (
				<Badge status="critical">{messages.exception}</Badge>
			) : (
				<NoData />
			),

			// Exception Reason
			lineExceptions.length > 0 ? exceptionsReasonsString : <NoData />,
		];
	});

	return (
		<DataTable
			title={messages.lines}
			loading={false}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.linesNotFound}
		/>
	);
}
