import { useDebouncedValue } from '@shopify/react-hooks';
import { ChoiceList, FilterInterface, Layout } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';

import { PutawayJobsTable } from './PutawayJobsTable';
import { PutawayJobsViews, PutawayJobsViewsViewStateMap } from './PutawayJobsViews';
import {
	GetPutawayJobsDocument,
	GetPutawayJobsQuery,
	GetPutawayJobsQueryVariables,
} from './graphql/GetPutawayJobs.w-api-graphql';
import {
	GetPutawaysCountDocument,
	GetPutawaysCountQuery,
	GetPutawaysCountQueryVariables,
} from './graphql/GetPutawaysCount.w-api-graphql';
import { OrderByDirection, PutawayOrderByFields } from '../../api/warehouse-api/types';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { Error } from '../../components/Error';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { Toteboard } from '../../components/Toteboard';
import { useGetExceptionReasonsQuery } from '../../graphql/GetExceptionReasons.w-api-graphql';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useArrayQueryState } from '../../hooks/useQueryState';

const EXCEPTION_REASONS_KEY = 'exceptionReasons';
const PAGINATION_CURSORS_KEY = 'paginationCursors';

export interface PutawayOrderBy {
	direction: OrderByDirection;
	field: PutawayOrderByFields;
}

export function PutawayJobs() {
	const { messages } = useLocalization();

	const defaultView = PutawayJobsViews.All;

	// Pagination
	const [paginationCursors, setPaginationCursors] = useArrayQueryState<string[]>(
		PAGINATION_CURSORS_KEY,
		[],
	);
	const [selectedSort, setSelectedSort] = useState<PutawayOrderBy[]>([
		{ direction: OrderByDirection.Desc, field: PutawayOrderByFields.ContainerId },
	]);

	// filtering logic
	const {
		query,
		view = defaultView,
		[EXCEPTION_REASONS_KEY]: exceptionParam,
	} = useFilters([EXCEPTION_REASONS_KEY]);
	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';
	const actualSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;
	const selectedExceptions = exceptionParam ? exceptionParam.split(',') : undefined;

	const statuses = PutawayJobsViewsViewStateMap[view as PutawayJobsViews];

	const [getPutawayJobsQuery] = usePollingQuery<GetPutawayJobsQuery, GetPutawayJobsQueryVariables>({
		query: GetPutawayJobsDocument,
		variables: {
			after: paginationCursors[0],
			cursor: paginationCursors[0],
			exceptionReasons: selectedExceptions,
			first: getPageSize(),
			hasExceptions: exceptionParam && exceptionParam.length > 0 ? true : false,
			orderBy: selectedSort.length > 0 ? selectedSort : undefined,
			searchText: actualSearchText,
			statuses,
		},
	});

	const [getPutawaysCountQuery] = usePollingQuery<
		GetPutawaysCountQuery,
		GetPutawaysCountQueryVariables
	>({
		query: GetPutawaysCountDocument,
	});

	const [getExceptionReasonQuery] = useGetExceptionReasonsQuery();

	const {
		unassigned = 0,
		inProgress = 0,
		interrupted = 0,
		completed = 0,
		cancelled = 0,
	} = getPutawaysCountQuery.data?.countPutaways || {};

	const totalCount = unassigned + inProgress + completed + cancelled;
	const totalPages = Math.max(Math.ceil(totalCount / getPageSize()), 1);

	// big card counts
	const toteboardItems = [
		{
			label: messages.unassigned,
			primaryValue: <span>{unassigned}</span>,
		},
		{
			label: messages.inProgress,
			primaryValue: <span>{inProgress}</span>,
		},
		{
			label: messages.interrupted,
			primaryValue: <span>{interrupted}</span>,
		},
		{
			label: messages.canceled,
			primaryValue: <span>{cancelled}</span>,
		},
	];

	const fetching =
		getPutawayJobsQuery.fetching ||
		getPutawaysCountQuery.fetching ||
		getExceptionReasonQuery.fetching;
	const error =
		getPutawayJobsQuery.error || getPutawaysCountQuery.error || getExceptionReasonQuery.error;

	const exceptionReasons = getExceptionReasonQuery.data?.getExceptions?.map((exc) => exc.reason);

	const filters: FilterInterface[] = [];
	if (exceptionReasons?.length) {
		filters.push({
			filter: (
				<ChoiceList
					title={messages.putaway.exceptions}
					titleHidden
					choices={exceptionReasons.map((er) => ({ label: er, value: er }))}
					selected={selectedExceptions || []}
					onChange={(selected) => {
						setFilters([{ key: EXCEPTION_REASONS_KEY, value: selected.join(',') }]);
					}}
					allowMultiple
				/>
			),
			key: EXCEPTION_REASONS_KEY,
			label: messages.putaway.exceptions,
			shortcut: true,
		});
	}

	const appliedFilters = [
		...(selectedExceptions
			? [
					{
						key: EXCEPTION_REASONS_KEY,
						label: selectedExceptions.map((reason) => reason).join(', '),
						onRemove: () => {
							setFilters([{ key: EXCEPTION_REASONS_KEY, value: '' }]);
						},
					},
			  ]
			: []),
	];

	// Views
	const views = [
		{
			id: PutawayJobsViews.All,
			label: messages.all,
		},
		{
			id: PutawayJobsViews.Unassigned,
			label: messages.unassigned,
			metaLabel: unassigned,
		},
		{
			id: PutawayJobsViews.InProgress,
			label: messages.inProgress,
			metaLabel: inProgress,
		},
		{
			id: PutawayJobsViews.Interrupted,
			label: messages.interrupted,
			metaLabel: interrupted,
		},
		{
			id: PutawayJobsViews.Completed,
			label: messages.completed,
			metaLabel: completed,
		},
		{
			id: PutawayJobsViews.Cancelled,
			label: messages.canceled,
			metaLabel: cancelled,
		},
	];

	return error ? (
		<Error graphQLError={error} />
	) : (
		<AutoRefreshPage
			queries={[getPutawayJobsQuery, getPutawaysCountQuery, getExceptionReasonQuery]}
			fullWidth
			title={messages.putawayJobs}
		>
			<Layout>
				<Layout.Section>
					<Toteboard items={toteboardItems} />
				</Layout.Section>
				<Layout.Section>
					<PutawayJobsTable
						data={getPutawayJobsQuery.data?.putawayJobs?.edges}
						fetching={fetching}
						pageInfo={getPutawayJobsQuery.data?.putawayJobs?.pageInfo}
						query={query}
						selectedView={view}
						setFilters={setFilters}
						totalPages={totalPages}
						views={views}
						selectedSort={selectedSort}
						onSortChange={setSelectedSort}
						filters={filters}
						appliedFilters={appliedFilters}
						setPaginationCursors={setPaginationCursors}
						paginationCursors={paginationCursors}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</AutoRefreshPage>
	);
}
