// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.w-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOutboundJobsQueryJobFragment = { __typename?: 'Job', id: string, externalId: string, pickStrategy: Types.PickStrategy, createdAt: any, expectedShipDate?: any | null, externalContainerId?: string | null, status: Types.JobStatus, isLate: boolean, isShorted: boolean, type: string, isHealJob: boolean, isAwaitingInventory: boolean, mfpId?: string | null, eligiblePickDeviceClasses: Array<string>, totalLines: { __typename?: 'Count', count: number }, totalLineQuantities: { __typename?: 'LineQuantities', completedLines: number, totalUnits: number, pickedUnits: number }, workflows: Array<{ __typename?: 'JobWorkflow', type: string, status: string }>, orders: Array<{ __typename?: 'Job', id: string, isHealable: boolean, pickingJobs: Array<{ __typename?: 'Job', id: string }> }> };

export type GetOutboundJobsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statuses?: Types.InputMaybe<Array<Types.JobStatus> | Types.JobStatus>;
  isLate?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isShorted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isAwaitingInventory?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isHealJob?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  completedAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  completedAtTo?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  expectedShipDateFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  expectedShipDateTo?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  createdAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  pickStrategies?: Types.InputMaybe<Array<Types.PickStrategy> | Types.PickStrategy>;
  orderBy?: Types.InputMaybe<Array<Types.JobOrderBy> | Types.JobOrderBy>;
  workOrderTypes?: Types.InputMaybe<Array<Types.WorkOrderType> | Types.WorkOrderType>;
  workAreaIds?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  usedEquipmentTypes?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  isOnChuck?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetOutboundJobsQuery = { __typename?: 'Query', jobs: { __typename?: 'JobConnection', edges: Array<{ __typename?: 'JobEdge', node: { __typename?: 'Job', id: string, externalId: string, pickStrategy: Types.PickStrategy, createdAt: any, expectedShipDate?: any | null, externalContainerId?: string | null, status: Types.JobStatus, isLate: boolean, isShorted: boolean, type: string, isHealJob: boolean, isAwaitingInventory: boolean, mfpId?: string | null, eligiblePickDeviceClasses: Array<string>, totalLines: { __typename?: 'Count', count: number }, totalLineQuantities: { __typename?: 'LineQuantities', completedLines: number, totalUnits: number, pickedUnits: number }, workflows: Array<{ __typename?: 'JobWorkflow', type: string, status: string }>, orders: Array<{ __typename?: 'Job', id: string, isHealable: boolean, pickingJobs: Array<{ __typename?: 'Job', id: string }> }> } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export const GetOutboundJobsQueryJobFragmentDoc = gql`
    fragment GetOutboundJobsQueryJob on Job {
  id
  externalId
  pickStrategy
  createdAt
  expectedShipDate
  externalContainerId
  status
  isLate
  isShorted
  type
  isHealJob
  isAwaitingInventory
  mfpId
  totalLines {
    count
  }
  totalLineQuantities {
    completedLines
    totalUnits
    pickedUnits
  }
  eligiblePickDeviceClasses
  workflows {
    type
    status
  }
  orders {
    id
    isHealable
    pickingJobs {
      id
    }
  }
}
    `;
export const GetOutboundJobsDocument = gql`
    query wapi_GetOutboundJobs($after: String, $first: Int, $ids: [String!], $searchText: String, $statuses: [JobStatus!], $isLate: Boolean, $isShorted: Boolean, $isAwaitingInventory: Boolean, $isHealJob: Boolean, $completedAtFrom: DateTime, $completedAtTo: DateTime, $expectedShipDateFrom: DateTime, $expectedShipDateTo: DateTime, $createdAtFrom: DateTime, $pickStrategies: [PickStrategy!], $orderBy: [JobOrderBy!], $workOrderTypes: [WorkOrderType!], $workAreaIds: [String!], $usedEquipmentTypes: [String!], $isOnChuck: Boolean) {
  jobs(
    after: $after
    first: $first
    ids: $ids
    searchText: $searchText
    statuses: $statuses
    isLate: $isLate
    isShorted: $isShorted
    isAwaitingInventory: $isAwaitingInventory
    isHealJob: $isHealJob
    completedAtFrom: $completedAtFrom
    completedAtTo: $completedAtTo
    expectedShipDateFrom: $expectedShipDateFrom
    expectedShipDateTo: $expectedShipDateTo
    createdAtFrom: $createdAtFrom
    pickStrategies: $pickStrategies
    orderBy: $orderBy
    workOrderTypes: $workOrderTypes
    workAreaIds: $workAreaIds
    usedEquipmentTypes: $usedEquipmentTypes
    isOnChuck: $isOnChuck
  ) {
    edges {
      node {
        ...GetOutboundJobsQueryJob
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${GetOutboundJobsQueryJobFragmentDoc}
${PageInfoFragmentDoc}`;

export function useGetOutboundJobsQuery(options?: Omit<Urql.UseQueryArgs<GetOutboundJobsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOutboundJobsQuery, GetOutboundJobsQueryVariables>({ query: GetOutboundJobsDocument, ...options });
};