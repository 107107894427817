// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrdersCountQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  equipmentTypes?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  statuses?: Types.InputMaybe<Array<Types.JobStatus> | Types.JobStatus>;
  isLate?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isShorted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isAwaitingInventory?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isServicedByHealJobs?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  expectedShipDateFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  expectedShipDateTo?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  completedAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  completedAtTo?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  createdAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  pickStrategies?: Types.InputMaybe<Array<Types.PickStrategy> | Types.PickStrategy>;
  workOrderTypes?: Types.InputMaybe<Array<Types.WorkOrderType> | Types.WorkOrderType>;
  workAreaIds?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  jobFlowRuleLpn?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bulkOrderProjectionId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetOrdersCountQuery = { __typename?: 'Query', TotalCount: { __typename?: 'JobsCount', completed: number, interrupted: number, cancelled: number, awaitingInventory: number, late: number, shorted: number, unassigned: number, assignedToSortWall: number, assignedToBatch: number, unknown: number, inProgress: number, total: number, orders: number }, StaticTotalCount: { __typename?: 'JobsCount', completed: number, interrupted: number, cancelled: number, awaitingInventory: number, late: number, shorted: number, unassigned: number, assignedToSortWall: number, assignedToBatch: number, unknown: number, inProgress: number, total: number, orders: number, ready: number }, StaticCompletedCount: { __typename?: 'JobsCount', completed: number, interrupted: number, cancelled: number, awaitingInventory: number, late: number, shorted: number, unassigned: number, assignedToSortWall: number, assignedToBatch: number, unknown: number, inProgress: number, total: number, orders: number, ready: number, count: number } };


export const GetOrdersCountDocument = gql`
    query wapi_GetOrdersCount($ids: [String!], $searchText: String, $equipmentTypes: [String!], $statuses: [JobStatus!], $isLate: Boolean, $isShorted: Boolean, $isAwaitingInventory: Boolean, $isServicedByHealJobs: Boolean, $expectedShipDateFrom: DateTime, $expectedShipDateTo: DateTime, $completedAtFrom: DateTime, $completedAtTo: DateTime, $createdAtFrom: DateTime, $pickStrategies: [PickStrategy!], $workOrderTypes: [WorkOrderType!], $workAreaIds: [String!], $jobFlowRuleLpn: String, $bulkOrderProjectionId: String) {
  TotalCount: ordersCount(
    ids: $ids
    searchText: $searchText
    statuses: $statuses
    equipmentTypes: $equipmentTypes
    isLate: $isLate
    isShorted: $isShorted
    isAwaitingInventory: $isAwaitingInventory
    isServicedByHealJobs: $isServicedByHealJobs
    expectedShipDateFrom: $expectedShipDateFrom
    expectedShipDateTo: $expectedShipDateTo
    createdAtFrom: $createdAtFrom
    pickStrategies: $pickStrategies
    workOrderTypes: $workOrderTypes
    workAreaIds: $workAreaIds
    jobFlowRuleLpn: $jobFlowRuleLpn
    bulkOrderProjectionId: $bulkOrderProjectionId
  ) {
    completed
    interrupted
    cancelled
    awaitingInventory
    late
    shorted
    unassigned
    assignedToSortWall
    assignedToBatch
    unknown
    inProgress
    total
    orders
  }
  StaticTotalCount: ordersCount(
    createdAtFrom: $createdAtFrom
    workAreaIds: $workAreaIds
  ) {
    completed
    interrupted
    cancelled
    awaitingInventory
    late
    shorted
    unassigned
    assignedToSortWall
    assignedToBatch
    unknown
    inProgress
    total
    orders
    ready
  }
  StaticCompletedCount: ordersCount(
    createdAtFrom: $createdAtFrom
    completedAtFrom: $completedAtFrom
    completedAtTo: $completedAtTo
    workAreaIds: $workAreaIds
  ) {
    completed
    interrupted
    cancelled
    awaitingInventory
    late
    shorted
    unassigned
    assignedToSortWall
    assignedToBatch
    unknown
    inProgress
    total
    orders
    ready
    count
  }
}
    `;

export function useGetOrdersCountQuery(options?: Omit<Urql.UseQueryArgs<GetOrdersCountQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrdersCountQuery, GetOrdersCountQueryVariables>({ query: GetOrdersCountDocument, ...options });
};