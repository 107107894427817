import { TextStyle } from '@sixriver/lighthouse-web-community';

import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';

interface TotalOrdersProps {
	pickWave: PickWaveFieldsFragment;
}

export function TotalOrders({ pickWave }: TotalOrdersProps) {
	return <TextStyle>{pickWave.orders.edges?.length}</TextStyle>;
}
