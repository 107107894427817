// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditJobFlowRuleMutationVariables = Types.Exact<{
  input: Types.JobFlowRuleInput;
}>;


export type EditJobFlowRuleMutation = { __typename?: 'Mutation', editJobFlowRule: { __typename?: 'MutationResponse', success: boolean, referenceId?: string | null } };


export const EditJobFlowRuleDocument = gql`
    mutation fapi_EditJobFlowRule($input: JobFlowRuleInput!) {
  editJobFlowRule(input: $input) {
    success
    referenceId
  }
}
    `;

export function useEditJobFlowRuleMutation() {
  return Urql.useMutation<EditJobFlowRuleMutation, EditJobFlowRuleMutationVariables>(EditJobFlowRuleDocument);
};