// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReprintLabelsMutationVariables = Types.Exact<{
  projectionId: Types.Scalars['String']['input'];
}>;


export type ReprintLabelsMutation = { __typename?: 'Mutation', printSortationLabels: { __typename?: 'MutationResponse', success: boolean } };


export const ReprintLabelsDocument = gql`
    mutation fapi_ReprintLabels($projectionId: String!) {
  printSortationLabels(projectionId: $projectionId) {
    success
  }
}
    `;

export function useReprintLabelsMutation() {
  return Urql.useMutation<ReprintLabelsMutation, ReprintLabelsMutationVariables>(ReprintLabelsDocument);
};