// THIS FILE IS GENERATED, DO NOT EDIT!
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
};

export type AssetType = {
  __typename?: 'AssetType';
  attributes?: Maybe<Scalars['JSONObject']['output']>;
  containerTypeType?: Maybe<ContainerTypeType>;
  createdAt: Scalars['DateTime']['output'];
  customerIdentifier: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  identifiers: Array<ProductIdentifier>;
  image?: Maybe<Scalars['String']['output']>;
  isContainerType: Scalars['Boolean']['output'];
  kind: Scalars['String']['output'];
  length?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  productId?: Maybe<Scalars['String']['output']>;
  scanValues: Array<Scalars['String']['output']>;
  siteName: Scalars['String']['output'];
  unitOfMeasureQuantity?: Maybe<Scalars['Int']['output']>;
  unitOfMeasureType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type AssetTypeConnection = {
  __typename?: 'AssetTypeConnection';
  edges: Array<AssetTypeEdge>;
  pageInfo: PageInfo;
};

export type AssetTypeEdge = {
  __typename?: 'AssetTypeEdge';
  cursor: Scalars['String']['output'];
  node: AssetType;
};

export enum AssetTypeOrderByFields {
  Description = 'Description',
  ExternalId = 'ExternalId',
  Name = 'Name'
}

export type BasicActor = {
  __typename?: 'BasicActor';
  id: Scalars['String']['output'];
  metaData?: Maybe<Scalars['JSONObject']['output']>;
  type: Scalars['String']['output'];
};

export type BulkOrderLocation = {
  __typename?: 'BulkOrderLocation';
  address?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalAisleId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  units?: Maybe<Scalars['Float']['output']>;
};

export type BulkOrderProduct = {
  __typename?: 'BulkOrderProduct';
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  unitsMoved?: Maybe<Scalars['Float']['output']>;
  unitsPacked?: Maybe<Scalars['Float']['output']>;
  unitsRequired?: Maybe<Scalars['Float']['output']>;
};

export type CapturedData = {
  __typename?: 'CapturedData';
  group: Array<Scalars['JSONObject']['output']>;
};

export type Container = {
  __typename?: 'Container';
  name?: Maybe<Scalars['String']['output']>;
};

export type ContainerInputs = {
  __typename?: 'ContainerInputs';
  containerDimensions?: Maybe<Scalars['JSONObject']['output']>;
  containerKind: Scalars['String']['output'];
  containerLabels: ContainerLabels;
  containerTypeId?: Maybe<Scalars['String']['output']>;
  customerData: Scalars['JSONObject']['output'];
  dueBy?: Maybe<Scalars['DateTime']['output']>;
  externalIds: Scalars['JSONObject']['output'];
  isVirtual: Scalars['Boolean']['output'];
};

export type ContainerLabels = {
  __typename?: 'ContainerLabels';
  default?: Maybe<Scalars['String']['output']>;
};

export type ContainerOutputs = {
  __typename?: 'ContainerOutputs';
  collectedContainerLabel: Scalars['JSONObject']['output'];
  hasUnresolvedExceptions?: Maybe<Scalars['Boolean']['output']>;
  inductedAt?: Maybe<Scalars['String']['output']>;
  takenOffAt?: Maybe<Scalars['String']['output']>;
  takeoffDestination?: Maybe<Array<Scalars['String']['output']>>;
};

export type ContainerType = {
  __typename?: 'ContainerType';
  attributes?: Maybe<Scalars['JSONObject']['output']>;
  barcode?: Maybe<Scalars['String']['output']>;
  columns?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  cubeVolume?: Maybe<Scalars['Float']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  externalId: Scalars['String']['output'];
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  inventory: Scalars['Boolean']['output'];
  length?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  rows?: Maybe<Scalars['Int']['output']>;
  siteName: Scalars['String']['output'];
  type: ContainerTypeType;
  updatedAt: Scalars['DateTime']['output'];
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type ContainerTypeConnection = {
  __typename?: 'ContainerTypeConnection';
  edges: Array<ContainerTypeEdge>;
  pageInfo: PageInfo;
};

export type ContainerTypeCount = {
  __typename?: 'ContainerTypeCount';
  count: Scalars['Int']['output'];
  type?: Maybe<ContainerTypeType>;
};

export type ContainerTypeEdge = {
  __typename?: 'ContainerTypeEdge';
  cursor: Scalars['String']['output'];
  node: ContainerType;
};

export enum ContainerTypeOrderByFields {
  Name = 'name'
}

export enum ContainerTypeType {
  Picking = 'picking',
  Shipping = 'shipping',
  Storage = 'storage'
}

export type Coordinates = {
  __typename?: 'Coordinates';
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
  z: Scalars['Int']['output'];
};

export type Count = {
  __typename?: 'Count';
  count: Scalars['Int']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CustomerOrderStatusDashboard = {
  __typename?: 'CustomerOrderStatusDashboard';
  chart: CustomerOrderStatusDashboardChart;
  stats: CustomerOrderStatusDashboardStats;
};

export type CustomerOrderStatusDashboardArgs = {
  bucketInterval: Scalars['String']['input'];
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type CustomerOrderStatusDashboardChart = {
  __typename?: 'CustomerOrderStatusDashboardChart';
  series: Array<Scalars['Int']['output']>;
  xAxis: Array<Scalars['Float']['output']>;
};

export type CustomerOrderStatusDashboardStats = {
  __typename?: 'CustomerOrderStatusDashboardStats';
  canceledCount: Scalars['Int']['output'];
  inProgressCount: Scalars['Int']['output'];
  interruptedCount: Scalars['Int']['output'];
  packedCount: Scalars['Int']['output'];
  pickedCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  unassignedCount: Scalars['Int']['output'];
};

export type ExpectedShipDateStats = {
  __typename?: 'ExpectedShipDateStats';
  key: Scalars['Float']['output'];
  lineStats: LineStats;
  statusStats: Array<JobStatusStats>;
  total: Scalars['Int']['output'];
};

export type HealPlan = {
  __typename?: 'HealPlan';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  healJobId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  lines: Array<HealPlanLine>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['String']['output'];
  strategy?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type HealPlanLine = {
  __typename?: 'HealPlanLine';
  createdAt: Scalars['DateTime']['output'];
  healLineIds: Array<Scalars['String']['output']>;
  healQuantityPicked: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  lineId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  shortedDoneQuantity: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ImExceptionCount = {
  __typename?: 'IMExceptionCount';
  count: Scalars['Int']['output'];
  reason: Scalars['String']['output'];
};

export type ImLine = {
  __typename?: 'IMLine';
  containerId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentActor?: Maybe<UserActor>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inputs: LineInputs;
  lastEventPersistedAt: Scalars['DateTime']['output'];
  outputs: LineOutputs;
  state: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Job = {
  __typename?: 'Job';
  allowedDeviceClasses: Array<Scalars['String']['output']>;
  batchDisplayId?: Maybe<Scalars['String']['output']>;
  batchId?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Please use allowedDeviceClasses instead. */
  eligiblePickDeviceClasses: Array<Scalars['String']['output']>;
  expectedShipDate?: Maybe<Scalars['DateTime']['output']>;
  externalContainerId?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  healJobs: Array<Job>;
  healPlans?: Maybe<Array<HealPlan>>;
  id: Scalars['String']['output'];
  isAwaitingInventory: Scalars['Boolean']['output'];
  isHealJob: Scalars['Boolean']['output'];
  isHealable: Scalars['Boolean']['output'];
  isLate: Scalars['Boolean']['output'];
  isOrder: Scalars['Boolean']['output'];
  isServicedByHealJobs: Scalars['Boolean']['output'];
  isShorted: Scalars['Boolean']['output'];
  isTerminal: Scalars['Boolean']['output'];
  lines: LineConnection;
  mfpId?: Maybe<Scalars['String']['output']>;
  orders: Array<Job>;
  packouts: PackoutConnection;
  pickStrategy: PickStrategy;
  pickingJobs: Array<Job>;
  servicedBy: Array<Job>;
  servicedBySortations: Array<Job>;
  servicing: Array<Job>;
  servicingSortations: Array<Job>;
  sortations: SortationConnection;
  stagingIdentifier?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: JobStatus;
  tasks?: Maybe<Array<Task>>;
  totalLineQuantities: LineQuantities;
  totalLines: Count;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  usedDeviceClasses: Array<Scalars['String']['output']>;
  wall?: Maybe<Scalars['String']['output']>;
  workflows: Array<JobWorkflow>;
};


export type JobLinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type JobPackoutsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type JobSortationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type JobConnection = {
  __typename?: 'JobConnection';
  edges: Array<JobEdge>;
  pageInfo: PageInfo;
};

export type JobEdge = {
  __typename?: 'JobEdge';
  cursor: Scalars['String']['output'];
  node: Job;
};

export type JobOrderBy = {
  direction: OrderByDirection;
  field: JobOrderByFields;
};

export enum JobOrderByFields {
  CreatedAt = 'createdAt',
  ExpectedShipDate = 'expectedShipDate',
  ExternalId = 'externalId',
  UpdatedAt = 'updatedAt'
}

export type JobStats = {
  __typename?: 'JobStats';
  expectedShipDateStats: Array<ExpectedShipDateStats>;
  lateStats: LateStats;
  lineStats: LineStats;
  statusStats: Array<JobStatusStats>;
  total: Scalars['Int']['output'];
};

export enum JobStatus {
  Assigned = 'Assigned',
  AssignedToBatch = 'AssignedToBatch',
  AssignedToSortWall = 'AssignedToSortWall',
  AwaitingUdi = 'AwaitingUdi',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Done = 'Done',
  Expired = 'Expired',
  InProgress = 'InProgress',
  Interrupted = 'Interrupted',
  Loaded = 'Loaded',
  Packable = 'Packable',
  Packed = 'Packed',
  Packing = 'Packing',
  Picked = 'Picked',
  Picking = 'Picking',
  Ready = 'Ready',
  Sortable = 'Sortable',
  Sorted = 'Sorted',
  Sorting = 'Sorting',
  Staged = 'Staged',
  Unassigned = 'Unassigned',
  Unknown = 'Unknown'
}

export type JobStatusStats = {
  __typename?: 'JobStatusStats';
  key: JobStatus;
  lineStats: LineStats;
  total: Scalars['Int']['output'];
};

export type JobWorkflow = {
  __typename?: 'JobWorkflow';
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type JobsCount = {
  __typename?: 'JobsCount';
  assignedToBatch: Scalars['Float']['output'];
  assignedToSortWall: Scalars['Float']['output'];
  awaitingInventory: Scalars['Float']['output'];
  cancelled: Scalars['Float']['output'];
  completed: Scalars['Float']['output'];
  count: Scalars['Float']['output'];
  inProgress: Scalars['Float']['output'];
  interrupted: Scalars['Float']['output'];
  late: Scalars['Float']['output'];
  orders: Scalars['Float']['output'];
  packable: Scalars['Float']['output'];
  packed: Scalars['Float']['output'];
  packing: Scalars['Float']['output'];
  picked: Scalars['Float']['output'];
  picking: Scalars['Float']['output'];
  ready: Scalars['Float']['output'];
  shorted: Scalars['Float']['output'];
  sortable: Scalars['Float']['output'];
  sorted: Scalars['Float']['output'];
  sorting: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  unassigned: Scalars['Float']['output'];
  unknown: Scalars['Float']['output'];
};

export type LateStats = {
  __typename?: 'LateStats';
  lineStats: LineStats;
  total: Scalars['Int']['output'];
};

export type Line = {
  __typename?: 'Line';
  allocatedQuantity: Scalars['Int']['output'];
  allowedDeviceClasses: Array<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  customer: Customer;
  devices: Array<LineDevice>;
  doneQuantity: Scalars['Int']['output'];
  /** @deprecated Please use allowedDeviceClasses */
  eligiblePickDeviceClasses: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isShorted: Scalars['Boolean']['output'];
  mfpId?: Maybe<Scalars['String']['output']>;
  productCustomerIdentifier?: Maybe<Scalars['String']['output']>;
  productDescription?: Maybe<Scalars['String']['output']>;
  productExternalId?: Maybe<Scalars['String']['output']>;
  productId: Scalars['String']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  status?: Maybe<Scalars['String']['output']>;
  storageLocation: LineStorageLocation;
  tasks: Array<Task>;
  workArea?: Maybe<WorkArea>;
};

export type LineConnection = {
  __typename?: 'LineConnection';
  edges: Array<LineEdge>;
  pageInfo: PageInfo;
};

export type LineDevice = {
  __typename?: 'LineDevice';
  deviceClass: Scalars['String']['output'];
  equipmentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  modelConfigId?: Maybe<Scalars['String']['output']>;
  modelConfigName?: Maybe<Scalars['String']['output']>;
  workflow: Scalars['String']['output'];
};

export type LineEdge = {
  __typename?: 'LineEdge';
  cursor: Scalars['String']['output'];
  node: Line;
};

export type LineInputs = {
  __typename?: 'LineInputs';
  amount: Scalars['Float']['output'];
  assetTypes: Array<Scalars['JSONObject']['output']>;
  customerData: Scalars['JSONObject']['output'];
  direction: Scalars['String']['output'];
  externalIds: Scalars['JSONObject']['output'];
  reservationSources: Array<Scalars['JSONObject']['output']>;
};

export type LineOutputs = {
  __typename?: 'LineOutputs';
  totalCompleted: Scalars['Float']['output'];
  transfers: Array<Transfer>;
};

export type LineQuantities = {
  __typename?: 'LineQuantities';
  assignedToBatchUnits: Scalars['Int']['output'];
  completedLines: Scalars['Int']['output'];
  completedUnits: Scalars['Int']['output'];
  inProgressUnits: Scalars['Int']['output'];
  loadedUnits: Scalars['Int']['output'];
  packedUnits: Scalars['Int']['output'];
  pickedUnits: Scalars['Int']['output'];
  sortedUnits: Scalars['Int']['output'];
  stagedUnits: Scalars['Int']['output'];
  totalUnits: Scalars['Int']['output'];
  unassignedUnits: Scalars['Int']['output'];
};

export type LineStats = {
  __typename?: 'LineStats';
  total: Scalars['Int']['output'];
  totalAllocated: Scalars['Int']['output'];
  totalDone: Scalars['Int']['output'];
  totalQuantity: Scalars['Int']['output'];
};

export type LineStorageLocation = {
  __typename?: 'LineStorageLocation';
  address?: Maybe<Scalars['String']['output']>;
};

export type OrderBy = {
  direction: OrderByDirection;
  field: PutawayOrderByFields;
};

export enum OrderByDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type OrderHealthDashboard = {
  __typename?: 'OrderHealthDashboard';
  stats: OrderHealthDashboardStats;
  table: Array<OrderHealthDashboardTable>;
};

export type OrderHealthDashboardArgs = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type OrderHealthDashboardStats = {
  __typename?: 'OrderHealthDashboardStats';
  activePickers: Scalars['Int']['output'];
  forecastedCompletionDate: Scalars['DateTime']['output'];
  lph: Scalars['Float']['output'];
  totalPickedJobs: Scalars['Int']['output'];
  totalPickedLines: Scalars['Int']['output'];
  totalPickedUnits: Scalars['Int']['output'];
};

export type OrderHealthDashboardTable = {
  __typename?: 'OrderHealthDashboardTable';
  pickedJobs: Scalars['Int']['output'];
  pickedLines: Scalars['Int']['output'];
  pickedUnits: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

export type Packout = {
  __typename?: 'Packout';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  mfpId?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  workflow?: Maybe<Scalars['String']['output']>;
};

export type PackoutConnection = {
  __typename?: 'PackoutConnection';
  edges: Array<PackoutEdge>;
  pageInfo: PageInfo;
};

export type PackoutEdge = {
  __typename?: 'PackoutEdge';
  cursor: Scalars['String']['output'];
  node: Packout;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PickStrategy {
  Bulk = 'Bulk',
  Consolidation = 'Consolidation',
  Discrete = 'Discrete',
  Group = 'Group',
  Singles = 'Singles',
  Sortation = 'Sortation',
  Unknown = 'Unknown'
}

export type PickedData = {
  __typename?: 'PickedData';
  date?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type PickerProductivityDashboard = {
  __typename?: 'PickerProductivityDashboard';
  chart: PickerProductivityDashboardChart;
  table: PickerProductivityDashboardTable;
};

export type PickerProductivityDashboardArgs = {
  bucketInterval: Scalars['String']['input'];
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
  /** Filtering by tasks gives back units picking rate */
  type: Scalars['String']['input'];
};

export type PickerProductivityDashboardChart = {
  __typename?: 'PickerProductivityDashboardChart';
  aveRate: Scalars['Float']['output'];
  series: Array<SeriesData>;
};

export type PickerProductivityDashboardTable = {
  __typename?: 'PickerProductivityDashboardTable';
  dates: Array<Scalars['String']['output']>;
  users: Array<PickerProductivityUsers>;
};

export type PickerProductivityUsers = {
  __typename?: 'PickerProductivityUsers';
  id: Scalars['String']['output'];
  picked: Array<PickedData>;
  total: Scalars['Int']['output'];
};

export type PickingRateDashArgs = {
  bucketInterval: Scalars['String']['input'];
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
  /** Filtering by tasks gives back units picking rate */
  type: Scalars['String']['input'];
};

export type PickingRateDashboard = {
  __typename?: 'PickingRateDashboard';
  chart: Array<PickingRateDashboardChart>;
  stats: PickingRateDashboardStats;
  table: Array<PickingRateDashboardTable>;
};

export type PickingRateDashboardChart = {
  __typename?: 'PickingRateDashboardChart';
  /** Number of picks */
  seriesRateData: Scalars['Float']['output'];
  /** Volume of picks */
  seriesVolumeData: Scalars['Float']['output'];
  /** Picking dates */
  xAxis: Scalars['String']['output'];
};

export type PickingRateDashboardStats = {
  __typename?: 'PickingRateDashboardStats';
  aveAllJPH: Scalars['Float']['output'];
  aveAllLPH: Scalars['Float']['output'];
  aveAllLinesPerJob: Scalars['Float']['output'];
  aveAllUPH: Scalars['Float']['output'];
  aveAllUnitsPerLine: Scalars['Float']['output'];
  totalChucks: Scalars['Float']['output'];
  totalJobsPicked: Scalars['Float']['output'];
  totalLinesPicked: Scalars['Float']['output'];
  totalPickers: Scalars['Float']['output'];
  totalUnitsAllocated: Scalars['Float']['output'];
  totalUnitsPicked: Scalars['Float']['output'];
};

export type PickingRateDashboardTable = {
  __typename?: 'PickingRateDashboardTable';
  date: Scalars['String']['output'];
  jobsPicked: Scalars['Float']['output'];
  jph: Scalars['Float']['output'];
  linesPerJob: Scalars['Float']['output'];
  linesPicked: Scalars['Float']['output'];
  lph: Scalars['Float']['output'];
  numberOfChucks: Scalars['Float']['output'];
  numberOfPickers: Scalars['Float']['output'];
  unitsAllocated: Scalars['Float']['output'];
  unitsPerLine: Scalars['Float']['output'];
  unitsPicked: Scalars['Float']['output'];
  uph: Scalars['Float']['output'];
};

export type ProductIdentifier = {
  __typename?: 'ProductIdentifier';
  allowedValues: Array<Scalars['String']['output']>;
  grabQuantity?: Maybe<Scalars['Float']['output']>;
  label: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  validationStrategy: ValidationStrategy;
};

export type PutawayConnection = {
  __typename?: 'PutawayConnection';
  edges: Array<PutawayContainerEdge>;
  pageInfo: PageInfo;
};

export type PutawayContainer = {
  __typename?: 'PutawayContainer';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentActor?: Maybe<UserActor>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inputs: ContainerInputs;
  lastEventPersistedAt: Scalars['DateTime']['output'];
  lineIds: Array<Scalars['String']['output']>;
  lines: Array<ImLine>;
  outputs: ContainerOutputs;
  siteName: Scalars['String']['output'];
  state: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type PutawayContainerEdge = {
  __typename?: 'PutawayContainerEdge';
  cursor: Scalars['String']['output'];
  node: PutawayContainer;
};

export type PutawayCount = {
  __typename?: 'PutawayCount';
  cancelled: Scalars['Int']['output'];
  completed: Scalars['Int']['output'];
  inProgress: Scalars['Int']['output'];
  interrupted: Scalars['Int']['output'];
  unassigned: Scalars['Int']['output'];
};

export enum PutawayOrderByFields {
  ContainerId = 'containerId',
  ExternalId = 'externalId'
}

export type Query = {
  __typename?: 'Query';
  assetType?: Maybe<AssetType>;
  containers: ContainerTypeConnection;
  countContainers: Array<ContainerTypeCount>;
  countPutaways?: Maybe<PutawayCount>;
  countShuttleAvailableContainers: ShuttleAvailableContainerCount;
  countShuttleAvailableDestinations: ShuttleAvailableDestinationCount;
  countShuttles: ShuttleCount;
  customerOrderStatus: CustomerOrderStatusDashboard;
  getContainer?: Maybe<ContainerType>;
  getExceptions?: Maybe<Array<ImExceptionCount>>;
  getOrders: JobConnection;
  job?: Maybe<Job>;
  jobStats: JobStats;
  jobs: JobConnection;
  jobsCount: JobsCount;
  locationTypeCounts: Array<StorageLocationTypeCount>;
  locations: StorageLocationConnection;
  orderHealth: OrderHealthDashboard;
  orderStats: JobStats;
  ordersCount: JobsCount;
  packout?: Maybe<Packout>;
  packouts: PackoutConnection;
  pickerProductivity: PickerProductivityDashboard;
  pickingRate: PickingRateDashboard;
  product?: Maybe<AssetType>;
  products: AssetTypeConnection;
  putawayJob?: Maybe<PutawayContainer>;
  putawayJobs: PutawayConnection;
  shuttleAvailableContainer?: Maybe<ShuttleAvailableContainer>;
  shuttleAvailableContainers: ShuttleAvailableContainerConnection;
  shuttleAvailableDestination?: Maybe<ShuttleAvailableDestination>;
  shuttleAvailableDestinations: ShuttleAvailableDestinationConnection;
  shuttleJob?: Maybe<ShuttleContainer>;
  shuttleJobs: ShuttleConnection;
  sortWalls: JobConnection;
  sortation?: Maybe<Sortation>;
  sortations: SortationConnection;
  storageLocation?: Maybe<StorageLocation>;
  user?: Maybe<User>;
  users: UserConnection;
};


export type QueryAssetTypeArgs = {
  id: Scalars['String']['input'];
};


export type QueryContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isInventory?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<ContainerTypeOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<ContainerTypeType>>;
};


export type QueryCountContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isInventory?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<ContainerTypeOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<ContainerTypeType>>;
};


export type QueryCountPutawaysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  exceptionReasons?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryCountShuttleAvailableContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Array<ShuttleAvailableContainerOrderBy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryCountShuttleAvailableDestinationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Array<ShuttleAvailableDestinationOrderBy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryCountShuttlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  exceptionReasons?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Array<ShuttleOrderBy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryCustomerOrderStatusArgs = {
  dashArgs: CustomerOrderStatusDashboardArgs;
};


export type QueryGetContainerArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  healPlanCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isAwaitingInventory?: InputMaybe<Scalars['Boolean']['input']>;
  isHealJob?: InputMaybe<Scalars['Boolean']['input']>;
  isHealable?: InputMaybe<Scalars['Boolean']['input']>;
  isLate?: InputMaybe<Scalars['Boolean']['input']>;
  isOnChuck?: InputMaybe<Scalars['Boolean']['input']>;
  isServicedByHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<Array<JobOrderBy>>;
  pickStrategies?: InputMaybe<Array<PickStrategy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobStatus>>;
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  usedEquipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryJobArgs = {
  id: Scalars['String']['input'];
};


export type QueryJobStatsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  healPlanCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isAwaitingInventory?: InputMaybe<Scalars['Boolean']['input']>;
  isHealJob?: InputMaybe<Scalars['Boolean']['input']>;
  isHealable?: InputMaybe<Scalars['Boolean']['input']>;
  isLate?: InputMaybe<Scalars['Boolean']['input']>;
  isOnChuck?: InputMaybe<Scalars['Boolean']['input']>;
  isServicedByHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<Array<JobOrderBy>>;
  pickStrategies?: InputMaybe<Array<PickStrategy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobStatus>>;
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  usedEquipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  healPlanCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isAwaitingInventory?: InputMaybe<Scalars['Boolean']['input']>;
  isHealJob?: InputMaybe<Scalars['Boolean']['input']>;
  isHealable?: InputMaybe<Scalars['Boolean']['input']>;
  isLate?: InputMaybe<Scalars['Boolean']['input']>;
  isOnChuck?: InputMaybe<Scalars['Boolean']['input']>;
  isServicedByHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<Array<JobOrderBy>>;
  pickStrategies?: InputMaybe<Array<PickStrategy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobStatus>>;
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  usedEquipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryJobsCountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  healPlanCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isAwaitingInventory?: InputMaybe<Scalars['Boolean']['input']>;
  isHealJob?: InputMaybe<Scalars['Boolean']['input']>;
  isHealable?: InputMaybe<Scalars['Boolean']['input']>;
  isLate?: InputMaybe<Scalars['Boolean']['input']>;
  isOnChuck?: InputMaybe<Scalars['Boolean']['input']>;
  isServicedByHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<Array<JobOrderBy>>;
  pickStrategies?: InputMaybe<Array<PickStrategy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobStatus>>;
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  usedEquipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryLocationTypeCountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<StorageLocationOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StorageLocationType>;
};


export type QueryLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<StorageLocationOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StorageLocationType>;
};


export type QueryOrderHealthArgs = {
  dashArgs: OrderHealthDashboardArgs;
};


export type QueryOrderStatsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  healPlanCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isAwaitingInventory?: InputMaybe<Scalars['Boolean']['input']>;
  isHealJob?: InputMaybe<Scalars['Boolean']['input']>;
  isHealable?: InputMaybe<Scalars['Boolean']['input']>;
  isLate?: InputMaybe<Scalars['Boolean']['input']>;
  isOnChuck?: InputMaybe<Scalars['Boolean']['input']>;
  isServicedByHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<Array<JobOrderBy>>;
  pickStrategies?: InputMaybe<Array<PickStrategy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobStatus>>;
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  usedEquipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryOrdersCountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  healPlanCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isAwaitingInventory?: InputMaybe<Scalars['Boolean']['input']>;
  isHealJob?: InputMaybe<Scalars['Boolean']['input']>;
  isHealable?: InputMaybe<Scalars['Boolean']['input']>;
  isLate?: InputMaybe<Scalars['Boolean']['input']>;
  isOnChuck?: InputMaybe<Scalars['Boolean']['input']>;
  isServicedByHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<Array<JobOrderBy>>;
  pickStrategies?: InputMaybe<Array<PickStrategy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobStatus>>;
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  usedEquipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryPackoutArgs = {
  id: Scalars['String']['input'];
};


export type QueryPackoutsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPickerProductivityArgs = {
  dashArgs: PickerProductivityDashboardArgs;
};


export type QueryPickingRateArgs = {
  dashArgs: PickingRateDashArgs;
};


export type QueryProductArgs = {
  id: Scalars['String']['input'];
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isExpirationTracked?: InputMaybe<Scalars['Boolean']['input']>;
  isLotTracked?: InputMaybe<Scalars['Boolean']['input']>;
  kinds?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<AssetTypeOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StorageLocationType>;
};


export type QueryPutawayJobArgs = {
  id: Scalars['String']['input'];
};


export type QueryPutawayJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  exceptionReasons?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryShuttleAvailableContainerArgs = {
  id: Scalars['String']['input'];
};


export type QueryShuttleAvailableContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Array<ShuttleAvailableContainerOrderBy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryShuttleAvailableDestinationArgs = {
  id: Scalars['String']['input'];
};


export type QueryShuttleAvailableDestinationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Array<ShuttleAvailableDestinationOrderBy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryShuttleJobArgs = {
  id: Scalars['String']['input'];
};


export type QueryShuttleJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  exceptionReasons?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Array<ShuttleOrderBy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QuerySortWallsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  healPlanCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isAwaitingInventory?: InputMaybe<Scalars['Boolean']['input']>;
  isHealJob?: InputMaybe<Scalars['Boolean']['input']>;
  isHealable?: InputMaybe<Scalars['Boolean']['input']>;
  isLate?: InputMaybe<Scalars['Boolean']['input']>;
  isOnChuck?: InputMaybe<Scalars['Boolean']['input']>;
  isServicedByHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<Array<JobOrderBy>>;
  pickStrategies?: InputMaybe<Array<PickStrategy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobStatus>>;
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  usedEquipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QuerySortationArgs = {
  id: Scalars['String']['input'];
};


export type QuerySortationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStorageLocationArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type SeriesData = {
  __typename?: 'SeriesData';
  rate: Scalars['Float']['output'];
  user: Scalars['String']['output'];
  volume: Scalars['Int']['output'];
};

export type ShuttleAvailableContainer = {
  __typename?: 'ShuttleAvailableContainer';
  activeShuttle?: Maybe<ShuttleContainer>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentActor?: Maybe<UserActor>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inputs: ShuttleAvailableContainerInputs;
  lastEventPersistedAt: Scalars['DateTime']['output'];
  outputs: ShuttleAvailableContainerOutputs;
  siteName: Scalars['String']['output'];
  state: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ShuttleAvailableContainerConnection = {
  __typename?: 'ShuttleAvailableContainerConnection';
  edges: Array<ShuttleAvailableContainerEdge>;
  pageInfo: PageInfo;
};

export type ShuttleAvailableContainerCount = {
  __typename?: 'ShuttleAvailableContainerCount';
  cancelled: Scalars['Int']['output'];
  completed: Scalars['Int']['output'];
  held: Scalars['Int']['output'];
  inProgress: Scalars['Int']['output'];
  interrupted: Scalars['Int']['output'];
  unassigned: Scalars['Int']['output'];
};

export type ShuttleAvailableContainerEdge = {
  __typename?: 'ShuttleAvailableContainerEdge';
  cursor: Scalars['String']['output'];
  node: ShuttleAvailableContainer;
};

export type ShuttleAvailableContainerInputs = {
  __typename?: 'ShuttleAvailableContainerInputs';
  aisle: Scalars['String']['output'];
  aislePos: Scalars['Float']['output'];
  containerDimensions?: Maybe<Scalars['JSONObject']['output']>;
  containerKind: Scalars['String']['output'];
  containerLabels: ContainerLabels;
  containerTypeId?: Maybe<Scalars['String']['output']>;
  customerData: Scalars['JSONObject']['output'];
  destinationCriteria?: Maybe<Scalars['JSONObject']['output']>;
  externalIds: Scalars['JSONObject']['output'];
  priority: Scalars['Float']['output'];
  queueAt?: Maybe<Scalars['String']['output']>;
  storageLocationAddress: Scalars['String']['output'];
  storageLocationId: Scalars['String']['output'];
};

export type ShuttleAvailableContainerOrderBy = {
  direction: OrderByDirection;
  field: ShuttleAvailableContainerOrderByFields;
};

export enum ShuttleAvailableContainerOrderByFields {
  Address = 'address',
  ContainerId = 'containerId',
  CreatedAt = 'createdAt',
  ExternalId = 'externalId',
  UpdatedAt = 'updatedAt'
}

export type ShuttleAvailableContainerOutputs = {
  __typename?: 'ShuttleAvailableContainerOutputs';
  activeTask?: Maybe<Scalars['String']['output']>;
  pickedUpBy?: Maybe<Scalars['String']['output']>;
};

export type ShuttleAvailableDestination = {
  __typename?: 'ShuttleAvailableDestination';
  activeShuttle?: Maybe<ShuttleContainer>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentActor?: Maybe<UserActor>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inputs: ShuttleAvailableDestinationInputs;
  lastEventPersistedAt: Scalars['DateTime']['output'];
  outputs: ShuttleAvailableDestinationOutputs;
  siteName: Scalars['String']['output'];
  state: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ShuttleAvailableDestinationConnection = {
  __typename?: 'ShuttleAvailableDestinationConnection';
  edges: Array<ShuttleAvailableDestinationEdge>;
  pageInfo: PageInfo;
};

export type ShuttleAvailableDestinationCount = {
  __typename?: 'ShuttleAvailableDestinationCount';
  cancelled: Scalars['Int']['output'];
  completed: Scalars['Int']['output'];
  held: Scalars['Int']['output'];
  inProgress: Scalars['Int']['output'];
  interrupted: Scalars['Int']['output'];
  unassigned: Scalars['Int']['output'];
};

export type ShuttleAvailableDestinationEdge = {
  __typename?: 'ShuttleAvailableDestinationEdge';
  cursor: Scalars['String']['output'];
  node: ShuttleAvailableDestination;
};

export type ShuttleAvailableDestinationInputs = {
  __typename?: 'ShuttleAvailableDestinationInputs';
  containerCriteria?: Maybe<Scalars['JSONObject']['output']>;
  customerData: Scalars['JSONObject']['output'];
  priority: Scalars['Float']['output'];
  queueAt?: Maybe<Scalars['String']['output']>;
  storageLocationAddress: Scalars['String']['output'];
  storageLocationId: Scalars['String']['output'];
  usage: Scalars['String']['output'];
};

export type ShuttleAvailableDestinationOrderBy = {
  direction: OrderByDirection;
  field: ShuttleAvailableDestinationOrderByFields;
};

export enum ShuttleAvailableDestinationOrderByFields {
  Address = 'address',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type ShuttleAvailableDestinationOutputs = {
  __typename?: 'ShuttleAvailableDestinationOutputs';
  activeTask?: Maybe<Scalars['String']['output']>;
};

export type ShuttleConnection = {
  __typename?: 'ShuttleConnection';
  edges: Array<ShuttleContainerEdge>;
  pageInfo: PageInfo;
};

export type ShuttleContainer = {
  __typename?: 'ShuttleContainer';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentActor?: Maybe<UserActor>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inputs: ContainerInputs;
  lastEventPersistedAt: Scalars['DateTime']['output'];
  lineIds: Array<Scalars['String']['output']>;
  lines: Array<ImLine>;
  outputs: ContainerOutputs;
  siteName: Scalars['String']['output'];
  state: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ShuttleContainerEdge = {
  __typename?: 'ShuttleContainerEdge';
  cursor: Scalars['String']['output'];
  node: ShuttleContainer;
};

export type ShuttleCount = {
  __typename?: 'ShuttleCount';
  cancelled: Scalars['Int']['output'];
  completed: Scalars['Int']['output'];
  inProgress: Scalars['Int']['output'];
  interrupted: Scalars['Int']['output'];
  unassigned: Scalars['Int']['output'];
};

export type ShuttleOrderBy = {
  direction: OrderByDirection;
  field: ShuttleOrderByFields;
};

export enum ShuttleOrderByFields {
  ContainerId = 'containerId',
  CreatedAt = 'createdAt',
  ExternalId = 'externalId',
  UpdatedAt = 'updatedAt'
}

export type Sortation = {
  __typename?: 'Sortation';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  mfpId?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  wallDisplayId: Scalars['String']['output'];
  wallExternalId: Scalars['String']['output'];
};

export type SortationConnection = {
  __typename?: 'SortationConnection';
  edges: Array<SortationEdge>;
  pageInfo: PageInfo;
};

export type SortationEdge = {
  __typename?: 'SortationEdge';
  cursor: Scalars['String']['output'];
  node: Sortation;
};

export type StorageLocation = {
  __typename?: 'StorageLocation';
  address: Scalars['String']['output'];
  containerType?: Maybe<ContainerType>;
  containerTypeId?: Maybe<Scalars['String']['output']>;
  containerTypeName?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Coordinates>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  externalAisleId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mapChunkId?: Maybe<Scalars['String']['output']>;
  requiredReach?: Maybe<Scalars['Int']['output']>;
  siteName: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
  z: Scalars['Int']['output'];
};

export type StorageLocationConnection = {
  __typename?: 'StorageLocationConnection';
  edges: Array<StorageLocationEdge>;
  pageInfo: PageInfo;
};

export type StorageLocationEdge = {
  __typename?: 'StorageLocationEdge';
  cursor: Scalars['String']['output'];
  node: StorageLocation;
};

export enum StorageLocationOrderByFields {
  Address = 'address',
  Type = 'type'
}

export enum StorageLocationType {
  Bin = 'bin',
  Cart = 'cart',
  Damaged = 'damaged',
  Disabled = 'disabled',
  Dock = 'dock',
  External = 'external',
  Mfp = 'mfp',
  Pallet = 'pallet',
  Rack = 'rack',
  Reserve = 'reserve',
  Slot = 'slot',
  Staging = 'staging',
  Unknown = 'unknown',
  Warehouse = 'warehouse'
}

export type StorageLocationTypeCount = {
  __typename?: 'StorageLocationTypeCount';
  count: Scalars['Int']['output'];
  type?: Maybe<StorageLocationType>;
};

export type Task = {
  __typename?: 'Task';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  container?: Maybe<Container>;
  doneQuantity: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isShorted: Scalars['Boolean']['output'];
  isUserDirectedProduct: Scalars['Boolean']['output'];
  isUserDirectedSourceLocation: Scalars['Boolean']['output'];
  pickType?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  sourceLocationAddress?: Maybe<Scalars['String']['output']>;
  sourceLocationId?: Maybe<Scalars['String']['output']>;
  takeoffDestination?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  workArea?: Maybe<WorkArea>;
  workAreaId?: Maybe<Scalars['String']['output']>;
};

export type Transfer = {
  __typename?: 'Transfer';
  assetTypeId: Scalars['String']['output'];
  capturedData?: Maybe<Array<CapturedData>>;
  completedAmount: Scalars['Float']['output'];
  exceptions?: Maybe<Array<Scalars['String']['output']>>;
  location: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  authMethod?: Maybe<Scalars['String']['output']>;
  badgeBarcode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  locale: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  roles: Array<UserRole>;
  tags: Array<UserTag>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserActor = {
  __typename?: 'UserActor';
  device?: Maybe<BasicActor>;
  user?: Maybe<BasicActor>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserRole = {
  __typename?: 'UserRole';
  name: Scalars['String']['output'];
};

export type UserTag = {
  __typename?: 'UserTag';
  name: Scalars['String']['output'];
};

export enum ValidationStrategy {
  Any = 'any',
  Contains = 'contains',
  EndsWith = 'endsWith',
  ExactMatch = 'exactMatch',
  LocationUnique = 'locationUnique',
  LocationUniqueStartsWith = 'locationUniqueStartsWith',
  NumericMatch = 'numericMatch',
  Regex = 'regex',
  StartsWith = 'startsWith',
  StripLeadingZeroes = 'stripLeadingZeroes'
}

export type WorkArea = {
  __typename?: 'WorkArea';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum WorkOrderType {
  Ecommerce = 'ecommerce',
  InventoryTransferToMerchant = 'inventoryTransferToMerchant',
  InventoryTransferToNode = 'inventoryTransferToNode'
}
