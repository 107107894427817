import { Card, Page, Pagination, Stack } from '@sixriver/lighthouse-web-community';
import { useArrayQueryState, useQueryState } from '@sixriver/react-support';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PickWavesFilter, PickWavesFilters } from './PickWavesFilters';
import { PickWavesTable } from './PickWavesTable';
import { PickWaveStatus } from '../../api/fulfillment-api/types';
import * as routes from '../../routes';
import {
	GetPickWavesDocument,
	GetPickWavesQuery,
	GetPickWavesQueryVariables,
} from './graphql/GetPickWaves.f-api-graphql';
import { useResolvePickWavesMutation } from './graphql/ResolvePickWaves.f-api-graphql';
import { usePollingQuery } from '../../hooks/usePollingQuery';

export function PickWaves() {
	const history = useHistory();
	const onClickPickWaveId = useCallback(
		(id: string) => {
			history.push(routes.pickWave(id));
		},
		[history],
	);

	const [searchText, setSearchText] = useQueryState<string | undefined>('query', undefined);
	const [statuses, setStatuses] = useArrayQueryState<PickWaveStatus[]>('statuses', []);

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	/**
	 * Pick Waves Query
	 */
	const setFilter = useCallback(
		(filter: PickWavesFilter) => {
			setSearchText(filter.searchText);
			setStatuses(filter.statuses);
		},
		[setSearchText, setStatuses],
	);

	const [getPickWavesQuery, executeGetPickWavesQuery] = usePollingQuery<
		GetPickWavesQuery,
		GetPickWavesQueryVariables
	>({
		query: GetPickWavesDocument,
		variables: {
			after: paginationCursors[0],
			first: 50,
			searchText,
			statuses,
		},
	});

	const pageInfo = useMemo(() => {
		return getPickWavesQuery.data?.pickWaves.pageInfo;
	}, [getPickWavesQuery.data?.pickWaves.pageInfo]);
	const pickWaves = useMemo(() => {
		return getPickWavesQuery.data?.pickWaves.edges?.map((edge) => edge.node) ?? [];
	}, [getPickWavesQuery.data?.pickWaves.edges]);

	/**
	 * Resolve Pick Waves
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, executeResolvePickWaveMutation] = useResolvePickWavesMutation();
	const handleResolvePickWaves = useCallback(
		async (ids: string[], clearSelection: () => void) => {
			const result = await executeResolvePickWaveMutation({ ids });

			if (!result.error) {
				await executeGetPickWavesQuery();
				clearSelection();
			}
		},
		[executeGetPickWavesQuery, executeResolvePickWaveMutation],
	);

	const handleOnNextPage = useCallback(() => {
		const endCursor = pageInfo?.endCursor;

		if (endCursor) {
			setPaginationCursors((prev) => {
				return [endCursor].concat(prev);
			});
		}
	}, [pageInfo]);
	const handleOnPreviousPage = useCallback(() => {
		setPaginationCursors(paginationCursors.slice(1));
	}, [paginationCursors, setPaginationCursors]);

	return (
		<Page fullWidth title="Pick wave log">
			<Card>
				<Card.Section>
					<PickWavesFilters filter={{ searchText, statuses }} onChange={setFilter} />
				</Card.Section>
				<PickWavesTable
					loading={getPickWavesQuery.fetching}
					pickWaves={pickWaves}
					onClickPickWaveId={onClickPickWaveId}
					onResolvePickWaves={handleResolvePickWaves}
				/>
				<Card.Section>
					<Stack distribution="center">
						<Pagination
							hasNext={pageInfo?.hasNextPage}
							hasPrevious={paginationCursors.length > 0}
							onNext={handleOnNextPage}
							onPrevious={handleOnPreviousPage}
						/>
					</Stack>
				</Card.Section>
			</Card>
		</Page>
	);
}
