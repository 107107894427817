import { Layout, Page, Stack } from '@sixriver/lighthouse-web-community';
import { useRouteMatch } from 'react-router-dom';

import { Details } from './Details';
import { Progress } from './Progress';
import { useGetShuttleJobQuery } from './graphql/GetShuttleJob.w-api-graphql';
import { GoalStates } from '../../api/fulfillment-api/types';
import { Error } from '../../components/Error';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useLocalization } from '../../hooks/useLocalization';
import { ShuttleJobStatus } from '../ShuttleJobs/ShuttleJobsTable/ShuttleJobStatus';

export function ShuttleJob() {
	const { messages } = useLocalization();

	const {
		params: { shuttleJobId },
	} = useRouteMatch<{ shuttleJobId: string }>();

	// query logic
	const [getShuttleJobQuery] = useGetShuttleJobQuery({
		variables: {
			id: shuttleJobId,
		},
	});

	const { shuttleJob } = getShuttleJobQuery.data || {};

	if (getShuttleJobQuery.error) {
		return <Error graphQLError={getShuttleJobQuery.error} />;
	}

	if (!getShuttleJobQuery.fetching && !shuttleJob) {
		return <Error heading={messages.shuttleJobNotFound} />;
	}

	return (
		<Page
			title={shuttleJob?.inputs.containerLabels.default || ''}
			titleMetadata={
				<Stack spacing="extraTight">
					<ShuttleJobStatus status={shuttleJob?.state as GoalStates} />
					{/* {numberOfExceptions ? <Badge status="critical">{messages.exception}</Badge> : null} */}
				</Stack>
			}
		>
			<Layout>
				<Layout.Section>
					<Details shuttleJob={shuttleJob} />
				</Layout.Section>
				<Layout.Section>
					<Progress shuttleJob={shuttleJob} />
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
