import { compare, getValueByPointer } from 'fast-json-patch';

export type ObjectDiff = {
	oldValue: unknown;
	newValue: unknown;
	path: string[];
};

export function objectDiff(oldValue?: object | null, newValue?: object | null): ObjectDiff[] {
	oldValue = oldValue ?? {};
	newValue = newValue ?? {};

	const result: ObjectDiff[] = [];
	const changes = compare(oldValue, newValue);

	if (!changes.length) {
		return result;
	}

	for (const diff of changes) {
		if (diff.op !== 'replace' && diff.op !== 'add' && diff.op !== 'remove') {
			continue;
		}

		const path = diff.path;
		const value = 'value' in diff ? diff.value : null;
		result.push({
			newValue: value,
			oldValue: getValueByPointer(oldValue, path) || 'null',
			path: (path.substring(0, 1) === '/' ? path.substring(1) : path).split('/'),
		});
	}

	return result;
}
