import {
	Banner,
	Card,
	Layout,
	Page,
	Stack,
	TextStyle,
	Link,
} from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';
import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useGetContainerQuery } from './graphql/GetContainer.w-api-graphql';
import { ContainerTypeType } from '../../api/warehouse-api/types';
import { CardDetails } from '../../components/CardDetails';
import { Error } from '../../components/Error';
import { NoData } from '../../components/NoData';
import { useExperimentalFlags } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

interface Params {
	containerId: string;
}

export function ContainerDetails() {
	const { lookBusy } = useContext(BusyIndicatorContext);
	const { messages, formatLength, formatVolume, formatWeight } = useLocalization();
	const { isUserAllowed } = useAuth();
	const experimentalFlags = useExperimentalFlags();

	// Routing
	const {
		params: { containerId },
	} = useRouteMatch<Params>();

	// Queries
	const [getContainerQuery] = useGetContainerQuery({
		requestPolicy: 'network-only',
		variables: {
			id: containerId,
		},
	});

	const container = getContainerQuery.data?.getContainer;

	if (lookBusy(getContainerQuery.fetching && !container)) {
		return null;
	}

	if (getContainerQuery.error || !container) {
		return (
			<Error
				graphQLError={getContainerQuery.error}
				message={getContainerQuery.error ? undefined : messages.dataNotFound}
			/>
		);
	}

	// computed
	const numberOfContainerSubsections = (container.columns || 1) * (container.rows || 1);
	const containerHasSubsections = numberOfContainerSubsections > 1;
	const wholeContainerVolume = (container.cubeVolume || 1) * numberOfContainerSubsections;
	const wholeContainerDimensions = {
		height: container.height,
		length: (container.length || 1) * (container.rows || 1),
		width: (container.width || 1) * (container.columns || 1),
	};

	// Render
	return (
		<Page
			title={container?.externalId || ((<NoData />) as unknown as string)}
			subtitle={container ? messages.containerTypes[container.type] : undefined}
			primaryAction={
				experimentalFlags.includes('MANAGE_CONTAINERS') &&
				isUserAllowed([UserRole.Admin, UserRole.EmployeeManager]) ? (
					<Link removeUnderline url={routes.editContainer(container.id)}>
						{messages.edit}
					</Link>
				) : null
			}
		>
			<Layout>
				{/* Container Details */}
				<Layout.Section>
					<Card title={messages.containerDetails} sectioned>
						<CardDetails
							loading={getContainerQuery.fetching}
							primary={[
								{
									content: messages.containerTypes[container?.type] || <NoData />,
									label: messages.containerType,
								},
								{
									content: container.name || <NoData />,
									label: messages.containerName,
								},
								{
									content: container.externalId || <NoData />,
									label: messages.externalId,
								},
								{
									content:
										!container?.description || container?.description === container?.name ? (
											<NoData />
										) : (
											container?.description
										),
									label: messages.description,
								},
								...(container.type === ContainerTypeType.Shipping
									? [
											{
												content: container?.barcode || <NoData />,
												label: messages.barcode,
											},
									  ]
									: []),
								...(container.type === ContainerTypeType.Shipping
									? [
											{
												content: (
													<Stack spacing="extraTight">
														<input type="checkbox" checked={container.enabled} disabled />
														<span>{container.enabled ? messages.active : messages.inactive}</span>
													</Stack>
												),
												label: messages.status,
											},
											{
												content: (
													<Stack spacing="extraTight">
														<input type="checkbox" checked={container.inventory} disabled />
														<span>
															{container.inventory ? messages.enabled : messages.disabled}
														</span>
													</Stack>
												),
												label: messages.trackAsInventory,
											},
									  ]
									: []),
								{
									content: container.attributes?.packageMaterialType,
									label: messages.materialType,
								},
							]}
						/>
						{container.inventory ? (
							<>
								<br />
								<Banner
									secondaryAction={{
										content: messages.viewInventoryLevels,
										url: routes.product(container.id),
									}}
									status="info"
								>
									<p>{messages.containerTracked}</p>
								</Banner>
							</>
						) : null}
					</Card>
				</Layout.Section>

				{/* Dimensions */}
				<Layout.Section secondary>
					<Card title={messages.containerDimensions}>
						<Card.Section
							title={
								containerHasSubsections ? (
									<TextStyle variation="strong">{messages.wholeContainer}</TextStyle>
								) : null
							}
						>
							<CardDetails
								primary={[
									{
										content: formatLength(wholeContainerDimensions.length),
										label: messages.length,
									},
									{
										content: formatLength(wholeContainerDimensions.width),
										label: messages.width,
									},
									{
										content: formatLength(wholeContainerDimensions.height || 0),
										label: messages.height,
									},
									{
										content: formatVolume(wholeContainerVolume),
										label: messages.volume,
									},
									...(container?.type === ContainerTypeType.Shipping
										? [
												{
													content:
														(container?.weight || 0) > 0 ? (
															formatWeight(container?.weight as number)
														) : (
															<NoData />
														),
													label: messages.weight,
												},
										  ]
										: []),
								]}
							/>
						</Card.Section>

						{/* Container subsections info */}
						{containerHasSubsections ? (
							<>
								<hr style={{ width: '100%' }} />
								<Card.Section
									title={<TextStyle variation="strong">{messages.subsection}</TextStyle>}
								>
									<CardDetails
										primary={[
											{
												content: container?.columns || <NoData />,
												label: messages.columns,
											},
											{
												content: container?.rows || <NoData />,
												label: messages.rows,
											},
										]}
									/>
								</Card.Section>
								<hr />
								<Card.Section
									title={<TextStyle variation="strong">{messages.subsectionDimensions}</TextStyle>}
								>
									<CardDetails
										primary={[
											{
												content: formatLength(container?.length || 0),
												label: messages.length,
											},
											{
												content: formatLength(container?.width || 0),
												label: messages.width,
											},
											{
												content: formatLength(container?.height || 0),
												label: messages.height,
											},
											{
												content: formatVolume(container?.cubeVolume || 0),
												label: messages.volume,
											},
										]}
									/>
								</Card.Section>
							</>
						) : null}
					</Card>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
