// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetMapPointConfigsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMapPointConfigsQuery = { __typename?: 'Query', mapPointConfigs: Array<{ __typename?: 'MapPointConfig', mapPointName: string, enabled: boolean, maxReservationCount: number }> };


export const GetMapPointConfigsDocument = gql`
    query fapi_GetMapPointConfigs {
  mapPointConfigs {
    mapPointName
    enabled
    maxReservationCount
  }
}
    `;

export function useGetMapPointConfigsQuery(options?: Omit<Urql.UseQueryArgs<GetMapPointConfigsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMapPointConfigsQuery, GetMapPointConfigsQueryVariables>({ query: GetMapPointConfigsDocument, ...options });
};