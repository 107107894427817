// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StartManualHealPlanMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type StartManualHealPlanMutation = { __typename?: 'Mutation', startManualHealPlan: { __typename?: 'MutationResponse', success: boolean } };


export const StartManualHealPlanDocument = gql`
    mutation fapi_StartManualHealPlan($id: String!) {
  startManualHealPlan(id: $id) {
    success
  }
}
    `;

export function useStartManualHealPlanMutation() {
  return Urql.useMutation<StartManualHealPlanMutation, StartManualHealPlanMutationVariables>(StartManualHealPlanDocument);
};