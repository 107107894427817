import { DateInterval } from '@hyper-fetch/core';
import { MfpConfig, ModelConfig, ServiceConfig, SiteConfig } from '@sixriver/config-schema';

import { client } from './client';

export type MfpInternalConfig = MfpConfig['config'] & {
	deviceClass: 'handheld' | 'packoutTerminal' | 'sortationKiosk' | 'terminalOnWheels' | 'chuck';
};

export type MfpConfigExtended = MfpConfig & {
	config?: MfpInternalConfig;
};

export const getMfpConfigs = client.createRequest<MfpConfigExtended[]>()({
	cacheTime: DateInterval.hour,
	endpoint: `/grm/MfpConfigs`,
	method: 'GET',
});

export const getNamespaceReady = client.createRequest<{ isUpdatePending: boolean }>()({
	cacheTime: 10 * DateInterval.second,
	endpoint: '/cfs/grm/v1/cloudConfigUpdateStatus',
	method: 'GET',
});

export const getMfpConfig = client.createRequest<MfpConfigExtended>()({
	cacheTime: DateInterval.hour,
	endpoint: `/cfs/grm/v1/MfpConfigs/findOne`,
	method: 'GET',
});

export const getServiceConfig = client.createRequest<{ config: ServiceConfig }>()({
	cacheTime: DateInterval.hour,
	endpoint: `/grm/ServiceConfig`,
	method: 'GET',
});

export const getSiteConfig = client.createRequest<SiteConfig>()({
	cacheTime: DateInterval.hour,
	endpoint: `/grm/Site`,
	method: 'GET',
});

export const getModelConfigs = client.createRequest<ModelConfig[]>()({
	cacheTime: DateInterval.hour,
	endpoint: `/cfs/grm/v1/ModelConfigs`,
	method: 'GET',
});
