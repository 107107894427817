import { TextStyle } from '@sixriver/lighthouse-web-community';

import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';

interface TotalLinesProps {
	pickWave: PickWaveFieldsFragment;
}

export function TotalLines({ pickWave }: TotalLinesProps) {
	return <TextStyle>{pickWave.totalLinesCount}</TextStyle>;
}
