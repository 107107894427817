import { IndexTable, Stack } from '@sixriver/lighthouse-web-community';

import { Count } from '../../../api/fulfillment-api/types';
import { OrderQuantityV2 } from '../../../components/OrderQuantityV2';
import { GetOrdersLineQuantityFragment } from '../graphql/GetOrders.w-api-graphql';

interface QuantityCellProps {
	totalLines: Count;
	totalLineQuantities: GetOrdersLineQuantityFragment;
}

export function QuantityCell({ totalLines, totalLineQuantities }: QuantityCellProps) {
	return (
		<IndexTable.Cell>
			<Stack vertical spacing="extraTight">
				<OrderQuantityV2 totalLines={totalLines} totalLineQuantities={totalLineQuantities} />
			</Stack>
		</IndexTable.Cell>
	);
}
