import { JobStatus } from '../../api/warehouse-api/types';
import { GetJobSortationJobFragment } from '../../pages/OutboundJob/graphql/GetJob.w-api-graphql';

export const useSortationJobsCount = (sortWall?: GetJobSortationJobFragment) => {
	const count = sortWall?.pickingJobs?.length;

	return count && sortWall?.status === JobStatus.Picking
		? count + '+'
		: count &&
		  (sortWall?.status === JobStatus.Picked ||
				sortWall?.status === JobStatus.Sorting ||
				// LOL-3313
				// || sortWall?.status === JobStatus.Exception)
				sortWall?.status === JobStatus.Sorted)
		? count
		: undefined;
};
