import { Layout } from '@sixriver/lighthouse-web-community';
import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { LocationInfo } from './LocationInfoV2';
import {
	GetStorageLocationDocument,
	GetStorageLocationQuery,
	GetStorageLocationQueryVariables,
} from './graphql/GetStorageLocation.w-api-graphql';
import { StorageLocationType } from '../../api/warehouse-api/types';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { Error } from '../../components/Error';
import { useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

export function Location() {
	const { messages } = useLocalization();
	const { lookBusy } = useContext(BusyIndicatorContext);
	const isWorkAreaEnabled = useIsWorkAreasEnabled();

	// Routing
	const {
		params: { locationId },
	} = useRouteMatch<{ locationId: string }>();

	// Queries
	const [getStorageLocationQuery] = usePollingQuery<
		GetStorageLocationQuery,
		GetStorageLocationQueryVariables
	>({
		query: GetStorageLocationDocument,
		variables: {
			id: locationId,
		},
	});

	const location = getStorageLocationQuery.data?.storageLocation;

	if (lookBusy(getStorageLocationQuery.fetching && !location)) {
		return null;
	}

	if (getStorageLocationQuery.error || !location) {
		return (
			<Error
				graphQLError={getStorageLocationQuery.error}
				message={getStorageLocationQuery.error ? undefined : messages.locationNotFound}
			/>
		);
	}

	// Render
	return (
		<>
			<AutoRefreshPage
				queries={[getStorageLocationQuery]}
				fullWidth
				title={location.address}
				subtitle={
					location.type ? messages.locationTypes[location.type as StorageLocationType] : '—'
				}
				breadcrumbs={[
					{
						content: messages.allLocations,
						url: routes.locations(),
					},
				]}
			>
				<Layout>
					<Layout.Section secondary>
						<LocationInfo location={location} isWorkAreaEnabled={isWorkAreaEnabled} />
					</Layout.Section>
				</Layout>
			</AutoRefreshPage>
		</>
	);
}
