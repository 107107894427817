import { Layout, Page, Card, Tag, TextStyle } from '@sixriver/lighthouse-web-community';
import { useRouteMatch } from 'react-router-dom';

import { ContainerSummaryCard } from './ContainerSummaryCard';
import { Error } from '../../components/Error';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';
import { LocationInventory } from '../Locations/LocationInventory';
import { useGetDetailedLocationQuery } from '../Locations/graphql/GetDetailedLocation.f-api-graphql';

interface RouteMatchParams {
	locationId: string;
	containerId: string;
}

export function NestedContainer() {
	const { messages, translate } = useLocalization();

	// Routing
	const {
		params: { locationId, containerId },
	} = useRouteMatch<RouteMatchParams>();

	// Queries
	const [getDetailedLocationQuery] = useGetDetailedLocationQuery({
		variables: {
			id: locationId,
		},
	});

	if (getDetailedLocationQuery.error) {
		return <Error graphQLError={getDetailedLocationQuery.error} />;
	}
	const location = getDetailedLocationQuery.data?.location;
	if (location) {
		location.products = location.products.filter((product) => {
			return product.inventory.subLocationDescriptorId === containerId;
		});
	}
	const externalId = location?.products[0]?.inventory.subLocation?.definition?.externalId || '';
	const numSKUs = new Set(location?.products.map((product) => product.inventory.assetTypeId)).size;

	if (getDetailedLocationQuery.fetching) {
		return null;
	}

	if (!location) {
		return <Error message={messages.locationNotFound} />;
	}

	// Render
	return (
		<>
			{/* TODO: breadcrumbs widget */}
			<Page fullWidth>
				<Layout>
					<Layout.Section>
						<Tag url={routes.locations()}>{messages.allLocations}</Tag>
						<TextStyle variation="subdued"> &#62; </TextStyle>
						<Tag url={routes.location(location.id)}>{location.address}</Tag>
						<TextStyle variation="subdued"> &#62; </TextStyle>
						{externalId}
					</Layout.Section>
				</Layout>
			</Page>
			<Page
				fullWidth
				title={externalId}
				subtitle={translate(messages.nestedContainers, { count: 1 }) as string}
			>
				<Layout>
					<Layout.Section>
						<LocationInventory location={location} containerId={containerId} />
						{
							/* TODO: support multi level nested sub locations */
							<Card title={translate(messages.nestedContainers, {})}>
								<Card.Section subdued>{messages.noContainers}</Card.Section>
							</Card>
						}
						{
							<Card title={messages.containerHistory}>
								<Card.Section subdued>{messages.containerHistoryContent}</Card.Section>
							</Card>
						}
					</Layout.Section>
					<Layout.Section secondary>
						<ContainerSummaryCard
							parentName={location.address}
							numSKUs={numSKUs}
							locationId={location.id}
						/>
					</Layout.Section>
				</Layout>
			</Page>
		</>
	);
}
