import {
	CircleMinusOutlineMinor,
	CircleDotsMajor,
	CircleTickMinor,
	CircleCancelMinor,
} from '@sixriver/lighthouse-icons';
import { Icon, IconProps, IconSource, Text } from '@sixriver/lighthouse-web-community';

import { ItemStatus } from './Progress';

interface StepProps {
	label: string;
	status: Exclude<ItemStatus, 'ignore'>;
}

function getStatusIcon(status: Exclude<ItemStatus, 'ignore'>): {
	source: IconSource;
	color: IconProps['color'];
} {
	switch (status) {
		case 'unstarted':
			return {
				color: 'subdued',
				source: CircleMinusOutlineMinor,
			};
		case 'started':
			return {
				color: 'base',
				source: CircleDotsMajor,
			};
		case 'completed':
			return {
				color: 'success',
				source: CircleTickMinor,
			};
		case 'failed':
			return {
				color: 'critical',
				source: CircleCancelMinor,
			};
	}
}

export function Step({ label, status }: StepProps) {
	return (
		<li>
			<Icon {...getStatusIcon(status)} />{' '}
			<Text as="span" variant="bodyMd">
				{label}
			</Text>
		</li>
	);
}
