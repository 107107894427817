import { ChoiceList } from '@sixriver/lighthouse-web-community';
import { noop } from 'lodash';

import { PickStrategy } from '../../../api/warehouse-api/types';
import { useLocalization } from '../../../hooks/useLocalization';
import { usePickStrategyName } from '../../../hooks/usePickStrategies';

interface PickStrategyFilterProps {
	selected?: PickStrategy[];
	options?: PickStrategy[];
	onChange?: (selected: PickStrategy[]) => void;
}

export function PickStrategyFilter({
	selected = [],
	options = [],
	onChange = noop,
}: PickStrategyFilterProps) {
	const { messages } = useLocalization();
	const getPickStrategyName = usePickStrategyName();

	return (
		<ChoiceList
			title={messages.pickStrategy}
			titleHidden
			choices={options.map((strategy) => ({
				label: getPickStrategyName(strategy),
				value: strategy,
			}))}
			selected={selected}
			onChange={onChange}
			allowMultiple
		/>
	);
}
