export function computeDurationMs(
	start: Date | string | undefined | null,
	end: Date | string | undefined | null,
): number | undefined {
	if (!start || !end) {
		return undefined;
	}

	start = new Date(start);
	end = new Date(end);

	return end.getTime() - start.getTime();
}
