import { Link, TextStyle } from '@sixriver/lighthouse-web-community';

import { useGetDevicesOrdersQuery } from './graphql/GetDevicesOrders.w-api-graphql';
import { AvailableMfp } from '../../api';
import * as routes from '../../routes';

interface RelatedOrdersProps {
	device: AvailableMfp;
}

export default function RelatedOrders({ device }: RelatedOrdersProps): JSX.Element {
	const ids = device.workspaceReservations.map(
		(workspaceReservation) => workspaceReservation.jobId,
	);
	const [getDevicesOrdersQuery] = useGetDevicesOrdersQuery({
		pause: ids.length === 0,
		variables: {
			ids,
		},
	});

	if (getDevicesOrdersQuery.data) {
		const orderIds = getDevicesOrdersQuery.data.jobs.edges.reduce<Set<string>>((orderIds, edge) => {
			edge.node.orders.forEach((order) => {
				orderIds.add(order.id);
			});
			return orderIds;
		}, new Set());

		if (orderIds.size > 0) {
			const params = new URLSearchParams();
			orderIds.forEach((orderId) => {
				params.append('ids', orderId);
			});
			const url = `${routes.orders()}?${params.toString()}`;
			return (
				<Link url={url} removeUnderline>
					{orderIds.size}
				</Link>
			);
		}
	}

	return <TextStyle variation="subdued">0</TextStyle>;
}
