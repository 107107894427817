import * as Sentry from '@sentry/react';
import { components } from '@sixriver/audit-log-oas-schema';
import { formatDate } from '@sixriver/react-support';

import { OrderByDirection } from '../../api/fulfillment-api/types';

type PaginatedAuditLogList = components['schemas']['PaginatedAuditLogList'];
type AuditLogRecord = components['schemas']['AuditLogRecord'];

export function getLabel(key: string, value: string[], local: string): string {
	switch (key) {
		case 'operationType':
			return value[0];
		case 'timestampPeriod': {
			return [formatDate(local, value[0]), formatDate(local, value[1])].join('-');
		}
		default:
			return value.toString();
	}
}

export function sortTimestamp(results: AuditLogRecord[], direction: OrderByDirection | undefined) {
	return results.sort((a: AuditLogRecord, b: AuditLogRecord) => {
		const timestampA = Date.parse(a.timestamp);
		const timestampB = Date.parse(b.timestamp);
		return direction === OrderByDirection.Asc ? timestampA - timestampB : timestampB - timestampA;
	});
}

export type FilterDataProps = {
	data: PaginatedAuditLogList;
	query: string;

	defaultEntityTypeValue: string;
};

export function filterData({
	data,
	query,
	defaultEntityTypeValue,
}: FilterDataProps): AuditLogRecord[] {
	return data.results?.filter((log: AuditLogRecord) => {
		let oldValue;
		try {
			oldValue = log.oldValue ? JSON.stringify(log.oldValue) : null;
		} catch (error) {
			oldValue = null;
		}
		let newValue;
		try {
			newValue = log.newValue ? JSON.stringify(log.newValue) : null;
		} catch (error) {
			newValue = null;
		}
		const entityType = formatMetadataValue(log.metadata.entityType, defaultEntityTypeValue);
		const matchesQuery =
			query === '' ||
			log.userId?.toLowerCase().includes(query.toLowerCase()) ||
			log.eventType?.toLowerCase().includes(query.toLowerCase()) ||
			log.timestamp?.toString().toLowerCase().includes(query.toLowerCase()) ||
			entityType.toLowerCase().includes(query.toLowerCase()) ||
			oldValue?.toLowerCase().includes(query.toLowerCase()) ||
			newValue?.toLowerCase().includes(query.toLowerCase());

		return matchesQuery;
	});
}

export function formatMetadataValue(value: unknown, defaultValue: string): string {
	if (!value) {
		return defaultValue;
	}

	if (typeof value === 'string') {
		return value;
	}

	if (typeof value === 'object') {
		try {
			return JSON.stringify(value, null, 2);
		} catch (err) {
			Sentry.captureException({
				err,
				reason: 'parsing audit log metadata value failed',
			});

			// fallback to a generic value
		}
	}

	return value.toString();
}
