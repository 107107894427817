import { Card, Button, Link } from '@sixriver/lighthouse-web-community';

import * as routes from '../../routes';
import { GetShuttleAvailableDestinationQuery } from './graphql/GetShuttleAvailableDestination.w-api-graphql';
import { CardDetails } from '../../components/CardDetails';
import { CopyButton } from '../../components/CopyButton';
import { DateTime } from '../../components/DateTime';
import { NoData } from '../../components/NoData';
import { RelativeDateTime } from '../../components/RelativeDateTime';
import { getMfpUrl } from '../../helpers/mfp';
import { useLocalization } from '../../hooks/useLocalization';

export function Details({ shuttleAvailableDestination }: GetShuttleAvailableDestinationQuery) {
	const { messages } = useLocalization();

	const device = shuttleAvailableDestination?.currentActor?.device;

	const deviceInfo: { deviceId: string; deviceType: string } | undefined =
		device?.type === 'Mfp'
			? {
					deviceId: device.id,
					deviceType: 'mfp',
			  }
			: undefined;

	const uniqueChuck = deviceInfo?.deviceId;

	return (
		<Card title={messages.details}>
			<Card.Section>
				<CardDetails
					primary={[
						{
							// TODO: link
							content: shuttleAvailableDestination?.inputs.storageLocationAddress || <NoData />,
							label: messages.destinationLocation,
						},
					]}
					secondary={[
						{
							content: (
								<>
									<DateTime date={shuttleAvailableDestination?.createdAt} />
									<RelativeDateTime date={shuttleAvailableDestination?.createdAt} />
								</>
							),
							label: messages.createdAt,
						},
						{
							content: <DateTime date={shuttleAvailableDestination?.lastEventPersistedAt} />,
							label: messages.updatedAt,
						},
						{
							content: <DateTime date={shuttleAvailableDestination?.endedAt} />,
							label: messages.completedAt,
						},
					]}
				/>
			</Card.Section>
			<Card.Section>
				<CardDetails
					primary={[
						{
							content: uniqueChuck || <NoData />,
							label: messages.chuck,
						},
					]}
				/>
				<br />
				{/* TODO: account for finding multiple devices */}
				<Button
					plain
					url={getMfpUrl(uniqueChuck && uniqueChuck[0])}
					disabled={!uniqueChuck?.length}
				>
					{messages.findThisChuck}
				</Button>
			</Card.Section>
			<Card.Section>
				<CardDetails
					primary={[
						{
							content: shuttleAvailableDestination?.id ? (
								<CopyButton text={shuttleAvailableDestination.id} />
							) : null,
							label: messages.uniqueJobId,
						},
					]}
				/>
			</Card.Section>
			<Card.Section>
				<CardDetails
					primary={[
						{
							// TODO: add badge for dropoff status?
							content: shuttleAvailableDestination?.outputs.activeTask ? (
								<Link url={routes.shuttleJob(shuttleAvailableDestination.outputs.activeTask)}>
									{shuttleAvailableDestination.outputs.activeTask}
								</Link>
							) : (
								<NoData />
							),
							label: messages.shuttleJob,
						},
						// TODO: find paired destination and link to that?
					]}
				/>
			</Card.Section>
		</Card>
	);
}
