// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetExceptionOrderQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetExceptionOrderQuery = { __typename?: 'Query', job?: { __typename?: 'Job', id: string, externalId: string, expectedShipDate?: any | null, externalContainerId?: string | null, createdAt: any, isShorted: boolean, isHealable: boolean, lines: { __typename?: 'LineConnection', edges: Array<{ __typename?: 'LineEdge', node: { __typename?: 'Line', quantity: number, doneQuantity: number } }> }, tasks?: Array<{ __typename?: 'Task', workArea?: { __typename?: 'WorkArea', id: string, name?: string | null } | null }> | null } | null };


export const GetExceptionOrderDocument = gql`
    query wapi_GetExceptionOrder($id: String!) {
  job(id: $id) {
    id
    externalId
    expectedShipDate
    externalContainerId
    createdAt
    isShorted
    isHealable
    lines {
      edges {
        node {
          quantity
          doneQuantity
        }
      }
    }
    tasks {
      workArea {
        id
        name
      }
    }
  }
}
    `;

export function useGetExceptionOrderQuery(options: Omit<Urql.UseQueryArgs<GetExceptionOrderQueryVariables>, 'query'>) {
  return Urql.useQuery<GetExceptionOrderQuery, GetExceptionOrderQueryVariables>({ query: GetExceptionOrderDocument, ...options });
};