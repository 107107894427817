// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetLocationsSummaryQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetLocationsSummaryQuery = { __typename?: 'Query', locations: { __typename?: 'StorageLocationSummaryPage', results: Array<{ __typename?: 'StorageLocationSummary', address: string }> } };


export const GetLocationsSummaryDocument = gql`
    query fapi_GetLocationsSummary($searchText: String) {
  locations(limit: 50, searchText: $searchText) {
    results {
      address
    }
  }
}
    `;

export function useGetLocationsSummaryQuery(options?: Omit<Urql.UseQueryArgs<GetLocationsSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLocationsSummaryQuery, GetLocationsSummaryQueryVariables>({ query: GetLocationsSummaryDocument, ...options });
};