import { components } from '@sixriver/audit-log-oas-schema';
import {
	AppliedFilterInterface,
	FilterInterface,
	TableData,
} from '@sixriver/lighthouse-web-community';
import { NoData } from '@sixriver/react-support';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { filterData, formatMetadataValue, sortTimestamp } from './auditLogsUtils';
import { OrderByDirection } from '../../api/fulfillment-api/types';
import { Column, DataTable } from '../../components/DataTable';
import styles from '../../components/DataTable/DataTable.module.css';
import { ObjectDiff } from '../../components/ObjectDiff/ObjectDiff';
import { formatValue, isExpandable } from '../../components/ObjectDiff/helpers';
import { Param } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { formatDateTime } from '../../utils/date';
import { objectDiff } from '../../utils/object-diff';

type AuditLogRecord = components['schemas']['AuditLogRecord'];
type PaginatedAuditLogList = components['schemas']['PaginatedAuditLogList'];

type AuditLogsTableProps = {
	fetching: boolean;
	data: PaginatedAuditLogList | undefined;
	appliedFilters: AppliedFilterInterface[];
	filters: FilterInterface[];
	query: string;
	setFilters: (params: Param[]) => void;
	cursors: string[];
	setCursors: React.Dispatch<React.SetStateAction<string[]>>;
	sort: string;
};

export function AuditLogsTable({
	fetching,
	data,
	appliedFilters,
	filters,
	query,
	setFilters,
	cursors,
	setCursors,
	sort,
}: AuditLogsTableProps) {
	const { messages, locale } = useLocalization();

	const [filteredData, setFilteredData] = useState<PaginatedAuditLogList | null>(null);

	const columns: Column[] = [
		{
			heading: messages.operation,
			type: 'text',
		},
		{
			heading: messages.timestamp,
			type: 'text',
		},
		{
			heading: messages.user,
			type: 'text',
		},
		{
			heading: messages.entityType,
			type: 'text',
		},
		{
			heading: messages.change,
			type: 'text',
		},
		{
			heading: '',
			type: 'text',
		},
	];

	const sortChoices = [
		{
			label: messages.sortOptions.timestampAsc,
			value: 'Timestamp' + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.timestampDsc,
			value: 'Timestamp' + ' ' + OrderByDirection.Desc,
		},
	];

	const defaultEntityTypeValue = messages.configurationType;

	const [, orderByDirection] = sort
		? (sort.split(' ') as ['Timestamp', OrderByDirection])
		: [undefined, undefined];

	useEffect(() => {
		if (!data) {
			return;
		}
		let filteredResults = filterData({
			data,
			defaultEntityTypeValue,
			query,
		});

		filteredResults = sortTimestamp(filteredResults, orderByDirection);

		setFilteredData({
			...data,
			results: filteredResults || [],
		});
	}, [data, sort, defaultEntityTypeValue, orderByDirection, query]);

	const rows: TableData[][] = (filteredData?.results || data?.results || []).map(
		(log: AuditLogRecord) => {
			const diff = objectDiff(log.oldValue, log.newValue);

			const row = [
				log.eventType ?? <NoData />,
				formatDateTime(locale, log.timestamp) ?? <NoData />,
				log.userId ?? <NoData />,
				formatMetadataValue(log.metadata.entityType, defaultEntityTypeValue),
				diff.length ? (
					diff.map((d) => {
						const path = d.path.join(' > ');
						const cls = clsx(styles.ConfigDiffObjectPath, {
							[styles.ValueExpandable]:
								isExpandable(formatValue(d.oldValue)) || isExpandable(formatValue(d.newValue)),
						});
						return (
							<div key={path} className={cls}>
								{path}
							</div>
						);
					})
				) : (
					<NoData />
				),
				diff.length ? <ObjectDiff diffs={diff} /> : <NoData />,
			];

			return row;
		},
	);

	return (
		<DataTable
			loading={fetching}
			appliedFilters={appliedFilters}
			columns={columns}
			emptyStateHeading={messages.auditLogsNotFound}
			filters={filters}
			query={query}
			queryPlaceholder={messages.filterAuditLogs}
			rows={rows}
			setFilters={setFilters}
			sortChoices={sortChoices}
			sortValue={sort}
			pageInfo={{
				endCursor: data?.meta.cursor !== null ? data?.meta.cursor : undefined,
				hasNextPage: data?.meta.cursor !== null,
			}}
			paginationCursors={cursors}
			setPaginationCursors={setCursors}
		/>
	);
}
