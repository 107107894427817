import { GoalStates } from '../../api/fulfillment-api/types';

export enum PutawayJobsViews {
	All = 'All',
	Unassigned = 'Unassigned',
	InProgress = 'In progress',
	Interrupted = 'Interrupted',
	Completed = 'Completed',
	Cancelled = 'Cancelled',
}

export const PutawayJobsViewsViewStateMap: {
	[key in PutawayJobsViews]: GoalStates[];
} = {
	[PutawayJobsViews.All]: [],
	[PutawayJobsViews.Unassigned]: [GoalStates.Creating, GoalStates.Created, GoalStates.Ready],
	[PutawayJobsViews.Interrupted]: [GoalStates.Paused],
	[PutawayJobsViews.InProgress]: [
		GoalStates.Running,
		GoalStates.TerminateRequested,
		GoalStates.Terminating,
		GoalStates.CancelRequested,
		GoalStates.Cancelling,
	],
	[PutawayJobsViews.Completed]: [GoalStates.Complete],
	[PutawayJobsViews.Cancelled]: [GoalStates.Cancelled, GoalStates.Terminated],
};
