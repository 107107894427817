import { Badge } from '@sixriver/lighthouse-web-community';

import { OrderExceptionStatus } from '../../../api/fulfillment-api/types';
import { NoData } from '../../../components/NoData';
import { useIsHealingEnabled } from '../../../hooks/useConfig';
import { useLocalization } from '../../../hooks/useLocalization';

interface Props {
	status?: OrderExceptionStatus | null;
}

export function ExceptionBadge({ status }: Props) {
	const { messages } = useLocalization();
	const isHealingEnabled = useIsHealingEnabled();

	if (status === OrderExceptionStatus.Cleared) {
		return (
			<Badge status="success">
				{isHealingEnabled ? messages.cleared : messages.manuallyResolved}
			</Badge>
		);
	}

	if (status === OrderExceptionStatus.Canceled) {
		return <Badge>{messages.canceled}</Badge>;
	}

	if (status === OrderExceptionStatus.Resolvable) {
		return <Badge status="warning">{messages.resolvable}</Badge>;
	}

	if (status === OrderExceptionStatus.Unresolvable) {
		return <Badge status="critical">{messages.unresolvable}</Badge>;
	}

	if (status === OrderExceptionStatus.Resolving) {
		return <Badge status="info">{messages.resolving}</Badge>;
	}

	return <NoData />;
}
