import { useState } from 'react';

import { useCancelOrderMutation } from './graphql/CancelOrder.f-api-graphql';

export const useCancelOrder: any = ({
	orderId,
	onSuccessfulCancel,
	onError,
	onBefore,
}: {
	orderId: string;
	onSuccessfulCancel?: () => void;
	onError?: () => void;
	onBefore?: () => void;
}) => {
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

	const closeCancelModal = () => {
		setIsCancelModalOpen(false);
	};

	const openCancelModal = () => {
		setIsCancelModalOpen(true);
	};

	const [{ fetching: cancelOrderLoading, error: cancelOrderError }, cancelOrderMutation] =
		useCancelOrderMutation();

	const cancelOrder = async () => {
		try {
			if (onBefore) {
				onBefore();
			}

			const { error } = await cancelOrderMutation({ id: orderId });
			closeCancelModal();

			if (error) {
				throw error;
			}

			if (onSuccessfulCancel) {
				onSuccessfulCancel();
			}
		} catch (e) {
			if (onError) {
				onError();
			}
		}
	};

	return {
		cancelOrder,
		cancelOrderError,
		cancelOrderLoading,
		closeCancelModal,
		isCancelModalOpen,
		openCancelModal,
	};
};
