// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetJobCountsQueryVariables = Types.Exact<{
  mfpIds?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  createdAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statuses?: Types.InputMaybe<Array<Types.JobStatus> | Types.JobStatus>;
  isLate?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isShorted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isAwaitingInventory?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isHealJob?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  completedAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  completedAtTo?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  expectedShipDateFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  expectedShipDateTo?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  pickStrategies?: Types.InputMaybe<Array<Types.PickStrategy> | Types.PickStrategy>;
  orderBy?: Types.InputMaybe<Array<Types.JobOrderBy> | Types.JobOrderBy>;
  workOrderTypes?: Types.InputMaybe<Array<Types.WorkOrderType> | Types.WorkOrderType>;
  workAreaIds?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  usedEquipmentTypes?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  isOnChuck?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetJobCountsQuery = { __typename?: 'Query', jobs: { __typename?: 'JobConnection', edges: Array<{ __typename?: 'JobEdge', node: { __typename?: 'Job', status: Types.JobStatus, isLate: boolean, totalLines: { __typename?: 'Count', count: number }, totalLineQuantities: { __typename?: 'LineQuantities', completedLines: number, totalUnits: number, pickedUnits: number, inProgressUnits: number } } }> } };


export const GetJobCountsDocument = gql`
    query wapi_GetJobCounts($mfpIds: [String!], $createdAtFrom: DateTime, $after: String, $first: Int, $ids: [String!], $searchText: String, $statuses: [JobStatus!], $isLate: Boolean, $isShorted: Boolean, $isAwaitingInventory: Boolean, $isHealJob: Boolean, $completedAtFrom: DateTime, $completedAtTo: DateTime, $expectedShipDateFrom: DateTime, $expectedShipDateTo: DateTime, $pickStrategies: [PickStrategy!], $orderBy: [JobOrderBy!], $workOrderTypes: [WorkOrderType!], $workAreaIds: [String!], $usedEquipmentTypes: [String!], $isOnChuck: Boolean) {
  jobs(
    mfpIds: $mfpIds
    after: $after
    first: $first
    ids: $ids
    searchText: $searchText
    statuses: $statuses
    isLate: $isLate
    isShorted: $isShorted
    isAwaitingInventory: $isAwaitingInventory
    isHealJob: $isHealJob
    completedAtFrom: $completedAtFrom
    completedAtTo: $completedAtTo
    expectedShipDateFrom: $expectedShipDateFrom
    expectedShipDateTo: $expectedShipDateTo
    createdAtFrom: $createdAtFrom
    pickStrategies: $pickStrategies
    orderBy: $orderBy
    workOrderTypes: $workOrderTypes
    workAreaIds: $workAreaIds
    isOnChuck: $isOnChuck
    usedEquipmentTypes: $usedEquipmentTypes
  ) {
    edges {
      node {
        status
        isLate
        totalLines {
          count
        }
        totalLineQuantities {
          completedLines
          totalUnits
          pickedUnits
          inProgressUnits
        }
      }
    }
  }
}
    `;

export function useGetJobCountsQuery(options?: Omit<Urql.UseQueryArgs<GetJobCountsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetJobCountsQuery, GetJobCountsQueryVariables>({ query: GetJobCountsDocument, ...options });
};