import {
	IndexTable,
	Pagination,
	Scrollable,
	Select,
	TextField,
} from '@sixriver/lighthouse-web-community';
import { DateTime } from '@sixriver/react-support';
import clsx from 'clsx';

import { AttemptFilters } from './AttemptFilters';
import { AttemptStatusIcon } from './AttemptStatusIcon';
import styles from './AttemptTable.module.css';
import tableStyles from './Table.module.css';
import { DeliveryAttemptFragment } from './graphql/GetDeliveryAttempts.edge-graphql';
import { computeDurationMs } from './util';
import {
	HookTransportType,
	HttpMessageDeliveryAttemptData,
	MessageDeliveryAttemptFilter,
	SortDirection,
} from '../../api/edge/types';

export interface AttemptTableData {
	data: DeliveryAttemptFragment[];
	pageInfo: {
		hasPreviousPage?: boolean;
		hasNextPage?: boolean;
		endCursor?: string | null;
		startCursor?: string | null;
	};
}

export interface AttemptTableProps {
	style?: React.CSSProperties;
	className?: string;

	tableData: AttemptTableData;

	onNext: () => void;
	onPrevious: () => void;

	filter: MessageDeliveryAttemptFilter | undefined;
	onFilterChanged: (value: MessageDeliveryAttemptFilter | undefined) => void;

	sortDirection: SortDirection;
	onSortDirectionChanged: (value: SortDirection) => void;

	loading: boolean;
}

export function AttemptTable(props: AttemptTableProps): JSX.Element {
	const { data, pageInfo } = props.tableData;

	const rowsMarkup = () => {
		return data?.map((attempt, index: number) => (
			<IndexTable.Row id={attempt.id} key={attempt.id} selected={false} position={index}>
				<IndexTable.Cell className={tableStyles.tableCellContainer} flush>
					<AttemptStatusIcon sendResult={attempt.sendResult} />
				</IndexTable.Cell>
				<IndexTable.Cell className={tableStyles.tableCellContainer}>
					{attempt.data?.type === HookTransportType.Http
						? (attempt.data as HttpMessageDeliveryAttemptData | undefined)?.status
						: undefined}
				</IndexTable.Cell>
				<IndexTable.Cell className={tableStyles.tableCellContainer}>
					{attempt.requestError}
				</IndexTable.Cell>
				<IndexTable.Cell className={tableStyles.tableCellContainer}>
					{attempt.sendResult}
				</IndexTable.Cell>
				<IndexTable.Cell className={tableStyles.tableCellContainer}>
					<DateTime date={attempt.startedAt} includeSecondsAndMilliseconds />
				</IndexTable.Cell>
				<IndexTable.Cell className={tableStyles.tableCellContainer}>{`${computeDurationMs(
					attempt.startedAt,
					attempt.endedAt,
				)} ms`}</IndexTable.Cell>
				<IndexTable.Cell className={tableStyles.tableCellContainer}>
					{attempt.attemptNumber}
				</IndexTable.Cell>
				<IndexTable.Cell className={tableStyles.tableCellContainer}>
					{attempt.workerId}
				</IndexTable.Cell>
				<IndexTable.Cell className={tableStyles.tableCellContainer}>
					<div className={tableStyles.tableCell}>
						<TextField autoComplete="off" label="" value={attempt.id} readOnly selectTextOnFocus />
					</div>
				</IndexTable.Cell>
				<IndexTable.Cell className={tableStyles.tableCellContainer}>
					<div className={tableStyles.tableCell}>
						<TextField
							autoComplete="off"
							label=""
							value={attempt.delivery.id}
							readOnly
							selectTextOnFocus
						/>
					</div>
				</IndexTable.Cell>
			</IndexTable.Row>
		));
	};
	return (
		<div style={props.style} className={clsx(styles.attemptTable, props.className)}>
			<div className={tableStyles.tableFilterSortContainer}>
				<div className={tableStyles.tableFilters}>
					<AttemptFilters filter={props.filter} onFilterChanged={props.onFilterChanged} />
				</div>
				<Pagination
					hasPrevious={pageInfo?.hasPreviousPage}
					onPrevious={props.onPrevious}
					hasNext={pageInfo?.hasNextPage}
					onNext={props.onNext}
				/>
				<div className={tableStyles.tableSortSelector}>
					<Select
						labelInline
						label="Sort Direction"
						options={Object.values(SortDirection)}
						value={props.sortDirection}
						onChange={(selected) => props.onSortDirectionChanged(selected as SortDirection)}
					/>
				</div>
			</div>
			<Scrollable>
				<IndexTable
					resourceName={{ plural: 'attempts', singular: 'attempt' }}
					itemCount={data.length}
					selectedItemsCount={0}
					selectable={false}
					loading={props.loading}
					headings={[
						{ hidden: true, title: 'Status' },
						{ title: 'HTTP Status' },
						{ title: 'Request Error' },
						{ title: 'Send Result' },
						{ title: 'Started' },
						{ title: 'Duration' },
						{ title: 'Attempt' },
						{ title: 'Worker Id' },
						{ title: 'Attempt ID' },
						{ title: 'Delivery ID' },
					]}
					hasMoreItems={pageInfo.hasNextPage}
				>
					{data.length && rowsMarkup()}
				</IndexTable>
			</Scrollable>
		</div>
	);
}
