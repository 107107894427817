import {
	Card,
	Layout,
	Modal,
	Page,
	PageActions,
	Stack,
	Tag,
	TextStyle,
	Link,
} from '@sixriver/lighthouse-web-community';
import { DateTime, UserRole, useAuth } from '@sixriver/react-support';
import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EmployeeTimestampsCard } from './EmployeeTimestampsCard';
import { useDeactivateEmployeeMutation } from './graphql/DeactivateEmployee.f-api-graphql';
import { useGetEmployeeQuery } from './graphql/GetEmployee.f-api-graphql';
import { useReactivateEmployeeMutation } from './graphql/ReactivateEmployee.f-api-graphql';
import { CardDetails } from '../../components/CardDetails';
import { Error } from '../../components/Error';
import { NoData } from '../../components/NoData';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

interface Params {
	employeeId: string;
}

// Mocked
export interface EmployeeEvent {
	date: Date;
	type: 'created' | 'updated';
	editedBy?: {
		name: string;
		id: string;
	};
}

export function Employee() {
	const { messages } = useLocalization();
	const { lookBusy } = useContext(BusyIndicatorContext);

	// State
	const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState<boolean>(false);
	const [isReactivating, setIsReactivating] = useState<boolean>(false);
	const [isDeactivating, setIsDeactivating] = useState<boolean>(false);

	// Auth
	const { getUser } = useAuth();
	const user = getUser();
	const loggedInUserEmail = user?.email;

	// Toasts
	const { showToast } = useToast();

	// Routing
	const params = useParams<Params>();
	const employeeId = params?.employeeId;
	const history = useHistory();

	// Queries
	const [getEmployeeQuery] = useGetEmployeeQuery({ variables: { id: employeeId } });
	const employee = getEmployeeQuery.data?.user;

	// Timestamps
	const timestamps: EmployeeEvent[] = [
		...(employee?.createdAt
			? [
					{
						date: new Date(employee.createdAt as string),
						// editedBy: {
						// 	id: '123',
						// 	name: 'Bob',
						// },
						type: 'created' as const,
					},
			  ]
			: []),
		...(employee?.updatedAt
			? [
					{
						date: new Date(employee?.updatedAt as string),
						// editedBy: {
						// 	id: '321',
						// 	name: 'Rob',
						// },
						type: 'updated' as const,
					},
			  ]
			: []),
	];

	// Methods
	const [, executeReactivateEmployeeMutation] = useReactivateEmployeeMutation();
	const [, executeDeactivateEmployeeMutation] = useDeactivateEmployeeMutation();
	const openDeactivateModal = () => setIsDeactivateModalOpen(true);

	const closeDeactivateModal = () => setIsDeactivateModalOpen(false);

	const reactivateEmployee = async () => {
		if (!employee?.id) {
			showToast(messages.errorToast, true);
			return;
		}

		setIsReactivating(true);

		const { data, error } = await executeReactivateEmployeeMutation({ userId: employee.id });

		setIsReactivating(false);

		if (error || !data?.reactivateUser?.success) {
			showToast(messages.errorToast, true);
			return;
		}

		// success
		showToast(messages.employeeReactivated);
		history.push(routes.employees());
	};

	const deactivateEmployee = async () => {
		if (!employee?.id) {
			showToast(messages.errorToast, true);
			return;
		}

		if (loggedInUserEmail === employee.email) {
			showToast(messages.errorToast, true);
			return;
		}

		setIsDeactivating(true);

		const { data, error } = await executeDeactivateEmployeeMutation({
			userId: employee.id,
		});

		setIsDeactivating(false);

		closeDeactivateModal();

		if (error || !data?.deactivateUser?.success) {
			showToast(messages.errorToast, true);
			return;
		}

		// success
		showToast(messages.employeeDeactivated);
		history.push(routes.inactiveEmployees());
	};

	if (lookBusy(getEmployeeQuery.fetching && !employee)) {
		return null;
	}

	if (getEmployeeQuery.error || !employee) {
		return (
			<Error
				graphQLError={getEmployeeQuery.error}
				message={getEmployeeQuery.error ? undefined : messages.dataNotFound}
			/>
		);
	}

	return (
		<>
			<Page title={employee?.name || employee?.badge || employee?.email || '—'}>
				<Stack distribution="trailing" alignment="trailing">
					<Link url={routes.editEmployee(employeeId.toString())} removeUnderline>
						{messages.editEmployee}
					</Link>
				</Stack>

				<br />

				<Layout>
					<Layout.Section>
						{/* Employee Details */}
						<Card title={messages.employeeDetails} sectioned>
							<CardDetails
								primary={[
									{
										content: employee?.name || <NoData />,
										label: messages.fullName,
									},
									{
										content:
											messages.supportedLocales[
												(employee?.locale as keyof typeof messages.supportedLocales) || 'en-US'
											],
										label: messages.language,
									},
								]}
							/>
						</Card>

						{/* 6RS Device Credentials */}
						<Card title={messages.deviceAccess} sectioned>
							{employee?.badge ? (
								<CardDetails
									primary={[
										{
											content: employee?.badge || <NoData />,
											label: messages.badgeBarcode,
										},
										{
											content: employee?.lastLogin ? (
												<DateTime date={employee?.lastLogin} />
											) : (
												<NoData />
											),
											label: messages.lastLogin,
										},
									]}
								/>
							) : (
								<TextStyle>{messages.noAccess}</TextStyle>
							)}
						</Card>

						{/* Bridge Credentials */}
						<Card title={messages.bridgeAccess} sectioned>
							{employee?.roles?.includes(UserRole.Admin) ? (
								<CardDetails
									primary={[
										{
											content: employee?.email || <NoData />,
											label: messages.email,
										},
										{
											content: '********',
											label: messages.password,
										},
									]}
								/>
							) : (
								<TextStyle>{messages.noAccess}</TextStyle>
							)}
						</Card>

						<br />
					</Layout.Section>

					<Layout.Section secondary>
						{/* TAGS  */}
						<Card title={messages.tags} sectioned>
							<Card.Section flush>
								<Stack>
									{(employee?.tags || []).map((tag) => {
										return <Tag key={tag.name}>{tag.name}</Tag>;
									})}
								</Stack>
							</Card.Section>
						</Card>

						{/* TimeStamps */}
						<EmployeeTimestampsCard timestamps={timestamps} />
					</Layout.Section>
				</Layout>

				<PageActions
					secondaryActions={
						employee.isActive
							? [
									{
										content: messages.deactivateEmployee,
										destructive: true,
										disabled: loggedInUserEmail === employee.email,
										onAction: openDeactivateModal,
										outline: true,
									},
							  ]
							: [
									{
										content: messages.reactivateEmployee,
										destructive: false,
										loading: isReactivating,
										onAction: () => void reactivateEmployee(),
										outline: true,
									},
							  ]
					}
				/>
			</Page>
			<Modal
				title={messages.deactivateEmployee}
				open={isDeactivateModalOpen}
				onClose={closeDeactivateModal}
				primaryAction={{
					content: messages.deactivateEmployee,
					destructive: true,
					disabled: loggedInUserEmail === employee.email,
					loading: isDeactivating,
					onAction: () => void deactivateEmployee(),
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: closeDeactivateModal,
					},
				]}
			>
				<Modal.Section>
					<TextStyle>{messages.confirmDeactivateEmployee}</TextStyle>
				</Modal.Section>
			</Modal>
		</>
	);
}
