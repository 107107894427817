import { IndexTable } from '@sixriver/lighthouse-web-community';

import { PickWaveOrderStatusBadge } from '../PickWaveOrderStatusBadge';
import { PickWaveOrderFieldsFragment } from '../graphql/PickWaveOrderConnectionFields.f-api-graphql';

interface StatusCellProps {
	order: PickWaveOrderFieldsFragment;
}

export function StatusCell({ order }: StatusCellProps) {
	return (
		<IndexTable.Cell>
			<PickWaveOrderStatusBadge order={order} />
		</IndexTable.Cell>
	);
}
