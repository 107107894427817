// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetUserByBadgeBarcodeQueryVariables = Types.Exact<{
  badgeBarcode: Types.Scalars['String']['input'];
}>;


export type GetUserByBadgeBarcodeQuery = { __typename?: 'Query', userByBadgeBarcode?: { __typename?: 'User', name?: string | null, id: string } | null };


export const GetUserByBadgeBarcodeDocument = gql`
    query fapi_GetUserByBadgeBarcode($badgeBarcode: String!) {
  userByBadgeBarcode(badgeBarcode: $badgeBarcode) {
    name
    id
  }
}
    `;

export function useGetUserByBadgeBarcodeQuery(options: Omit<Urql.UseQueryArgs<GetUserByBadgeBarcodeQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserByBadgeBarcodeQuery, GetUserByBadgeBarcodeQueryVariables>({ query: GetUserByBadgeBarcodeDocument, ...options });
};