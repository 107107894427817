import { DiamondAlertMajor } from '@sixriver/lighthouse-icons';
import { Button, Icon, Link, Popover, Stack, Tooltip } from '@sixriver/lighthouse-web-community';
import { noop } from 'lodash';
import { useCallback, useState } from 'react';

import styles from './SourceAddressPopover.module.css';
import { useLocalization } from '../../../../hooks/useLocalization';
import { GetOutboundJobLineTaskFragment } from '../../../../pages/OutboundJob/graphql/GetLines.w-api-graphql';

interface Props {
	tasks: GetOutboundJobLineTaskFragment[];
	onViewJobHistory?: () => void;
}

export function SourceAddressPopover({ tasks, onViewJobHistory = noop }: Props) {
	const { messages, translate } = useLocalization();
	const [popoverActive, setPopoverActive] = useState(false);
	const togglePopoverActive = useCallback(
		() => setPopoverActive((popoverActive) => !popoverActive),
		[],
	);

	const activator = (
		<Button
			onClick={togglePopoverActive}
			disclosure="down"
			outline={false}
			monochrome
			plain
			removeUnderline
			accessibilityLabel="pick short reported"
		>
			Multiple locations
		</Button>
	);
	return (
		<Popover
			fluidContent
			preferredAlignment="left"
			active={popoverActive}
			activator={activator || ''}
			onClose={togglePopoverActive}
		>
			<Popover.Section>
				{tasks.map((task) => {
					return (
						<div key={task.id} className={styles.row}>
							<Stack distribution="equalSpacing">
								<Stack.Item>
									<Stack distribution="leading">
										<Stack.Item>{task.sourceLocationAddress}</Stack.Item>
										<Stack.Item>
											{task.isShorted && (
												<Tooltip content={messages.pickShortReported} dismissOnMouseOut>
													<Icon source={DiamondAlertMajor} color={'critical'}></Icon>
												</Tooltip>
											)}
										</Stack.Item>
									</Stack>
								</Stack.Item>
								<Stack.Item>&nbsp;</Stack.Item>
								<Stack.Item fill={false}>
									{translate(messages.pickedOfTotal, {
										picked: task.doneQuantity,
										total: task.quantity,
									})}
								</Stack.Item>
							</Stack>
						</div>
					);
				})}
			</Popover.Section>
			<Popover.Section>
				<Link onClick={onViewJobHistory} removeUnderline>
					{messages.viewJobHistory}
				</Link>
			</Popover.Section>
		</Popover>
	);
}
