// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrderQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetOrderQuery = { __typename?: 'Query', job?: { __typename?: 'Job', id: string, externalId: string, isTerminal: boolean, isOrder: boolean, isLate: boolean, isAwaitingInventory: boolean, pickStrategy: Types.PickStrategy, status: Types.JobStatus, stagingIdentifier?: string | null, expectedShipDate?: any | null, createdAt: any, updatedAt: any, completedAt?: any | null, isShorted: boolean, isHealable: boolean, allowedDeviceClasses: Array<string>, usedDeviceClasses: Array<string>, lines: { __typename?: 'LineConnection', edges: Array<{ __typename?: 'LineEdge', node: { __typename?: 'Line', id: string, status?: string | null, quantity: number, doneQuantity: number, completedAt?: any | null, productId: string, productCustomerIdentifier?: string | null, productName?: string | null, isShorted: boolean, customer: { __typename?: 'Customer', name?: string | null } } }> }, totalLines: { __typename?: 'Count', count: number }, totalLineQuantities: { __typename?: 'LineQuantities', inProgressUnits: number, completedUnits: number, pickedUnits: number, totalUnits: number, unassignedUnits: number, completedLines: number, packedUnits: number }, servicedBySortations: Array<{ __typename?: 'Job', id: string, externalContainerId?: string | null, type: string, pickStrategy: Types.PickStrategy }>, pickingJobs: Array<{ __typename?: 'Job', id: string, isHealJob: boolean, status: Types.JobStatus, createdAt: any, pickStrategy: Types.PickStrategy, type: string, completedAt?: any | null }>, packouts: { __typename?: 'PackoutConnection', edges: Array<{ __typename?: 'PackoutEdge', node: { __typename?: 'Packout', completedAt?: any | null } }> }, healPlans?: Array<{ __typename?: 'HealPlan', status: string }> | null, tasks?: Array<{ __typename?: 'Task', id: string, type: string, completedAt?: any | null }> | null, workflows: Array<{ __typename?: 'JobWorkflow', type: string, status: string }> } | null };


export const GetOrderDocument = gql`
    query wapi_GetOrder($id: String!) {
  job(id: $id) {
    id
    externalId
    isTerminal
    isOrder
    isLate
    isAwaitingInventory
    pickStrategy
    status
    stagingIdentifier
    expectedShipDate
    createdAt
    updatedAt
    completedAt
    isShorted
    isHealable
    lines {
      edges {
        node {
          id
          status
          quantity
          doneQuantity
          completedAt
          productId
          productCustomerIdentifier
          productName
          isShorted
          customer {
            name
          }
        }
      }
    }
    totalLines {
      count
    }
    totalLineQuantities {
      inProgressUnits
      completedUnits
      pickedUnits
      totalUnits
      unassignedUnits
      completedLines
      packedUnits
    }
    servicedBySortations {
      id
      externalContainerId
      type
      pickStrategy
    }
    pickingJobs {
      id
      isHealJob
      status
      createdAt
      pickStrategy
      type
      completedAt
    }
    packouts {
      edges {
        node {
          completedAt
        }
      }
    }
    healPlans {
      status
    }
    tasks {
      id
      type
      completedAt
    }
    allowedDeviceClasses
    usedDeviceClasses
    workflows {
      type
      status
    }
  }
}
    `;

export function useGetOrderQuery(options: Omit<Urql.UseQueryArgs<GetOrderQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrderQuery, GetOrderQueryVariables>({ query: GetOrderDocument, ...options });
};