// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.f-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EmployeesQueryVariables = Types.Exact<{
  type: Types.UserActivityType;
  searchText: Types.Scalars['String']['input'];
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type EmployeesQuery = { __typename?: 'Query', users?: { __typename?: 'UserConnection', edges: Array<{ __typename?: 'UserEdge', node: { __typename?: 'User', id: string, name?: string | null, locale: string, badge?: string | null, roles: Array<string>, updatedAt?: any | null, lastLogin?: any | null, email?: string | null, tags: Array<{ __typename?: 'Tag', name: string }> } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const EmployeesDocument = gql`
    query fapi_Employees($type: UserActivityType!, $searchText: String!, $first: Int, $last: Int, $before: String, $after: String) {
  users(
    type: $type
    searchText: $searchText
    first: $first
    last: $last
    before: $before
    after: $after
  ) {
    edges {
      node {
        id
        name
        locale
        badge
        roles
        updatedAt
        lastLogin
        email
        tags {
          name
        }
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${PageInfoFragmentDoc}`;

export function useEmployeesQuery(options: Omit<Urql.UseQueryArgs<EmployeesQueryVariables>, 'query'>) {
  return Urql.useQuery<EmployeesQuery, EmployeesQueryVariables>({ query: EmployeesDocument, ...options });
};