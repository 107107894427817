import { IndexTable, Link } from '@sixriver/lighthouse-web-community';

import { PickStrategy } from '../../../api/fulfillment-api/types';
import { NoData } from '../../../components/NoData';
import * as routes from '../../../routes';
import { GetOrdersOrderFragment } from '../graphql/GetOrders.w-api-graphql';

interface Props {
	order: GetOrdersOrderFragment;
}

export function SortWallCell({ order }: Props) {
	if (
		order.pickStrategy !== PickStrategy.Sortation ||
		!order.stagingIdentifier ||
		order.servicedBy.length === 0
	) {
		return (
			<IndexTable.Cell>
				<NoData />
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<Link url={routes.sortWall(order.servicedBy[0]?.id)}>{order.stagingIdentifier}</Link>
		</IndexTable.Cell>
	);
}
