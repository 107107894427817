import { DateTime } from '@sixriver/react-support';

import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';

interface ReceivedProps {
	pickWave: PickWaveFieldsFragment;
}

export function Received({ pickWave }: ReceivedProps) {
	return <DateTime date={pickWave.createdAt} />;
}
