// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/edge/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeliveryFragment = { __typename?: 'MessageDelivery', id: string, deliveryState: Types.DeliveryState, enqueuedAt: any, attemptsStarted: number, hook: { __typename?: 'Hook', id: string, name: string }, message: { __typename?: 'Message', id: string, orderingKey?: string | null, messageType: any, payload: any, deliveryType?: Types.DeliveryType | null } };

export type GetDeliveriesQueryVariables = Types.Exact<{
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.MessageDeliveryAndAttemptFilter>;
  orderDirection?: Types.InputMaybe<Types.SortDirection>;
}>;


export type GetDeliveriesQuery = { __typename?: 'Query', getDeliveries: { __typename?: 'MessageDeliveryConnection', edges: Array<{ __typename?: 'MessageDeliveryEdge', cursor: string, node: { __typename?: 'MessageDelivery', id: string, deliveryState: Types.DeliveryState, enqueuedAt: any, attemptsStarted: number, hook: { __typename?: 'Hook', id: string, name: string }, message: { __typename?: 'Message', id: string, orderingKey?: string | null, messageType: any, payload: any, deliveryType?: Types.DeliveryType | null } } }>, pageInfo: { __typename?: 'MessageDeliveryPageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } };

export const DeliveryFragmentDoc = gql`
    fragment Delivery on MessageDelivery {
  id
  deliveryState
  enqueuedAt
  hook {
    id
    name
  }
  message {
    id
    orderingKey
    messageType
    payload
    deliveryType
  }
  attemptsStarted
}
    `;
export const GetDeliveriesDocument = gql`
    query edge_GetDeliveries($before: String, $after: String, $first: Int, $last: Int, $filter: MessageDeliveryAndAttemptFilter, $orderDirection: SortDirection) {
  getDeliveries(
    before: $before
    after: $after
    first: $first
    last: $last
    filter: $filter
    order: {orderField: EnqueuedAt, sortDirection: $orderDirection}
  ) {
    edges {
      node {
        ...Delivery
      }
      cursor
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${DeliveryFragmentDoc}`;

export function useGetDeliveriesQuery(options?: Omit<Urql.UseQueryArgs<GetDeliveriesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDeliveriesQuery, GetDeliveriesQueryVariables>({ query: GetDeliveriesDocument, ...options });
};