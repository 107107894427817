import { IndexTable, IndexTableProps } from '@sixriver/lighthouse-web-community';
import { useMemo } from 'react';

import { FailureTypeCell } from './FailureTypeCell';
import { IdCell } from './IdCell';
import { LinesDroppedCell } from './LinesDroppedCell';
import { StatusCell } from './StatusCell';
import { TotalLinesCell } from './TotalLinesCell';
import { PickWaveOrderFieldsFragment } from '../graphql/PickWaveOrderConnectionFields.f-api-graphql';

type PickWaveOrdersTableProps = {
	loading?: boolean;
	orders?: PickWaveOrderFieldsFragment[];
};

export function PickWaveOrdersTable({ loading, orders = [] }: PickWaveOrdersTableProps) {
	const resourceName = useMemo(
		() => ({
			plural: 'Orders',
			singular: 'Order',
		}),
		[],
	);
	const headings: IndexTableProps['headings'] = useMemo(
		() => [
			{ title: 'Order ID' },
			{ title: 'Ingestion status' },
			{ title: 'Total lines' },
			{ title: 'Lines dropped' },
			{ title: 'Failure type' },
		],
		[],
	);

	const rowMarkup = orders.map((order, index) => {
		const { id } = order;
		return (
			<IndexTable.Row id={id} key={index} position={index}>
				<IdCell order={order} />
				<StatusCell order={order} />
				<TotalLinesCell order={order} />
				<LinesDroppedCell order={order} />
				<FailureTypeCell order={order} />
			</IndexTable.Row>
		);
	});

	return (
		<IndexTable
			loading={loading}
			resourceName={resourceName}
			itemCount={orders.length}
			headings={headings}
			selectable={false}
		>
			{rowMarkup}
		</IndexTable>
	);
}
