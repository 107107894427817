import * as Sentry from '@sentry/react';
import { UserRole } from '@sixriver/react-support';
import { useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { Frame } from './Frame';
import { FeatureFlaggedRoute } from '../components/FeatureFlaggedRoute';
import { LoginRoute } from '../components/LoginRoute';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { Toast } from '../components/Toast';
import { useExperimentalFlags, useSiteConfig } from '../hooks/useConfig';
import { cleanUpPrintLabelLocalStorage } from '../hooks/usePrintLabelButton';
import { ActionLog } from '../pages/ActionLog';
import { AllocationRules } from '../pages/AllocationRules';
import { AuditLogs } from '../pages/AuditLogs';
import { Login, Logout } from '../pages/Auth';
import { ForgotPassword } from '../pages/Auth/ForgotPassword';
import { SetPassword } from '../pages/Auth/SetPassword';
import { Containers, NestedContainer } from '../pages/Containers';
import { AddContainer } from '../pages/Containers/ContainerAdd';
import { ContainerDetails } from '../pages/Containers/ContainerDetails';
import { EditContainer } from '../pages/Containers/ContainerEdit';
import { Count } from '../pages/Count';
import { Device, Devices } from '../pages/Devices';
import { DeviceEdit } from '../pages/Devices/DeviceEdit';
import { Employees } from '../pages/Employees';
import { Employee } from '../pages/Employees/Employee';
import { AddEmployee } from '../pages/Employees/EmployeeAdd';
import { EmployeeEdit } from '../pages/Employees/EmployeeEdit';
import { Exception } from '../pages/Exception';
import { Exceptions } from '../pages/Exceptions';
import { FloorView } from '../pages/FloorView';
import { FulfillmentTracker } from '../pages/FulfillmentTracker';
import { JobFlowRules, JobFlowRule, EditJobFlowRule } from '../pages/JobFlowRules';
import { AddJobFlowRule } from '../pages/JobFlowRules/AddJobFlowRule';
import { Location, Locations, AddStorageLocation, EditStorageLocation } from '../pages/Locations';
import { DeliveryLogPage } from '../pages/MessageLog';
import { MfaEntryRoute } from '../pages/MobileFulfillmentApp/MfaEntryRoute';
import * as mfaRoutes from '../pages/MobileFulfillmentApp/routes';
import { Move } from '../pages/Move';
import { Order } from '../pages/Order';
import { Orders } from '../pages/Orders';
import { OutboundJob } from '../pages/OutboundJob';
import { OutboundJobs } from '../pages/OutboundJobs';
import { PickWave, PickWaves } from '../pages/PickWaves';
import { ProductDetails } from '../pages/Product';
import { AddProduct } from '../pages/Product/AddProduct';
import { EditProduct } from '../pages/Product/EditProduct';
import { Products } from '../pages/Products';
import { PutawayJob } from '../pages/PutawayJob';
import { PutawayJobs } from '../pages/PutawayJobs';
import { Settings } from '../pages/Settings';
import { ShuttleAvailableContainer } from '../pages/ShuttleAvailableContainer';
import { ShuttleAvailableContainers } from '../pages/ShuttleAvailableContainers';
import { ShuttleAvailableDestination } from '../pages/ShuttleAvailableDestination';
import { ShuttleAvailableDestinations } from '../pages/ShuttleAvailableDestinations';
import { ShuttleJob } from '../pages/ShuttleJob';
import { ShuttleJobs } from '../pages/ShuttleJobs';
import { SortWall } from '../pages/SortWall';
import { SortWalls } from '../pages/SortWalls';
import { Support } from '../pages/Support';
import { Tableau } from '../pages/Tableau';
import { ConfigServiceProvider } from '../providers';
import * as routes from '../routes';

export function App() {
	useEffect(() => {
		cleanUpPrintLabelLocalStorage();
	}, []);

	const { data: siteConfig } = useSiteConfig();
	useEffect(() => {
		if (siteConfig?.environment) {
			Sentry.setTag('site-environment', siteConfig.environment);
			Sentry.setTag('site-status', siteConfig.status);
		}
	}, [siteConfig]);

	const experimentalFlags = useExperimentalFlags();

	const content = (
		<Switch>
			{/* Exceptions */}
			<ProtectedRoute exact path={routes.exceptions()}>
				<Exceptions />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.exception()}>
				<Exception />
			</ProtectedRoute>

			{/* Orders */}
			<ProtectedRoute exact path={routes.fulfillmentTracker()}>
				<FulfillmentTracker />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.orders()}>
				<Orders />
			</ProtectedRoute>
			{/* don't move action log because it will parse as an order id! */}
			<ProtectedRoute exact path={routes.actionLog()}>
				<ActionLog />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.order()}>
				<Order />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.outboundJobs()}>
				<OutboundJobs />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.outboundJob()}>
				<OutboundJob />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.sortWalls()}>
				<SortWalls />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.sortWall()}>
				<SortWall />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.countJobs()}>
				<Count />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.moveJobs()}>
				<Move />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.addJobFlowRule()}>
				<AddJobFlowRule />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.editJobFlowRule()}>
				<EditJobFlowRule />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.jobFlowRules()}>
				<JobFlowRules />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.jobFlowRule()}>
				<JobFlowRule />
			</ProtectedRoute>

			{/* Products */}
			<ProtectedRoute exact path={routes.products()}>
				<Products />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.addProduct()}>
				<AddProduct />
			</ProtectedRoute>
			<ProtectedRoute
				exact
				path={routes.product()}
				allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}
			>
				<ProductDetails />
			</ProtectedRoute>
			<ProtectedRoute
				exact
				path={routes.editProduct()}
				allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}
			>
				<EditProduct />
			</ProtectedRoute>

			{/* Employees */}
			<ProtectedRoute exact path={routes.employees()} allowedRoles={[UserRole.Admin]}>
				<Employees />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.addEmployee()} allowedRoles={[UserRole.Admin]}>
				<AddEmployee />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.editEmployee()} allowedRoles={[UserRole.Admin]}>
				<EmployeeEdit />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.employee()} allowedRoles={[UserRole.Admin]}>
				<Employee />
			</ProtectedRoute>

			{/* Devices */}
			<ProtectedRoute exact path={routes.devices()}>
				<Devices />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.device()}>
				<Device />
			</ProtectedRoute>
			{experimentalFlags.includes('EDIT_DEVICE') ? (
				<ProtectedRoute
					exact
					path={routes.editDevice()}
					allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}
				>
					<ConfigServiceProvider>
						<DeviceEdit />
					</ConfigServiceProvider>
				</ProtectedRoute>
			) : null}
			<ProtectedRoute exact path={routes.floorView()}>
				<FloorView />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.allocationRules()}>
				<AllocationRules />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.support()}>
				<Support />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.analytics()}>
				<Tableau />
			</ProtectedRoute>

			{/* Putaway */}
			<ProtectedRoute exact path={routes.putawayJobs()}>
				<PutawayJobs />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.putawayJob()}>
				<PutawayJob />
			</ProtectedRoute>

			{/* System-Direected Shuttle */}
			<ProtectedRoute exact path={routes.shuttleJobs()}>
				<ShuttleJobs />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.shuttleJob()}>
				<ShuttleJob />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.shuttleAvailableContainers()}>
				<ShuttleAvailableContainers />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.shuttleAvailableContainer()}>
				<ShuttleAvailableContainer />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.shuttleAvailableDestinations()}>
				<ShuttleAvailableDestinations />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.shuttleAvailableDestination()}>
				<ShuttleAvailableDestination />
			</ProtectedRoute>

			{/* Locations */}
			<ProtectedRoute exact path={routes.locations()}>
				<Locations />
			</ProtectedRoute>
			{experimentalFlags.includes('MANAGE_STORAGE_LOCATIONS') ? (
				<ProtectedRoute
					exact
					path={routes.addLocation()}
					allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}
				>
					<AddStorageLocation />
				</ProtectedRoute>
			) : null}
			{experimentalFlags.includes('MANAGE_STORAGE_LOCATIONS') ? (
				<ProtectedRoute
					exact
					path={routes.editLocation()}
					allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}
				>
					<EditStorageLocation />
				</ProtectedRoute>
			) : null}
			<ProtectedRoute exact path={routes.location()}>
				<Location />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.nestedContainer()}>
				<NestedContainer />
			</ProtectedRoute>

			{/* Containers */}
			<ProtectedRoute exact path={routes.containers()}>
				<Containers />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.addContainer()}>
				<AddContainer />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.editContainer()}>
				<EditContainer />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.container()}>
				<ContainerDetails />
			</ProtectedRoute>

			{/* Pick Waves */}
			<ProtectedRoute exact path={routes.pickWaves()}>
				<PickWaves />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.pickWave()}>
				<PickWave />
			</ProtectedRoute>

			{/* Message logs */}
			<ProtectedRoute exact path={routes.deliveryLog()} allowedRoles={[UserRole.Admin]}>
				<DeliveryLogPage />
			</ProtectedRoute>

			{/* Settings */}
			{experimentalFlags.includes('SETTINGS_PAGE') ? (
				<ProtectedRoute
					exact
					path={routes.settings()}
					allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}
				>
					<ConfigServiceProvider>
						<Settings />
					</ConfigServiceProvider>
				</ProtectedRoute>
			) : null}

			{/* Audit logs */}
			<ProtectedRoute
				exact
				path={routes.auditLogs()}
				allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}
			>
				<FeatureFlaggedRoute flag="AUDIT_LOGS_PAGE">
					<AuditLogs />
				</FeatureFlaggedRoute>
			</ProtectedRoute>

			{/* Mobile Fulfillment App */}
			<ProtectedRoute path={mfaRoutes.mfas()}>
				<MfaEntryRoute />
			</ProtectedRoute>

			{/* Default route */}
			<Redirect from="/" to={routes.orders()} />
		</Switch>
	);

	return (
		<Switch>
			<LoginRoute exact path={routes.login()}>
				<Login />
			</LoginRoute>
			<Route exact path={routes.forgotPassword()}>
				<ForgotPassword />
			</Route>
			<Route exact path={routes.setPassword()}>
				<SetPassword />
			</Route>
			<ProtectedRoute exact path={routes.logout()}>
				<Logout />
			</ProtectedRoute>
			<ProtectedRoute path="/">
				<Frame>
					{content}
					<Toast />
				</Frame>
			</ProtectedRoute>
		</Switch>
	);
}
