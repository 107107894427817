// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetContainerQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetContainerQuery = { __typename?: 'Query', getContainer?: { __typename?: 'ContainerType', id: string, externalId: string, name: string, type: Types.ContainerTypeType, width?: number | null, height?: number | null, length?: number | null, weight?: number | null, columns?: number | null, rows?: number | null, barcode?: string | null, inventory: boolean, enabled: boolean, description: string, cubeVolume?: number | null, attributes?: any | null } | null };


export const GetContainerDocument = gql`
    query wapi_GetContainer($id: String!) {
  getContainer(id: $id) {
    id
    externalId
    name
    type
    width
    height
    length
    weight
    columns
    rows
    barcode
    inventory
    enabled
    description
    cubeVolume
    attributes
  }
}
    `;

export function useGetContainerQuery(options: Omit<Urql.UseQueryArgs<GetContainerQueryVariables>, 'query'>) {
  return Urql.useQuery<GetContainerQuery, GetContainerQueryVariables>({ query: GetContainerDocument, ...options });
};