// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetContainerQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetContainerQuery = { __typename?: 'Query', container?: { __typename?: 'Container', id: string, externalId: string, name: string, type: Types.ContainerTypeWithFallback, width: number, height: number, length: number, weight: number, columns: number, rows: number, barcode?: string | null, inventory: boolean, enabled: boolean, imageURL?: string | null, description?: string | null, volume: number, instructions?: string | null, attributes?: { __typename?: 'ContainerAttributes', packageMaterialType?: string | null } | null } | null };


export const GetContainerDocument = gql`
    query fapi_GetContainer($id: String!) {
  container(id: $id) {
    id
    externalId
    name
    type
    width
    height
    length
    weight
    columns
    rows
    barcode
    inventory
    enabled
    imageURL
    description
    volume
    instructions
    attributes {
      packageMaterialType
    }
  }
}
    `;

export function useGetContainerQuery(options: Omit<Urql.UseQueryArgs<GetContainerQueryVariables>, 'query'>) {
  return Urql.useQuery<GetContainerQuery, GetContainerQueryVariables>({ query: GetContainerDocument, ...options });
};