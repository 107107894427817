import { IndexTable, TextStyle } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';
import { GetOutboundJobLineFragment } from '../../../pages/OutboundJob/graphql/GetLines.w-api-graphql';

interface Props {
	line: GetOutboundJobLineFragment;
}

export function PickingUnitsCell({ line }: Props): JSX.Element {
	const { messages, translate } = useLocalization();
	return (
		<IndexTable.Cell>
			<TextStyle>
				{translate(messages.pickedOfTotal, {
					picked: line.doneQuantity,
					total: line.quantity,
				})}
			</TextStyle>
		</IndexTable.Cell>
	);
}
