import { useDebouncedValue } from '@shopify/react-hooks';
import { AppliedFilterInterface, Filters } from '@sixriver/lighthouse-web-community';
import { useCallback, useEffect, useState } from 'react';

import { StatusFilter } from './StatusFilter';
import { PickWaveOrderStatus } from '../../../api/fulfillment-api/types';

const MIN_QUERY_LENGTH = 3;

export interface PickWaveOrdersFilter {
	searchText?: string;
	statuses: PickWaveOrderStatus[];
}

export interface PickWaveOrdersFilterProps {
	filter: PickWaveOrdersFilter;
	onChange: (filter: PickWaveOrdersFilter) => void;
}

const STATUS_FILTER_KEY = 'status';

export function PickWaveOrdersFilters({ filter, onChange }: PickWaveOrdersFilterProps) {
	const [searchText, setSearchText] = useState<string | undefined>(filter.searchText);
	const debouncedSearchText = useDebouncedValue(searchText);
	const [statuses, setStatuses] = useState<PickWaveOrderStatus[]>(filter.statuses);

	useEffect(() => {
		let searchText;

		if (debouncedSearchText && debouncedSearchText.length >= MIN_QUERY_LENGTH) {
			searchText = debouncedSearchText;
		}

		onChange({
			searchText,
			statuses,
		});
	}, [onChange, debouncedSearchText, statuses]);

	const handleStatusesChange = useCallback((value: PickWaveOrderStatus[]) => {
		setStatuses(value);
	}, []);

	const handleStatusesClear = useCallback(() => {
		setStatuses([]);
	}, []);

	const handleQueryChange = useCallback((queryValue: string) => {
		setSearchText(queryValue);
	}, []);

	const handleQueryClear = useCallback(() => {
		setSearchText(undefined);
	}, []);

	const handleClearAll = useCallback(() => {
		handleStatusesClear();
	}, [handleStatusesClear]);

	const filters = [
		{
			filter: <StatusFilter selected={statuses} onChange={handleStatusesChange} />,
			key: STATUS_FILTER_KEY,
			label: 'Status',
		},
	];
	const appliedFilters: AppliedFilterInterface[] = [];

	if (!isEmpty(statuses)) {
		appliedFilters.push({
			key: STATUS_FILTER_KEY,
			label: disambiguateLabel(STATUS_FILTER_KEY, statuses),
			onRemove: handleStatusesClear,
		});
	}

	return (
		<Filters
			queryValue={searchText}
			queryPlaceholder="Search by order ID"
			onQueryChange={handleQueryChange}
			onQueryClear={handleQueryClear}
			filters={filters}
			appliedFilters={appliedFilters}
			onClearAll={handleClearAll}
		></Filters>
	);
}

function isEmpty(value: unknown): boolean {
	if (Array.isArray(value)) {
		return value.length === 0;
	} else {
		return value === '' || value === null || value === undefined;
	}
}

function disambiguateLabel(key: string, value: string[] | string): string {
	if (key === STATUS_FILTER_KEY) {
		if (Array.isArray(value)) {
			return `Status: ${value.join(', ')}`;
		}

		return `Status: ${value}`;
	}

	if (Array.isArray(value)) {
		return value.join(', ');
	}

	return value;
}
