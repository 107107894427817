// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetContainerCountQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  isInventory?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetContainerCountQuery = { __typename?: 'Query', countContainers: Array<{ __typename?: 'ContainerTypeCount', type?: Types.ContainerTypeType | null, count: number }> };


export const GetContainerCountDocument = gql`
    query wapi_GetContainerCount($searchText: String, $isInventory: Boolean) {
  countContainers(searchText: $searchText, isInventory: $isInventory) {
    type
    count
  }
}
    `;

export function useGetContainerCountQuery(options?: Omit<Urql.UseQueryArgs<GetContainerCountQueryVariables>, 'query'>) {
  return Urql.useQuery<GetContainerCountQuery, GetContainerCountQueryVariables>({ query: GetContainerCountDocument, ...options });
};