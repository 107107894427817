// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetAislesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAislesQuery = { __typename?: 'Query', aisles: Array<{ __typename?: 'Aisle', id: string, externalId: string }> };


export const GetAislesDocument = gql`
    query fapi_GetAisles {
  aisles {
    id
    externalId
  }
}
    `;

export function useGetAislesQuery(options?: Omit<Urql.UseQueryArgs<GetAislesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAislesQuery, GetAislesQueryVariables>({ query: GetAislesDocument, ...options });
};