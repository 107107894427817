// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type JobLinesCountQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type JobLinesCountQuery = { __typename?: 'Query', All: { __typename?: 'Count', count: number }, Exceptions: { __typename?: 'Count', count: number }, Unassigned: { __typename?: 'Count', count: number }, InProgress: { __typename?: 'Count', count: number }, Completed: { __typename?: 'Count', count: number } };


export const JobLinesCountDocument = gql`
    query fapi_JobLinesCount($id: String!, $searchText: String) {
  All: jobsLinesV2Count(id: $id, searchText: $searchText) {
    count
  }
  Exceptions: jobsLinesV2Count(id: $id, searchText: $searchText, view: Exceptions) {
    count
  }
  Unassigned: jobsLinesV2Count(id: $id, searchText: $searchText, view: Unassigned) {
    count
  }
  InProgress: jobsLinesV2Count(id: $id, searchText: $searchText, view: InProgress) {
    count
  }
  Completed: jobsLinesV2Count(id: $id, searchText: $searchText, view: Complete) {
    count
  }
}
    `;

export function useJobLinesCountQuery(options: Omit<Urql.UseQueryArgs<JobLinesCountQueryVariables>, 'query'>) {
  return Urql.useQuery<JobLinesCountQuery, JobLinesCountQueryVariables>({ query: JobLinesCountDocument, ...options });
};