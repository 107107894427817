import type { FC } from 'react';
import { createContext, useContext, useState } from 'react';

import { Reservation } from './Reservation';

interface CreateReservationDto {
	deviceId: string;
	type: 'vhandheld';
	resource?: {
		id: string;
	};
}

interface UpdateReservationDto {
	status?: string;
	deviceId: string;
	type: 'vhandheld';
	validUntil?: number;
}

interface ReservationContextValue {
	expired: Reservation[] | null;
	reservations: Reservation[] | null;
	fetchActiveReservations: (deviceId?: string) => Promise<void>;
	expireReservation: (reservation: Reservation) => Promise<void>;
	createReservation: (deviceId: string, resourceId?: string) => Promise<void>;
	fetchExpiredReservations: (deviceId: string, limit: number) => Promise<void>;
}

const ReservationContext = createContext<ReservationContextValue>({
	createReservation: async () => {
		return;
	},
	expireReservation: async () => {
		return;
	},
	expired: [],
	fetchActiveReservations: async () => {
		return;
	},
	fetchExpiredReservations: async () => {
		return;
	},
	reservations: [],
});

export const useReservations = () => useContext(ReservationContext);

export const ReservationContextProvider: FC = ({ children }) => {
	const [expired, setExpired] = useState<Reservation[] | null>(null);
	const [reservations, setReservations] = useState<Reservation[] | null>(null);

	const fetchActiveReservations = async () => {
		try {
			const url = `${
				import.meta.env.VITE_MFP_RESERVE_URL
			}/api/v2/reservations?status=active&page=1&limit=200`;
			const response = await fetch(url);

			if (!response.ok) {
				throw new Error(`GET ${url}: ${response.status} ${response.statusText}`);
			}

			setReservations((await response.json()).data);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
		}
	};

	const fetchExpiredReservations = async (deviceId: string, limit: number) => {
		try {
			const url = `${
				import.meta.env.VITE_MFP_RESERVE_URL
			}/api/v2/reservations?deviceId=${deviceId}&status=expired&limit=${limit}`;
			const response = await fetch(url);

			if (!response.ok) {
				throw new Error(`GET ${url}: ${response.status} ${response.statusText}`);
			}

			setExpired((await response.json()).data);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
		}
	};

	const expireReservation = async (reservation: Reservation) => {
		try {
			const url = `${import.meta.env.VITE_MFP_RESERVE_URL}/api/v2/reservations/${reservation.id}`;
			const response = await fetch(url, {
				body: JSON.stringify({
					deviceId: reservation.deviceId,
					status: 'expired',
					type: reservation.type,
				} as UpdateReservationDto),
				method: 'PUT',
			});

			if (!response.ok) {
				throw new Error(`PUT ${url}: ${response.status} ${response.statusText}`);
			}

			setReservations(reservations?.filter((r) => r.id !== reservation.id) ?? []);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
		}
	};

	const createReservation = async (deviceId: string, resourceId?: string) => {
		try {
			const url = `${import.meta.env.VITE_MFP_RESERVE_URL}/api/v2/reservations`;
			const response = await fetch(url, {
				body: JSON.stringify({
					deviceId,
					resource: !resourceId ? null : { id: resourceId },
					type: 'vhandheld',
				} as CreateReservationDto),
				method: 'POST',
			});

			if (!response.ok) {
				throw new Error(`POST ${url}: ${response.status} ${response.statusText}`);
			}

			setReservations([...(reservations ?? []), (await response.json()) as Reservation]);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
		}
	};

	return (
		<ReservationContext.Provider
			value={{
				createReservation,
				expireReservation,
				expired,
				fetchActiveReservations,
				fetchExpiredReservations,
				reservations,
			}}
		>
			{children}
		</ReservationContext.Provider>
	);
};
