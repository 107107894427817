import { Badge } from '@sixriver/lighthouse-web-community';

import { PickWaveOrderFieldsFragment } from './graphql/PickWaveOrderConnectionFields.f-api-graphql';
import { PickWaveOrderStatus } from '../../api/fulfillment-api/types';
import { useLocalization } from '../../hooks/useLocalization';

interface PickWaveOrderStatusBadgeProps {
	order: PickWaveOrderFieldsFragment;
}

export function PickWaveOrderStatusBadge({ order }: PickWaveOrderStatusBadgeProps) {
	const { messages } = useLocalization();

	let badge = <Badge>Unknown</Badge>;

	if (order.status === PickWaveOrderStatus.Failure) {
		badge = <Badge status="critical">{messages.failure}</Badge>;
	}

	if (order.status === PickWaveOrderStatus.PartialSuccess) {
		badge = <Badge status="warning">{messages.partialSuccess}</Badge>;
	}

	if (order.status === PickWaveOrderStatus.Success) {
		badge = <Badge status="success">{messages.success}</Badge>;
	}

	return badge;
}
