import { Modal, Page, PageActions } from '@sixriver/lighthouse-web-community';
import { useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { StorageLocationForm } from './Location.form';
import { useDeleteLocationMutation } from './graphql/DeleteLocation.f-api-graphql';
import { useEditLocationMutation } from './graphql/EditLocation.f-api-graphql';
import { useGetLocationByIdQuery } from './graphql/GetLocationById.f-api-graphql';
import { StorageLocationInput, StorageLocationType } from '../../api/fulfillment-api/types';
import { Error } from '../../components/Error';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

export function EditStorageLocation(): JSX.Element {
	const history = useHistory();
	const { showToast } = useToast();
	const { messages } = useLocalization();
	const { lookBusy } = useContext(BusyIndicatorContext);
	const [modalVisible, setModalVisible] = useState(false);

	const {
		params: { locationId },
	} = useRouteMatch<{ locationId: string }>();

	// Queries
	const [locationByIdQuery] = useGetLocationByIdQuery({
		pause: !locationId,
		requestPolicy: 'network-only',
		variables: {
			id: locationId,
		},
	});

	// Mutations
	const [editLocationMutation, executeEditLocationMutation] = useEditLocationMutation();
	const [deleteLocationMutation, executeDeleteLocationMutation] = useDeleteLocationMutation();

	const onEdit = async (input: StorageLocationInput) => {
		lookBusy(true);

		const { error } = await executeEditLocationMutation({ input });

		if (error) {
			lookBusy(false);
			return;
		}

		setTimeout(() => {
			lookBusy(false);
			showToast(messages.dataSaved);
			history.push(routes.location(locationId));
		}, 500);
	};

	const onDelete = async () => {
		lookBusy(true);
		setModalVisible(false);

		const { error } = await executeDeleteLocationMutation({ id: locationId });

		if (error) {
			lookBusy(false);
			return;
		}

		setTimeout(() => {
			lookBusy(false);
			history.push(routes.locations());
		}, 500);
	};

	if (lookBusy(locationByIdQuery.fetching && !locationByIdQuery.data?.location)) {
		return <></>;
	}

	if (locationByIdQuery.error) {
		return <Error graphQLError={locationByIdQuery.error} />;
	}

	return (
		<Page
			fullWidth
			title={locationByIdQuery.data?.location?.address}
			subtitle={messages.locationTypes[locationByIdQuery.data?.location?.type || 'unknown']}
			breadcrumbs={[{ content: messages.locations, url: routes.location(locationId) }]}
		>
			<StorageLocationForm
				mode="edit"
				onSubmit={onEdit}
				error={editLocationMutation.error || deleteLocationMutation.error}
				data={
					{
						address: locationByIdQuery.data?.location?.address,
						containerTypeId: locationByIdQuery.data?.location?.containerType?.id,
						description: locationByIdQuery.data?.location?.description,
						// replace `null` with the empty string so it aligns with the visual "None"
						// option. The selection mapping is also setup in Location.form.tsx.
						externalAisleId: locationByIdQuery.data?.location?.externalAisleId ?? '',

						id: locationByIdQuery.data?.location?.id,

						type: locationByIdQuery.data?.location?.type as StorageLocationType,

						x: locationByIdQuery.data?.location?.coordinates?.x || 0,
						y: locationByIdQuery.data?.location?.coordinates?.y || 0,
						z: locationByIdQuery.data?.location?.coordinates?.z || 0,
					} as StorageLocationInput
				}
			/>
			<br />
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteLocation,
						destructive: true,
						onAction: () => setModalVisible(true),
						outline: true,
					},
				]}
			/>
			<Modal
				open={modalVisible}
				title={messages.deleteLocation}
				onClose={() => setModalVisible(false)}
				primaryAction={{
					content: messages.deleteLocation,
					destructive: true,
					loading: deleteLocationMutation.fetching,
					onAction: () => void onDelete(),
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.confirmDeleteLocation}</Modal.Section>
			</Modal>
		</Page>
	);
}
