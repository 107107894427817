import { CircleDisabledMajor } from '@sixriver/lighthouse-icons';
import { Modal, Page, PageActions, Banner } from '@sixriver/lighthouse-web-community';
import { useCallback, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ContainerForm } from './Container.form';
import { useDeleteContainerMutation } from './graphql/DeleteContainer.f-api-graphql';
import { useGetContainerQuery } from './graphql/GetContainer.f-api-graphql';
import { useUpdateContainerMutation } from './graphql/UpdateContainer.f-api-graphql';
import {
	AddContainerInput,
	ContainerType,
	ContainerTypeWithFallback,
	UpdateContainerInput,
} from '../../api/fulfillment-api/types';
import { Error } from '../../components/Error';
import { fetchAllocationRules } from '../../helpers/allocation-rules';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';

export function EditContainer(): JSX.Element {
	const { messages } = useLocalization();

	// Routing
	const history = useHistory();
	const {
		params: { containerId },
	} = useRouteMatch<{ containerId: string }>();

	// Toasts
	const { showToast } = useToast();

	// Queries
	const [getContainerQuery] = useGetContainerQuery({
		pause: !containerId,
		requestPolicy: 'network-only', // Do not run this query unless a container id was provided
		variables: {
			id: containerId,
		},
	});

	const container = getContainerQuery.data?.container;

	// Mutations
	const [updateContainerMutation, executeUpdateContainerMutation] = useUpdateContainerMutation();
	const [deleteContainerMutation, executeDeleteContainerMutation] = useDeleteContainerMutation();

	// State
	const [modalVisible, setModalVisible] = useState(false);
	const [modalEditWarningVisible, setModalEditWarningVisible] = useState(false);
	const [pendingData, setPendingData] = useState<AddContainerInput | null>(null);
	const [associatedAllocationRulesWarning, setAssociatedAllocationRulesWarning] = useState(false);

	// Methods
	const onSubmit = useCallback(
		async (input) => {
			const { error } = await executeUpdateContainerMutation({
				input: {
					...input,
					id: containerId,
				},
			});

			if (!error) {
				showToast(messages.dataSaved);
				history.push(routes.container(containerId));
			}
		},
		[executeUpdateContainerMutation, containerId, showToast, history, messages],
	);

	const onSave = useCallback(
		async (input) => {
			if (input.externalId !== container?.externalId) {
				const hasRules = await hasAssociatedAllocationRules(container?.externalId || '');
				if (hasRules) {
					setModalEditWarningVisible(true);
					setPendingData(input);
					return;
				}
			}

			onSubmit(input);
		},
		[onSubmit, container],
	);

	const onDeleteContainer = useCallback(async () => {
		const hasRules = await hasAssociatedAllocationRules(container?.externalId || '');
		if (hasRules) {
			setAssociatedAllocationRulesWarning(true);
		}

		setModalVisible(true);
	}, [container]);

	const deleteContainer = async () => {
		const { error } = await executeDeleteContainerMutation({ id: containerId });
		if (!error) {
			history.push(routes.containers());
		}

		setModalVisible(false);
	};

	const hasAssociatedAllocationRules = async (externalId: string): Promise<boolean> => {
		try {
			const allocationRules = await fetchAllocationRules();

			return allocationRules.some((rule) => rule.criteria.containerType === externalId);
		} catch (error) {
			// Be cautious and assume there are associated rules if we can't fetch them
			return true;
		}
	};

	// Render
	if (getContainerQuery.fetching) {
		return <></>;
	}

	if (!container) {
		return <Error message={messages.dataNotFound} />;
	}

	if (getContainerQuery.error) {
		return <Error graphQLError={getContainerQuery.error} />;
	}
	// eslint-disable-next-line no-console
	console.log('container', container);

	container.description;

	const formDefaults: UpdateContainerInput = {
		...container,

		description: container.description || undefined,

		materialType: container.attributes?.packageMaterialType,
		// enums
		type:
			container?.type === ContainerTypeWithFallback.Unknown
				? undefined
				: (container?.type as unknown as ContainerType),
	};

	formDefaults.description;

	return (
		<Page
			title={container.name}
			breadcrumbs={[{ url: routes.container(containerId) }]}
			fullWidth
			subtitle={messages.containerTypes[container.type || 'unknown']}
		>
			<ContainerForm
				mode="edit"
				data={formDefaults}
				error={updateContainerMutation.error || deleteContainerMutation.error}
				onSubmit={onSave}
			/>
			<br />
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteContainer,
						destructive: true,
						onAction: onDeleteContainer,
						outline: true,
					},
				]}
			/>
			<Modal
				open={modalVisible}
				onClose={() => setModalVisible(false)}
				title={messages.deleteContainer}
				primaryAction={{
					content: messages.deleteContainer,
					destructive: true,
					loading: deleteContainerMutation.fetching,
					onAction: () => void deleteContainer(),
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				{associatedAllocationRulesWarning ? (
					<Modal.Section>
						<Banner
							status="warning"
							icon={CircleDisabledMajor}
							title={messages.associatedAllocationRules}
						>
							{messages.containerHasAssociatedAllocationRules}
						</Banner>
					</Modal.Section>
				) : null}
				<Modal.Section>{messages.confirmDeleteContainer}</Modal.Section>
			</Modal>
			<Modal
				open={modalEditWarningVisible}
				onClose={() => setModalEditWarningVisible(false)}
				title={messages.associatedAllocationRules}
				primaryAction={{
					content: messages.proceed,
					destructive: true,
					onAction: () => {
						onSubmit(pendingData);

						setPendingData(null);
					},
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						onAction: () => setModalEditWarningVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.containerHasAssociatedAllocationRules}</Modal.Section>
			</Modal>
		</Page>
	);
}
