import { Card, Frame, Loading, Page, Pagination, Stack } from '@sixriver/lighthouse-web-community';
import { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { PickWaveDetails } from './PickWaveDetails';
import { PickWaveOrdersFilter, PickWaveOrdersFilters } from './PickWaveOrdersFilters';
import { PickWaveOrdersTable } from './PickWaveOrdersTable';
import { PickWaveStatusBadge } from './PickWaveStatusBadge';
import { ResolvePickWaveButton } from './ResolvePickWaveButton';
import {
	GetPickWaveDocument,
	GetPickWaveQuery,
	GetPickWaveQueryVariables,
} from './graphql/GetPickWave.f-api-graphql';
import {
	GetPickWaveOrdersDocument,
	GetPickWaveOrdersQuery,
	GetPickWaveOrdersQueryVariables,
} from './graphql/GetPickWaveOrders.f-api-graphql';
import { useResolvePickWavesMutation } from './graphql/ResolvePickWaves.f-api-graphql';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';

const DEFAULT_FILTER: PickWaveOrdersFilter = { searchText: undefined, statuses: [] };

interface PickWaveRouteMatchParams {
	pickWaveId: string;
}

export function PickWave() {
	const {
		params: { pickWaveId },
	} = useRouteMatch<PickWaveRouteMatchParams>();
	const { messages } = useLocalization();

	const [getPickWaveQuery, refreshGetPickWaveQuery] = usePollingQuery<
		GetPickWaveQuery,
		GetPickWaveQueryVariables
	>({
		query: GetPickWaveDocument,
		variables: { id: pickWaveId },
	});
	const pickWave = getPickWaveQuery.data?.pickWave;

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	/**
	 * Pick Wave Orders Query
	 */
	const [ordersFilter, setOrdersFilters] = useState<PickWaveOrdersFilter>(DEFAULT_FILTER);
	const [getPickWaveOrdersQuery] = usePollingQuery<
		GetPickWaveOrdersQuery,
		GetPickWaveOrdersQueryVariables
	>({
		query: GetPickWaveOrdersDocument,
		variables: {
			...ordersFilter,
			after: paginationCursors[0],
			first: 50,
			pickWaveIds: [pickWaveId],
		},
	});
	const pickWaveOrders = useMemo(() => {
		return getPickWaveOrdersQuery.data?.pickWaveOrders.edges?.map((edge) => edge.node) ?? [];
	}, [getPickWaveOrdersQuery.data?.pickWaveOrders]);

	const getPickWaveOrdersPageInfo = useMemo(() => {
		return getPickWaveOrdersQuery.data?.pickWaveOrders.pageInfo;
	}, [getPickWaveOrdersQuery.data?.pickWaveOrders.pageInfo]);

	const handleOnNextPage = useCallback(() => {
		if (!getPickWaveOrdersPageInfo) {
			return;
		}
		const { endCursor } = getPickWaveOrdersPageInfo;
		if (endCursor) {
			setPaginationCursors((prev) => {
				return [endCursor].concat(prev);
			});
		}
	}, [getPickWaveOrdersPageInfo]);
	const handleOnPreviousPage = useCallback(() => {
		setPaginationCursors(paginationCursors.slice(1));
	}, [paginationCursors, setPaginationCursors]);

	/**
	 * Resolve Pick Wave
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, executeResolvePickWaveMutation] = useResolvePickWavesMutation();
	const handleResolvePickWave = useCallback(async () => {
		if (!!pickWave) {
			const result = await executeResolvePickWaveMutation({ ids: [pickWave.id] });

			if (!result.error) {
				await refreshGetPickWaveQuery();
			}
		}
	}, [executeResolvePickWaveMutation, pickWave, refreshGetPickWaveQuery]);

	if (getPickWaveQuery.fetching) {
		return (
			<Frame>
				<Loading />
			</Frame>
		);
	}

	if (getPickWaveQuery.error) {
		return <h1>{getPickWaveQuery.error}</h1>;
	}

	if (!pickWave) {
		return <h1>{messages.pickWaveNotFound}</h1>;
	}

	return (
		<Page
			fullWidth
			breadcrumbs={[{ content: messages.pickWaves, url: routes.pickWaves() }]}
			title={pickWave.id}
			titleMetadata={<PickWaveStatusBadge pickWave={pickWave} />}
			primaryAction={<ResolvePickWaveButton pickWave={pickWave} onClick={handleResolvePickWave} />}
		>
			<PickWaveDetails pickWave={pickWave} />
			<Card title="Orders">
				<Card.Section>
					<PickWaveOrdersFilters filter={ordersFilter} onChange={setOrdersFilters} />
				</Card.Section>
				<PickWaveOrdersTable loading={getPickWaveOrdersQuery.fetching} orders={pickWaveOrders} />
				<Card.Section>
					<Stack distribution="center">
						<Pagination
							hasNext={getPickWaveOrdersPageInfo?.hasNextPage}
							hasPrevious={paginationCursors.length > 0}
							onNext={handleOnNextPage}
							onPrevious={handleOnPreviousPage}
						/>
					</Stack>
				</Card.Section>
			</Card>
		</Page>
	);
}
