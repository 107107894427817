import {
	GetDevicesDocument,
	GetDevicesQuery,
	GetDevicesQueryVariables,
} from './graphql/GetDevices.f-api-graphql';
import {
	GetMapPointConfigsDocument,
	GetMapPointConfigsQuery,
	GetMapPointConfigsQueryVariables,
} from './graphql/GetMapPointConfigs.f-api-graphql';
import { useGetUserByBadgeBarcodeQuery } from './graphql/GetUserByBadgeBarcode.f-api-graphql';
import { useGetWorkAreasQuery } from './graphql/GetWorkAreas.f-api-graphql';
import { usePollingQuery } from '../../hooks/usePollingQuery';

export function useWorkAreasQuery(): any {
	const [workAreasQuery] = useGetWorkAreasQuery();

	const results = workAreasQuery.data?.workAreas || [];

	return { error: workAreasQuery.error, fetching: workAreasQuery.fetching, results };
}

export function useDevicesQuery(): any {
	const [getDevicesQuery] = usePollingQuery<GetDevicesQuery, GetDevicesQueryVariables>({
		pause: !navigator.onLine,
		pollInterval: 10_000,
		query: GetDevicesDocument,
	});

	const results = getDevicesQuery.data?.devices?.edges.map((edge) => edge.node) || [];

	return { error: getDevicesQuery.error, fetching: getDevicesQuery.fetching, results };
}

export function useMapPointsQuery(): any {
	const [{ error, fetching, data }, refetch] = usePollingQuery<
		GetMapPointConfigsQuery,
		GetMapPointConfigsQueryVariables
	>({
		pollInterval: 300_000, // 5 minutes
		query: GetMapPointConfigsDocument,
	});

	const results = data?.mapPointConfigs || [];

	return { error, fetching, refetch, results };
}

export function useUserQuery(badgeBarcode: string, visible?: boolean): any {
	const [{ error, fetching, data }] = useGetUserByBadgeBarcodeQuery({
		pause: !badgeBarcode || !visible,
		requestPolicy: 'cache-first',
		variables: {
			badgeBarcode,
		},
	});

	const result = data?.userByBadgeBarcode || null;

	return { error, fetching, result };
}
