import { ChoiceList } from '@sixriver/lighthouse-web-community';
import { noop } from 'lodash';

import { JobStatus } from '../../../api/warehouse-api/types';
import { useLocalization } from '../../../hooks/useLocalization';

interface StatusesFilterProps {
	selected?: JobStatus[];
	onChange?: (selected: JobStatus[], name: string) => void;
}

export function StatusesFilter({ selected = [], onChange = noop }: StatusesFilterProps) {
	const { messages } = useLocalization();

	return (
		<ChoiceList
			title={messages.orderStatus}
			titleHidden
			choices={[
				{
					label: messages.unassigned,
					value: JobStatus.Unassigned,
				},
				{
					label: messages.assignedToWall,
					value: JobStatus.AssignedToSortWall,
				},
				{
					label: messages.inProgress,
					value: JobStatus.InProgress,
				},
				{
					label: messages.interrupted,
					value: JobStatus.Interrupted,
				},
				{
					label: messages.completed,
					value: JobStatus.Completed,
				},
				{
					label: messages.canceled,
					value: JobStatus.Canceled,
				},
				{
					label: messages.awaitingUDI,
					value: JobStatus.AwaitingUdi,
				},
			]}
			selected={selected}
			onChange={onChange}
			allowMultiple
		/>
	);
}
