// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetConfigQuery = { __typename?: 'Query', config?: { __typename?: 'Config', countJobsUrl?: string | null, moveJobsUrl?: string | null } | null };


export const GetConfigDocument = gql`
    query fapi_GetConfig {
  config: configuration {
    countJobsUrl
    moveJobsUrl
  }
}
    `;

export function useGetConfigQuery(options?: Omit<Urql.UseQueryArgs<GetConfigQueryVariables>, 'query'>) {
  return Urql.useQuery<GetConfigQuery, GetConfigQueryVariables>({ query: GetConfigDocument, ...options });
};