// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrderStatsQueryVariables = Types.Exact<{
  isLate?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isShorted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isAwaitingInventory?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  expectedShipDateFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  expectedShipDateTo?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type GetOrderStatsQuery = { __typename?: 'Query', orderStats: { __typename?: 'JobStats', total: number, lineStats: { __typename?: 'LineStats', total: number, totalDone: number, totalQuantity: number, totalAllocated: number }, statusStats: Array<{ __typename?: 'JobStatusStats', key: Types.JobStatus, total: number, lineStats: { __typename?: 'LineStats', total: number, totalDone: number, totalQuantity: number, totalAllocated: number } }>, lateStats: { __typename?: 'LateStats', total: number, lineStats: { __typename?: 'LineStats', total: number, totalDone: number, totalQuantity: number, totalAllocated: number } }, expectedShipDateStats: Array<{ __typename?: 'ExpectedShipDateStats', key: number, total: number, statusStats: Array<{ __typename?: 'JobStatusStats', key: Types.JobStatus, total: number, lineStats: { __typename?: 'LineStats', total: number, totalDone: number, totalQuantity: number, totalAllocated: number } }>, lineStats: { __typename?: 'LineStats', total: number, totalDone: number, totalQuantity: number, totalAllocated: number } }> } };


export const GetOrderStatsDocument = gql`
    query wapi_GetOrderStats($isLate: Boolean, $isShorted: Boolean, $isAwaitingInventory: Boolean, $expectedShipDateFrom: DateTime, $expectedShipDateTo: DateTime) {
  orderStats(
    isLate: $isLate
    isShorted: $isShorted
    isAwaitingInventory: $isAwaitingInventory
    expectedShipDateFrom: $expectedShipDateFrom
    expectedShipDateTo: $expectedShipDateTo
  ) {
    total
    lineStats {
      total
      totalDone
      totalQuantity
      totalAllocated
    }
    statusStats {
      key
      total
      lineStats {
        total
        totalDone
        totalQuantity
        totalAllocated
      }
    }
    lateStats {
      total
      lineStats {
        total
        totalDone
        totalQuantity
        totalAllocated
      }
    }
    expectedShipDateStats {
      key
      total
      statusStats {
        key
        total
        lineStats {
          total
          totalDone
          totalQuantity
          totalAllocated
        }
      }
      lineStats {
        total
        totalDone
        totalQuantity
        totalAllocated
      }
    }
  }
}
    `;

export function useGetOrderStatsQuery(options?: Omit<Urql.UseQueryArgs<GetOrderStatsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrderStatsQuery, GetOrderStatsQueryVariables>({ query: GetOrderStatsDocument, ...options });
};