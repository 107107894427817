// THIS FILE IS GENERATED, DO NOT EDIT!
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
};

/** New Container data */
export type AddContainerInput = {
  /** The container's barcode */
  barcode?: InputMaybe<Scalars['String']['input']>;
  /** The number of columns in the container */
  columns?: Scalars['Int']['input'];
  /** The description of the container */
  description?: Scalars['String']['input'];
  /** Should the container be enabled */
  enabled: Scalars['Boolean']['input'];
  /** The container's external identifier */
  externalId: Scalars['String']['input'];
  /** The height of the container in millimeters */
  height: Scalars['Float']['input'];
  /** The url to an image of the container */
  imageURL?: InputMaybe<Scalars['String']['input']>;
  /** Special instructions for the container */
  instructions?: InputMaybe<Scalars['String']['input']>;
  /** Should this container be tracked in the inventory system? */
  inventory: Scalars['Boolean']['input'];
  /** The length of the container in millimeters */
  length: Scalars['Float']['input'];
  /** The container material type */
  materialType?: InputMaybe<Scalars['String']['input']>;
  /** The name of the container */
  name: Scalars['String']['input'];
  /** The number of rows in the container */
  rows?: Scalars['Int']['input'];
  /** The type of container */
  type?: ContainerType;
  /** The weight of the container in grams */
  weight?: Scalars['Float']['input'];
  /** The width of the container in millimeters */
  width: Scalars['Float']['input'];
};

export type Aisle = {
  __typename?: 'Aisle';
  externalId: Scalars['String']['output'];
  /** A unique ID. */
  id: Scalars['ID']['output'];
  workAreaId?: Maybe<Scalars['String']['output']>;
};

export type BatteryThresholds = {
  __typename?: 'BatteryThresholds';
  critical?: Maybe<Scalars['Int']['output']>;
  viable?: Maybe<Scalars['Int']['output']>;
};

export type BulkOrder = {
  __typename?: 'BulkOrder';
  assetTypes: Array<BulkOrderProduct>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expectedShipDate?: Maybe<Scalars['DateTime']['output']>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  jobFlowRuleId: Scalars['String']['output'];
  locations: Array<BulkOrderLocation>;
  lpn: Scalars['String']['output'];
  merchant?: Maybe<Scalars['String']['output']>;
  ordersCollectedCount: Scalars['Float']['output'];
  ordersPackedCount: Scalars['Float']['output'];
  progress?: Maybe<BulkOrderProgress>;
  status: BulkOrderStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export enum BulkOrderByFields {
  BatchId = 'BatchId',
  CreatedAt = 'CreatedAt',
  Cutoff = 'Cutoff',
  Merchant = 'Merchant'
}

export type BulkOrderConnection = {
  __typename?: 'BulkOrderConnection';
  edges: Array<BulkOrderEdge>;
  pageInfo: PageInfo;
};

export type BulkOrderEdge = {
  __typename?: 'BulkOrderEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: BulkOrder;
};

export type BulkOrderJobFlowRuleInput = {
  /** The asset type ID. */
  assetTypeId: Scalars['String']['input'];
  /** Optional rule description. */
  description?: InputMaybe<Scalars['String']['input']>;
};

export type BulkOrderLocation = {
  __typename?: 'BulkOrderLocation';
  address?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalAisleId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  units?: Maybe<Scalars['Float']['output']>;
};

export type BulkOrderProduct = {
  __typename?: 'BulkOrderProduct';
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  unitsMoved?: Maybe<Scalars['Float']['output']>;
  unitsPacked?: Maybe<Scalars['Float']['output']>;
  unitsRequired?: Maybe<Scalars['Float']['output']>;
};

export enum BulkOrderProgress {
  Complete = 'Complete',
  InProgress = 'InProgress',
  Unassigned = 'Unassigned'
}

export type BulkOrderRecommendation = {
  __typename?: 'BulkOrderRecommendation';
  assetTypes: Array<Product>;
  cutoff: Scalars['DateTime']['output'];
  /** A unique ID. */
  id: Scalars['ID']['output'];
  merchant?: Maybe<Scalars['String']['output']>;
  ordersCollectedCount: Scalars['Float']['output'];
};

export enum BulkOrderStatus {
  Closed = 'Closed',
  Open = 'Open',
  Sealed = 'Sealed'
}

export type ChuckLoginLog = {
  __typename?: 'ChuckLoginLog';
  chuckName: Scalars['String']['output'];
  happenedAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  macAddr: Scalars['String']['output'];
};

export type Config = {
  __typename?: 'Config';
  asnCancellable: Scalars['Boolean']['output'];
  asnCloseable: Scalars['Boolean']['output'];
  autoHealingEnabled: Scalars['Boolean']['output'];
  batteryThresholds: BatteryThresholds;
  containerMaterialTypes: Array<Scalars['String']['output']>;
  countJobsUrl?: Maybe<Scalars['String']['output']>;
  deliveryCancellable: Scalars['Boolean']['output'];
  deliveryCloseable: Scalars['Boolean']['output'];
  deviceDwellThreshold: Scalars['Float']['output'];
  deviceOfflineThreshold: Scalars['Float']['output'];
  enabledDeviceTypes: Array<DeviceType>;
  environment?: Maybe<Scalars['String']['output']>;
  equipmentTypes: Array<Scalars['String']['output']>;
  experimentalFlags: Array<Scalars['String']['output']>;
  expirationDateOffset: Scalars['Float']['output'];
  externalInventoryEnabled: Scalars['Boolean']['output'];
  healingEnabled: Scalars['Boolean']['output'];
  healingStrategies: Array<HealingStrategy>;
  highPriorityChuckEnabled: Scalars['Boolean']['output'];
  highPriorityChuckReasonsOrdered: Array<HighPriorityReason>;
  inventoryEnabled: Scalars['Boolean']['output'];
  jobAllocationMethods: Array<JobAllocationMethod>;
  jobFlowRuleFilters: Array<JobFlowRuleFilter>;
  mapPointQueueDepthEditable: Scalars['Boolean']['output'];
  mapPointStatusEditable: Scalars['Boolean']['output'];
  moveJobsUrl?: Maybe<Scalars['String']['output']>;
  orderTagEnabled: Scalars['Boolean']['output'];
  packoutEnabled: Scalars['Boolean']['output'];
  /** node `process.env` results */
  processEnv?: Maybe<Scalars['String']['output']>;
  putawayEnabled: Scalars['Boolean']['output'];
  receivingEnabled: Scalars['Boolean']['output'];
  receivingOverageTolerance: Scalars['Float']['output'];
  replenishmentEnabled: Scalars['Boolean']['output'];
  returnsEnabled: Scalars['Boolean']['output'];
  reworkEnabled: Scalars['Boolean']['output'];
  shippingEnabled: Scalars['Boolean']['output'];
  siteId?: Maybe<Scalars['String']['output']>;
  siteName: Scalars['String']['output'];
  sortationEnabled: Scalars['Boolean']['output'];
  supportEnabled: Scalars['Boolean']['output'];
  warehouseApiEnabled: Scalars['Boolean']['output'];
  workAreasEnabled: Scalars['Boolean']['output'];
};

export type Container = {
  __typename?: 'Container';
  /** The container's attributes such as package material type */
  attributes?: Maybe<ContainerAttributes>;
  /** The container's barcode */
  barcode?: Maybe<Scalars['String']['output']>;
  /** The number of columns in the container */
  columns: Scalars['Int']['output'];
  /** The description of the container */
  description?: Maybe<Scalars['String']['output']>;
  /** TODO */
  enabled: Scalars['Boolean']['output'];
  externalId: Scalars['String']['output'];
  height: Scalars['Float']['output'];
  /** A unique ID. */
  id: Scalars['ID']['output'];
  /** The url to an image of the container */
  imageURL?: Maybe<Scalars['String']['output']>;
  /** Special instructions for the container */
  instructions?: Maybe<Scalars['String']['output']>;
  /** TODO */
  inventory: Scalars['Boolean']['output'];
  length: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** The number of rows in the container */
  rows: Scalars['Int']['output'];
  type: ContainerTypeWithFallback;
  volume: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
};

export type ContainerAttributes = {
  __typename?: 'ContainerAttributes';
  packageMaterialType?: Maybe<Scalars['String']['output']>;
};

export enum ContainerOrderByFields {
  Description = 'description',
  Id = 'id',
  Length = 'length',
  Name = 'name'
}

export enum ContainerType {
  Picking = 'picking',
  Shipping = 'shipping',
  Storage = 'storage'
}

export enum ContainerTypeWithFallback {
  Picking = 'picking',
  Shipping = 'shipping',
  Storage = 'storage',
  Unknown = 'unknown'
}

export type ContainersPage = {
  __typename?: 'ContainersPage';
  cursor?: Maybe<Scalars['String']['output']>;
  results: Array<Container>;
};

export type Count = {
  __typename?: 'Count';
  count: Scalars['Int']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Cutoff = {
  __typename?: 'Cutoff';
  dates: Array<Scalars['DateTime']['output']>;
};

export enum DatePrecision {
  Century = 'century',
  Day = 'day',
  Decade = 'decade',
  Hour = 'hour',
  Microseconds = 'microseconds',
  Millennium = 'millennium',
  Milliseconds = 'milliseconds',
  Minute = 'minute',
  Month = 'month',
  Quarter = 'quarter',
  Second = 'second',
  Week = 'week',
  Year = 'year'
}

export type Device = {
  __typename?: 'Device';
  badge?: Maybe<Scalars['String']['output']>;
  batteryLevel?: Maybe<Scalars['Int']['output']>;
  calibratedAt?: Maybe<Scalars['DateTime']['output']>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  orders: Array<Order>;
  packouts: Array<Packout>;
  phase: Scalars['String']['output'];
  pickingJobs: Array<Picking>;
  presence?: Maybe<DevicePresence>;
  sortations: Array<Sortation>;
  ssid?: Maybe<Scalars['String']['output']>;
  state: DeviceState;
  type?: Maybe<DeviceType>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  workArea?: Maybe<WorkArea>;
  workAreaId?: Maybe<Scalars['String']['output']>;
};

export type DeviceConnection = {
  __typename?: 'DeviceConnection';
  edges: Array<DeviceEdge>;
  pageInfo: PageInfo;
};

export type DeviceEdge = {
  __typename?: 'DeviceEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Device;
};

export enum DeviceOrderByFields {
  BatteryLevel = 'BatteryLevel',
  LastUsed = 'LastUsed',
  Name = 'Name'
}

export type DevicePresence = {
  __typename?: 'DevicePresence';
  everOnline: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isOnline: Scalars['Boolean']['output'];
  lastOnline?: Maybe<Scalars['DateTime']['output']>;
  safeToForceOffline: Scalars['Boolean']['output'];
};

export enum DeviceState {
  Idle = 'Idle',
  InUse = 'InUse',
  Off = 'Off',
  Unknown = 'Unknown'
}

export enum DeviceType {
  Chuck = 'Chuck',
  Handheld = 'Handheld',
  PackoutTerminal = 'PackoutTerminal',
  SortationKiosk = 'SortationKiosk',
  TerminalOnWheels = 'TerminalOnWheels'
}

export type EquipmentDevice = {
  __typename?: 'EquipmentDevice';
  id?: Maybe<Scalars['String']['output']>;
  modelConfigId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type EquipmentDeviceConfig = {
  __typename?: 'EquipmentDeviceConfig';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Exception = {
  __typename?: 'Exception';
  availableHealStrategies: Array<Scalars['String']['output']>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  isHealable: Scalars['Boolean']['output'];
  previousHealStrategies: Array<Scalars['String']['output']>;
  unhealableReason?: Maybe<Scalars['String']['output']>;
};

export enum GoalStates {
  Cancelled = 'CANCELLED',
  Cancelling = 'CANCELLING',
  CancelRequested = 'CANCEL_REQUESTED',
  Complete = 'COMPLETE',
  Created = 'CREATED',
  Creating = 'CREATING',
  Failed = 'FAILED',
  Hold = 'HOLD',
  Paused = 'PAUSED',
  Ready = 'READY',
  Running = 'RUNNING',
  Terminated = 'TERMINATED',
  TerminateRequested = 'TERMINATE_REQUESTED',
  Terminating = 'TERMINATING'
}

export type HealPlan = {
  __typename?: 'HealPlan';
  healJob?: Maybe<Picking>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  status: HealPlanStatus;
  strategy?: Maybe<Scalars['String']['output']>;
  timeline: HealPlanTimeline;
};

export type HealPlanConnection = {
  __typename?: 'HealPlanConnection';
  edges: Array<HealPlanEdge>;
  pageInfo: PageInfo;
};

export type HealPlanEdge = {
  __typename?: 'HealPlanEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: HealPlan;
};

export enum HealPlanStatus {
  Accepted = 'ACCEPTED',
  Applied = 'APPLIED',
  Cancelled = 'CANCELLED',
  CancelledApplied = 'CANCELLED_APPLIED',
  PendingApply = 'PENDING_APPLY',
  Ready = 'READY',
  Running = 'RUNNING'
}

export type HealPlanTimeline = {
  __typename?: 'HealPlanTimeline';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type HealPlanV2 = {
  __typename?: 'HealPlanV2';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  strategy?: Maybe<Scalars['String']['output']>;
};

export enum HealingStrategy {
  Automated = 'Automated',
  Manual = 'Manual'
}

export enum HighPriorityReason {
  BatteryLevel = 'BatteryLevel',
  DwellTime = 'DwellTime',
  IsExpedite = 'IsExpedite',
  IsSlaDanger = 'IsSlaDanger',
  TimeSinceInduct = 'TimeSinceInduct'
}

export enum IcqaReasonCodes {
  IcqaMiscAdjustment = 'ICQA_MISC_ADJUSTMENT',
  IcqaResolutionCount = 'ICQA_RESOLUTION_COUNT'
}

export type InServiceOf = {
  __typename?: 'InServiceOf';
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Indication = {
  __typename?: 'Indication';
  carrierName?: Maybe<Scalars['String']['output']>;
  count: Scalars['Float']['output'];
  expectedShipDate: Scalars['DateTime']['output'];
  indication: Scalars['String']['output'];
  isTerminal: Scalars['Boolean']['output'];
};

export type InventoryAdjustedEvent = InventoryEvent & {
  __typename?: 'InventoryAdjustedEvent';
  balance?: Maybe<Scalars['Int']['output']>;
  conflictReasons: Array<InventoryConflictReason>;
  hasException: Scalars['Boolean']['output'];
  intent?: Maybe<IcqaReasonCodes>;
  quantity: Scalars['Int']['output'];
  quantityAttempted?: Maybe<Scalars['Int']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type InventoryAmnestyPutEvent = InventoryEvent & {
  __typename?: 'InventoryAmnestyPutEvent';
  balance?: Maybe<Scalars['Int']['output']>;
  conflictReasons: Array<InventoryConflictReason>;
  hasException: Scalars['Boolean']['output'];
  intent?: Maybe<IcqaReasonCodes>;
  quantity: Scalars['Int']['output'];
  quantityAttempted?: Maybe<Scalars['Int']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export enum InventoryCondition {
  Damaged = 'Damaged',
  Expired = 'Expired',
  Immature = 'Immature',
  MerchantHold = 'MerchantHold',
  Mislabeled = 'Mislabeled',
  Other = 'Other',
  Rework = 'Rework'
}

export enum InventoryConflictReason {
  Miscount = 'Miscount',
  MixedExpirationDates = 'MixedExpirationDates',
  MixedLotCodes = 'MixedLotCodes',
  NegativeQuantity = 'NegativeQuantity',
  Other = 'Other',
  PickShort = 'PickShort',
  UnknownExpirationDate = 'UnknownExpirationDate',
  UnknownLotCode = 'UnknownLotCode',
  WrongExpirationDate = 'WrongExpirationDate',
  WrongLotCode = 'WrongLotCode'
}

export enum InventoryDisposition {
  Dispose = 'Dispose',
  Donate = 'Donate',
  Other = 'Other',
  Recycle = 'Recycle',
  ReturnToMerchant = 'ReturnToMerchant',
  Rework = 'Rework'
}

export type InventoryEvent = {
  balance?: Maybe<Scalars['Int']['output']>;
  conflictReasons: Array<InventoryConflictReason>;
  hasException: Scalars['Boolean']['output'];
  intent?: Maybe<IcqaReasonCodes>;
  quantity?: Maybe<Scalars['Int']['output']>;
  quantityAttempted?: Maybe<Scalars['Int']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type InventoryEventPage = {
  __typename?: 'InventoryEventPage';
  cursor?: Maybe<Scalars['String']['output']>;
  results: Array<InventoryEvent>;
};

export type InventoryInspectedEvent = InventoryEvent & {
  __typename?: 'InventoryInspectedEvent';
  balance?: Maybe<Scalars['Int']['output']>;
  conflictReasons: Array<InventoryConflictReason>;
  hasException: Scalars['Boolean']['output'];
  intent?: Maybe<IcqaReasonCodes>;
  quantity?: Maybe<Scalars['Int']['output']>;
  quantityAttempted?: Maybe<Scalars['Int']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type InventoryMovedEvent = InventoryEvent & {
  __typename?: 'InventoryMovedEvent';
  balance?: Maybe<Scalars['Int']['output']>;
  conflictReasons: Array<InventoryConflictReason>;
  detail?: Maybe<InventoryMovedEventDetail>;
  hasException: Scalars['Boolean']['output'];
  intent?: Maybe<IcqaReasonCodes>;
  quantity: Scalars['Int']['output'];
  quantityAttempted?: Maybe<Scalars['Int']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type InventoryMovedEventDetail = {
  __typename?: 'InventoryMovedEventDetail';
  destLocationAddress?: Maybe<Scalars['String']['output']>;
  destLocationId?: Maybe<Scalars['String']['output']>;
  sourceLocationAddress?: Maybe<Scalars['String']['output']>;
  sourceLocationId?: Maybe<Scalars['String']['output']>;
};

export type InventoryPickedEvent = InventoryEvent & {
  __typename?: 'InventoryPickedEvent';
  balance?: Maybe<Scalars['Int']['output']>;
  conflictReasons: Array<InventoryConflictReason>;
  detail?: Maybe<InventoryPickedEventDetail>;
  hasException: Scalars['Boolean']['output'];
  intent?: Maybe<IcqaReasonCodes>;
  quantity: Scalars['Int']['output'];
  quantityAttempted?: Maybe<Scalars['Int']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type InventoryPickedEventDetail = {
  __typename?: 'InventoryPickedEventDetail';
  containerId?: Maybe<Scalars['String']['output']>;
  projectionId?: Maybe<Scalars['String']['output']>;
};

export type InventorySatDefinition = {
  __typename?: 'InventorySATDefinition';
  condition?: Maybe<Scalars['String']['output']>;
  disposition?: Maybe<InventoryDisposition>;
  expiresAt?: Maybe<Scalars['String']['output']>;
  inServiceOf?: Maybe<Array<InServiceOf>>;
  lot?: Maybe<Scalars['String']['output']>;
};

export type InventoryState = {
  __typename?: 'InventoryState';
  assetTypeId: Scalars['String']['output'];
  conditions: Array<InventoryCondition>;
  conflictReasons: Array<InventoryConflictReason>;
  conflicted: Scalars['Boolean']['output'];
  /** A unique ID. */
  id: Scalars['ID']['output'];
  liveQuantity?: Maybe<Scalars['Int']['output']>;
  storageLocationId: Scalars['String']['output'];
  subAssetDescriptorId?: Maybe<Scalars['String']['output']>;
  subAssetType?: Maybe<InventoryTag>;
  subLocation?: Maybe<InventorySubLocation>;
  subLocationDescriptorId?: Maybe<Scalars['String']['output']>;
  totalPlannedAddQuantity: Scalars['Int']['output'];
  totalPlannedRemoveQuantity: Scalars['Int']['output'];
};

export type InventorySubLocation = {
  __typename?: 'InventorySubLocation';
  definition?: Maybe<InventorySubLocationDefinition>;
  id?: Maybe<Scalars['String']['output']>;
};

export type InventorySubLocationDefinition = {
  __typename?: 'InventorySubLocationDefinition';
  externalId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type InventoryTag = {
  __typename?: 'InventoryTag';
  definition?: Maybe<InventorySatDefinition>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Job = {
  __typename?: 'Job';
  bulkOrder?: Maybe<BulkOrder>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  destinationNode?: Maybe<Scalars['String']['output']>;
  eligiblePickDeviceClasses: Array<Scalars['String']['output']>;
  exceptions: Array<Exception>;
  expectedShipDate?: Maybe<Scalars['DateTime']['output']>;
  externalContainerId?: Maybe<Scalars['String']['output']>;
  /** Identifier provided by the customer. Not guaranteed to be unique. */
  externalId: Scalars['String']['output'];
  healJobs: Array<Job>;
  healPlans: Array<HealPlanV2>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  isAwaitingInventory: Scalars['Boolean']['output'];
  isCancelable: Scalars['Boolean']['output'];
  isHealJob: Scalars['Boolean']['output'];
  isLate: Scalars['Boolean']['output'];
  isOrder: Scalars['Boolean']['output'];
  isPackoutRequired: Scalars['Boolean']['output'];
  isShippedShort: Scalars['Boolean']['output'];
  isShorted: Scalars['Boolean']['output'];
  isTerminal: Scalars['Boolean']['output'];
  lines: Array<LineV2>;
  mfp?: Maybe<Mfp>;
  orders: Array<Job>;
  packout?: Maybe<Packout>;
  pickStrategy?: Maybe<PickStrategy>;
  pickingJobs: Array<Job>;
  servicedBy: Array<Job>;
  servicing: Array<Job>;
  servicingSortations: Array<Sortation>;
  stagingIdentifier?: Maybe<Scalars['String']['output']>;
  status: JobStatus;
  tag?: Maybe<Scalars['String']['output']>;
  tasks: Array<TaskV2>;
  totalLineCustomers: Array<Scalars['String']['output']>;
  totalLineQuantities: LineQuantities;
  totalLines: Count;
  trackingNumbers?: Maybe<Array<Scalars['String']['output']>>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  usedPickDeviceClasses: Array<Scalars['String']['output']>;
  usedPickEquipment: Array<PickEquipment>;
  workOrderTypes?: Maybe<Array<WorkOrderType>>;
};

export enum JobAllocationMethod {
  Batch = 'Batch',
  BulkOrders = 'BulkOrders',
  Consolidation = 'Consolidation',
  Discrete = 'Discrete',
  Group = 'Group',
  MobileSortWall = 'MobileSortWall',
  SingleLineMultiUnit = 'SingleLineMultiUnit',
  SingleLineSingleUnit = 'SingleLineSingleUnit'
}

export type JobConnection = {
  __typename?: 'JobConnection';
  edges: Array<JobEdge>;
  pageInfo: PageInfo;
};

export type JobContainer = {
  __typename?: 'JobContainer';
  /** The container's attributes such as package material type */
  attributes?: Maybe<ContainerAttributes>;
  /** The container's barcode */
  barcode?: Maybe<Scalars['String']['output']>;
  /** The number of columns in the container */
  columns: Scalars['Int']['output'];
  /** The description of the container */
  description?: Maybe<Scalars['String']['output']>;
  /** TODO */
  enabled: Scalars['Boolean']['output'];
  externalId: Scalars['String']['output'];
  height: Scalars['Float']['output'];
  /** A unique ID. */
  id: Scalars['ID']['output'];
  /** The url to an image of the container */
  imageURL?: Maybe<Scalars['String']['output']>;
  /** Special instructions for the container */
  instructions?: Maybe<Scalars['String']['output']>;
  /** TODO */
  inventory: Scalars['Boolean']['output'];
  length: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** The number of rows in the container */
  rows: Scalars['Int']['output'];
  type: ContainerTypeWithFallback;
  volume: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
};

export type JobEdge = {
  __typename?: 'JobEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Job;
};

export type JobFlowRule = {
  __typename?: 'JobFlowRule';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  rule: Scalars['JSONObject']['output'];
  ruleDescription: Scalars['String']['output'];
  ruleSubType: Array<Scalars['String']['output']>;
};

export type JobFlowRuleFilter = {
  __typename?: 'JobFlowRuleFilter';
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  type: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

/** Add or edit a Handheld Job Flow Rule */
export type JobFlowRuleInput = {
  /** A system-generated ID */
  id?: InputMaybe<Scalars['String']['input']>;
  /** The rule criteria. See Allocator docs */
  rule: Scalars['JSONObject']['input'];
  /** A friendly description, e.g. 'Picking with forklift' */
  ruleDescription: Scalars['String']['input'];
  /** The equipment types, e.g. ['Forklift', 'Uboat']. */
  ruleSubType: Array<Scalars['String']['input']>;
};

/** Re-priorities Job Flow Rules */
export type JobFlowRulePrioritiesInput = {
  /** All handheld JFR ids in priority order, high to low */
  ids: Array<Scalars['String']['input']>;
};

export type JobOrderBy = {
  direction: OrderByDirection;
  field: JobOrderByFields;
};

export enum JobOrderByFields {
  CreatedAt = 'createdAt',
  ExpectedShipDate = 'expectedShipDate',
  ExternalId = 'externalId'
}

export enum JobStatus {
  AssignedToBatch = 'AssignedToBatch',
  AssignedToSortWall = 'AssignedToSortWall',
  Canceled = 'Canceled',
  Expired = 'Expired',
  Interrupted = 'Interrupted',
  Loaded = 'Loaded',
  Packable = 'Packable',
  Packed = 'Packed',
  Packing = 'Packing',
  Picked = 'Picked',
  Picking = 'Picking',
  Sortable = 'Sortable',
  Sorted = 'Sorted',
  Sorting = 'Sorting',
  Staged = 'Staged',
  Unassigned = 'Unassigned',
  Unknown = 'Unknown'
}

export type JobsCount = {
  __typename?: 'JobsCount';
  assignedToBatch: Scalars['Int']['output'];
  assignedToSortWall: Scalars['Int']['output'];
  awaitingInventory: Scalars['Int']['output'];
  cancelled: Scalars['Int']['output'];
  completed: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  inProgress: Scalars['Int']['output'];
  interrupted: Scalars['Int']['output'];
  late: Scalars['Int']['output'];
  orders: Scalars['Int']['output'];
  shorted: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  unassigned: Scalars['Int']['output'];
  unknown: Scalars['Int']['output'];
};

export type KitComponentProductDetail = {
  __typename?: 'KitComponentProductDetail';
  customerIdentifier: Scalars['String']['output'];
  eachQuantity?: Maybe<Scalars['Float']['output']>;
  exceptions: Array<PreKitException>;
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  quantityUsed: Scalars['Float']['output'];
  totalRequired?: Maybe<Scalars['Float']['output']>;
};

export type KitProduct = {
  __typename?: 'KitProduct';
  customerIdentifier: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  needsInspection: Scalars['Boolean']['output'];
};

export type KittingProject = SpecialProject & {
  __typename?: 'KittingProject';
  componentProducts: Array<KitComponentProductDetail>;
  dateReceived?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  /** Each type of special project will determine if this boolean needs to be set to true */
  hasIssues?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  instructions: Array<ProjectInstructions>;
  merchant?: Maybe<Scalars['String']['output']>;
  product: KitProduct;
  quantity: Scalars['Float']['output'];
  quantityRequested: Scalars['Float']['output'];
  status?: Maybe<GoalStates>;
  totalTimeLogged: Scalars['Float']['output'];
  type?: Maybe<SpecialProjectType>;
};

export type Line = {
  __typename?: 'Line';
  customer?: Maybe<Customer>;
  destination?: Maybe<StorageLocation>;
  deviceClass: Array<Scalars['String']['output']>;
  doneQuantity: Scalars['Int']['output'];
  equipmentId: Array<Scalars['String']['output']>;
  equipmentModelId: Array<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  product?: Maybe<Product>;
  quantity: Scalars['Int']['output'];
  source?: Maybe<StorageLocation>;
  status?: Maybe<Array<LineStatus>>;
  timeline: LineTimeline;
  user?: Maybe<User>;
};

export type LineConnection = {
  __typename?: 'LineConnection';
  edges: Array<LineEdge>;
  pageInfo: PageInfo;
};

export type LineConnectionV2 = {
  __typename?: 'LineConnectionV2';
  edges: Array<LineEdgeV2>;
  pageInfo: PageInfo;
};

export type LineEdge = {
  __typename?: 'LineEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Line;
};

export type LineEdgeV2 = {
  __typename?: 'LineEdgeV2';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: LineV2;
};

export type LineOrderCounts = {
  __typename?: 'LineOrderCounts';
  All: Scalars['Int']['output'];
  Complete: Scalars['Int']['output'];
  Exceptions: Scalars['Int']['output'];
  InProgress: Scalars['Int']['output'];
  Unassigned: Scalars['Int']['output'];
};

export type LinePickingCounts = {
  __typename?: 'LinePickingCounts';
  All: Scalars['Int']['output'];
  Exceptions: Scalars['Int']['output'];
};

export type LineQuantities = {
  __typename?: 'LineQuantities';
  assignedToBatchUnits: Scalars['Float']['output'];
  completedLines: Scalars['Float']['output'];
  completedUnits: Scalars['Float']['output'];
  inProgressUnits: Scalars['Float']['output'];
  loadedUnits: Scalars['Float']['output'];
  packedUnits: Scalars['Float']['output'];
  pickedUnits: Scalars['Float']['output'];
  sortedUnits: Scalars['Float']['output'];
  stagedUnits: Scalars['Float']['output'];
  totalUnits: Scalars['Float']['output'];
  unassignedUnits: Scalars['Float']['output'];
};

export enum LineStatus {
  AwaitingInventory = 'AWAITING_INVENTORY',
  CantProceed = 'CANT_PROCEED',
  Cleared = 'Cleared',
  Complete = 'Complete',
  DamagedProduct = 'DAMAGED_PRODUCT',
  Done = 'DONE',
  InProgress = 'InProgress',
  MfpOffline = 'MFP_OFFLINE',
  MissingProduct = 'MISSING_PRODUCT',
  MissingSourceContainer = 'MISSING_SOURCE_CONTAINER',
  Other = 'OTHER',
  Packed = 'PACKED',
  Repicked = 'REPICKED',
  SubLocationDepleted = 'SUB_LOCATION_DEPLETED',
  TapToScan = 'TAP_TO_SCAN',
  ToteTooFull = 'TOTE_TOO_FULL',
  Unassigned = 'Unassigned',
  WorkflowException = 'WORKFLOW_EXCEPTION'
}

export type LineStatusSummary = {
  __typename?: 'LineStatusSummary';
  /** The number of containers that were already cancelled */
  containerAlreadyCancelled: Scalars['Float']['output'];
  /** The number of containers that were already complete */
  containerAlreadyComplete: Scalars['Float']['output'];
  /** The number of containers that were a;ready inducted */
  containerAlreadyInducted: Scalars['Float']['output'];
  /** The number of containers that were not found */
  containerNotFound: Scalars['Float']['output'];
  /** The number of sucessfully cancelled containers */
  success: Scalars['Float']['output'];
  /** The number of containers that were unable to be cancelled due to an unknown error */
  unknownError: Scalars['Float']['output'];
};

export type LineTimeline = {
  __typename?: 'LineTimeline';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LineV2 = {
  __typename?: 'LineV2';
  allocatedQuantity: Scalars['Int']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  doneQuantity: Scalars['Int']['output'];
  eligiblePickDeviceClasses: Array<Scalars['String']['output']>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  isShorted?: Maybe<Scalars['Boolean']['output']>;
  jobId: Scalars['ID']['output'];
  pickEquipment: Array<PickEquipment>;
  productCustomerIdentifier?: Maybe<Scalars['String']['output']>;
  productDescription?: Maybe<Scalars['String']['output']>;
  productId: Scalars['String']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  servicedBy: Array<LineV2>;
  status?: Maybe<Array<LineStatus>>;
  storageLocation?: Maybe<StorageLocation>;
  tasks: Array<TaskV2>;
  updatedAt: Scalars['DateTime']['output'];
  workArea?: Maybe<WorkArea>;
  workAreaId?: Maybe<Scalars['String']['output']>;
};

export enum LinesViews {
  All = 'All',
  Complete = 'Complete',
  Exceptions = 'Exceptions',
  InProgress = 'InProgress',
  Unassigned = 'Unassigned'
}

export type LoggedAction = {
  __typename?: 'LoggedAction';
  actionType: LoggedActionType;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** A unique ID. */
  id: Scalars['ID']['output'];
  lineStatusSummary: LineStatusSummary;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['String']['output'];
  /** The user who initiated the action */
  user?: Maybe<User>;
};

export type LoggedActionPage = {
  __typename?: 'LoggedActionPage';
  cursor?: Maybe<Scalars['String']['output']>;
  results: Array<LoggedAction>;
};

export enum LoggedActionType {
  CancelOrder = 'cancelOrder'
}

export type MapPointConfig = {
  __typename?: 'MapPointConfig';
  enabled: Scalars['Boolean']['output'];
  mapPointName: Scalars['String']['output'];
  maxReservationCount: Scalars['Float']['output'];
};

export type MapPointConfigInput = {
  enabled: Scalars['Boolean']['input'];
  mapPointName: Scalars['String']['input'];
  maxReservationCount: Scalars['Float']['input'];
};

export type Media = {
  __typename?: 'Media';
  contentType: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Mfp = {
  __typename?: 'Mfp';
  name: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Attempts to add the container to inventory */
  addContainerToInventory: MutationResponse;
  addJobFlowRule: MutationResponse;
  addLocation: MutationResponse;
  addProduct: MutationResponse;
  addTagToUser: User;
  addTimeLog: MutationResponse;
  cancelJob: MutationResponse;
  cancelOrder: MutationResponse;
  cancelOrders: MutationResponse;
  cancelPickingJob: MutationResponse;
  cancelSortWall: MutationResponse;
  closeBulkOrder: MutationResponse;
  closeJobFlowRule: MutationResponse;
  createBatch: MutationResponse;
  /** Attempts to create a container with the given attributes */
  createContainer: MutationResponse;
  createSortWall: MutationResponse;
  createTag: Tag;
  createUser: MutationResponse;
  deactivateUser: MutationResponse;
  /** Attempts to delete a container with the given Id */
  deleteContainer: MutationResponse;
  deleteLocation: MutationResponse;
  deleteProduct: MutationResponse;
  /** Attempts to enable a disabled container with the given Id */
  disableContainer: MutationResponse;
  editJobFlowRule: MutationResponse;
  editLocation: MutationResponse;
  editMapPointConfig: MutationResponse;
  editProduct: MutationResponse;
  editTimeLog: MutationResponse;
  /** Attempts to disable a container with the given Id */
  enableContainer: MutationResponse;
  forceDeviceOffline?: Maybe<Device>;
  markAsComplete: MutationResponse;
  printSortationLabels: MutationResponse;
  /** @deprecated User badges are not supposed to be printed */
  printUserBadgeBarcode: MutationResponse;
  prioritizeJobFlowRules: MutationResponse;
  reactivateUser: MutationResponse;
  /** Attempts to remove the container from inventory */
  removeContainerFromInventory: MutationResponse;
  removeTagFromUser: User;
  reportIssues: MutationResponse;
  reprintBatchBarcode: MutationResponse;
  resolvePickWaves: MutationResponse;
  shipShortOrder: MutationResponse;
  shipShortOrderV2: MutationResponse;
  startHealPlanAndPrintLabels: StartHealPlanPayload;
  startHealPlanAndPrintLabelsV2: MutationResponse;
  startManualHealPlan: MutationResponse;
  startManualHealPlanV2: MutationResponse;
  /** Attempts to update a container with the given attributes. Returns the updated container. */
  updateContainer: MutationResponse;
  updateUser: MutationResponse;
  updateUserPreference: MutationResponse;
};


export type MutationAddContainerToInventoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationAddJobFlowRuleArgs = {
  input: JobFlowRuleInput;
};


export type MutationAddLocationArgs = {
  input: StorageLocationInput;
};


export type MutationAddProductArgs = {
  input: ProductInput;
};


export type MutationAddTagToUserArgs = {
  tag: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAddTimeLogArgs = {
  input: WorkLogInput;
};


export type MutationCancelJobArgs = {
  id: Scalars['String']['input'];
};


export type MutationCancelOrderArgs = {
  id: Scalars['String']['input'];
};


export type MutationCancelOrdersArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationCancelPickingJobArgs = {
  projectionId: Scalars['String']['input'];
};


export type MutationCancelSortWallArgs = {
  projectionId: Scalars['String']['input'];
};


export type MutationCloseBulkOrderArgs = {
  id: Scalars['String']['input'];
};


export type MutationCloseJobFlowRuleArgs = {
  id: Scalars['String']['input'];
};


export type MutationCreateBatchArgs = {
  input: BulkOrderJobFlowRuleInput;
};


export type MutationCreateContainerArgs = {
  input: AddContainerInput;
};


export type MutationCreateTagArgs = {
  input: NewTagInput;
};


export type MutationCreateUserArgs = {
  input: NewUserInput;
};


export type MutationDeactivateUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationDeleteContainerArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteLocationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['String']['input'];
};


export type MutationDisableContainerArgs = {
  id: Scalars['String']['input'];
};


export type MutationEditJobFlowRuleArgs = {
  input: JobFlowRuleInput;
};


export type MutationEditLocationArgs = {
  input: StorageLocationInput;
};


export type MutationEditMapPointConfigArgs = {
  input: MapPointConfigInput;
};


export type MutationEditProductArgs = {
  input: ProductInput;
};


export type MutationEditTimeLogArgs = {
  input: WorkLogInput;
};


export type MutationEnableContainerArgs = {
  id: Scalars['String']['input'];
};


export type MutationForceDeviceOfflineArgs = {
  name: Scalars['String']['input'];
};


export type MutationMarkAsCompleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationPrintSortationLabelsArgs = {
  projectionId: Scalars['String']['input'];
};


export type MutationPrioritizeJobFlowRulesArgs = {
  input: JobFlowRulePrioritiesInput;
};


export type MutationReactivateUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationRemoveContainerFromInventoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveTagFromUserArgs = {
  tag: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationReportIssuesArgs = {
  input: SpecialProjectIssuesInput;
};


export type MutationReprintBatchBarcodeArgs = {
  id: Scalars['String']['input'];
};


export type MutationResolvePickWavesArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationShipShortOrderArgs = {
  projectionId: Scalars['String']['input'];
};


export type MutationShipShortOrderV2Args = {
  id: Scalars['String']['input'];
};


export type MutationStartHealPlanAndPrintLabelsArgs = {
  id: Scalars['String']['input'];
};


export type MutationStartHealPlanAndPrintLabelsV2Args = {
  id: Scalars['String']['input'];
};


export type MutationStartManualHealPlanArgs = {
  id: Scalars['String']['input'];
};


export type MutationStartManualHealPlanV2Args = {
  id: Scalars['String']['input'];
};


export type MutationUpdateContainerArgs = {
  input: UpdateContainerInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserPreferenceArgs = {
  userPreference: UserPreferenceInput;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  referenceId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type NewTagInput = {
  name: Scalars['ID']['input'];
};

export type NewUserInput = {
  authMethod?: InputMaybe<UserAuthMethod>;
  /** The user's badge barcode */
  badgeBarcode?: InputMaybe<Scalars['String']['input']>;
  /** The user's email or username */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The date of the user's credentials expiry */
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The user's locale */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** The user's name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Is this user required? */
  requiredUser?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user's roles */
  roles?: InputMaybe<Array<RoleInput>>;
  /** Tags assigned to the user */
  tags?: InputMaybe<Array<NewTagInput>>;
};

export type Order = {
  __typename?: 'Order';
  bulkOrderProjectionId?: Maybe<Scalars['String']['output']>;
  canShipShort?: Maybe<ShipShort>;
  /** Identifier provided by the customer, also known as externalId. Not guaranteed to be unique. */
  customerIdentifier?: Maybe<Scalars['String']['output']>;
  customers: Array<Customer>;
  destinationNode?: Maybe<Scalars['String']['output']>;
  exception?: Maybe<OrderException>;
  expectedShipDate?: Maybe<Scalars['DateTime']['output']>;
  healPlan?: Maybe<HealPlan>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  isCancelable: Scalars['Boolean']['output'];
  isHealable: Scalars['Boolean']['output'];
  isHealed: Scalars['Boolean']['output'];
  isShippedShort: Scalars['Boolean']['output'];
  isShorted: Scalars['Boolean']['output'];
  jobExternalId?: Maybe<Scalars['String']['output']>;
  jobFlowRuleLpn?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
  lines: Array<Line>;
  packout?: Maybe<Packout>;
  packoutCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  servicedBy: Array<OrderServicedBy>;
  slot?: Maybe<Scalars['String']['output']>;
  stagingIdentifier?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OrderStatus>;
  tag?: Maybe<Scalars['String']['output']>;
  timeline: OrderTimeline;
  trackingNumbers?: Maybe<Array<Scalars['String']['output']>>;
  type?: Maybe<PickStrategy>;
  workAreaIds: Array<Scalars['String']['output']>;
  workAreas: Array<WorkArea>;
  workOrderTypes?: Maybe<Array<WorkOrderType>>;
};

export enum OrderByDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type OrderCancelledEvent = OrderEvent & WorkflowEvent & {
  __typename?: 'OrderCancelledEvent';
  timestamp: Scalars['DateTime']['output'];
};

export type OrderCompletedEvent = OrderEvent & WorkflowEvent & {
  __typename?: 'OrderCompletedEvent';
  timestamp: Scalars['DateTime']['output'];
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges: Array<OrderEdge>;
  pageInfo: PageInfo;
};

export type OrderCreatedEvent = OrderEvent & WorkflowEvent & {
  __typename?: 'OrderCreatedEvent';
  timestamp: Scalars['DateTime']['output'];
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Order;
};

export type OrderEvent = {
  timestamp: Scalars['DateTime']['output'];
};

export type OrderException = {
  __typename?: 'OrderException';
  status: OrderExceptionStatus;
};

export enum OrderExceptionStatus {
  Canceled = 'Canceled',
  Cleared = 'Cleared',
  Resolvable = 'Resolvable',
  Resolving = 'Resolving',
  Unresolvable = 'Unresolvable'
}

export enum OrderOrderByFields {
  ExpectedShipDate = 'ExpectedShipDate',
  ExternalId = 'ExternalId',
  InductedAt = 'InductedAt',
  JobCreatedAt = 'JobCreatedAt'
}

export type OrderServicedBy = HealPlan | Picking | Sortation;

export enum OrderState {
  AwaitingInventory = 'AwaitingInventory',
  BeingServiced = 'BeingServiced',
  Cancelled = 'Cancelled',
  Done = 'Done',
  Exception = 'Exception',
  Expired = 'Expired',
  InProgress = 'InProgress',
  NotActive = 'NotActive',
  Offline = 'Offline',
  Packable = 'Packable',
  Packed = 'Packed',
  Packing = 'Packing',
  Sorted = 'Sorted',
  Sorting = 'Sorting',
  Unassigned = 'Unassigned'
}

export enum OrderStatus {
  AssignedToBatch = 'AssignedToBatch',
  AssignedToWall = 'AssignedToWall',
  AwaitingInventory = 'AwaitingInventory',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Exception = 'Exception',
  Expired = 'Expired',
  InProgress = 'InProgress',
  Interrupted = 'Interrupted',
  Shipped = 'Shipped',
  Unassigned = 'Unassigned',
  Unknown = 'Unknown'
}

export type OrderTimeline = {
  __typename?: 'OrderTimeline';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  pickedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrderV2 = {
  __typename?: 'OrderV2';
  bulkOrder?: Maybe<BulkOrder>;
  canShipShort?: Maybe<ShipShort>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  destinationNode?: Maybe<Scalars['String']['output']>;
  eligiblePickDeviceClasses: Array<Scalars['String']['output']>;
  exceptions: Array<Exception>;
  expectedShipDate?: Maybe<Scalars['DateTime']['output']>;
  externalContainerId?: Maybe<Scalars['String']['output']>;
  /** Identifier provided by the customer. Not guaranteed to be unique. */
  externalId: Scalars['String']['output'];
  healJobs: Array<Job>;
  healPlans: Array<HealPlanV2>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  isAwaitingInventory: Scalars['Boolean']['output'];
  isCancelable: Scalars['Boolean']['output'];
  isHealJob: Scalars['Boolean']['output'];
  isLate: Scalars['Boolean']['output'];
  isOrder: Scalars['Boolean']['output'];
  isPackoutRequired: Scalars['Boolean']['output'];
  isShippedShort: Scalars['Boolean']['output'];
  isShorted: Scalars['Boolean']['output'];
  isTerminal: Scalars['Boolean']['output'];
  jobStatus: JobStatus;
  lines: Array<LineV2>;
  mfp?: Maybe<Mfp>;
  orders: Array<Job>;
  packout?: Maybe<Packout>;
  pickStrategy?: Maybe<PickStrategy>;
  pickingJobs: Array<Job>;
  servicedBy: Array<Job>;
  servicing: Array<Job>;
  servicingSortations: Array<Sortation>;
  slot?: Maybe<Scalars['String']['output']>;
  stagingIdentifier?: Maybe<Scalars['String']['output']>;
  status: OrderV2Status;
  tag?: Maybe<Scalars['String']['output']>;
  tasks: Array<TaskV2>;
  totalLineCustomers: Array<Scalars['String']['output']>;
  totalLineQuantities: LineQuantities;
  totalLines: Count;
  trackingNumbers?: Maybe<Array<Scalars['String']['output']>>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  usedPickDeviceClasses: Array<Scalars['String']['output']>;
  usedPickEquipment: Array<PickEquipment>;
  workOrderTypes?: Maybe<Array<WorkOrderType>>;
};

export type OrderV2Connection = {
  __typename?: 'OrderV2Connection';
  edges: Array<OrderV2Edge>;
  pageInfo: PageInfo;
};

export type OrderV2Edge = {
  __typename?: 'OrderV2Edge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: OrderV2;
};

export enum OrderV2Status {
  AssignedToBatch = 'AssignedToBatch',
  AssignedToSortWall = 'AssignedToSortWall',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Expired = 'Expired',
  InProgress = 'InProgress',
  Interrupted = 'Interrupted',
  Loaded = 'Loaded',
  Packable = 'Packable',
  Packed = 'Packed',
  Packing = 'Packing',
  Picked = 'Picked',
  Picking = 'Picking',
  Sortable = 'Sortable',
  Sorted = 'Sorted',
  Sorting = 'Sorting',
  Staged = 'Staged',
  Unassigned = 'Unassigned',
  Unknown = 'Unknown'
}

export type Packout = {
  __typename?: 'Packout';
  cancellable: Scalars['Boolean']['output'];
  containerTypes: Array<Scalars['String']['output']>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  lines: Array<Line>;
  orders: Array<Order>;
  packStation?: Maybe<Mfp>;
  packoutRequestIdentifier: Scalars['String']['output'];
  pickingJobs: Array<Picking>;
  status: PackoutStatus;
  timeline: PackoutTimeline;
  user?: Maybe<User>;
};

export type PackoutCompletedEvent = PackoutEvent & WorkflowEvent & {
  __typename?: 'PackoutCompletedEvent';
  deviceId?: Maybe<Scalars['String']['output']>;
  externalContainerId?: Maybe<Scalars['String']['output']>;
  projectionId?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type PackoutConnection = {
  __typename?: 'PackoutConnection';
  edges: Array<PackoutEdge>;
  pageInfo: PageInfo;
};

export type PackoutEdge = {
  __typename?: 'PackoutEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Packout;
};

export type PackoutEvent = {
  deviceId?: Maybe<Scalars['String']['output']>;
  externalContainerId?: Maybe<Scalars['String']['output']>;
  projectionId?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type PackoutInductedEvent = PackoutEvent & WorkflowEvent & {
  __typename?: 'PackoutInductedEvent';
  deviceId?: Maybe<Scalars['String']['output']>;
  externalContainerId?: Maybe<Scalars['String']['output']>;
  projectionId?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export enum PackoutStatus {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Done = 'Done',
  Inducted = 'Inducted',
  Rejected = 'Rejected',
  Unknown = 'Unknown'
}

export type PackoutTimeline = {
  __typename?: 'PackoutTimeline';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  inductedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PickEquipment = {
  __typename?: 'PickEquipment';
  createdAt: Scalars['DateTime']['output'];
  deviceClass?: Maybe<Scalars['String']['output']>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  lineId: Scalars['String']['output'];
  mfpConfigId?: Maybe<Scalars['String']['output']>;
  modelConfigId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum PickStrategy {
  Bulk = 'Bulk',
  Consolidation = 'Consolidation',
  Discrete = 'Discrete',
  Group = 'Group',
  Singles = 'Singles',
  Sortation = 'Sortation'
}

export type PickWave = {
  __typename?: 'PickWave';
  createdAt: Scalars['DateTime']['output'];
  droppedLinesCount: Scalars['Int']['output'];
  errorCodes: Array<PickWaveOrderLineErrorCode>;
  id: Scalars['String']['output'];
  orders: PickWaveOrderConnection;
  resolvedAt?: Maybe<Scalars['DateTime']['output']>;
  status: PickWaveStatus;
  totalLinesCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type PickWaveOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pickWaveIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<PickWaveOrderStatus>>;
};

export type PickWaveConnection = {
  __typename?: 'PickWaveConnection';
  edges?: Maybe<Array<PickWaveEdge>>;
  pageInfo?: Maybe<PickWavePageInfo>;
};

export type PickWaveEdge = {
  __typename?: 'PickWaveEdge';
  cursor: Scalars['String']['output'];
  node: PickWave;
};

export type PickWaveOrder = {
  __typename?: 'PickWaveOrder';
  createdAt: Scalars['DateTime']['output'];
  droppedLinesCount: Scalars['Int']['output'];
  errorCodes: Array<PickWaveOrderLineErrorCode>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lines: PickWaveOrderLineConnection;
  status: PickWaveOrderStatus;
  totalLinesCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type PickWaveOrderLinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pickWaveOrderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type PickWaveOrderBy = {
  direction: OrderByDirection;
  field: PickWaveOrderByFields;
};

export enum PickWaveOrderByFields {
  CreatedAt = 'createdAt'
}

export type PickWaveOrderConnection = {
  __typename?: 'PickWaveOrderConnection';
  edges?: Maybe<Array<PickWaveOrderEdge>>;
  pageInfo?: Maybe<PickWaveOrderPageInfo>;
};

export type PickWaveOrderEdge = {
  __typename?: 'PickWaveOrderEdge';
  cursor: Scalars['String']['output'];
  node: PickWaveOrder;
};

export type PickWaveOrderLine = {
  __typename?: 'PickWaveOrderLine';
  createdAt: Scalars['DateTime']['output'];
  errorCode?: Maybe<PickWaveOrderLineErrorCode>;
  id: Scalars['ID']['output'];
  productDescription?: Maybe<Scalars['String']['output']>;
  productId: Scalars['String']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PickWaveOrderLineConnection = {
  __typename?: 'PickWaveOrderLineConnection';
  edges?: Maybe<Array<PickWaveOrderLineEdge>>;
  pageInfo?: Maybe<PickWaveOrderLinePageInfo>;
};

export type PickWaveOrderLineEdge = {
  __typename?: 'PickWaveOrderLineEdge';
  cursor: Scalars['String']['output'];
  node: PickWaveOrderLine;
};

export enum PickWaveOrderLineErrorCode {
  AllowedProductsNotSupportedInLegacyMode = 'AllowedProductsNotSupportedInLegacyMode',
  CascadeError = 'CascadeError',
  ConflictingNotExactIdentifiersInGroup = 'ConflictingNotExactIdentifiersInGroup',
  ConflictingNotExactUnitOfMeasureQuantityIdentifiers = 'ConflictingNotExactUnitOfMeasureQuantityIdentifiers',
  ConflictingStartsWithIdentifiersInGroup = 'ConflictingStartsWithIdentifiersInGroup',
  ConflictingStartsWithUnitOfMeasureQuantityIdentifiers = 'ConflictingStartsWithUnitOfMeasureQuantityIdentifiers',
  ContainerIdInUse = 'ContainerIdInUse',
  DifferentGroupTypesSameGroupId = 'DifferentGroupTypesSameGroupId',
  DuplicateExactMatchIdentifier = 'DuplicateExactMatchIdentifier',
  DuplicateExactMatchIdentifierInGroup = 'DuplicateExactMatchIdentifierInGroup',
  DuplicateExternalId = 'DuplicateExternalId',
  DuplicateIdentifiersInGroup = 'DuplicateIdentifiersInGroup',
  GroupIdInUse = 'GroupIdInUse',
  ImpossibleUnitOfMeasureQuantity = 'ImpossibleUnitOfMeasureQuantity',
  InvalidPickTaskCriteria = 'InvalidPickTaskCriteria',
  InvalidPickTaskWeightCriteria = 'InvalidPickTaskWeightCriteria',
  InvalidQuantity = 'InvalidQuantity',
  InvalidUnitOfMeasureQuantity = 'InvalidUnitOfMeasureQuantity',
  InventoryAllocationError = 'InventoryAllocationError',
  MismatchAllowedProduct = 'MismatchAllowedProduct',
  MismatchedFieldsInGroup = 'MismatchedFieldsInGroup',
  MismatchedGrabQuantityIdentifiers = 'MismatchedGrabQuantityIdentifiers',
  MissingDestinationLocation = 'MissingDestinationLocation',
  MissingUnitOfMeasureQuantityIdentifier = 'MissingUnitOfMeasureQuantityIdentifier',
  NoDefaultContainerType = 'NoDefaultContainerType',
  NoDefaultPackoutContainerType = 'NoDefaultPackoutContainerType',
  NoIdentifiers = 'NoIdentifiers',
  NoIdentifiersTapToScanStrict = 'NoIdentifiersTapToScanStrict',
  OrderTooBig = 'OrderTooBig',
  PackoutInstructionsTooLong = 'PackoutInstructionsTooLong',
  PackoutInvalidLabelProvider = 'PackoutInvalidLabelProvider',
  PackoutInvalidPalletizeFlag = 'PackoutInvalidPalletizeFlag',
  SourceLocationNotSpecified = 'SourceLocationNotSpecified',
  TooManyNonExactIdentifiersInGroup = 'TooManyNonExactIdentifiersInGroup',
  TooManyNonExactUnitOfMeasureQuantityIdentifiers = 'TooManyNonExactUnitOfMeasureQuantityIdentifiers',
  TooManyProducts = 'TooManyProducts',
  TooManyRegexIdentifiersInGroup = 'TooManyRegexIdentifiersInGroup',
  TooManyRegexUnitOfMeasureQuantityIdentifiers = 'TooManyRegexUnitOfMeasureQuantityIdentifiers',
  Unknown = 'Unknown',
  UnknownSourceLocation = 'UnknownSourceLocation',
  UnsupportedAlternatePickLocation = 'UnsupportedAlternatePickLocation',
  UnsupportedProjectType = 'UnsupportedProjectType',
  WeightedItemsInvalidOrderType = 'WeightedItemsInvalidOrderType',
  WeightedItemsNotEnabled = 'WeightedItemsNotEnabled',
  WholesaleMissingOrConflictingSpecialProjectId = 'WholesaleMissingOrConflictingSpecialProjectId'
}

export type PickWaveOrderLinePageInfo = {
  __typename?: 'PickWaveOrderLinePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PickWaveOrderPageInfo = {
  __typename?: 'PickWaveOrderPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PickWaveOrderStatus {
  Failure = 'Failure',
  InProgress = 'InProgress',
  PartialSuccess = 'PartialSuccess',
  Success = 'Success',
  Unknown = 'Unknown'
}

export type PickWavePageInfo = {
  __typename?: 'PickWavePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PickWaveStatus {
  Failure = 'Failure',
  InProgress = 'InProgress',
  PartialSuccess = 'PartialSuccess',
  Success = 'Success',
  Unknown = 'Unknown'
}

export type Picking = {
  __typename?: 'Picking';
  container?: Maybe<PickingContainer>;
  destinationNode?: Maybe<Scalars['String']['output']>;
  exception?: Maybe<PickingJobExceptionStatus>;
  expectedShipDate?: Maybe<Scalars['DateTime']['output']>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  isCancelable: Scalars['Boolean']['output'];
  isHealJob: Scalars['Boolean']['output'];
  isPackoutRequired: Scalars['Boolean']['output'];
  jobId: Scalars['String']['output'];
  lines: Array<Line>;
  mfp?: Maybe<Mfp>;
  packout?: Maybe<Packout>;
  pickingStatus: PickingJobStatus;
  servicing: Array<PickingServicing>;
  stagingIdentifier?: Maybe<Scalars['String']['output']>;
  takeoffDestination?: Maybe<Scalars['String']['output']>;
  taskCoordinatorIdentifier: Scalars['String']['output'];
  timeline: PickingTimeline;
  type?: Maybe<PickStrategy>;
  users: Array<User>;
  workAreaIds: Array<Scalars['String']['output']>;
  workAreas: Array<WorkArea>;
  workOrderTypes?: Maybe<Array<WorkOrderType>>;
};

export type PickingByEquipmentSummary = {
  __typename?: 'PickingByEquipmentSummary';
  deviceClass: Array<Scalars['String']['output']>;
  expectedShipDate: Scalars['DateTime']['output'];
  lines: Scalars['Float']['output'];
  orders: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  units: Scalars['Float']['output'];
};

export type PickingCancelledEvent = PickingEvent & WorkflowEvent & {
  __typename?: 'PickingCancelledEvent';
  deviceId?: Maybe<Scalars['String']['output']>;
  equipmentId?: Maybe<Scalars['String']['output']>;
  equipmentType?: Maybe<Scalars['String']['output']>;
  externalContainerId?: Maybe<Scalars['String']['output']>;
  isHealing: Scalars['Boolean']['output'];
  jobId: Scalars['String']['output'];
  orderProjectionId?: Maybe<Scalars['String']['output']>;
  projectionId?: Maybe<Scalars['String']['output']>;
  takeoffDestination?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type PickingCompletedEvent = PickingEvent & WorkflowEvent & {
  __typename?: 'PickingCompletedEvent';
  deviceId?: Maybe<Scalars['String']['output']>;
  equipmentId?: Maybe<Scalars['String']['output']>;
  equipmentType?: Maybe<Scalars['String']['output']>;
  externalContainerId?: Maybe<Scalars['String']['output']>;
  isHealing: Scalars['Boolean']['output'];
  jobId: Scalars['String']['output'];
  orderProjectionId?: Maybe<Scalars['String']['output']>;
  projectionId?: Maybe<Scalars['String']['output']>;
  takeoffDestination?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type PickingConnection = {
  __typename?: 'PickingConnection';
  edges: Array<PickingEdge>;
  pageInfo: PageInfo;
};

export type PickingContainer = {
  __typename?: 'PickingContainer';
  /** The container's attributes such as package material type */
  attributes?: Maybe<ContainerAttributes>;
  /** The container's barcode */
  barcode?: Maybe<Scalars['String']['output']>;
  /** The number of columns in the container */
  columns: Scalars['Int']['output'];
  /** The description of the container */
  description?: Maybe<Scalars['String']['output']>;
  /** TODO */
  enabled: Scalars['Boolean']['output'];
  externalId: Scalars['String']['output'];
  height: Scalars['Float']['output'];
  /** A unique ID. */
  id: Scalars['ID']['output'];
  /** The url to an image of the container */
  imageURL?: Maybe<Scalars['String']['output']>;
  /** Special instructions for the container */
  instructions?: Maybe<Scalars['String']['output']>;
  /** TODO */
  inventory: Scalars['Boolean']['output'];
  length: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** The number of rows in the container */
  rows: Scalars['Int']['output'];
  type: ContainerTypeWithFallback;
  volume: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
};

export type PickingCreatedEvent = PickingEvent & WorkflowEvent & {
  __typename?: 'PickingCreatedEvent';
  deviceId?: Maybe<Scalars['String']['output']>;
  equipmentId?: Maybe<Scalars['String']['output']>;
  equipmentType?: Maybe<Scalars['String']['output']>;
  externalContainerId?: Maybe<Scalars['String']['output']>;
  isHealing: Scalars['Boolean']['output'];
  jobId: Scalars['String']['output'];
  orderProjectionId?: Maybe<Scalars['String']['output']>;
  projectionId?: Maybe<Scalars['String']['output']>;
  takeoffDestination?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type PickingEdge = {
  __typename?: 'PickingEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Picking;
};

export type PickingEvent = {
  deviceId?: Maybe<Scalars['String']['output']>;
  equipmentId?: Maybe<Scalars['String']['output']>;
  equipmentType?: Maybe<Scalars['String']['output']>;
  externalContainerId?: Maybe<Scalars['String']['output']>;
  isHealing: Scalars['Boolean']['output'];
  jobId: Scalars['String']['output'];
  orderProjectionId?: Maybe<Scalars['String']['output']>;
  projectionId?: Maybe<Scalars['String']['output']>;
  takeoffDestination?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type PickingInductedEvent = PickingEvent & WorkflowEvent & {
  __typename?: 'PickingInductedEvent';
  deviceId?: Maybe<Scalars['String']['output']>;
  equipmentId?: Maybe<Scalars['String']['output']>;
  equipmentType?: Maybe<Scalars['String']['output']>;
  externalContainerId?: Maybe<Scalars['String']['output']>;
  healedJobExternalContainerId?: Maybe<Scalars['String']['output']>;
  isHealing: Scalars['Boolean']['output'];
  jobId: Scalars['String']['output'];
  orderProjectionId?: Maybe<Scalars['String']['output']>;
  projectionId?: Maybe<Scalars['String']['output']>;
  takeoffDestination?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export enum PickingJobExceptionStatus {
  Cleared = 'Cleared',
  MfpOffline = 'MFP_OFFLINE',
  Unresolved = 'Unresolved',
  WorkflowException = 'WORKFLOW_EXCEPTION'
}

export enum PickingJobOrderByFields {
  ExpectedShipDate = 'ExpectedShipDate',
  JobCreatedAt = 'JobCreatedAt'
}

/** The status of the inbound line */
export enum PickingJobStatus {
  AwaitingInventory = 'AwaitingInventory',
  Cancelled = 'Cancelled',
  Exception = 'Exception',
  Offline = 'Offline',
  Packable = 'Packable',
  Packed = 'Packed',
  Packing = 'Packing',
  Picked = 'Picked',
  Picking = 'Picking',
  /** @deprecated Not availble within Database */
  Sortable = 'Sortable',
  Sorted = 'Sorted',
  Sorting = 'Sorting',
  Unassigned = 'Unassigned',
  Unknown = 'Unknown'
}

export type PickingPickedEvent = PickingEvent & WorkflowEvent & {
  __typename?: 'PickingPickedEvent';
  deviceId?: Maybe<Scalars['String']['output']>;
  doneQuantity: Scalars['Int']['output'];
  equipmentId?: Maybe<Scalars['String']['output']>;
  equipmentType?: Maybe<Scalars['String']['output']>;
  exception?: Maybe<Scalars['String']['output']>;
  externalContainerId?: Maybe<Scalars['String']['output']>;
  isHealing: Scalars['Boolean']['output'];
  jobId: Scalars['String']['output'];
  orderProjectionId?: Maybe<Scalars['String']['output']>;
  productExternalId?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  projectionId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  storageLocationAddress?: Maybe<Scalars['String']['output']>;
  storageLocationId?: Maybe<Scalars['String']['output']>;
  takeoffDestination?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type PickingServicing = Order | Sortation;

export type PickingTimeline = {
  __typename?: 'PickingTimeline';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  pickedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PreKitException = {
  __typename?: 'PreKitException';
  eachQuantity: Scalars['Float']['output'];
  product: Product;
  reason: PreKitExceptionReason;
};

export type PreKitExceptionInput = {
  eachQuantity: Scalars['Float']['input'];
  productId: Scalars['String']['input'];
  reason: PreKitExceptionReason;
};

export enum PreKitExceptionReason {
  DamagedProduct = 'damagedProduct',
  MissingProduct = 'missingProduct',
  Other = 'other'
}

export type Product = {
  __typename?: 'Product';
  attributes: ProductAttributes;
  cubeVolume: Scalars['Float']['output'];
  customerIdentifier: Scalars['String']['output'];
  data: Scalars['JSONObject']['output'];
  description: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  height: Scalars['Float']['output'];
  /** A unique ID. */
  id: Scalars['ID']['output'];
  identifiers: Array<ProductIdentifier>;
  image?: Maybe<Scalars['String']['output']>;
  kind: ProductKind;
  lastInspected?: Maybe<Scalars['DateTime']['output']>;
  length: Scalars['Float']['output'];
  locations: Array<StorageLocationInventory>;
  name: Scalars['String']['output'];
  needsInspection: Scalars['Boolean']['output'];
  productId?: Maybe<Scalars['String']['output']>;
  rework: ReworkDiscoveryRules;
  scanValues: Array<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  volume: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
};

export type ProductAttributes = {
  __typename?: 'ProductAttributes';
  expiresAt?: Maybe<Scalars['String']['output']>;
  lot?: Maybe<Scalars['String']['output']>;
  rework?: Maybe<Scalars['String']['output']>;
};

export type ProductIdentifier = {
  __typename?: 'ProductIdentifier';
  allowedValues: Array<Scalars['String']['output']>;
  grabQuantity?: Maybe<Scalars['Float']['output']>;
  label: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  validationStrategy: Scalars['String']['output'];
};

/** Add or edit a product */
export type ProductInput = {
  /** External-facing product ID. */
  customerIdentifier: Scalars['String']['input'];
  /** Product description. */
  description: Scalars['String']['input'];
  /** Product height in millimeters. */
  height: Scalars['Float']['input'];
  /** A system-generated unique ID. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Optional product image URL. */
  image?: InputMaybe<Scalars['String']['input']>;
  /** Product length in millimeters. */
  length: Scalars['Float']['input'];
  /** Product name or SKU. */
  name: Scalars['String']['input'];
  /** Product weight in grams. */
  weight: Scalars['Float']['input'];
  /** Product width in millimeters. */
  width: Scalars['Float']['input'];
};

export type ProductInventory = {
  __typename?: 'ProductInventory';
  inventory: InventoryState;
  product: Product;
};

/** The kind of product, for inventory tracking purposes */
export enum ProductKind {
  /** A form of packaging, such as a shipping container. */
  Packaging = 'packaging',
  /** A sellable product, such as a bottle of hot sauce. */
  Product = 'product'
}

export enum ProductOrderByFields {
  CustomerIdentifier = 'CustomerIdentifier',
  Description = 'Description',
  Name = 'Name'
}

export type ProductSummary = {
  __typename?: 'ProductSummary';
  attributes: ProductAttributes;
  customerIdentifier: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifiers: Array<Scalars['String']['output']>;
  inventories: Array<ProductSummaryInventory>;
  isContainerType: Scalars['Boolean']['output'];
  isExpirationTracked: Scalars['Boolean']['output'];
  isLotTracked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type ProductSummaryInventory = {
  __typename?: 'ProductSummaryInventory';
  address: Scalars['String']['output'];
  conflictReasons: Array<InventoryConflictReason>;
  conflicted: Scalars['Boolean']['output'];
  liveQuantity?: Maybe<Scalars['Int']['output']>;
  locationType?: Maybe<StorageLocationType>;
  totalPlannedAddQuantity: Scalars['Int']['output'];
  totalPlannedRemoveQuantity: Scalars['Int']['output'];
};

export type ProductSummaryPage = {
  __typename?: 'ProductSummaryPage';
  cursor?: Maybe<Scalars['String']['output']>;
  results: Array<ProductSummary>;
};

export type ProjectInstructions = {
  __typename?: 'ProjectInstructions';
  media: Array<Media>;
  text: Scalars['String']['output'];
};

export type PutawayJob = {
  __typename?: 'PutawayJob';
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  containerId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastRunningAt?: Maybe<Scalars['DateTime']['output']>;
  lines: Array<PutawayLineJob>;
  status: GoalStates;
  terminatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum PutawayJobsOrderByFields {
  DateReceived = 'DateReceived',
  Id = 'Id'
}

export type PutawayJobsSummaryPage = {
  __typename?: 'PutawayJobsSummaryPage';
  cursor?: Maybe<Scalars['String']['output']>;
  results: Array<PutawayJob>;
};

export type PutawayLineJob = {
  __typename?: 'PutawayLineJob';
  assetTypeId?: Maybe<Scalars['String']['output']>;
  assetTypeOverrides?: Maybe<Product>;
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceType?: Maybe<Scalars['String']['output']>;
  exceptions?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  lastPausedAt?: Maybe<Scalars['DateTime']['output']>;
  lastRunningAt?: Maybe<Scalars['DateTime']['output']>;
  /** Location of where the product is put away */
  location?: Maybe<StorageLocation>;
  locationId?: Maybe<Scalars['String']['output']>;
  /** The product to be putaway */
  product?: Maybe<Product>;
  terminatedAt?: Maybe<Scalars['DateTime']['output']>;
  totalCount?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userType?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** A list of logged bulk actions */
  actionLog: LoggedActionPage;
  aisles: Array<Aisle>;
  /** @deprecated Use userByBadgeBarcode */
  badgeBarcodeExists: Scalars['Boolean']['output'];
  bulkOrderById?: Maybe<BulkOrder>;
  bulkOrdersCount: Count;
  configuration?: Maybe<Config>;
  /** Attempts to find a container with the given Id */
  container?: Maybe<Container>;
  containerTypes: Array<Container>;
  /** Attempts to find containers that match the given filter criteria, returns a paginated list of results */
  containers: ContainersPage;
  /** Count the total number of containers, or the containers that match the given filter */
  containersCount: Count;
  createdBulkOrders: BulkOrderConnection;
  cutoffForBulkOrders: Cutoff;
  cutoffForJobsV2: Cutoff;
  cutoffForOrders: Cutoff;
  cutoffForOrdersV2: Cutoff;
  cutoffForPickingJobs: Cutoff;
  device?: Maybe<Device>;
  devices: DeviceConnection;
  devicesCount: Count;
  equipmentConfig?: Maybe<EquipmentDeviceConfig>;
  equipmentConfigs?: Maybe<Array<EquipmentDeviceConfig>>;
  equipmentDevice?: Maybe<EquipmentDevice>;
  equipmentDevices?: Maybe<Array<EquipmentDevice>>;
  getPopularTags: Array<Tag>;
  getWorkLog: WorkLogPage;
  healPlanById?: Maybe<HealPlan>;
  healPlanByJobId?: Maybe<HealPlan>;
  healPlans: HealPlanConnection;
  indicationsByExpectedShipDate: Array<Indication>;
  inventoryEvents: InventoryEventPage;
  jobEvents: Array<WorkflowEvent>;
  jobEventsV2: Array<WorkflowEvent>;
  jobFlowRule: JobFlowRule;
  jobFlowRules: Array<JobFlowRule>;
  jobV2?: Maybe<Job>;
  jobsLinesV2: LineConnectionV2;
  jobsLinesV2Count: Count;
  jobsV2: JobConnection;
  jobsV2Count: JobsCount;
  line?: Maybe<Line>;
  lines: LineConnection;
  linesByOrderProjectionId: LineConnection;
  linesByPickingProjectionId: LineConnection;
  linesOrderCounts: LineOrderCounts;
  linesPickingCounts: LinePickingCounts;
  location?: Maybe<StorageLocation>;
  locationTypeCounts: Array<StorageLocationTypeCount>;
  locations: StorageLocationSummaryPage;
  mapPointConfig?: Maybe<MapPointConfig>;
  mapPointConfigs: Array<MapPointConfig>;
  me: User;
  orderByJobId?: Maybe<Order>;
  orderByProjectionId?: Maybe<Order>;
  orderEvents: Array<WorkflowEvent>;
  orderEventsV2: Array<WorkflowEvent>;
  orderV2?: Maybe<OrderV2>;
  orders: OrderConnection;
  ordersCount: Count;
  ordersV2: OrderV2Connection;
  ordersV2Count: JobsCount;
  packout: Packout;
  packoutByJobId?: Maybe<Array<Packout>>;
  packouts: PackoutConnection;
  pickWave?: Maybe<PickWave>;
  pickWaveOrderLines: PickWaveOrderLineConnection;
  pickWaveOrders: PickWaveOrderConnection;
  pickWaves: PickWaveConnection;
  pickWavesCount: Count;
  pickingByEquipmentTypeAndExpectedShipDate: Array<PickingByEquipmentSummary>;
  pickingByJobId?: Maybe<Picking>;
  pickingByProjectionId?: Maybe<Picking>;
  pickingJobs: PickingConnection;
  pickingJobsCount: Count;
  pickingJobsInProgressCount: Count;
  product: Product;
  productTypeCountsByLocationType: Array<StorageLocationTypeCount>;
  products: ProductSummaryPage;
  putawayJob: PutawayJob;
  putawayJobCount: Count;
  putawayJobs: PutawayJobsSummaryPage;
  recommendedBulkOrders: Array<BulkOrderRecommendation>;
  roles: Array<Scalars['String']['output']>;
  searchTags: Array<Tag>;
  sortationByJobId?: Maybe<Sortation>;
  sortations: SortationConnection;
  sortationsCount: Count;
  specialProject: SpecialProject;
  specialProjectCount: Count;
  specialProjects: SpecialProjectPage;
  uniqueCarrierNames: Array<Scalars['String']['output']>;
  uniqueCustomerNames: Array<Scalars['String']['output']>;
  user?: Maybe<User>;
  userByBadgeBarcode?: Maybe<User>;
  userByEmail?: Maybe<User>;
  users?: Maybe<UserConnection>;
  usersCount: Count;
  workAreas: Array<WorkArea>;
};


export type QueryActionLogArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryBadgeBarcodeExistsArgs = {
  badgeBarcode: Scalars['String']['input'];
};


export type QueryBulkOrderByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryBulkOrdersCountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  batchClosedAt?: InputMaybe<Scalars['DateTime']['input']>;
  batchStates?: InputMaybe<Array<BulkOrderStatus>>;
  before?: InputMaybe<Scalars['String']['input']>;
  expectedShipDate?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BulkOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  progressStates?: InputMaybe<Array<BulkOrderProgress>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryContainerArgs = {
  id: Scalars['String']['input'];
};


export type QueryContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  barcode?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isInventory?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  materialTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<ContainerOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<ContainerType>>;
};


export type QueryContainersCountArgs = {
  isInventory?: InputMaybe<Scalars['Boolean']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<ContainerType>>;
};


export type QueryCreatedBulkOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  batchClosedAt?: InputMaybe<Scalars['DateTime']['input']>;
  batchStates?: InputMaybe<Array<BulkOrderStatus>>;
  before?: InputMaybe<Scalars['String']['input']>;
  expectedShipDate?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BulkOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  progressStates?: InputMaybe<Array<BulkOrderProgress>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCutoffForJobsV2Args = {
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  datePrecision: DatePrecision;
};


export type QueryCutoffForOrdersArgs = {
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  datePrecision: DatePrecision;
};


export type QueryCutoffForOrdersV2Args = {
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  datePrecision: DatePrecision;
};


export type QueryCutoffForPickingJobsArgs = {
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  datePrecision: DatePrecision;
};


export type QueryDeviceArgs = {
  name: Scalars['String']['input'];
};


export type QueryDevicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<DeviceOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Array<DeviceState>>;
  supportTicketStatuses?: InputMaybe<Array<SupportTicketStatus>>;
  types?: InputMaybe<Array<DeviceType>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryDevicesCountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<DeviceOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Array<DeviceState>>;
  supportTicketStatuses?: InputMaybe<Array<SupportTicketStatus>>;
  types?: InputMaybe<Array<DeviceType>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryEquipmentConfigArgs = {
  id: Scalars['String']['input'];
};


export type QueryEquipmentDeviceArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPopularTagsArgs = {
  limit?: Scalars['Float']['input'];
};


export type QueryGetWorkLogArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryHealPlanByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryHealPlanByJobIdArgs = {
  jobId: Scalars['String']['input'];
};


export type QueryHealPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryIndicationsByExpectedShipDateArgs = {
  carrierNames?: InputMaybe<Array<Scalars['String']['input']>>;
  customerNames?: InputMaybe<Array<Scalars['String']['input']>>;
  dimension?: InputMaybe<Scalars['String']['input']>;
  hoursBackward?: InputMaybe<Scalars['Float']['input']>;
  hoursForward?: InputMaybe<Scalars['Float']['input']>;
  indication?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInventoryEventsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locationId: Scalars['String']['input'];
  productId: Scalars['String']['input'];
};


export type QueryJobEventsArgs = {
  projectionId: Scalars['String']['input'];
};


export type QueryJobEventsV2Args = {
  id: Scalars['String']['input'];
};


export type QueryJobFlowRuleArgs = {
  id: Scalars['String']['input'];
};


export type QueryJobV2Args = {
  id: Scalars['String']['input'];
};


export type QueryJobsLinesV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  view?: InputMaybe<LinesViews>;
};


export type QueryJobsLinesV2CountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  view?: InputMaybe<LinesViews>;
};


export type QueryJobsV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  healPlanCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isAwaitingInventory?: InputMaybe<Scalars['Boolean']['input']>;
  isHealJob?: InputMaybe<Scalars['Boolean']['input']>;
  isHealable?: InputMaybe<Scalars['Boolean']['input']>;
  isLate?: InputMaybe<Scalars['Boolean']['input']>;
  isOrder?: InputMaybe<Scalars['Boolean']['input']>;
  isServicedByHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<Array<JobOrderBy>>;
  pickStrategies?: InputMaybe<Array<PickStrategy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobStatus>>;
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  usedEquipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryJobsV2CountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  healPlanCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isAwaitingInventory?: InputMaybe<Scalars['Boolean']['input']>;
  isHealJob?: InputMaybe<Scalars['Boolean']['input']>;
  isHealable?: InputMaybe<Scalars['Boolean']['input']>;
  isLate?: InputMaybe<Scalars['Boolean']['input']>;
  isOrder?: InputMaybe<Scalars['Boolean']['input']>;
  isServicedByHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<Array<JobOrderBy>>;
  pickStrategies?: InputMaybe<Array<PickStrategy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobStatus>>;
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  usedEquipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryLineArgs = {
  id: Scalars['String']['input'];
};


export type QueryLinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLinesByOrderProjectionIdArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  projectionId: Scalars['String']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
  view?: InputMaybe<LinesViews>;
};


export type QueryLinesByPickingProjectionIdArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  projectionId: Scalars['String']['input'];
  view?: InputMaybe<LinesViews>;
};


export type QueryLinesOrderCountsArgs = {
  projectionId: Scalars['String']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLinesPickingCountsArgs = {
  projectionId: Scalars['String']['input'];
};


export type QueryLocationArgs = {
  id: Scalars['String']['input'];
};


export type QueryLocationTypeCountsArgs = {
  containerTypeId?: InputMaybe<Scalars['String']['input']>;
  fullness?: InputMaybe<Scalars['Float']['input']>;
  isConflicted?: InputMaybe<Scalars['Boolean']['input']>;
  isSlotted?: InputMaybe<Scalars['Boolean']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryLocationsArgs = {
  containerTypeId?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  fullness?: InputMaybe<Scalars['Float']['input']>;
  isConflicted?: InputMaybe<Scalars['Boolean']['input']>;
  isSlotted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<StorageLocationOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StorageLocationType>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryMapPointConfigArgs = {
  name: Scalars['String']['input'];
};


export type QueryOrderByJobIdArgs = {
  jobId: Scalars['String']['input'];
};


export type QueryOrderByProjectionIdArgs = {
  projectionId: Scalars['String']['input'];
};


export type QueryOrderEventsArgs = {
  projectionId: Scalars['String']['input'];
};


export type QueryOrderEventsV2Args = {
  id: Scalars['String']['input'];
};


export type QueryOrderV2Args = {
  id: Scalars['String']['input'];
};


export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasCompletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  hasHealPlan?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanStatuses?: InputMaybe<Array<HealPlanStatus>>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobCreatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  jobCreatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  jobExternalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  jobUpdatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  jobUpdatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lateOnly?: InputMaybe<Scalars['Boolean']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<OrderOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  pickTypes?: InputMaybe<Array<PickStrategy>>;
  projectionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<OrderState>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryOrdersCountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasCompletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  hasHealPlan?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanStatuses?: InputMaybe<Array<HealPlanStatus>>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobCreatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  jobCreatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  jobExternalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  jobUpdatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  jobUpdatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lateOnly?: InputMaybe<Scalars['Boolean']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<OrderOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  pickTypes?: InputMaybe<Array<PickStrategy>>;
  projectionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<OrderState>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryOrdersV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  healPlanCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isAwaitingInventory?: InputMaybe<Scalars['Boolean']['input']>;
  isHealable?: InputMaybe<Scalars['Boolean']['input']>;
  isLate?: InputMaybe<Scalars['Boolean']['input']>;
  isServicedByHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<Array<JobOrderBy>>;
  pickStrategies?: InputMaybe<Array<PickStrategy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<OrderV2Status>>;
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  usedEquipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryOrdersV2CountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bulkOrderProjectionId?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasExceptions?: InputMaybe<Scalars['Boolean']['input']>;
  healPlanCompletedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  healPlanCompletedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isAwaitingInventory?: InputMaybe<Scalars['Boolean']['input']>;
  isHealable?: InputMaybe<Scalars['Boolean']['input']>;
  isLate?: InputMaybe<Scalars['Boolean']['input']>;
  isServicedByHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  isShippedShort?: InputMaybe<Scalars['Boolean']['input']>;
  isShorted?: InputMaybe<Scalars['Boolean']['input']>;
  jobFlowRuleLpn?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<Array<JobOrderBy>>;
  pickStrategies?: InputMaybe<Array<PickStrategy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<OrderV2Status>>;
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  usedEquipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryPackoutArgs = {
  id: Scalars['String']['input'];
};


export type QueryPackoutByJobIdArgs = {
  jobId: Scalars['String']['input'];
};


export type QueryPackoutsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPickWaveArgs = {
  id: Scalars['String']['input'];
};


export type QueryPickWaveOrderLinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pickWaveOrderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPickWaveOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pickWaveIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<PickWaveOrderStatus>>;
};


export type QueryPickWavesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isResolved?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PickWaveOrderBy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<PickWaveStatus>>;
};


export type QueryPickWavesCountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isResolved?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PickWaveOrderBy>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<PickWaveStatus>>;
};


export type QueryPickingByEquipmentTypeAndExpectedShipDateArgs = {
  carrierNames?: InputMaybe<Array<Scalars['String']['input']>>;
  cutoffDates?: InputMaybe<Array<Scalars['String']['input']>>;
  hoursBackward?: InputMaybe<Scalars['Float']['input']>;
  hoursForward?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryPickingByJobIdArgs = {
  jobId: Scalars['String']['input'];
};


export type QueryPickingByProjectionIdArgs = {
  projectionId: Scalars['String']['input'];
};


export type QueryPickingJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  jobCreatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  jobCreatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  jobUpdatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  jobUpdatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PickingJobOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  pickTypes?: InputMaybe<Array<PickStrategy>>;
  projectionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<PickingJobStatus>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryPickingJobsCountArgs = {
  expectedShipDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  expectedShipDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  includeHealJobs?: InputMaybe<Scalars['Boolean']['input']>;
  jobCreatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  jobCreatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  jobUpdatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  jobUpdatedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  pickTypes?: InputMaybe<Array<PickStrategy>>;
  projectionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<PickingJobStatus>>;
  workAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderTypes?: InputMaybe<Array<WorkOrderType>>;
};


export type QueryPickingJobsInProgressCountArgs = {
  deviceId: Scalars['String']['input'];
};


export type QueryProductArgs = {
  id: Scalars['String']['input'];
};


export type QueryProductTypeCountsByLocationTypeArgs = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  isExpirationTracked?: InputMaybe<Scalars['Boolean']['input']>;
  isLotTracked?: InputMaybe<Scalars['Boolean']['input']>;
  kinds?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ProductOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StorageLocationType>;
};


export type QueryPutawayJobArgs = {
  id: Scalars['String']['input'];
};


export type QueryPutawayJobCountArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  dateReceivedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateReceivedTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<PutawayJobsOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<GoalStates>>;
};


export type QueryPutawayJobsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  dateReceivedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateReceivedTo?: InputMaybe<Scalars['DateTime']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<PutawayJobsOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<GoalStates>>;
};


export type QuerySearchTagsArgs = {
  searchTerm: Scalars['String']['input'];
};


export type QuerySortationByJobIdArgs = {
  id: Scalars['String']['input'];
};


export type QuerySortationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<SortationOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  projectionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<SortationStatus>>;
};


export type QuerySortationsCountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  completedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  mfpIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<SortationOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  projectionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<SortationStatus>>;
};


export type QuerySpecialProjectArgs = {
  id: Scalars['String']['input'];
};


export type QuerySpecialProjectCountArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  dateReceivedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateReceivedTo?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<SpecialProjectsOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<GoalStates>>;
  types?: InputMaybe<Array<SpecialProjectType>>;
};


export type QuerySpecialProjectsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  dateReceivedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateReceivedTo?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<SpecialProjectsOrderByFields>;
  orderByDirection?: InputMaybe<OrderByDirection>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<GoalStates>>;
  types?: InputMaybe<Array<SpecialProjectType>>;
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserByBadgeBarcodeArgs = {
  badgeBarcode: Scalars['String']['input'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  badgeBarcodes?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserActivityType>;
};


export type QueryUsersCountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  badgeBarcodes?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserActivityType>;
};

export type ReworkDiscoveryRules = {
  __typename?: 'ReworkDiscoveryRules';
  receiving?: Maybe<Scalars['String']['output']>;
};

export type RoleInput = {
  name: Scalars['String']['input'];
};

export type ShipShort = {
  __typename?: 'ShipShort';
  doneQuantity: Scalars['Float']['output'];
  exceptionCount: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  unassignedCount: Scalars['Float']['output'];
};

export type Sortation = {
  __typename?: 'Sortation';
  batchId?: Maybe<Scalars['String']['output']>;
  customerOrders: Array<Order>;
  customerOrdersV2: Array<OrderV2>;
  expectedShipDate?: Maybe<Scalars['DateTime']['output']>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  isCancelable: Scalars['Boolean']['output'];
  job: Job;
  jobId: Scalars['String']['output'];
  kiosk?: Maybe<Scalars['String']['output']>;
  lines: Array<Line>;
  linesExceptionsCount: Count;
  linesV2: Array<LineV2>;
  pickingJobs: Array<Picking>;
  pickingJobsExceptions: Array<Job>;
  pickingJobsExceptionsCount: Count;
  pickingJobsV2: Array<Job>;
  status: SortationStatus;
  takeoffDestination?: Maybe<Scalars['String']['output']>;
  timeline: SortationTimeline;
  totalLineQuantities: LineQuantities;
  totalLines: Count;
  users: Array<User>;
  wall?: Maybe<Scalars['String']['output']>;
};

export type SortationConnection = {
  __typename?: 'SortationConnection';
  edges: Array<SortationEdge>;
  pageInfo: PageInfo;
};

export type SortationEdge = {
  __typename?: 'SortationEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Sortation;
};

export type SortationEvent = {
  timestamp: Scalars['DateTime']['output'];
};

export enum SortationOrderByFields {
  JobUpdatedAt = 'JobUpdatedAt'
}

export type SortationPickedEvent = SortationEvent & WorkflowEvent & {
  __typename?: 'SortationPickedEvent';
  timestamp: Scalars['DateTime']['output'];
};

export type SortationSortedEvent = SortationEvent & WorkflowEvent & {
  __typename?: 'SortationSortedEvent';
  timestamp: Scalars['DateTime']['output'];
};

export enum SortationStatus {
  Canceled = 'Canceled',
  CanceledOrphan = 'CanceledOrphan',
  Exception = 'Exception',
  Packable = 'Packable',
  Picked = 'Picked',
  Picking = 'Picking',
  Sorted = 'Sorted',
  Sorting = 'Sorting',
  Unassigned = 'Unassigned',
  Unknown = 'Unknown'
}

export type SortationTimeline = {
  __typename?: 'SortationTimeline';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  pickedAt?: Maybe<Scalars['DateTime']['output']>;
  sortedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SpecialProject = {
  dateReceived?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  /** Each type of special project will determine if this boolean needs to be set to true */
  hasIssues?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  merchant?: Maybe<Scalars['String']['output']>;
  status?: Maybe<GoalStates>;
  totalTimeLogged: Scalars['Float']['output'];
  type?: Maybe<SpecialProjectType>;
};

export type SpecialProjectIssuesInput = {
  exceptions: Array<PreKitExceptionInput>;
  goalId: Scalars['String']['input'];
};

export type SpecialProjectPage = {
  __typename?: 'SpecialProjectPage';
  cursor?: Maybe<Scalars['String']['output']>;
  results: Array<SpecialProject>;
};

export enum SpecialProjectType {
  CycleCount = 'cycleCount',
  InventoryTransferToMerchant = 'inventoryTransferToMerchant',
  Kitting = 'kitting',
  Other = 'other',
  Prekit = 'prekit',
  QualityControl = 'qualityControl',
  Relabelling = 'relabelling',
  Repackaging = 'repackaging',
  Returns = 'returns'
}

export enum SpecialProjectsOrderByFields {
  DateReceived = 'DateReceived',
  Id = 'Id'
}

export type StartHealPlanPayload = {
  __typename?: 'StartHealPlanPayload';
  success: Scalars['Boolean']['output'];
};

export type StorageLocation = {
  __typename?: 'StorageLocation';
  address: Scalars['String']['output'];
  containerType?: Maybe<Container>;
  containerTypeId?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<StorageLocationCoordinates>;
  description: Scalars['String']['output'];
  externalAisleId?: Maybe<Scalars['String']['output']>;
  /** A unique ID. */
  id: Scalars['ID']['output'];
  mapChunkId?: Maybe<Scalars['String']['output']>;
  products: Array<ProductInventory>;
  requiredReach?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<StorageLocationType>;
  workArea?: Maybe<WorkArea>;
};

export type StorageLocationCoordinates = {
  __typename?: 'StorageLocationCoordinates';
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
  z: Scalars['Int']['output'];
};

/** New storage location data */
export type StorageLocationInput = {
  /** The address of the storage location, e.g. "RSB-1" */
  address: Scalars['String']['input'];
  /** A reference to the container type utilized by the storage location. */
  containerTypeId?: InputMaybe<Scalars['String']['input']>;
  /** A short description of the storage location, e.g. "Return Sellable Bin 1" */
  description: Scalars['String']['input'];
  /** A reference to the aisle containing the storage location. */
  externalAisleId?: InputMaybe<Scalars['String']['input']>;
  /** A system-generated unique ID. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The type of storage location, e.g. bin or pallet. */
  type: StorageLocationType;
  /** The x-coordinate of the storage location, in millimeters. */
  x: Scalars['Int']['input'];
  /** The y-coordinate of the storage location, in millimeters. */
  y: Scalars['Int']['input'];
  /** The z-coordinate (vertical level) of the storage location. */
  z: Scalars['Int']['input'];
};

export type StorageLocationInventory = {
  __typename?: 'StorageLocationInventory';
  inventory: InventoryState;
  location: StorageLocation;
};

export enum StorageLocationOrderByFields {
  Address = 'Address',
  FillFraction = 'FillFraction',
  LiveQuantity = 'LiveQuantity',
  Type = 'Type'
}

export type StorageLocationSummary = {
  __typename?: 'StorageLocationSummary';
  address: Scalars['String']['output'];
  conflicted: Scalars['Boolean']['output'];
  containerTypeId?: Maybe<Scalars['String']['output']>;
  containerTypeName?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  externalAisleId?: Maybe<Scalars['String']['output']>;
  fillFraction: Scalars['Float']['output'];
  /** A unique ID. */
  id: Scalars['ID']['output'];
  inventories: Array<StorageLocationSummaryInventory>;
  /** A list of SATs {id, definition}, each of which can be regarded as a "tag" on that location */
  inventoryTags?: Maybe<Array<InventoryTag>>;
  liveQuantity?: Maybe<Scalars['Int']['output']>;
  mapChunkId?: Maybe<Scalars['String']['output']>;
  numAssetTypes: Scalars['Int']['output'];
  numSubAssetTypes: Scalars['Int']['output'];
  numSubLocations: Scalars['Int']['output'];
  totalPlannedAddQuantity: Scalars['Int']['output'];
  totalPlannedRemoveQuantity: Scalars['Int']['output'];
  type?: Maybe<StorageLocationType>;
  usedVolume: Scalars['Float']['output'];
  workArea?: Maybe<WorkArea>;
};

export type StorageLocationSummaryInventory = {
  __typename?: 'StorageLocationSummaryInventory';
  assetTypeDescription?: Maybe<Scalars['String']['output']>;
  assetTypeExternalId?: Maybe<Scalars['String']['output']>;
  assetTypeId?: Maybe<Scalars['String']['output']>;
  assetTypeName?: Maybe<Scalars['String']['output']>;
  conflictReasons: Array<InventoryConflictReason>;
  liveQuantity?: Maybe<Scalars['Int']['output']>;
  subLocationId?: Maybe<Scalars['String']['output']>;
  totalPlannedAddQuantity?: Maybe<Scalars['Int']['output']>;
  totalPlannedRemoveQuantity?: Maybe<Scalars['Int']['output']>;
};

export type StorageLocationSummaryPage = {
  __typename?: 'StorageLocationSummaryPage';
  cursor?: Maybe<Scalars['String']['output']>;
  results: Array<StorageLocationSummary>;
};

export enum StorageLocationType {
  Bin = 'bin',
  Cart = 'cart',
  Damaged = 'damaged',
  Dock = 'dock',
  External = 'external',
  Mfp = 'mfp',
  Pallet = 'pallet',
  Rack = 'rack',
  Reserve = 'reserve',
  Slot = 'slot',
  Staging = 'staging',
  Unknown = 'unknown',
  Warehouse = 'warehouse'
}

export type StorageLocationTypeCount = {
  __typename?: 'StorageLocationTypeCount';
  count: Scalars['Int']['output'];
  type?: Maybe<StorageLocationType>;
};

export enum SupportTicketStatus {
  Open = 'Open',
  Pending = 'Pending',
  Resolved = 'Resolved',
  WorkInProgress = 'WorkInProgress'
}

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['ID']['output'];
};

export type TaskV2 = {
  __typename?: 'TaskV2';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  container?: Maybe<JobContainer>;
  doneQuantity: Scalars['Int']['output'];
  /** A unique ID. */
  id: Scalars['ID']['output'];
  isShorted: Scalars['Boolean']['output'];
  isUserDirectedProduct: Scalars['Boolean']['output'];
  isUserDirectedSourceLocation: Scalars['Boolean']['output'];
  pickType?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  sourceLocationAddress?: Maybe<Scalars['String']['output']>;
  sourceLocationId?: Maybe<Scalars['String']['output']>;
  takeoffDestination?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
  workArea?: Maybe<WorkArea>;
  workAreaId?: Maybe<Scalars['String']['output']>;
};

/** Update container data */
export type UpdateContainerInput = {
  /** The container's barcode */
  barcode?: InputMaybe<Scalars['String']['input']>;
  /** The number of columns in the container */
  columns?: Scalars['Int']['input'];
  /** The description of the container */
  description?: Scalars['String']['input'];
  /** Should the container be enabled */
  enabled: Scalars['Boolean']['input'];
  /** The container's external identifier */
  externalId: Scalars['String']['input'];
  /** The height of the container in millimeters */
  height: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
  /** The url to an image of the container */
  imageURL?: InputMaybe<Scalars['String']['input']>;
  /** Special instructions for the container */
  instructions?: InputMaybe<Scalars['String']['input']>;
  /** Should this container be tracked in the inventory system? */
  inventory: Scalars['Boolean']['input'];
  /** The length of the container in millimeters */
  length: Scalars['Float']['input'];
  /** The container material type */
  materialType?: InputMaybe<Scalars['String']['input']>;
  /** The name of the container */
  name: Scalars['String']['input'];
  /** The number of rows in the container */
  rows?: Scalars['Int']['input'];
  /** The type of container */
  type?: ContainerType;
  /** The weight of the container in grams */
  weight?: Scalars['Float']['input'];
  /** The width of the container in millimeters */
  width: Scalars['Float']['input'];
};

export type UpdateUserInput = {
  /** The user's badge barcode */
  badgeBarcode?: InputMaybe<Scalars['String']['input']>;
  /** The user's email or username */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The date of the user's credentials expiry */
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The user's identifier */
  id: Scalars['String']['input'];
  /** The user's locale */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** The user's name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Is this user required? */
  requiredUser?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user's roles */
  roles?: InputMaybe<Array<RoleInput>>;
  /** Tags assigned to the user */
  tags?: InputMaybe<Array<NewTagInput>>;
};

export type User = {
  __typename?: 'User';
  badge?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isLoggedIn?: Maybe<Scalars['Boolean']['output']>;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  locale: Scalars['String']['output'];
  logins: Array<ChuckLoginLog>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  preferences: Array<UserPreference>;
  roles: Array<Scalars['String']['output']>;
  tags: Array<Tag>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum UserActivityType {
  Active = 'active',
  Inactive = 'inactive'
}

export enum UserAuthMethod {
  Google = 'GOOGLE',
  Internal = 'Internal',
  Ldap = 'LDAP'
}

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  key: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type UserPreferenceInput = {
  key: Scalars['String']['input'];
  type: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type WorkArea = {
  __typename?: 'WorkArea';
  /** A unique ID. */
  id: Scalars['ID']['output'];
  map: Scalars['JSONObject']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type WorkLog = {
  __typename?: 'WorkLog';
  dateLogged: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isSystemGenerated: Scalars['Boolean']['output'];
  task: Scalars['String']['output'];
  timeLogged: Scalars['Float']['output'];
  user?: Maybe<User>;
};

export type WorkLogInput = {
  dateLogged: Scalars['DateTime']['input'];
  goalId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  task: Scalars['String']['input'];
  timeLogged: Scalars['Float']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type WorkLogPage = {
  __typename?: 'WorkLogPage';
  cursor?: Maybe<Scalars['String']['output']>;
  results: Array<WorkLog>;
};

export type WorkOrderProduct = {
  __typename?: 'WorkOrderProduct';
  customerIdentifier: Scalars['String']['output'];
  eachQuantity?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type WorkOrderProject = SpecialProject & {
  __typename?: 'WorkOrderProject';
  dateReceived?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  /** Each type of special project will determine if this boolean needs to be set to true */
  hasIssues?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  instructions: Array<ProjectInstructions>;
  merchant?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<WorkOrderProduct>>;
  status?: Maybe<GoalStates>;
  totalTimeLogged: Scalars['Float']['output'];
  type?: Maybe<SpecialProjectType>;
};

export enum WorkOrderType {
  Ecommerce = 'ecommerce',
  InventoryTransferToMerchant = 'inventoryTransferToMerchant',
  InventoryTransferToNode = 'inventoryTransferToNode'
}

export type WorkflowEvent = {
  timestamp: Scalars['DateTime']['output'];
};
