import { Layout, Page, Stack } from '@sixriver/lighthouse-web-community';
import { useRouteMatch } from 'react-router-dom';

import { Details } from './Details';
import { Progress } from './Progress';
import { GoalStates } from '../../api/fulfillment-api/types';
import { Error } from '../../components/Error';
import { useLocalization } from '../../hooks/useLocalization';
import { ShuttleJobStatus } from '../ShuttleJobs/ShuttleJobsTable';
import { useGetShuttleAvailableDestinationQuery } from './graphql/GetShuttleAvailableDestination.w-api-graphql';
import { TimezoneFooter } from '../../components/TimezoneFooter';

export function ShuttleAvailableDestination() {
	const { messages } = useLocalization();
	const {
		params: { id },
	} = useRouteMatch<{ id: string }>();

	const [query] = useGetShuttleAvailableDestinationQuery({
		variables: {
			id,
		},
	});
	const { shuttleAvailableDestination } = query.data ?? {};

	if (query.error) {
		return <Error graphQLError={query.error} />;
	} else if (!query.fetching && !shuttleAvailableDestination) {
		return <Error heading={messages.shuttleAvailableDestinationNotFound} />;
	}

	return (
		<Page
			title={shuttleAvailableDestination?.inputs.storageLocationAddress || ''}
			titleMetadata={
				<Stack spacing="extraTight">
					<ShuttleJobStatus status={shuttleAvailableDestination?.state as GoalStates} />
				</Stack>
			}
		>
			<Layout>
				<Layout.Section>
					<Details shuttleAvailableDestination={shuttleAvailableDestination} />
				</Layout.Section>
				<Layout.Section>
					<Progress shuttleAvailableDestination={shuttleAvailableDestination} />
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
