import { Modal, Page, PageActions } from '@sixriver/lighthouse-web-community';
import { useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ProductForm } from './ProductForm';
import { useDeleteProductMutation } from './graphql/DeleteProduct.f-api-graphql';
import { useEditProductMutation } from './graphql/EditProduct.f-api-graphql';
import { useGetProductByIdQuery } from './graphql/GetProductById.f-api-graphql';
import { ProductInput } from '../../api/fulfillment-api/types';
import { Error } from '../../components/Error';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

export function EditProduct() {
	const history = useHistory();
	const { showToast } = useToast();
	const { messages } = useLocalization();
	const { lookBusy } = useContext(BusyIndicatorContext);
	const [modalVisible, setModalVisible] = useState(false);

	const {
		params: { productId },
	} = useRouteMatch<{ productId: string }>();

	const [getProductByIdQuery] = useGetProductByIdQuery({
		requestPolicy: 'network-only',
		variables: {
			id: productId,
		},
	});

	const [editProductMutation, executeEditProductMutation] = useEditProductMutation();
	const [deleteProductMutation, executeDeleteProductMutation] = useDeleteProductMutation();

	const onEdit = async (input: ProductInput) => {
		lookBusy(true);

		const { error } = await executeEditProductMutation({ input });

		if (error) {
			lookBusy(false);
			return;
		}

		setTimeout(() => {
			showToast(messages.dataSaved);
			history.push(routes.product(productId));
		}, 500);
	};

	const onDelete = async () => {
		lookBusy(true);
		setModalVisible(false);

		const { error } = await executeDeleteProductMutation({ id: productId });

		if (error) {
			lookBusy(false);
			showToast(messages.dataNotSaved, true);
			return;
		}

		setTimeout(() => {
			showToast(messages.dataSaved);
			history.push(routes.products());
		}, 500);
	};

	if (lookBusy(getProductByIdQuery.fetching && !getProductByIdQuery.data?.product)) {
		return null;
	}

	if (getProductByIdQuery.error) {
		return <Error graphQLError={getProductByIdQuery.error} />;
	}

	return (
		<Page
			fullWidth
			title={getProductByIdQuery.data?.product.customerIdentifier}
			breadcrumbs={[
				{
					url: routes.product(productId),
				},
			]}
		>
			<ProductForm
				mode="edit"
				onSubmit={onEdit}
				error={editProductMutation.error || deleteProductMutation.error}
				data={
					{
						customerIdentifier: getProductByIdQuery.data?.product.customerIdentifier || '',
						description: getProductByIdQuery.data?.product.description || '',
						height: getProductByIdQuery.data?.product.height || 0,
						id: getProductByIdQuery.data?.product.id,
						image: getProductByIdQuery.data?.product.image,
						length: getProductByIdQuery.data?.product.length || 0,
						name: getProductByIdQuery.data?.product.name || '',
						weight: getProductByIdQuery.data?.product.weight || 0,
						width: getProductByIdQuery.data?.product.width || 0,
					} as ProductInput
				}
			/>
			<br />
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteProduct,
						destructive: true,
						onAction: () => setModalVisible(true),
						outline: true,
					},
				]}
			/>
			<Modal
				open={modalVisible}
				title={messages.deleteProduct}
				onClose={() => setModalVisible(false)}
				primaryAction={{
					content: messages.deleteProduct,
					destructive: true,
					loading: deleteProductMutation.fetching,
					onAction: () => void onDelete(),
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.confirmDeleteProduct}</Modal.Section>
			</Modal>
		</Page>
	);
}
