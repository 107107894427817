// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetLocationTypeCountsQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetLocationTypeCountsQuery = { __typename?: 'Query', locationTypeCounts: Array<{ __typename?: 'StorageLocationTypeCount', type?: Types.StorageLocationType | null, count: number }> };


export const GetLocationTypeCountsDocument = gql`
    query wapi_GetLocationTypeCounts($searchText: String) {
  locationTypeCounts(searchText: $searchText) {
    type
    count
  }
}
    `;

export function useGetLocationTypeCountsQuery(options?: Omit<Urql.UseQueryArgs<GetLocationTypeCountsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLocationTypeCountsQuery, GetLocationTypeCountsQueryVariables>({ query: GetLocationTypeCountsDocument, ...options });
};