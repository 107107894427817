import { Button } from '@sixriver/lighthouse-web-community';

import styles from './ObjectDiff.module.css';
import { useLocalization } from '../../hooks/useLocalization';

type ExpandButtonProps = {
	expanded: boolean;
};

export function ExpandButton({ expanded }: ExpandButtonProps) {
	const { messages } = useLocalization();

	return (
		<div className={styles.ExpandButton}>
			{/* the onclick event gets propagated to the container */}
			<Button size="slim" plain>
				{(expanded ? messages.collapse : messages.expand).toLowerCase()}
			</Button>
		</div>
	);
}
