import { Badge } from '@sixriver/lighthouse-web-community';

import { LineStatus } from '../../../../api/fulfillment-api/types';
import { GetLinesQueryLine } from '../../../../graphql/derived';
import { useLocalization } from '../../../../hooks/useLocalization';
import { NoData } from '../../../NoData';

interface Props {
	line: GetLinesQueryLine;
}

export function ProgressBadge({ line }: Props): JSX.Element {
	const { messages } = useLocalization();

	if (!line.status) {
		return <NoData />;
	}

	if (line.status.includes(LineStatus.AwaitingInventory)) {
		return <Badge status="warning">{messages.awaitingInventory}</Badge>;
	}

	if (line.status.includes(LineStatus.Unassigned)) {
		return <Badge status="warning">{messages.unassigned}</Badge>;
	}

	if (line.status.includes(LineStatus.InProgress)) {
		return <Badge status="info">{messages.inProgress}</Badge>;
	}

	// LOL-3364
	// if ('packout' in job) {
	// 	if (job.packout?.status === PackoutStatus.Inducted) {
	// 		return <Badge status="info">{messages.packing}</Badge>;
	// 	}
	// }

	if (line.status.includes(LineStatus.Complete) || line.status.includes(LineStatus.Done)) {
		// LOL-3364
		// if (job.isPackoutRequired) {
		// 	if (job.packout?.status) {
		// 		return <Badge>{messages.readyToPack}</Badge>;
		// 	}
		// 	return <Badge>{messages.picked}</Badge>;
		// }
		return <Badge>{messages.completed}</Badge>;
	}

	if (line.status.includes(LineStatus.Packed)) {
		return <Badge>{messages.completed}</Badge>;
	}

	return <NoData />;
}
