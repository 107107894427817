import { Page } from '@sixriver/lighthouse-web-community';
import { useHistory } from 'react-router-dom';

import JobFlowRuleForm from './JobFlowRuleForm';
import { useAddJobFlowRuleMutation } from './graphql/AddJobFlowRule.f-api-graphql';
import { JobFlowRuleInput } from '../../api/fulfillment-api/types';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';

export function AddJobFlowRule() {
	const { messages } = useLocalization();

	const history = useHistory();

	const { showToast } = useToast();

	const [addJobFlowRuleMutation, executeAddJobFlowRuleMutation] = useAddJobFlowRuleMutation();

	const onSubmit = async (input: JobFlowRuleInput) => {
		const { error } = await executeAddJobFlowRuleMutation({ input });

		if (!error) {
			showToast(messages.dataSaved);

			history.push(routes.jobFlowRules());
		}
	};

	const input: JobFlowRuleInput = {
		rule: {
			line: {
				and: [
					{
						'sourceLoc.address': {
							eq: '',
						},
					},
				],
			},
		},
		ruleDescription: '',
		ruleSubType: [],
	};

	return (
		<Page
			fullWidth
			title={messages.addJobFlowRule}
			breadcrumbs={[{ content: messages.jobFlowRules, url: routes.jobFlowRules() }]}
		>
			<JobFlowRuleForm
				mode="add"
				data={input}
				error={addJobFlowRuleMutation.error}
				onSubmit={onSubmit}
			/>
		</Page>
	);
}
