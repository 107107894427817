// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOutboundJobsCountQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statuses?: Types.InputMaybe<Array<Types.JobStatus> | Types.JobStatus>;
  isLate?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isShorted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isAwaitingInventory?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  isHealJob?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  expectedShipDateFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  expectedShipDateTo?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  createdAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  pickStrategies?: Types.InputMaybe<Array<Types.PickStrategy> | Types.PickStrategy>;
  workOrderTypes?: Types.InputMaybe<Array<Types.WorkOrderType> | Types.WorkOrderType>;
  usedEquipmentTypes?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  workAreaIds?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;


export type GetOutboundJobsCountQuery = { __typename?: 'Query', TotalCount: { __typename?: 'JobsCount', count: number, interrupted: number, late: number, shorted: number, awaitingInventory: number }, StaticTotalCount: { __typename?: 'JobsCount', unassigned: number, picking: number, picked: number, sortable: number, sorting: number, packable: number, packing: number, packed: number, count: number, interrupted: number, ready: number, sorted: number } };


export const GetOutboundJobsCountDocument = gql`
    query wapi_GetOutboundJobsCount($ids: [String!], $searchText: String, $statuses: [JobStatus!], $isLate: Boolean, $isShorted: Boolean, $isAwaitingInventory: Boolean, $isHealJob: Boolean, $expectedShipDateFrom: DateTime, $expectedShipDateTo: DateTime, $createdAtFrom: DateTime, $pickStrategies: [PickStrategy!], $workOrderTypes: [WorkOrderType!], $usedEquipmentTypes: [String!], $workAreaIds: [String!]) {
  TotalCount: jobsCount(
    ids: $ids
    searchText: $searchText
    statuses: $statuses
    isLate: $isLate
    isShorted: $isShorted
    isAwaitingInventory: $isAwaitingInventory
    isHealJob: $isHealJob
    expectedShipDateFrom: $expectedShipDateFrom
    expectedShipDateTo: $expectedShipDateTo
    createdAtFrom: $createdAtFrom
    pickStrategies: $pickStrategies
    workOrderTypes: $workOrderTypes
    usedEquipmentTypes: $usedEquipmentTypes
    workAreaIds: $workAreaIds
  ) {
    count
    interrupted
    late
    shorted
    awaitingInventory
  }
  StaticTotalCount: jobsCount(
    createdAtFrom: $createdAtFrom
    workAreaIds: $workAreaIds
  ) {
    unassigned
    picking
    picked
    sortable
    sorting
    packable
    packing
    packed
    count
    interrupted
    ready
    sorted
  }
}
    `;

export function useGetOutboundJobsCountQuery(options?: Omit<Urql.UseQueryArgs<GetOutboundJobsCountQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOutboundJobsCountQuery, GetOutboundJobsCountQueryVariables>({ query: GetOutboundJobsCountDocument, ...options });
};