import { IndexTable } from '@sixriver/lighthouse-web-community';

import { PutawayJobStatus } from './PutawayJobStatus';
import { GoalStates } from '../../../api/fulfillment-api/types';

export function ProgressCell({ status }: { status?: GoalStates }) {
	return (
		<IndexTable.Cell>
			<PutawayJobStatus status={status} />
		</IndexTable.Cell>
	);
}
