import { ChoiceList, Stack, TextStyle } from '@sixriver/lighthouse-web-community';
import { noop } from 'lodash';

import { JobStatus } from '../../../api/warehouse-api/types';
import { useLocalization } from '../../../hooks/useLocalization';

interface StatusesFilterProps {
	selected?: JobStatus[];
	onChange?: (selected: JobStatus[], name: string) => void;
}

export function StatusesFilter({ selected = [], onChange = noop }: StatusesFilterProps) {
	const { messages } = useLocalization();

	return (
		<Stack vertical>
			<ChoiceList
				title={<TextStyle variation="strong">{messages.resolutionJobs}</TextStyle>}
				choices={[
					{
						label: messages.unassigned,
						value: JobStatus.Unassigned,
					},
				]}
				selected={selected}
				onChange={onChange}
				allowMultiple
			/>
			<ChoiceList
				title={<TextStyle variation="strong">{messages.allJobs}</TextStyle>}
				choices={[
					{
						label: messages.picking,
						value: JobStatus.Picking,
					},
					{
						label: messages.picked,
						value: JobStatus.Picked,
					},
					{
						label: messages.readyToSort,
						value: JobStatus.Sortable,
					},
					{
						label: messages.sorting,
						value: JobStatus.Sorting,
					},
					{
						label: messages.sorted,
						value: JobStatus.Sorted,
					},
					{
						label: messages.readyToPack,
						value: JobStatus.Packable,
					},
					{
						label: messages.packing,
						value: JobStatus.Packing,
					},
					{
						label: messages.packed,
						value: JobStatus.Packed,
					},
					{
						label: messages.interrupted,
						value: JobStatus.Interrupted,
					},
					{
						label: messages.canceled,
						value: JobStatus.Canceled,
					},
				]}
				selected={selected}
				onChange={onChange}
				allowMultiple
			/>
		</Stack>
	);
}
