import {
	MAX_WILDCARDS_PER_CHAR,
	MIN_PAYLOAD_FILTER_LENGTH,
	isPayloadFilterTooShort,
	payloadFilterHasTooManyWildCards,
} from '@sixriver/edge-services-common';
import {
	ChoiceList,
	Filters,
	AppliedFilterInterface,
	TextField,
} from '@sixriver/lighthouse-web-community';
import { asArray } from '@sixriver/typescript-support';
import { useCallback } from 'react';

import { DeliveryTypeIcon } from './DeliveryTypeIcon';
import { MessageTypePicker } from './MessageTypePicker';
import { ValidationResult, ValidatedTextField } from './shared';
import {
	usePolarisFilterPropsFactories,
	GetFilterFieldValue,
	GetPolarisFilterProps,
	SetFilterFieldValue,
} from './usePolarisFilterPropsFactories';
import { DeliveryState, DeliveryType, MessageDeliveryAndAttemptFilter } from '../../api/edge/types';

const uuidRegex = /^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/i;

export interface DeliveryFiltersProps {
	filter: MessageDeliveryAndAttemptFilter | undefined;
	onFilterChanged: (value: MessageDeliveryAndAttemptFilter | undefined) => void;
}

export function DeliveryFilters(props: DeliveryFiltersProps): JSX.Element {
	const filterFactories = [
		useFilterFactories(
			'Delivery Type',
			(name, value, onChange) => ({
				filter: (
					<ChoiceList
						title={name}
						titleHidden
						choices={Object.values(DeliveryType).map((s) => ({
							label: (
								<div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
									<DeliveryTypeIcon deliveryType={s} color="subdued" />
									<span style={{ marginLeft: '8px' }}>{s}</span>
								</div>
							),
							value: s,
						}))}
						selected={asArray(value)}
						onChange={(selected) => onChange(selected[0])}
					/>
				),
				shortcut: true,
			}),
			(f) => f?.message?.deliveryType,
			(f, v) => ({
				...f,
				message: {
					...f?.message,
					deliveryType: v,
				},
			}),
		),
		useFilterFactories(
			'Delivery State',
			useCallback(
				(name, value, onChange) => ({
					filter: (
						<ChoiceList
							title={name}
							titleHidden
							choices={Object.values(DeliveryState).map((s) => ({
								label: s,
								value: s,
							}))}
							selected={asArray(value)}
							onChange={(selected) => onChange(selected[0])}
						/>
					),
					shortcut: true,
				}),
				[],
			),
			useCallback((f) => f?.deliveryState, []),
			useCallback(
				(f, v) => ({
					...f,
					deliveryState: v,
				}),
				[],
			),
		),
		useFilterFactories(
			'Message Type',
			useCallback(
				(name, value, onChange) => ({
					filter: (
						<MessageTypePicker label={name} labelHidden value={value ?? ''} onChange={onChange} />
					),
					shortcut: true,
				}),
				[],
			),
			useCallback((f) => f?.message?.messageType, []),
			useCallback(
				(f, v) => ({
					...f,
					message: {
						...f?.message,
						messageType: v,
					},
				}),
				[],
			),
		),
		useFilterFactories(
			'Message ID',
			useCallback(
				(name, value, onChange) => ({
					filter: (
						<ValidatedTextField
							label={name}
							value={value ?? ''}
							onChange={onChange}
							autoComplete="off"
							labelHidden
							isValid={isUuidValid}
						/>
					),
					shortcut: true,
				}),
				[],
			),
			useCallback((f) => f?.message?.ids?.[0], []),
			useCallback(
				(f, v) => ({
					...f,
					message: {
						...f?.message,
						ids: v ? [v] : undefined,
					},
				}),
				[],
			),
		),
		useFilterFactories(
			'Min attempts',
			useCallback(
				(name, value, onChange) => ({
					filter: (
						<TextField
							type="number"
							step={1}
							label={name}
							value={value ?? ''}
							onChange={(v) => onChange(Number(v))}
							autoComplete="off"
							labelHidden
						/>
					),
					shortcut: true,
				}),
				[],
			),
			useCallback((f) => f?.minAttemptsStarted, []),
			useCallback(
				(f, v) => ({
					...f,
					minAttemptsStarted: v ? parseInt(v) : undefined,
				}),
				[],
			),
		),
		useFilterFactories(
			'Http Status',
			useCallback(
				(name, value, onChange) => ({
					filter: (
						<TextField
							type="number"
							label={name}
							value={value ?? ''}
							onChange={onChange}
							autoComplete="off"
							labelHidden
						/>
					),
					shortcut: true,
				}),
				[],
			),
			useCallback((f) => f?.httpStatus, []),
			useCallback(
				(f, v) => ({
					...f,
					httpStatus: v ? parseInt(v) : undefined,
				}),
				[],
			),
		),
		useFilterFactories(
			'Max attempts',
			useCallback(
				(name, value, onChange) => ({
					filter: (
						<TextField
							type="number"
							step={1}
							label={name}
							value={value ?? ''}
							onChange={(v) => onChange(Number(v))}
							autoComplete="off"
							labelHidden
						/>
					),
					shortcut: true,
				}),
				[],
			),
			useCallback((f) => f?.maxAttemptsStarted, []),
			useCallback(
				(f, v) => ({
					...f,
					maxAttemptsStarted: v ? parseInt(v) : undefined,
				}),
				[],
			),
		),
		useFilterFactories(
			'Payload Content',
			useCallback(
				(name, value, onChange) => ({
					filter: (
						<ValidatedTextField
							label={name}
							value={value ?? ''}
							onChange={onChange}
							autoComplete="off"
							labelHidden
							isValid={isPayloadFilterValid}
						/>
					),
					shortcut: true,
				}),
				[],
			),
			useCallback((f) => f?.message?.payloadContent, []),
			useCallback(
				(f, v) => ({
					...f,
					message: {
						...f?.message,
						payloadContent: !v?.length ? undefined : v,
					},
				}),
				[],
			),
		),
	];

	const appliedFilters = filterFactories
		.map((f) => f.getPolarisAppliedFilterProps())
		.filter((a): a is AppliedFilterInterface => !!a);

	return (
		<Filters
			filters={filterFactories.map((f) => f.getPolarisFilterProps())}
			appliedFilters={appliedFilters}
			onClearAll={() => props.onFilterChanged(undefined)}
			hideQueryField
			onQueryChange={emptyFunction}
			onQueryClear={emptyFunction}
		/>
	);

	function useFilterFactories(
		name: string,
		getPolarisFilterProps: GetPolarisFilterProps,
		getFilterFieldValue: GetFilterFieldValue<MessageDeliveryAndAttemptFilter>,
		setFilterFieldValue: SetFilterFieldValue<MessageDeliveryAndAttemptFilter>,
	) {
		return usePolarisFilterPropsFactories(
			props.filter,
			props.onFilterChanged,
			name,
			getPolarisFilterProps,
			getFilterFieldValue,
			setFilterFieldValue,
		);
	}
}

const emptyFunction = () => {
	return;
};

function isUuidValid(value: string | undefined): ValidationResult {
	if (!value) {
		return { valid: true };
	}

	return uuidRegex.test(value)
		? { valid: true }
		: { message: 'Message ID must be a valid UUID', valid: false };
}

function isPayloadFilterValid(value: string | undefined): ValidationResult {
	if (!value) {
		return { valid: true };
	}
	if (isPayloadFilterTooShort(value)) {
		return {
			message: `Filter must be at least ${MIN_PAYLOAD_FILTER_LENGTH} characters`,
			valid: false,
		};
	}
	if (payloadFilterHasTooManyWildCards(value)) {
		const maxForCurrentFilter = Math.floor(value.length * MAX_WILDCARDS_PER_CHAR);
		return {
			message: `Filter of length ${value.length} may have at most ${maxForCurrentFilter} wildcards`,
			valid: false,
		};
	}
	return { valid: true };
}
