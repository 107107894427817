import { Page } from '@sixriver/lighthouse-web-community';
import { useCallback, useContext, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EmployeeForm, EmployeeFormData } from './Employee.form';
import { getEmployeeFormDataFromExistingEmployee, getRolesFromFormData } from './Employee.utils';
import { useGetEmployeeQuery } from './graphql/GetEmployee.f-api-graphql';
import { useUpdateUserMutation } from './graphql/UpdateUser.f-api-graphql';
import { Error } from '../../components/Error';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

interface Params {
	employeeId: string;
}

export function EmployeeEdit() {
	const history = useHistory();
	const { showToast } = useToast();
	const { messages } = useLocalization();
	const params = useParams<Params>();
	const { lookBusy } = useContext(BusyIndicatorContext);

	const employeeId = params?.employeeId;

	// Queries
	const [getEmployeeQuery] = useGetEmployeeQuery({ variables: { id: employeeId } });

	const employee = getEmployeeQuery.data?.user;
	const formDefaults: EmployeeFormData = useMemo(
		() => getEmployeeFormDataFromExistingEmployee(employee),
		[employee],
	);

	// Mutations
	const [updateUserMutation, executeUpdateUserMutation] = useUpdateUserMutation();

	// Methods
	const onEdit = useCallback(
		async (formData: EmployeeFormData) => {
			lookBusy(false);

			const { data } = await executeUpdateUserMutation({
				input: {
					badgeBarcode: formData.badgeBarcode || null,
					email: formData.email || null,
					id: employeeId,
					locale: formData.locale || null,
					name: formData.name || null,
					roles: getRolesFromFormData(formData).map((name) => ({ name })),
					tags: formData.tags.map((tag) => ({ name: tag })),
				},
			});

			if (!data?.updateUser?.success || !data.updateUser?.referenceId) {
				lookBusy(false);
				showToast(messages.errorToast, true);
				return;
			}

			setTimeout(() => {
				lookBusy(false);
				history.push(routes.employee(data.updateUser.referenceId!));
			}, 500);
		},
		[lookBusy, executeUpdateUserMutation, employeeId, showToast, messages.errorToast, history],
	);

	if (lookBusy(getEmployeeQuery.fetching && !employee)) {
		return null;
	}

	if (getEmployeeQuery.error) {
		return <Error graphQLError={getEmployeeQuery.error} />;
	}

	return (
		<Page
			fullWidth
			title={formDefaults.name || messages.editEmployee}
			breadcrumbs={[
				{
					content: messages.employee,
					url: routes.employee(params?.employeeId),
				},
			]}
		>
			<EmployeeForm
				mode="edit"
				onSubmit={onEdit}
				data={formDefaults}
				error={updateUserMutation.error}
			/>
		</Page>
	);
}
