import { useFetch } from '@hyper-fetch/react';
import { useDebouncedValue } from '@shopify/react-hooks';
import { Banner, Layout, Spinner, TextStyle } from '@sixriver/lighthouse-web-community';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import styles from './Readiness.module.css';
import { getNamespaceReady } from '../../api';
import { useLocalization } from '../../hooks/useLocalization';

const Readiness = () => {
	const { data, loading, refetch } = useFetch(getNamespaceReady);
	const { messages } = useLocalization();

	const [fetchingIntervalId, setFetchingIntervalId] = useState<number | null>(null);
	const [isVisible, setIsVisible] = useState(true);

	const isLoading = useDebouncedValue(loading, { timeoutMs: 1000 });

	const startFetching = useCallback(() => {
		const intervalId = window.setInterval(() => {
			refetch();
		}, 10000);

		setFetchingIntervalId(intervalId);

		return intervalId;
	}, [refetch]);

	const stopFetching = useCallback(() => {
		if (fetchingIntervalId === null) {
			return;
		}

		clearInterval(fetchingIntervalId);
		setFetchingIntervalId(null);
	}, [fetchingIntervalId]);

	useEffect(() => {
		if (isVisible && fetchingIntervalId === null) {
			startFetching();
		}

		if (!isVisible && fetchingIntervalId !== null) {
			stopFetching();
		}

		return () => {
			stopFetching();
		};
	}, [isVisible, fetchingIntervalId, startFetching, stopFetching]);

	const onVisibilityChange = useCallback(() => {
		if (document.visibilityState === 'visible') {
			setIsVisible(true);
		}

		if (document.visibilityState === 'hidden') {
			setIsVisible(false);
		}
	}, [setIsVisible]);

	useLayoutEffect(() => {
		document.addEventListener('visibilitychange', onVisibilityChange);

		return () => document.removeEventListener('visibilitychange', onVisibilityChange);
	}, [onVisibilityChange]);

	return (
		<Layout.Section>
			<Banner
				title={messages.cloudServicesUpdateStatus}
				status={isLoading ? 'info' : data?.isUpdatePending ? 'warning' : 'success'}
			>
				<div className={styles.readinessContainer}>
					{isLoading ? (
						<>
							<div className={styles.readinessIcon}>
								{isLoading ? <Spinner size="small" /> : <></>}
							</div>
							<div className={styles.readinessText}>
								<TextStyle variation="subdued">{messages.loading}</TextStyle>
							</div>
						</>
					) : (
						<TextStyle variation={data?.isUpdatePending ? 'subdued' : 'positive'}>
							{data?.isUpdatePending ? messages.updatePending : messages.noUpdatePending}
						</TextStyle>
					)}
				</div>
			</Banner>
		</Layout.Section>
	);
};

export default Readiness;
