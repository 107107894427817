import { IndexTable, TextStyle } from '@sixriver/lighthouse-web-community';

import { PickWaveOrderFieldsFragment } from '../graphql/PickWaveOrderConnectionFields.f-api-graphql';

type IdCellProps = {
	order: PickWaveOrderFieldsFragment;
};

export function IdCell({ order }: IdCellProps) {
	return (
		<IndexTable.Cell>
			<TextStyle>{order.externalId}</TextStyle>
		</IndexTable.Cell>
	);
}
