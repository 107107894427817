import type { PaginationArguments } from '@sixriver/typescript-support';
import { useMemo, useState } from 'react';

import { AttemptTable, AttemptTableData } from './AttemptTable';
import { useGetDeliveryAttemptsQuery } from './graphql/GetDeliveryAttempts.edge-graphql';
import { MessageDeliveryFilter, SortDirection } from '../../api/edge/types';

export interface AttemptsLogProps {
	style?: React.CSSProperties;
	className?: string;

	deliveryIds: string[];
	pageSize: number;
	debounceWaitMs: number;
}

export function AttemptsLog(props: AttemptsLogProps) {
	const [attemptFilter, setAttemptFilter] = useState<MessageDeliveryFilter>();

	const fullFilter = useMemo(() => {
		return {
			...attemptFilter,
			delivery: {
				ids: props.deliveryIds,
			},
		};
	}, [attemptFilter, props.deliveryIds]);

	const [attemptSortDirection, setAttemptSortDirection] = useState<SortDirection>(
		SortDirection.Desc,
	);

	const [paginationArguments, setPaginationArguments] = useState<PaginationArguments>({
		first: props.pageSize,
	});

	const [getDeliveryAttemptsQuery] = useGetDeliveryAttemptsQuery({
		variables: {
			...paginationArguments,
			filter: fullFilter,
			orderDirection: attemptSortDirection,
		},
	});

	const attemptsTableData: AttemptTableData = useMemo(() => {
		const pageData = getDeliveryAttemptsQuery?.data?.getDeliveryAttempts.edges.map((edge) => {
			return { cursor: edge.cursor, ...edge.node };
		});
		const pageInfo = getDeliveryAttemptsQuery?.data?.getDeliveryAttempts.pageInfo;
		return {
			data: pageData ?? [],
			pageInfo: {
				endCursor: pageInfo?.endCursor ?? null,
				hasNextPage: pageInfo?.hasNextPage,
				hasPreviousPage: pageInfo?.hasPreviousPage,
				startCursor: pageInfo?.startCursor ?? null,
			},
		};
	}, [
		getDeliveryAttemptsQuery?.data?.getDeliveryAttempts.edges,
		getDeliveryAttemptsQuery?.data?.getDeliveryAttempts.pageInfo,
	]);

	if (getDeliveryAttemptsQuery.error) {
		return <div>{getDeliveryAttemptsQuery.error.message ?? 'Unknown error'}</div>;
	}

	return (
		<AttemptTable
			style={props.style}
			className={props.className}
			tableData={attemptsTableData}
			onNext={() =>
				setPaginationArguments({
					after: attemptsTableData.pageInfo.endCursor,
					before: undefined,
					first: props.pageSize,
					last: undefined,
				})
			}
			onPrevious={() =>
				setPaginationArguments({
					after: undefined,
					before: attemptsTableData.pageInfo.startCursor,
					first: undefined,
					last: props.pageSize,
				})
			}
			filter={attemptFilter}
			onFilterChanged={setAttemptFilter}
			sortDirection={attemptSortDirection}
			onSortDirectionChanged={setAttemptSortDirection}
			loading={getDeliveryAttemptsQuery.fetching}
		/>
	);
}
