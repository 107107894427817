// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ResolvePickWavesMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type ResolvePickWavesMutation = { __typename?: 'Mutation', resolvePickWaves: { __typename?: 'MutationResponse', success: boolean } };


export const ResolvePickWavesDocument = gql`
    mutation fapi_ResolvePickWaves($ids: [String!]!) {
  resolvePickWaves(ids: $ids) {
    success
  }
}
    `;

export function useResolvePickWavesMutation() {
  return Urql.useMutation<ResolvePickWavesMutation, ResolvePickWavesMutationVariables>(ResolvePickWavesDocument);
};