import { DateTime, NoData } from '@sixriver/react-support';

import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';

interface ResolvedProps {
	pickWave: PickWaveFieldsFragment;
}

export function Resolved({ pickWave }: ResolvedProps) {
	if (pickWave.resolvedAt) {
		return <DateTime date={pickWave.resolvedAt} />;
	}

	return <NoData />;
}
