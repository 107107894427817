import { IndexTable } from '@sixriver/lighthouse-web-community';

import { PickWaveStatusBadge } from '../PickWaveStatusBadge';
import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';

interface StatusCellProps {
	pickWave: PickWaveFieldsFragment;
}

export function StatusCell({ pickWave }: StatusCellProps) {
	return (
		<IndexTable.Cell>
			<PickWaveStatusBadge pickWave={pickWave} />
		</IndexTable.Cell>
	);
}
