// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PrintLabelMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type PrintLabelMutation = { __typename?: 'Mutation', startHealPlanAndPrintLabels: { __typename?: 'StartHealPlanPayload', success: boolean } };


export const PrintLabelDocument = gql`
    mutation fapi_PrintLabel($id: String!) {
  startHealPlanAndPrintLabels(id: $id) {
    success
  }
}
    `;

export function usePrintLabelMutation() {
  return Urql.useMutation<PrintLabelMutation, PrintLabelMutationVariables>(PrintLabelDocument);
};