import {
	IndexTable,
	IndexTableProps,
	useIndexResourceState,
} from '@sixriver/lighthouse-web-community';
import { useCallback, useMemo } from 'react';

import { IdCell } from './IdCell';
import { LinesDroppedCell } from './LinesDroppedCell';
import { OrdersDroppedCell } from './OrdersDroppedCell';
import { ReceivedCell } from './ReceivedCell';
import { StatusCell } from './StatusCell';
import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';

export type onClickPickWaveIdFn = (id: string) => void;
export type onResolvePickWavesFn = (ids: string[], clearSelection: () => void) => void;

type PickWavesTableProps = {
	loading?: boolean;
	pickWaves?: PickWaveFieldsFragment[];
	onClickPickWaveId: onClickPickWaveIdFn;
	onResolvePickWaves?: onResolvePickWavesFn;
};

enum SelectionType {
	All = 'all',
	Page = 'page',
	Multi = 'multi',
	Single = 'single',
}

export function PickWavesTable({
	loading,
	pickWaves = [],
	onClickPickWaveId,
	onResolvePickWaves = () => undefined,
}: PickWavesTableProps) {
	const resourceName = useMemo(
		() => ({
			plural: 'pick waves',
			singular: 'pick wave',
		}),
		[],
	);
	const headings: IndexTableProps['headings'] = useMemo(
		() => [
			{ title: 'Pick wave ID' },
			{ title: 'Received' },
			{ title: 'Lines dropped' },
			{ title: 'Orders dropped' },
			{ title: 'Status' },
		],
		[],
	);
	const { selectedResources, allResourcesSelected, handleSelectionChange } =
		useIndexResourceState(pickWaves);

	// TODO: In version 2.15.0 of lighthouse there is a clearSelection function avaialable in useIndexResourceState
	const clearSelection = useCallback(
		() => handleSelectionChange(SelectionType.All, false),
		[handleSelectionChange],
	);
	const promotedBulkActions = useMemo(
		() => [
			{
				content: 'Mark as resolved',
				onAction: () => onResolvePickWaves(selectedResources, clearSelection),
			},
		],
		[clearSelection, onResolvePickWaves, selectedResources],
	);

	const rowMarkup = pickWaves.map((pickWave, index) => {
		const { id } = pickWave;
		return (
			<IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
				<IdCell pickWave={pickWave} onClickPickWaveId={onClickPickWaveId} />
				<ReceivedCell pickWave={pickWave} />
				<LinesDroppedCell pickWave={pickWave} />
				<OrdersDroppedCell pickWave={pickWave} />
				<StatusCell pickWave={pickWave} />
			</IndexTable.Row>
		);
	});

	return (
		<IndexTable
			loading={loading}
			resourceName={resourceName}
			itemCount={pickWaves.length}
			selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
			onSelectionChange={handleSelectionChange}
			promotedBulkActions={promotedBulkActions}
			headings={headings}
		>
			{rowMarkup}
		</IndexTable>
	);
}
