import { DuplicateMinor } from '@sixriver/lighthouse-icons';
import {
	Layout,
	Stack,
	Tabs,
	ComplexAction,
	MenuGroupDescriptor,
	Loading,
	Frame,
	Card,
} from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';
import { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { BreakdownAndIssues } from './BreakdownAndIssues';
import { Details } from './Details';
import { MarkAsCompleteModal } from './MarkAsCompleteModal';
import { Progress } from './Progress';
import { ResolutionSummaryCalloutCard } from './ResolutionSummaryCalloutCard';
import { ShippedShortBanner } from './ShippedShortBanner';
import {
	GetOrderDocument,
	GetOrderQuery,
	GetOrderQueryVariables,
} from './graphql/GetOrder.w-api-graphql';
import {
	GetOrderEventsDocument,
	GetOrderEventsQuery,
	GetOrderEventsQueryVariables,
} from './graphql/GetOrderEvents.f-api-graphql';
import { PickStrategy } from '../../api/warehouse-api/types';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { CancelOrderModal } from '../../components/CancelOrderModal';
import { Error } from '../../components/Error';
import { ErrorBanner } from '../../components/ErrorBanner';
import { Lines } from '../../components/Lines';
import { LinesTableColumn } from '../../components/Lines/LinesTable';
import { LinesTab } from '../../components/Lines/LinesTabs';
import { OrderStatusBadgeV2 } from '../../components/OrderStatusBadge/OrderStatusBadgeV2';
import { ResolutionCountText } from '../../components/ResolutionCountText';
import { Timeline } from '../../components/Timeline';
import { useWorkflowEvents } from '../../components/Timeline/Timeline.hooks';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useCancelOrder } from '../../hooks/useCancelOrder';
import { useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { useSetFilters, useFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';

export interface RouteMatchParams {
	orderId: string;
}

export function Order() {
	const {
		params: { orderId },
	} = useRouteMatch<RouteMatchParams>();

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);
	const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
	const { messages } = useLocalization();
	const { isUserAllowed } = useAuth();
	const { copyToClipboard } = useCopyToClipboard();
	const history = useHistory();
	const setFilters = useSetFilters();
	const { isCancelModalOpen, openCancelModal, closeCancelModal, cancelOrder, cancelOrderLoading } =
		useCancelOrder({
			onBefore: () => {
				setIsBannerErrorVisible(false);
			},
			onError: () => {
				setIsBannerErrorVisible(true);
			},
			onSuccessfulCancel: () => {
				history.push(routes.orders());
			},
			orderId,
		});
	const tabs = [
		{
			content: messages.overview,
			id: 'overview',
		},
		{
			content: messages.history,
			id: 'history',
		},
	];

	const { tab } = useFilters(['tab']);

	const tabIndex = tab === tabs[1]?.id ? 1 : 0;

	const [getOrderQuery] = usePollingQuery<GetOrderQuery, GetOrderQueryVariables>({
		query: GetOrderDocument,
		variables: {
			id: orderId,
		},
	});

	const [getOrderEventsQuery] = usePollingQuery<GetOrderEventsQuery, GetOrderEventsQueryVariables>({
		pause: tab !== 'history',
		query: GetOrderEventsDocument,
		variables: {
			id: orderId,
		},
	});

	const { job: order } = getOrderQuery.data || {};
	const fetching = getOrderQuery.fetching || getOrderEventsQuery.fetching;
	const error = getOrderQuery.error;

	const events = useWorkflowEvents(
		getOrderEventsQuery?.data?.orderEventsV2 || [],
		'ORDER',
		order?.pickStrategy === PickStrategy.Sortation,
	);

	const copyOrderNumberButton = {
		content: messages.copyOrderNumber,
		disabled: !order?.externalId,
		icon: DuplicateMinor,
		onAction: () => copyToClipboard(order?.externalId),
	};

	const noCancel = [PickStrategy.Singles, PickStrategy.Sortation, PickStrategy.Bulk].includes(
		order?.pickStrategy as PickStrategy,
	);

	const cancelOrderButton = {
		content: messages.cancelOrder,
		disabled: !isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]) || noCancel,
		onAction: getOrderQuery.data ? openCancelModal : undefined,
	};

	// LOL-3312
	// const disableMarkAsCompleteButton =
	// 	!isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]) ||
	// 	!order?.canShipShort ||
	// 	order?.isShippedShort;

	// LOL-3312
	// const markAsCompleteButton = {
	// 	content: messages.markAsComplete,
	// 	disabled: disableMarkAsCompleteButton,
	// 	onAction: () => setIsReviewModalOpen(true),
	// };

	const isWorkAreaEnabled = useIsWorkAreasEnabled();
	// LOL-3312
	// const isInventoryTransferOrder = order?.workOrderTypes?.includes(
	// 	WorkOrderType.InventoryTransferToNode,
	// );
	const secondaryActions: ComplexAction[] = [];
	const actionGroups: MenuGroupDescriptor[] = [];

	// LOL-3312
	// if (isInventoryTransferOrder) {
	// 	secondaryActions.push(copyOrderNumberButton);
	// 	actionGroups.push({
	// 		actions: [cancelOrderButton, markAsCompleteButton],
	// 		title: messages.moreActions,
	// 	});
	// } else {
	secondaryActions.push(copyOrderNumberButton);
	secondaryActions.push(cancelOrderButton);
	// }

	if (fetching) {
		return (
			<Frame>
				<Loading />
			</Frame>
		);
	}
	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!order) {
		return <Error heading={messages.orderNotFound} />;
	}

	return (
		<AutoRefreshPage
			queries={[getOrderQuery, getOrderEventsQuery]}
			title={order.externalId}
			titleMetadata={
				<div data-testid="order-status">
					<Stack spacing="extraTight">
						<OrderStatusBadgeV2 order={order} />
					</Stack>
				</div>
			}
			additionalMetadata={<ResolutionCountText order={order} />}
			secondaryActions={secondaryActions}
			actionGroups={actionGroups}
		>
			<Tabs
				tabs={tabs}
				selected={tabIndex}
				onSelect={(tabIndex) => {
					setFilters([{ key: 'tab', value: tabs[tabIndex]?.id }]);
				}}
			>
				<Layout>
					<Layout.Section>
						<Stack vertical>
							<ErrorBanner
								isVisible={isBannerErrorVisible}
								onDismiss={() => {
									setIsBannerErrorVisible(false);
								}}
							/>
							{/* LOL-3312 */}
							<ShippedShortBanner />
							<ResolutionSummaryCalloutCard order={order} />
						</Stack>
					</Layout.Section>
					{tab === 'history' ? (
						<Layout.Section>
							<Timeline
								title={messages.orderHistory}
								events={events}
								loading={getOrderEventsQuery.fetching}
								error={getOrderEventsQuery.error}
							/>
						</Layout.Section>
					) : (
						<>
							<Layout.Section>
								<div data-testid="details">
									<Details order={order as any} />
								</div>
							</Layout.Section>
							<Layout.Section key={order.id}>
								<div data-testid="breakdown">
									<Card key={order.id}>
										<BreakdownAndIssues order={order}></BreakdownAndIssues>
										<Progress order={order} />
									</Card>
								</div>
							</Layout.Section>
							<Layout.Section>
								<div data-testid="lines">
									<Lines
										job={order}
										columns={[
											LinesTableColumn.id,
											LinesTableColumn.name,
											LinesTableColumn.pickingUnits,
											LinesTableColumn.progress,
											LinesTableColumn.exceptions,
											isWorkAreaEnabled ? LinesTableColumn.workArea : null,
										]}
										tabs={[LinesTab.all, LinesTab.exceptions]}
									/>
								</div>
							</Layout.Section>
						</>
					)}
					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</Tabs>

			<CancelOrderModal
				isOpen={isCancelModalOpen}
				onClose={closeCancelModal}
				onConfirm={cancelOrder}
				loading={cancelOrderLoading}
			/>
			<MarkAsCompleteModal
				isOpen={isReviewModalOpen}
				onClose={() => setIsReviewModalOpen(false)}
				order={order}
			/>
		</AutoRefreshPage>
	);
}
