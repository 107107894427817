// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ShipShortOrderMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ShipShortOrderMutation = { __typename?: 'Mutation', shipShortOrderV2: { __typename?: 'MutationResponse', success: boolean } };


export const ShipShortOrderDocument = gql`
    mutation fapi_ShipShortOrder($id: String!) {
  shipShortOrderV2(id: $id) {
    success
  }
}
    `;

export function useShipShortOrderMutation() {
  return Urql.useMutation<ShipShortOrderMutation, ShipShortOrderMutationVariables>(ShipShortOrderDocument);
};