import { IndexTable, TextStyle } from '@sixriver/lighthouse-web-community';
import { NoData } from '@sixriver/react-support';

import { PickWaveOrderStatus } from '../../../api/fulfillment-api/types';
import { PickWaveOrderFieldsFragment } from '../graphql/PickWaveOrderConnectionFields.f-api-graphql';

interface LinesDroppedCellProps {
	order: PickWaveOrderFieldsFragment;
}

export function LinesDroppedCell({ order }: LinesDroppedCellProps) {
	if (order.status === PickWaveOrderStatus.Success) {
		return (
			<IndexTable.Cell>
				<NoData />
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<TextStyle>{order.droppedLinesCount}</TextStyle>
		</IndexTable.Cell>
	);
}
