import { PickWaveOrderFieldsFragment } from './graphql/PickWaveOrderConnectionFields.f-api-graphql';
import { PickWaveOrderLineErrorCode, PickWaveOrderStatus } from '../../api/fulfillment-api/types';

export function isPickWaveOrderDropped(order: PickWaveOrderFieldsFragment): boolean {
	return (
		order.status === PickWaveOrderStatus.Failure ||
		order.status === PickWaveOrderStatus.PartialSuccess
	);
}

export function errorCodeToMessage(errorCode: string): string {
	if (errorCode === PickWaveOrderLineErrorCode.UnknownSourceLocation) {
		return 'Unknown source location';
	}

	return errorCode;
}
