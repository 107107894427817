// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditMapPointConfigMutationVariables = Types.Exact<{
  input: Types.MapPointConfigInput;
}>;


export type EditMapPointConfigMutation = { __typename?: 'Mutation', editMapPointConfig: { __typename?: 'MutationResponse', success: boolean } };


export const EditMapPointConfigDocument = gql`
    mutation fapi_EditMapPointConfig($input: MapPointConfigInput!) {
  editMapPointConfig(input: $input) {
    success
  }
}
    `;

export function useEditMapPointConfigMutation() {
  return Urql.useMutation<EditMapPointConfigMutation, EditMapPointConfigMutationVariables>(EditMapPointConfigDocument);
};