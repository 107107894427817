import { useState } from 'react';

import styles from './ObjectDiff.module.css';
import { ObjectDiffValue } from './ObjectDiffValue';
import { formatValue } from './helpers';
import type { ObjectDiff } from '../../utils/object-diff';

type ObjectDiffProps = {
	diffs: ObjectDiff[];
};

export function ObjectDiff({ diffs }: ObjectDiffProps) {
	return (
		<div data-testid="object-diff">
			{diffs.map((change) => (
				<ChangedObject diff={change} key={change.path.join(',')} />
			))}
		</div>
	);
}

type ChangedObjectProps = {
	diff: ObjectDiff;
};

function ChangedObject({ diff: change }: ChangedObjectProps) {
	const [expanded, setExpanded] = useState<boolean>(false);

	const oldValue = formatValue(change.oldValue);
	const newValue = formatValue(change.newValue);

	return (
		<div data-testid="object-diff-row">
			<div className={styles.Values} onClick={() => setExpanded((v) => !v)}>
				<ObjectDiffValue value={oldValue} expanded={expanded} variant="before" />
				<ObjectDiffValue value={newValue} expanded={expanded} variant="after" />
			</div>
		</div>
	);
}
