// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CloseJobFlowRuleMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type CloseJobFlowRuleMutation = { __typename?: 'Mutation', closeJobFlowRule: { __typename?: 'MutationResponse', success: boolean, referenceId?: string | null } };


export const CloseJobFlowRuleDocument = gql`
    mutation fapi_CloseJobFlowRule($id: String!) {
  closeJobFlowRule(id: $id) {
    success
    referenceId
  }
}
    `;

export function useCloseJobFlowRuleMutation() {
  return Urql.useMutation<CloseJobFlowRuleMutation, CloseJobFlowRuleMutationVariables>(CloseJobFlowRuleDocument);
};