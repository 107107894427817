import { Page } from '@sixriver/lighthouse-web-community';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { ProductForm } from './ProductForm';
import { useAddProductMutation } from './graphql/AddProduct.f-api-graphql';
import { ProductInput } from '../../api/fulfillment-api/types';
import { useLocalization } from '../../hooks/useLocalization';
import { BusyIndicatorContext } from '../../providers/BusyIndicatorProvider';
import * as routes from '../../routes';

export function AddProduct(): JSX.Element {
	const history = useHistory();
	const { messages } = useLocalization();
	const { lookBusy } = useContext(BusyIndicatorContext);

	const [addProductMutation, executeAddProductMutation] = useAddProductMutation();

	const onSubmit = async (input: ProductInput) => {
		lookBusy(true);

		const { data } = await executeAddProductMutation({ input });

		const newId = data?.addProduct?.referenceId;

		if (!newId) {
			lookBusy(false);
			return;
		}

		setTimeout(() => {
			lookBusy(false);
			history.push(routes.product(newId));
		}, 500);
	};

	return (
		<Page
			fullWidth
			title={messages.addProduct}
			breadcrumbs={[{ content: messages.products, url: routes.products() }]}
		>
			<ProductForm
				mode="add"
				onSubmit={onSubmit}
				error={addProductMutation.error}
				data={
					{
						customerIdentifier: '',
						description: '',
						height: 1,
						image: null,
						length: 1,
						name: '',
						weight: 1,
						width: 1,
					} as ProductInput
				}
			/>
		</Page>
	);
}
