// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetShuttleJobQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetShuttleJobQuery = { __typename?: 'Query', shuttleJob?: { __typename?: 'ShuttleContainer', id: string, type: string, state: string, externalId: string, createdAt?: any | null, endedAt?: any | null, lastEventPersistedAt: any, currentActor?: { __typename?: 'UserActor', user?: { __typename?: 'BasicActor', type: string, id: string } | null, device?: { __typename?: 'BasicActor', type: string, id: string } | null } | null, inputs: { __typename?: 'ContainerInputs', containerKind: string, customerData: any, containerLabels: { __typename?: 'ContainerLabels', default?: string | null } }, outputs: { __typename?: 'ContainerOutputs', collectedContainerLabel: any, takeoffDestination?: Array<string> | null, takenOffAt?: string | null }, lines: Array<{ __typename?: 'IMLine', id: string, type: string, state: string, externalId?: string | null, currentActor?: { __typename?: 'UserActor', user?: { __typename?: 'BasicActor', type: string, id: string } | null, device?: { __typename?: 'BasicActor', type: string, id: string } | null } | null, inputs: { __typename?: 'LineInputs', assetTypes: Array<any>, amount: number, customerData: any, reservationSources: Array<any> }, outputs: { __typename?: 'LineOutputs', totalCompleted: number, transfers: Array<{ __typename?: 'Transfer', location: string, assetTypeId: string, completedAmount: number, exceptions?: Array<string> | null, capturedData?: Array<{ __typename?: 'CapturedData', group: Array<any> }> | null }> } }> } | null };


export const GetShuttleJobDocument = gql`
    query wapi_GetShuttleJob($id: String!) {
  shuttleJob(id: $id) {
    id
    type
    state
    externalId
    createdAt
    endedAt
    lastEventPersistedAt
    currentActor {
      user {
        type
        id
      }
      device {
        type
        id
      }
    }
    inputs {
      containerLabels {
        default
      }
      containerKind
      customerData
    }
    outputs {
      collectedContainerLabel
      takeoffDestination
      takenOffAt
    }
    lines {
      id
      type
      state
      externalId
      currentActor {
        user {
          type
          id
        }
        device {
          type
          id
        }
      }
      inputs {
        assetTypes
        amount
        customerData
        reservationSources
      }
      outputs {
        totalCompleted
        transfers {
          location
          assetTypeId
          completedAmount
          exceptions
          capturedData {
            group
          }
        }
      }
    }
  }
}
    `;

export function useGetShuttleJobQuery(options: Omit<Urql.UseQueryArgs<GetShuttleJobQueryVariables>, 'query'>) {
  return Urql.useQuery<GetShuttleJobQuery, GetShuttleJobQueryVariables>({ query: GetShuttleJobDocument, ...options });
};