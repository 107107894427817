// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CutoffForOrdersV2QueryVariables = Types.Exact<{
  createdAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  datePrecision: Types.DatePrecision;
}>;


export type CutoffForOrdersV2Query = { __typename?: 'Query', cutoffForOrdersV2: { __typename?: 'Cutoff', dates: Array<any> } };


export const CutoffForOrdersV2Document = gql`
    query fapi_CutoffForOrdersV2($createdAtFrom: DateTime, $datePrecision: DatePrecision!) {
  cutoffForOrdersV2(datePrecision: $datePrecision, createdAtFrom: $createdAtFrom) {
    dates
  }
}
    `;

export function useCutoffForOrdersV2Query(options: Omit<Urql.UseQueryArgs<CutoffForOrdersV2QueryVariables>, 'query'>) {
  return Urql.useQuery<CutoffForOrdersV2Query, CutoffForOrdersV2QueryVariables>({ query: CutoffForOrdersV2Document, ...options });
};