// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/edge/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeliveryAttemptFragment = { __typename?: 'MessageDeliveryAttempt', id: string, attemptNumber: number, workerId: string, requestError?: string | null, sendResult?: Types.AttemptResultCode | null, startedAt: any, endedAt?: any | null, delivery: { __typename?: 'MessageDelivery', id: string }, data?: { __typename?: 'HttpMessageDeliveryAttemptData', type: Types.HookTransportType, status?: number | null, body?: any | null } | { __typename?: 'SftpMessageDeliveryAttemptData', type: Types.HookTransportType, body?: any | null } | null };

export type GetDeliveryAttemptsQueryVariables = Types.Exact<{
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.MessageDeliveryAttemptFilter>;
  orderDirection?: Types.InputMaybe<Types.SortDirection>;
}>;


export type GetDeliveryAttemptsQuery = { __typename?: 'Query', getDeliveryAttempts: { __typename?: 'MessageDeliveryAttemptConnection', edges: Array<{ __typename?: 'MessageDeliveryAttemptEdge', cursor: string, node: { __typename?: 'MessageDeliveryAttempt', id: string, attemptNumber: number, workerId: string, requestError?: string | null, sendResult?: Types.AttemptResultCode | null, startedAt: any, endedAt?: any | null, delivery: { __typename?: 'MessageDelivery', id: string }, data?: { __typename?: 'HttpMessageDeliveryAttemptData', type: Types.HookTransportType, status?: number | null, body?: any | null } | { __typename?: 'SftpMessageDeliveryAttemptData', type: Types.HookTransportType, body?: any | null } | null } }>, pageInfo: { __typename?: 'MessageDeliveryAttemptPageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } };

export const DeliveryAttemptFragmentDoc = gql`
    fragment DeliveryAttempt on MessageDeliveryAttempt {
  id
  delivery {
    id
  }
  attemptNumber
  workerId
  requestError
  sendResult
  startedAt
  endedAt
  data {
    ... on HttpMessageDeliveryAttemptData {
      type
      status
      body
    }
    ... on SftpMessageDeliveryAttemptData {
      type
      body
    }
  }
}
    `;
export const GetDeliveryAttemptsDocument = gql`
    query edge_GetDeliveryAttempts($before: String, $after: String, $first: Int, $last: Int, $filter: MessageDeliveryAttemptFilter, $orderDirection: SortDirection) {
  getDeliveryAttempts(
    before: $before
    after: $after
    first: $first
    last: $last
    filter: $filter
    order: {orderField: StartedAt, sortDirection: $orderDirection}
  ) {
    edges {
      node {
        ...DeliveryAttempt
      }
      cursor
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${DeliveryAttemptFragmentDoc}`;

export function useGetDeliveryAttemptsQuery(options?: Omit<Urql.UseQueryArgs<GetDeliveryAttemptsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDeliveryAttemptsQuery, GetDeliveryAttemptsQueryVariables>({ query: GetDeliveryAttemptsDocument, ...options });
};