import { FakeHandheld } from './FakeHandheld';
import { Idle } from './Idle';
import './preview.css';

interface MfaPreviewProps {
	reservationId?: string;
}

export function MfaPreview({ reservationId }: MfaPreviewProps) {
	const useLatest = window.location.hostname !== 'localhost';
	const url = import.meta.env.VITE_MFP_UI_URL.replace(
		'{HOSTNAME}',
		window.location.hostname,
	).concat(useLatest ? '/latest/' : '', `?reservation=${reservationId}`);

	return (
		<FakeHandheld>
			{reservationId ? <iframe className="mfaPreview" src={url} /> : <Idle />}
		</FakeHandheld>
	);
}
