import { Button } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';

import { PickWaveFieldsFragment } from './graphql/PickWaveFields.f-api-graphql';
import { PickWaveStatus } from '../../api/fulfillment-api/types';

interface ResolvePickWaveButtonProps {
	pickWave: PickWaveFieldsFragment;
	onClick: () => void;
}

export function ResolvePickWaveButton({ pickWave, onClick }: ResolvePickWaveButtonProps) {
	const [disabled] = useState(isDisabled(pickWave));
	return (
		<Button primary disabled={disabled} onClick={onClick}>
			Mark as resolved
		</Button>
	);
}

function isDisabled(pickWave: PickWaveFieldsFragment): boolean {
	if (pickWave.resolvedAt) {
		return true;
	}

	if (pickWave.status === PickWaveStatus.Success) {
		return true;
	}

	return false;
}
