// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOutboundJobsCutoffsQueryVariables = Types.Exact<{
  datePrecision: Types.DatePrecision;
  createdAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type GetOutboundJobsCutoffsQuery = { __typename?: 'Query', cutoffForJobsV2: { __typename?: 'Cutoff', dates: Array<any> } };


export const GetOutboundJobsCutoffsDocument = gql`
    query fapi_GetOutboundJobsCutoffs($datePrecision: DatePrecision!, $createdAtFrom: DateTime) {
  cutoffForJobsV2(datePrecision: $datePrecision, createdAtFrom: $createdAtFrom) {
    dates
  }
}
    `;

export function useGetOutboundJobsCutoffsQuery(options: Omit<Urql.UseQueryArgs<GetOutboundJobsCutoffsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOutboundJobsCutoffsQuery, GetOutboundJobsCutoffsQueryVariables>({ query: GetOutboundJobsCutoffsDocument, ...options });
};