import { getPickingJobs } from './order';
import {
	HealPlan,
	Line,
	Order,
	OrderServicedBy,
	OrderStatus,
	Picking,
	PickingJobStatus,
	PickStrategy,
} from '../api/fulfillment-api/types';
import { GetOutboundJobLineFragment } from '../pages/OutboundJob/graphql/GetLines.w-api-graphql';

export const sortJobs = (jobs?: OrderServicedBy[] | null) =>
	(jobs || [])
		.filter((job) => job?.id)
		.sort((jobA, jobB) =>
			new Date(jobB?.timeline?.createdAt as string) < new Date(jobA?.timeline?.createdAt as string)
				? 1
				: -1,
		);

export function getFirstPickingJob(servicedBy?: OrderServicedBy[] | null): Picking | undefined {
	const pickingJobs = getPickingJobs(servicedBy);
	const sortedPickingJobs = sortJobs(pickingJobs);
	return sortedPickingJobs[0] as Picking;
}

export function getMostRecentPickingJob(
	servicedBy?: OrderServicedBy[] | null,
): Picking | undefined {
	const pickingJobs = getPickingJobs(servicedBy);
	const sortedPickingJobs = sortJobs(pickingJobs);
	return sortedPickingJobs[sortedPickingJobs.length - 1] as Picking;
}

export function getReadyHealPlan(servicedBy?: OrderServicedBy[] | null) {
	return (servicedBy || []).find(
		(job) => job.__typename === 'HealPlan' && job.status === 'READY',
	) as HealPlan | undefined;
}

export function isLineException(line?: Line) {
	if (line) {
		return !!line.timeline?.completedAt && line.doneQuantity < line.quantity;
	}
	return false;
}

export function countLineExceptions(lines?: Line[]) {
	const exceptionLines = (lines || []).filter((line) => isLineException(line));
	return exceptionLines.length;
}

export function countLineExceptionsV2(lines?: GetOutboundJobLineFragment[]) {
	const exceptionLines = (lines || []).filter((line) => line.isShorted);
	return exceptionLines.length;
}

export function countUnitExceptionsV2(lines?: GetOutboundJobLineFragment[]) {
	const exceptionLines = (lines || []).filter((line) => line.isShorted);
	return exceptionLines.reduce((sum, acc) => (sum += acc.quantity - acc.doneQuantity), 0);
}

export function countSortationPickingExceptions(pickingJobs?: Picking[]) {
	return (pickingJobs || []).reduce<number>((numberOfExceptions, pickingJob) => {
		const lineExceptions = countLineExceptions(pickingJob.lines);
		if (lineExceptions > 0) {
			return numberOfExceptions + lineExceptions;
		}

		if (
			pickingJob.pickingStatus === PickingJobStatus.Exception ||
			pickingJob.pickingStatus === PickingJobStatus.Offline
		) {
			return numberOfExceptions + 1;
		}

		return numberOfExceptions;
	}, 0);
}

export function countSortationOrderExceptions(orders?: Order[]) {
	return (orders || []).reduce<number>((numberOfExceptions, order) => {
		const lineExceptions = countLineExceptions(order.lines);
		if (lineExceptions > 0) {
			return numberOfExceptions + lineExceptions;
		}

		if (order.status === OrderStatus.Exception) {
			return numberOfExceptions + 1;
		}

		return numberOfExceptions;
	}, 0);
}

export function getExceptionLines(order?: Order) {
	return order?.lines;
}

export function isExceptionActionable(
	packoutEnabled = false,
	healingEnabled = false,
	job?: Order | Picking,
): boolean {
	// If singles order, attempt to get packout from servicing picking job
	const packout =
		job?.type === PickStrategy.Singles && job?.__typename === 'Order' && packoutEnabled
			? (job.servicedBy[0] as Picking).packout
			: undefined;
	const healPlan = (job as Order)?.healPlan;
	/**
	 * Consider exception actionable if:
	 * - At any moment if pick strategy is not singles
	 * - For singles if packout is enabled only at packout, otherwise at any moment
	 */
	return (
		job?.type !== PickStrategy.Singles ||
		((!!packout || !packoutEnabled) && (!!healPlan || !healingEnabled))
	);
}
export function isExceptionActionableV2(job?: { isHealable: boolean }): boolean {
	return !!job?.isHealable;
}
