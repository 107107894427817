import { IndexTable, TextContainer, TextStyle } from '@sixriver/lighthouse-web-community';

export function UnitsCell({
	totalLineQuantities,
}: {
	totalLineQuantities: {
		pickedUnits: number;
		totalUnits: number;
	};
}) {
	return (
		<IndexTable.Cell>
			<TextContainer>
				<TextStyle>
					{totalLineQuantities?.pickedUnits} of {totalLineQuantities?.totalUnits}
				</TextStyle>
			</TextContainer>
		</IndexTable.Cell>
	);
}
